const Parser = require("../lib/dutils.js").Parser;

const levelXPTarget = [
    null,
    {easy:25, medium:50, hard:75, deadly:100}, // 1
    {easy:50, medium:100, hard:150, deadly:200}, // 2
    {easy:75, medium:150, hard:225, deadly:400}, // 3
    {easy:125, medium:250, hard:375, deadly:500}, // 4
    {easy:250, medium:500, hard:750, deadly:1100}, // 5
    {easy:300, medium:600, hard:900, deadly:1400}, // 6
    {easy:350, medium:750, hard:1100, deadly:1700}, // 7
    {easy:450, medium:900, hard:1400, deadly:2100}, // 8
    {easy:550, medium:1100, hard:1600, deadly:2400}, // 9
    {easy:600, medium:1200, hard:1900, deadly:2800}, // 10
    {easy:800, medium:1600, hard:2400, deadly:3600}, // 11
    {easy:1000, medium:2000, hard:3000, deadly:4500}, // 12
    {easy:1100, medium:2200, hard:3400, deadly:5100}, // 13
    {easy:1250, medium:2500, hard:3800, deadly:5700}, // 14
    {easy:1400, medium:2800, hard:4300, deadly:6400}, // 15
    {easy:1600, medium:3200, hard:4800, deadly:7200}, // 16
    {easy:2000, medium:3900, hard:5900, deadly:8800}, // 17
    {easy:2100, medium:4200, hard:6300, deadly:9500}, // 18
    {easy:2400, medium:4900, hard:7300, deadly:10900}, // 19
    {easy:2800, medium:5700, hard:8500, deadly:12700}, // 20
];

const levelList = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
const bonusTen = ["+10","+9","+8","+7","+6","+5","+4","+3","+2","+1","0","-1","-2","-3","-4","-5"];
const bonus=bonusTen;
const proficiencyTypes=["none", "half", "proficient", "expert"];

const spellDCAttack = {
    "character":"character",
    1:"DC1 / -7",
    2:"DC2 / -6",
    3:"DC3 / -5",
    4:"DC4 / -4",
    5:"DC5 / -3",
    6:"DC6 / -2",
    7:"DC7 / -1",
    8:"DC8 / +0",
    9:"DC9 / +1",
    10:"DC10 / +2",
    11:"DC11 / +3",
    12:"DC12 / +4",
    13:"DC13 / +5",
    14:"DC14 / +6",
    15:"DC15 / +7",
    16:"DC16 / +8",
    17:"DC17 / +9",
    18:"DC18 / +10",
    19:"DC19 / +11",
    20:"DC20 / +12",
}

const crStats = {
    "0":{ profbonus:2, ac:13, hpmin:1, hpmax:6, atkbonus:3, damagemin:0, damagemax:1, savedc:13, xp:10, hpResistMult:2, hpImmunMult:2},
    "1/8":{ profbonus:2, ac:13, hpmin:7, hpmax:35, atkbonus:3, damagemin:2, damagemax:3, savedc:13, xp:25, hpResistMult:2, hpImmunMult:2},
    "1/4":{ profbonus:2, ac:13, hpmin:36, hpmax:49, atkbonus:3, damagemin:4, damagemax:5, savedc:13, xp:50, hpResistMult:2, hpImmunMult:2},
    "1/2":{ profbonus:2, ac:13, hpmin:50, hpmax:70, atkbonus:3, damagemin:6, damagemax:8, savedc:13, xp:100, hpResistMult:2, hpImmunMult:2},
    "1":{ profbonus:2, ac:13, hpmin:71, hpmax:85, atkbonus:3, damagemin:9, damagemax:14, savedc:13, xp:200, hpResistMult:2, hpImmunMult:2},
    "2":{ profbonus:2, ac:13, hpmin:86, hpmax:100, atkbonus:3, damagemin:15, damagemax:20, savedc:13, xp:450, hpResistMult:2, hpImmunMult:2},
    "3":{ profbonus:2, ac:13, hpmin:101, hpmax:115, atkbonus:4, damagemin:21, damagemax:26, savedc:13, xp:700, hpResistMult:2, hpImmunMult:2},
    "4":{ profbonus:2, ac:14, hpmin:116, hpmax:130, atkbonus:5, damagemin:27, damagemax:32, savedc:14, xp:1100, hpResistMult:2, hpImmunMult:2},
    "5":{ profbonus:3, ac:15, hpmin:131, hpmax:145, atkbonus:6, damagemin:33, damagemax:38, savedc:15, xp:1800, hpResistMult:1.5, hpImmunMult:2},
    "6":{ profbonus:3, ac:15, hpmin:146, hpmax:160, atkbonus:6, damagemin:39, damagemax:44, savedc:15, xp:2300, hpResistMult:1.5, hpImmunMult:2},
    "7":{ profbonus:3, ac:15, hpmin:161, hpmax:175, atkbonus:6, damagemin:45, damagemax:50, savedc:15, xp:2900, hpResistMult:1.5, hpImmunMult:2},
    "8":{ profbonus:3, ac:16, hpmin:176, hpmax:190, atkbonus:7, damagemin:51, damagemax:56, savedc:16, xp:3900, hpResistMult:1.5, hpImmunMult:2},
    "9":{ profbonus:4, ac:16, hpmin:191, hpmax:205, atkbonus:7, damagemin:57, damagemax:62, savedc:16, xp:5000, hpResistMult:1.5, hpImmunMult:2},
    "10":{ profbonus:4, ac:17, hpmin:206, hpmax:220, atkbonus:7, damagemin:63, damagemax:68, savedc:16, xp:5900, hpResistMult:1.5, hpImmunMult:2},
    "11":{ profbonus:4, ac:17, hpmin:221, hpmax:235, atkbonus:8, damagemin:69, damagemax:74, savedc:17, xp:7200, hpResistMult:1.25, hpImmunMult:1.5},
    "12":{ profbonus:4, ac:17, hpmin:236, hpmax:250, atkbonus:8, damagemin:75, damagemax:80, savedc:17, xp:8400, hpResistMult:1.25, hpImmunMult:1.5},
    "13":{ profbonus:5, ac:18, hpmin:251, hpmax:265, atkbonus:8, damagemin:81, damagemax:86, savedc:18, xp:10000, hpResistMult:1.25, hpImmunMult:1.5},
    "14":{ profbonus:5, ac:18, hpmin:266, hpmax:280, atkbonus:8, damagemin:87, damagemax:92, savedc:18, xp:11500, hpResistMult:1.25, hpImmunMult:1.5},
    "15":{ profbonus:5, ac:18, hpmin:281, hpmax:295, atkbonus:8, damagemin:93, damagemax:98, savedc:18, xp:13000, hpResistMult:1.25, hpImmunMult:1.5},
    "16":{ profbonus:5, ac:18, hpmin:296, hpmax:310, atkbonus:9, damagemin:99, damagemax:104, savedc:18, xp:15000, hpResistMult:1.25, hpImmunMult:1.5},
    "17":{ profbonus:6, ac:19, hpmin:311, hpmax:325, atkbonus:10, damagemin:105, damagemax:110, savedc:19, xp:18000, hpResistMult:1, hpImmunMult:1.25},
    "18":{ profbonus:6, ac:19, hpmin:326, hpmax:340, atkbonus:10, damagemin:111, damagemax:116, savedc:19, xp:20000, hpResistMult:1, hpImmunMult:1.25},
    "19":{ profbonus:6, ac:19, hpmin:341, hpmax:355, atkbonus:10, damagemin:117, damagemax:122, savedc:19, xp:22000, hpResistMult:1, hpImmunMult:1.25},
    "20":{ profbonus:6, ac:19, hpmin:356, hpmax:400, atkbonus:10, damagemin:123, damagemax:140, savedc:19, xp:25000, hpResistMult:1, hpImmunMult:1.25},
    "21":{ profbonus:7, ac:19, hpmin:401, hpmax:445, atkbonus:11, damagemin:141, damagemax:158, savedc:20, xp:33000, hpResistMult:1, hpImmunMult:1.25},
    "22":{ profbonus:7, ac:19, hpmin:446, hpmax:490, atkbonus:11, damagemin:159, damagemax:176, savedc:20, xp:41000, hpResistMult:1, hpImmunMult:1.25},
    "23":{ profbonus:7, ac:19, hpmin:491, hpmax:535, atkbonus:11, damagemin:177, damagemax:194, savedc:20, xp:50000, hpResistMult:1, hpImmunMult:1.25},
    "24":{ profbonus:7, ac:19, hpmin:536, hpmax:580, atkbonus:12, damagemin:195, damagemax:212, savedc:21, xp:62000, hpResistMult:1, hpImmunMult:1.25},
    "25":{ profbonus:8, ac:19, hpmin:581, hpmax:625, atkbonus:12, damagemin:213, damagemax:230, savedc:21, xp:75000, hpResistMult:1, hpImmunMult:1.25},
    "26":{ profbonus:8, ac:19, hpmin:626, hpmax:670, atkbonus:12, damagemin:231, damagemax:248, savedc:21, xp:90000, hpResistMult:1, hpImmunMult:1.25},
    "27":{ profbonus:8, ac:19, hpmin:671, hpmax:715, atkbonus:13, damagemin:249, damagemax:266, savedc:22, xp:105000, hpResistMult:1, hpImmunMult:1.25},
    "28":{ profbonus:8, ac:19, hpmin:716, hpmax:760, atkbonus:13, damagemin:267, damagemax:284, savedc:22, xp:120000, hpResistMult:1, hpImmunMult:1.25},
    "29":{ profbonus:9, ac:19, hpmin:761, hpmax:805, atkbonus:13, damagemin:285, damagemax:302, savedc:22, xp:135000, hpResistMult:1, hpImmunMult:1.25},
    "30":{ profbonus:9, ac:19, hpmin:806, hpmax:850, atkbonus:14, damagemin:303, damagemax:320, savedc:23, xp:155000, hpResistMult:1, hpImmunMult:1.25},
}

const crLevelMap=[
    {cr:"0", crsort:0},
    {cr:"1/4", crsort:0.25},
    {cr:"1/2", crsort:0.5},
    {cr:"1", crsort:1},
    {cr:"1", crsort:1},
    {cr:"2", crsort:2},
    {cr:"2", crsort:2},
    {cr:"3", crsort:3},
    {cr:"4", crsort:4},
    {cr:"5", crsort:5},
    {cr:"6", crsort:6},
    {cr:"7", crsort:7},
    {cr:"8", crsort:8},
    {cr:"9", crsort:9},
    {cr:"10", crsort:10},
    {cr:"11", crsort:11},
    {cr:"12", crsort:12},
    {cr:"13", crsort:13},
    {cr:"14", crsort:14},
    {cr:"15", crsort:15},
    {cr:"16", crsort:16},
];

const conditionList = [
    "blinded",
    "charmed",
    "deafened",
    "exhaustion",
    "frightened",
    "grappled",
    "incapacitated",
    "invisible",
    "paralyzed",
    "petrified",
    "poisoned",
    "prone",
    "restrained",
    "stunned",
    "unconscious",
];

const conditionExtraList = [
    "rage",
    "dread",
    "insanity",
    "inspiration"
];

const damageTypesList = [
    "acid",
    "bludgeoning",
    "cold",
    "fire",
    "force",
    "lightning",
    "necrotic",
    "piercing",
    "poison",
    "psychic",
    "radiant",
    "slashing",
    "thunder"
];

const extraEffectsList = [
    "heal",
    "reduce damage",
    "bonus",
    "penalty",
    "max hp"
];

const actionTypeMap={
    "action":"A",
    "attack":"ATK",
    "bonus action":"BA",
    "reaction":"RA",
    "free action":"FA",
    "long rest":"LR",
    "short rest":"SR"
}
const upperActionTypeMap={
    "action":"Action",
    "attack":"Attack",
    "bonus action":"Bonus Action",
    "reaction":"Reaction",
    "free action":"Free Action",
    "long rest":"Long Rest",
    "short rest":"Short Rest"
}


const extraEffectsDamageList = ["damage"].concat(extraEffectsList);

const sensesList = [
    "blindsight",
    "darkvision",
    "keensense",
    "tremorsense",
    "truesight",
    "keen smell"
];

const abilities = [
    "str",
    "dex",
    "con",
    "int",
    "wis",
    "cha"
];

const abilitiesPlus = [
    "str",
    "dex",
    "con",
    "int",
    "wis",
    "cha",
    "str1",
    "dex1",
    "con1",
    "int1",
    "wis1",
    "cha1"
]

const abilityNames = {
    "str":"strength",
    "dex":"dexterity",
    "con":"constitution",
    "int":"intelligence",
    "wis":"wisdom",
    "cha":"charisma"
};

const abilityLongNames = {
    "strength":"str",
    "dexterity":"dex",
    "constitution":"con",
    "intelligence":"int",
    "wisdom":"wis",
    "charisma":"cha"
};

const abilityNamesScores = [
    {name:"strength", value:"str"},
    {name:"dexterity", value:"dex"},
    {name:"constitution", value:"con"},
    {name:"intelligence", value:"int"},
    {name:"wisdom", value:"wis"},
    {name:"charisma", value:"cha"},
    {name:"DC20", value:"20"},
    {name:"DC19", value:"19"},
    {name:"DC18", value:"18"},
    {name:"DC17", value:"17"},
    {name:"DC16", value:"16"},
    {name:"DC15", value:"15"},
    {name:"DC14", value:"14"},
    {name:"DC13", value:"13"},
    {name:"DC12", value:"12"},
    {name:"DC11", value:"11"},
    {name:"DC10", value:"10"},
    {name:"DC9", value:"9"},
    {name:"DC8", value:"8"},
];

const defaultShapeAbilities = [
    "str",
    "dex",
    "con",
];

const abilityNamesScoresVals = ["str","dex","con","int","wis","cha","20","19","18","17","16","15","14","13","12","11","10","9","8"];

const abilityNamesFull = [
    "strength",
    "dexterity",
    "constitution",
    "intelligence",
    "wisdom",
    "charisma"
];

const abilityModFromSpellDC={
    11:1,
    12:1,
    13:2,
    14:2,
    15:3,
    16:3,
    17:4,
    18:4,
    19:5,
    20:5
}

const skillVals = [
    {mod:'dex', skill:"Acrobatics"},
    {mod:'wis', skill:"Animal Handling"},
    {mod:'int', skill:"Arcana"},
    {mod:'str', skill:"Athletics"},
    {mod:'cha', skill:"Deception"},
    {mod:'int', skill:"History"},
    {mod:'wis', skill:"Insight"},
    {mod:'cha', skill:"Intimidation"},
    {mod:'int', skill:"Investigation"},
    {mod:'wis', skill:"Medicine"},
    {mod:'int', skill:"Nature"},
    {mod:'wis', skill:"Perception"},
    {mod:'cha', skill:"Performance"},
    {mod:'cha', skill:"Persuasion"},
    {mod:'int', skill:"Religion"},
    {mod:'dex', skill:"Sleight of Hand"},
    {mod:'dex', skill:"Stealth"},
    {mod:'wis', skill:"Survival"}
];

const skillLookup = {
    "Acrobatics":'dex',
    "Animal Handling":"wis",
    "Arcana":"int",
    "Athletics":"str",
    "Deception":"cha",
    "History":"int",
    "Insight":"wis",
    "Intimidation":"cha",
    "Investigation":"int",
    "Medicine":"wis",
    "Nature":"int",
    "Perception":"wis",
    "Performance":"cha",
    "Persuasion":"cha",
    "Religion":"int",
    "Sleight of Hand":"dex",
    "Stealth":"dex",
    "Survival":"wis"
};

const skillsList = [
    "Acrobatics",
    "Animal Handling",
    "Arcana",
    "Athletics",
    "Deception",
    "History",
    "Insight",
    "Intimidation",
    "Investigation",
    "Medicine",
    "Nature",
    "Perception",
    "Performance",
    "Persuasion",
    "Religion",
    "Sleight of Hand",
    "Stealth",
    "Survival"
];

const proficiencyMap = {
    "light":"light armor",
    "medium":"medium armor",
    "heavy":"heavy armor",
    "simple":"simple weapons",
    "martial":"martial weapons",
    "Acrobatics":"{@skill Acrobatics}",
    "Animal Handling":"{@skill Animal Handling}",
    "Arcana":"{@skill Arcana}",
    "Athletics":"{@skill Athletics}",
    "Deception":"{@skill Deception}",
    "History":"{@skill History}",
    "Insight":"{@skill Insight}",
    "Intimidation":"{@skill Intimidation}",
    "Investigation":"{@skill Investigation}",
    "Medicine":"{@skill Medicine}",
    "Nature":"{@skill Nature}",
    "Perception":"{@skill Perception}",
    "Performance":"{@skill Performance}",
    "Persuasion":"{@skill Persuasion}",
    "Religion":"{@skill Religion}",
    "Sleight of Hand":"{@skill Sleight of Hand}",
    "Stealth":"{@skill Stealth}",
    "Survival":"{@skill Survival}"
}

const durationSuggestions=[
    "none",
    "1 round",
    "2 rounds",
    "3 rounds",
    "4 rounds",
    "5 rounds",
    "6 rounds",
    "7 rounds",
    "8 rounds",
    "9 rounds",
    "10 rounds",
    "1 minute",
    "10 minutes",
    "1 hour",
    "4 hours",
    "8 hours",
];

function upperFirst(str){
    if (!str || str.length==0)
        return str;
    return str.slice(0,1).toUpperCase()+str.slice(1);
}

function joinCommaAnd(list,sep) {
    if (!list || !list.length) {
        return "";
    }
    let ret = list[0];

    if (list.length==1) {
        return ret;
    }

    for (let i=1; i< (list.length-1); i++) {
        ret = ret+", "+list[i];
    }

    return ret+(sep||" and ")+list[list.length-1];
}

function pluralString(string, count, map) {
    if (!count || !string) {
        return "";
    }
    if ((count > 1) && string.length && !string.match(/[0-9]$/)) {
        if (!string.match(/[hs]$/)) {
            string=string+"s";
        } else {
            string = string+"es";
        }
    }
    if (!map) {
        return string;
    }
    return (map[count]||(count+" "))+string;
}

function signedNum(n) {
    if (n<0) {
        return n.toString();
    } else {
        return "+"+n;
    }
}


function getProficiencyString (prof) {
    if (!prof) {
        return "None.";
    }
    if (Array.isArray(prof)) {
        if (!prof.length) {
            return "None."
        }
        return upperFirst(prof.map(it => {return proficiencyMap[it]||it}).join(", "))+".";
    }

    const numString =  Parser.numberToString(prof.choose);
    const defaults = (prof.defaults||[]).map(it => {return proficiencyMap[it]||it}).join(", ");
    const from = (prof.from||[]).map(it => {return proficiencyMap[it]||it}).join(", ");

    if (!from.length) {
        if (!defaults.length) {
            return `Any ${numString}.`;
        } else {
            return  upperFirst(`${defaults} and any ${numString}.`);
        }
    }

    if (!defaults.length) {
        return `Choose ${numString} from ${from}.`;
    } else {
        return  upperFirst(`${defaults} and choose ${numString} from ${from}.`);
    }
}


const monsterSkillsMap = {
    "acrobatics":"Acrobatics",
    "animal handling":"Animal Handling",
    "arcana":"Arcana",
    "athletics":"Athletics",
    "deception":"Deception",
    "history":"History",
    "insight":"Insight",
    "intimidation":"Intimidation",
    "investigation":"Investigation",
    "medicine":"Medicine",
    "nature":"Nature",
    "perception":"Perception",
    "performance":"Performance",
    "persuasion":"Persuasion",
    "religion":"Religion",
    "sleight of hand":"Sleight of Hand",
    "stealth":"Stealth",
    "survival":"Survival"
}

function getNumRange(min, max) {
    const res = [];
    for (let i=min; i<=max; i++) {
        res.push(i);
    }
    return res;
}

const abilitiesList = getNumRange(1, 30);

const abilitiesListWithZero = getNumRange(0, 30);

const perceptionValuesList = getNumRange(1, 30);

const armorClassValuesList = getNumRange(0, 30);

const initiativeValuesList = {
    "--":"--",0:0,1:1,2:2,3:3,4:4,5:5,6:6,7:7,8:8,9:9,10:10,11:11,12:12,13:13,14:14,15:15,16:16,17:17,18:18,19:19,20:20,21:21,22:22,23:23,24:24,25:25,26:26,27:27,28:28,29:29,30:30
};

const conditionDurationValuesList = [
    "none", 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30
];

const conditionLevelsList = [
    "none", 1,2,3,4,5,6,7,8,9
];

const zeroTo20 = getNumRange(0, 20);

const oneTo30 = getNumRange(1, 30);

const oneTo50 = getNumRange(1, 50);

const oneTo20 = getNumRange(1, 20);


const languagesList = [
    "All",
    "Common",
    "Common Sign Language",
    "Draconic",
    "Dwarvish",
    "Elvish",
    "Giant",
    "Gnomish",
    "Goblin",
    "Halfling",
    "Orc",

    "Abyssal",
    "Celestial",
    "Deep Speech",
    "Druidic",
    "Infernal",
    "Minotaur",
    "Primordial",
    "Sylvan",
    "Thieves' Cant",
    "Undercommon",

    "Aquan",
    "Auran",
    "Ignan",
    "Terran",
/*
    "Aarakocra",
    "Blink Dog",
    "Bothii",
    "Bullywug",
    "Giant Eagle",
    "Giant Elk",
    "Giant Owl",
    "Gith",
    "Gnoll",
    "Grell",
    "Grung",
    "Hook Horror",
    "Ice Toad",
    "Ixitxachitl",
    "Kraul",
    "Loxodon",
    "Modron",
    "Netherese",
    "Olman",
    "Otyugh",
    "Quori",
    "Sahuagin",
    "Slaad",
    "Sphinx",
    "Telepathy",
    "Thayan",
    "Thri-kreen",
    "Tlincalli",
    "Troglodyte",
    "Umber Hulk",
    "Vedalken",
    "Vegepygmy",
    "Winter Wolf",
    "Worg",
    "Yeti",
    "Yikaria",
*/
]

const sizeList=[
    {name:"Tiny", value:"T"},
    {name:"Small", value:"S"},
    {name:"Medium", value:"M"},
    {name:"Large", value:"L"},
    {name:"Huge", value:"H"},
    {name:"Gargantuan", value:"G"},
];

const sizeSelectList=[
    {name:"Tiny", value:"T"},
    {name:"Small", value:"S"},
    {name:"Medium", value:"M"},
    {name:"Large", value:"L"},
    {name:"Huge", value:"H"},
    {name:"Gargantuan", value:"G"},
    {name:"User Pick", value:"V"},
];

const simpleMonsterTypes = [
    'aberration',
    'beast',
    'celestial',
    'construct',
    'dragon',
    'elemental',
    'fey',
    'fiend',
    'giant',
    'humanoid',
    'monstrosity',
    'ooze',
    'plant',
    'swarm',
    'undead',
    'vehicle'
];

const allignmentTypeList = [
    {name:'Any alignment', value:["A"]},
    {name:'Good', value:["G"]},
    {name:'Evil', value:["E"]},
    {name:'Chaotic Evil', value:["C","E"]},
    {name:'Chaotic Good', value:["C","G"]},
    {name:'Chaotic Neutral', value:["C","N"]},
    {name:'Lawful Evil', value:["L","E"]},
    {name:'Lawful Good', value:["L","G"]},
    {name:'Lawful Neutral', value:["L","N"]},
    {name:'Neutral', value:["N"]},
    {name:'Neutral Evil', value:["N","E"]},
    {name:'Neutral Good', value:["N","G"]},
    {name:'Unaligned', value:["U"]},
    {name:'None', value:null},
];

const allignmentTypes = [
    'Chaotic Evil',
    'Chaotic Good',
    'Chaotic Neutral',
    'Lawful Evil',
    'Lawful Good',
    'Lawful Neutral',
    'Neutral',
    'Neutral Evil',
    'Neutral Good',
    'Unaligned',
];

const usageTypes = {
    "long":"recover after a long rest",
    "short":"recover after a short rest",
    "random":"recover a random amount after a long rest",
    "none":"does not recover"
}

const environmentTypeList = [
    "arctic",
    "badland",
    "coastal",
    "desert",
    "farmland",
    "forest",
    "grassland",
    "hill",
    "mountain",
    "planar",
    "swamp",
    "underdark",
    "underwater",
    "urban",
];

const sizeScaleMap = {
    T:0.65,
    S:0.85,
    M:1,
    L:2,
    H:3,
    G:4,
};

const itemTypeFromAbbreviation = 
{
    "$": "Treasure",
	"A": "Ammunition",
	"AF": "Ammunition",
	"AT": "Artisan Tool",
    "coin":"Currency",
	"EM": "Eldritch Machine",
	"EXP": "Explosive",
	"G": "Adventuring Gear",
	"GS": "Gaming Set",
	"HA": "Armor",
	"INS": "Instrument",
	"LA": "Armor",
	"M": "Weapon",
	"MA": "Armor",
	"MNT": "Mount",
	"GV": "Generic Variant",
	"P": "Potion",
	"R": "Weapon",
	"RD": "Rod",
	"RG": "Ring",
	"S": "Armor",
	"SC": "Scroll",
	"SCF": "Spellcasting Focus",
	"STAFF": "Staff",
	"OTH": "Other",
	"T": "Tool",
	"TAH": "Tack and Harness",
	"TG": "Trade Good",
	"VEH": "Vehicle",
    "SHP": "Vehicle",
    "WON":"Wondrous Item",
	"WD": "Wand"
};

const itemTypeSelect = 
[
	"Armor",
	"Weapon",
	"Adventuring Gear",
	"Artisan Tool",
    "Currency",
	"Gaming Set",
	"Instrument",
	"Mount",
	"Potion",
	"Ring",
	"Rod",
	"Spellcasting Focus",
	"Tack and Harness",
	"Tool",
	"Trade Good",
    "Treasure",
	"Scroll",
	"Wand",
	"Eldritch Machine",
	"Explosive",
	"Other",
    "Vehicle",
    "Wondrous",
];


const itemTypes = [
    {
        "abbreviation": "A",
        "entries": [
            {
                "type": "entries",
                "name": "Ammunition",
                "entries": [
                    "You can use a weapon that has the ammunition property to make a ranged attack only if you have ammunition to fire from the weapon. Each time you attack with the weapon, you expend one piece of ammunition. Drawing the ammunition from a quiver, case, or other container is part of the attack. Loading a one-handed weapon requires a free hand. At the end of the battle, you can recover half your expended ammunition by taking a minute to search the battlefield.",
                    "If you use a weapon that has the ammunition property to make a melee attack, you treat the weapon as an improvised weapon. A sling must be loaded to deal any damage when used in this way."
                ]
            }
        ]
    },
    {
        "abbreviation": "AF",
        "entries": [
            {
                "type": "entries",
                "name": "Ammunition",
                "entries": [
                    "You can use a weapon that has the ammunition property to make a ranged attack only if you have ammunition to fire from the weapon. Each time you attack with the weapon, you expend one piece of ammunition. Drawing the ammunition from a quiver, case, or other container is part of the attack. Loading a one-handed weapon requires a free hand. The ammunition of a firearm is destroyed upon use.",
                    "If you use a weapon that has the ammunition property to make a melee attack, you treat the weapon as an improvised weapon. A sling must be loaded to deal any damage when used in this way."
                ]
            }
        ]
    },
    {
        "abbreviation": "AT",
        "entries":[
            {
                "name": "Artisan Tools",
                "entries": [
                    "These special tools include the items needed to pursue a craft or trade. Proficiency with a set of artisan's tools lets you add your proficiency bonus to any ability checks you make using the tools in your craft. Each type of artisan's tools requires a separate proficiency."
                ]
            }
        ]
    },
    {
        "abbreviation": "GS",
        "entries":[
            {
                "name": "Gaming Set",
                "entries": [
                    "If you are proficient with a gaming set, you can add your proficiency bonus to ability checks you make to play a game with that set. Each type of gaming set requires a separate proficiency."
                ]
            }
        ]
    },
    {
        "abbreviation": "INS",
        "entries":[
            {
                "name": "Instrument",
                "entries": [
                    "If you have proficiency with a given musical instrument, you can add your proficiency bonus to any ability checks you make to play music with the instrument.",
                    "A bard can use a musical instrument as a spellcasting focus, substituting it for any material component that does not list a cost.",
                    "Each type of musical instrument requires a separate proficiency."
                ]
            }
        ]
    },
    {
        "abbreviation": "R",
        "entries": [
            {
                "type": "entries",
                "name": "Range",
                "entries": [
                    "A weapon that can be used to make a ranged attack has a range shown in parentheses after the ammunition or thrown property. The range lists two numbers. The first is the weapon's normal range in feet, and the second indicates the weapon's maximum range. When attacking a target beyond normal range, you have disadvantage on the attack roll. You can't attack a target beyond the weapon's long range."
                ]
            }
        ]
    },
    {
        "abbreviation": "TG",
        "entries":[
            {
                "name": "Trade Good",
                "entries": [
                    "Most wealth is not in coins. It is measured in livestock, grain, land, rights to collect taxes, or rights to resources (such as a mine or a forest).",
                    "Guilds, nobles, and royalty regulate trade. Chartered companies are granted rights to conduct trade along certain routes, to send merchant ships to various ports, or to buy or sell specific goods. Guilds set prices for the goods or services that they control, and determine who may or may not offer those goods and services. Merchants commonly exchange trade goods without using currency."
                ]
            }
        ]
    },
    {
        "abbreviation": "SHP",
        "name": "Vehicle",
        "entries": [
            {
                "name":"Vehicle",
                "entries":[
                    {
                        "type": "entries",
                        "name": "Crew",
                        "entries": [
                            "A ship needs a crew of skilled hirelings to function. As per the Player's Handbook, one skilled hireling costs at least 2 gp per day. The minimum number of skilled hirelings needed to crew a ship depends on the type of vessel.",
                            "You can track the loyalty of individual crew members or the crew as a whole using the optional loyalty rules in chapter 4 of the Dungeon Master's Guide. If at least half the crew becomes disloyal during a voyage, the crew turns hostile and stages a mutiny. If the ship is berthed, disloyal crew members leave the ship and never return."
                        ]
                    },
                    {
                        "type": "entries",
                        "name": "Passengers",
                        "entries": [
                            "The table indicates the number of Small and Medium passengers the ship can accommodate.",
                            "Accommodations consist of shared hammocks in tight quarters. A ship outfitted with private accommodations can carry one-fifth as many passengers.",
                            "A passenger is usually expected to pay 5 sp per day for a hammock, but prices can vary from ship to ship. A small private cabin usually costs 2 gp per day."
                        ]
                    },
                    {
                        "type": "entries",
                        "name": "Cargo",
                        "entries": [
                            "The maximum tonnage the ship can carry."
                        ]
                    },
                    {
                        "type": "entries",
                        "name": "Damage Threshold",
                        "entries": [
                            "If a ship has a Damage Threshold, it has immunity to all damage unless it takes an amount of damage equal to or greater than its damage threshold, in which case it takes damage as normal. Any damage that fails to meet or exceed the damage threshold is considered superficial and doesn't reduce the ship's hit points"
                        ]
                    },
                    {
                        "type": "entries",
                        "name": "Ship Repair",
                        "entries": [
                            "Repairs to a damaged ship can be made while the vessel is berthed. Repairing 1 hit point of damage requires 1 day and costs 20 gp for materials and labor."
                        ]
                    }
                ]
            }
        ]
    }
];


const itemProperties= [
    {
        "abbreviation": "BF",
        "entries": [
            {
                "type": "entries",
                "name": "Burst Fire",
                "entries": [
                    "A weapon that has the burst fire property can make a single-target attack, or it can spray a 10-foot-cube area within normal range with shots. Each creature in the area must succeed on a DC 15 Dexterity saving throw or take the weapon's normal damage. This action uses ten pieces of ammunition."
                ]
            }
        ]
    },
    {
        "abbreviation": "F",
        "entries": [
            {
                "type": "entries",
                "name": "Finesse",
                "entries": [
                    "When making an attack with a finesse weapon, you use your choice of your Strength or Dexterity modifier for the attack and damage rolls. You must use the same modifier for both rolls."
                ]
            }
        ]
    },
    {
        "abbreviation": "H",
        "entries": [
            {
                "type": "entries",
                "name": "Heavy",
                "entries": [
                    "Small creatures have disadvantage on attack rolls with heavy weapons. A heavy weapon's size and bulk make it too large for a Small creature to use effectively."
                ]
            }
        ]
    },
    {
        "abbreviation": "L",
        "entries": [
            {
                "type": "entries",
                "name": "Light",
                "entries": [
                    "A light weapon is small and easy to handle, making it ideal for use when fighting with two weapons."
                ]
            }
        ]
    },
    {
        "abbreviation": "LD",
        "entries": [
            {
                "type": "entries",
                "name": "Loading",
                "entries": [
                    "Because of the time required to load this weapon, you can fire only one piece of ammunition from it when you use an action, bonus action, or reaction to fire it, regardless of the number of attacks you can normally make."
                ]
            }
        ]
    },
    {
        "abbreviation": "R",
        "entries": [
            {
                "type": "entries",
                "name": "Reach",
                "entries": [
                    "This weapon adds 5 feet to your reach when you attack with it. This property also determines your reach for opportunity attacks with a reach weapon."
                ]
            }
        ]
    },
    {
        "abbreviation": "RLD",
        "entries": [
            {
                "type": "entries",
                "name": "Reload",
                "entries": [
                    "A limited number of shots can be made with a weapon that has the reload property. A character must then reload it using an action or a bonus action (the character's choice)."
                ]
            }
        ]
    },
    {
        "abbreviation": "T",
        "entries": [
            {
                "type": "entries",
                "name": "Thrown",
                "entries": [
                    "If a weapon has the thrown property, you can throw the weapon to make a ranged attack. If the weapon is a melee weapon, you use the same ability modifier for that attack roll and damage roll that you would use for a melee attack with the weapon. For example, if you throw a handaxe, you use your Strength, but if you throw a dagger, you can use either your Strength or your Dexterity, since the dagger has the finesse property."
                ]
            }
        ]
    },
    {
        "abbreviation": "2H",
        "entries": [
            {
                "type": "entries",
                "name": "Two-Handed",
                "entries": [
                    "This weapon requires two hands to use. This property is relevant only when you attack with the weapon, not when you simply hold it."
                ]
            }
        ]
    },
    {
        "abbreviation": "V",
        "entries": [
            {
                "type": "entries",
                "name": "Versatile",
                "entries": [
                    "This weapon can be used with one or two hands. A damage value in parentheses appears with the property\u2014the damage when the weapon is used with two hands to make a melee attack."
                ]
            }
        ]
    }
];

const armorTypeFromAbbreviation = {
    "HA":"heavy",
    "MA":"medium",
    "LA":"light",
    "S":"shield"
};

const armorOrder = {
    "light":1,
    "medium":2,
    "heavy":3,
    "shield":4
}

const coinNames = {
    "cp":"Copper Pieces",
    "sp":"Silver Pieces",
    "gp":"Gold Pieces",
    "ep":"Electrum Pieces",
    "pp":"Platinum Pieces"
}

const baseCoins= {
    "cp":{type:"coin", coinType:"cp", weight:0.02, displayName:"Copper Pieces", countPerGP:100},
    "sp":{type:"coin", coinType:"sp", weight:0.02, displayName:"Silver Pieces", countPerGP:10},
    "gp":{type:"coin", coinType:"gp", weight:0.02, displayName:"Gold Pieces", countPerGP:1},
    "ep":{type:"coin", coinType:"ep", weight:0.02, displayName:"Electrum Pieces", countPerGP:2},
    "pp":{type:"coin", coinType:"pp", weight:0.02, displayName:"Platinum Pieces", countPerGP:0.1},
}

const armorTypes = ["light", "medium", "heavy", "shields"];
const weaponProficiencies = ["*simple","club","light crossbow","dagger","dart","greatclub","handaxe","javelin","light hammer","mace","quarterstaff","shortbow","sickle","sling","spear","yklwa",
    "*martial","battleaxe","blowgun","hand crossbow","heavy crossbow","double-bladed scimitar","flail","glaive","greataxe","greatsword","halberd","lance","longbow","longsword","maul","morningstar",
    "net","pike","rapier","scimitar","shortsword","trident","war pick","warhammer","whip","*firearms","antimatter rifle","bad news","blunderbuss","hand mortar","laser pistol","laser rifle",
    "musket","palm pistol","pepperbox","pistol","pistol, automatic","revolver","rifle, automatic","rifle, hunting","shotgun"];

const weaponSpecificProficiencies = ["club","light crossbow","dagger","dart","greatclub","handaxe","javelin","light hammer","mace","quarterstaff","shortbow","sickle","sling","spear","yklwa",
    "battleaxe","blowgun","hand crossbow","heavy crossbow","double-bladed scimitar","flail","glaive","greataxe","greatsword","halberd","lance","longbow","longsword","maul","morningstar",
    "net","pike","rapier","scimitar","shortsword","trident","war pick","warhammer","whip","antimatter rifle","bad news","blunderbuss","hand mortar","laser pistol","laser rifle",
    "musket","palm pistol","pepperbox","pistol","pistol, automatic","revolver","rifle, automatic","rifle, hunting","shotgun"];

function getWeaponProficiency(it) {
    if (it.weaponProficiency) {
        return it.weaponProficiency;
    }
    let match = "";
    const ln = (it.displayName||"").toLowerCase();
    for (let i in weaponSpecificProficiencies) {
        const w = weaponSpecificProficiencies[i];
        if ((w.length > match.length) && ln.includes(w.toLowerCase())) {
            match=w;
        }
    }
    if (it.baseItem && !match) {
        let ln = it.baseItem.toLowerCase();
        for (let i in weaponSpecificProficiencies) {
            const w = weaponSpecificProficiencies[i];
            if ((w.length > match.length) && ln.includes(w.toLowerCase())) {
                match=w;
            }
        }
    }
    return match;
}

const tradeToolProficiencies = [
    "Disguise Kit",
    "Forgery Kit",
    "Herbalism Kit",
    "Navigator's Tools",
    "Poisoner's Kit",
    "Thieves' Tools",
    "Vehicles (land)",
    "Vehicles (water)"
];

const artisanToolProficiencies = [
    "Alchemist's Supplies",
    "Brewer's Supplies",
    "Calligrapher's Supplies",
    "Carpenter's Tools",
    "Cartographer's Tools",
    "Cobbler's Tools",
    "Cook's Utensils",
    "Glassblower's Tools",
    "Jeweler's Tools",
    "Leatherworker's Tools",
    "Mason's Tools",
    "Painter's Supplies",
    "Potter's Tools",
    "Smith's Tools",
    "Tinker's Tools",
    "Weaver's Tools",
    "Woodcarver's Tools",
];

const gamingsetToolProficiencies = [
    "Dice Set",
    "Dragonchess Set",
    "Playing Card Set",
    "Three-Dragon Ante Set"
];

const instrumentProficiencies = [
    "Bagpipes",
    "Birdpipes",
    "Drum",
    "Dulcimer",
    "Flute",
    "Glaur",
    "Hand Drum",
    "Horn",
    "Longhorn",
    "Lute",
    "Lyre",
    "Pan Flute",
    "Shawm",
    "Songhorn",
    "Tantan",
    "Thelarr",
    "Tocken",
    "Viol",
    "Wargong",
    "Yarting",
    "Zulkoon"
];

const toolProficiencies = ["*Trade Tools"]
    .concat(tradeToolProficiencies)
    .concat(["*Artisan's Tools"])
    .concat(artisanToolProficiencies)
    .concat(["*Gaming Set"])
    .concat(gamingsetToolProficiencies)
    .concat(["*Musical Instrument"])
    .concat(instrumentProficiencies);

const toolSingleProficiencies = tradeToolProficiencies
    .concat(artisanToolProficiencies)
    .concat(gamingsetToolProficiencies)
    .concat(instrumentProficiencies);

const toolShortProficiencies = ["Trade Tools","Artisan's Tools","Gaming Set","Musical Instrument"];   
const chooseProficienciesExpand = {
    "Trade Tools":tradeToolProficiencies,
    "Artisan's Tools":artisanToolProficiencies,
    "Gaming Set":gamingsetToolProficiencies,
    "Musical Instrument":instrumentProficiencies
}

const gamesystemOptions={
    "5e":"D&D 5E (2014)",
    "bf":"Black Flag Roleplaying",
    "5e24":"D&D 5E (2024)"
}

const gamesystemRaceOptions={
    "5e":"D&D 5E (2014)",
    "5e24":"D&D 5E (2024)"
}

const gamesystemAnyOptions={
    "any":"Any",
    "5e":"D&D 5E (2014)",
    "bf":"Black Flag Roleplaying",
    "5e24":"D&D 5E (2024)"
}

const colorNameMap = {
    '#4d4d4d':"Gray30",
    '#999999':"Gray60", 
    '#ffffff':"White", 
    '#f44e3b':"Sunset Orange", 
    '#fe9200':"Orange", 
    '#fcdc00':"Golden Yellow", 
    '#dbdf00':"Chartreuse Yellow", 
    '#a4dd00':"Inch Worm", 
    '#68ccca':"Cyan", 
    '#73d8ff':"Sky Blue", 
    '#aea1ff':"Lavender", 
    '#fda1ff':"Pink", 
    '#333333':"Grey20", 
    '#808080':"Gray", 
    '#cccccc':"Gray80", 
    '#d33115':"Red", 
    '#e27300':"Mango", 
    '#fcc400':"Yellow", 
    '#b0bc00':"La Rioja", 
    '#68bc00':"Christi Green", 
    '#16a5a5':"Sea Green", 
    '#009ce0':"Pacific Blue", 
    '#7b64ff':"Slate Blue", 
    '#fa28ff':"Magenta", 
    '#000000':"Black", 
    '#666666':"Gray40", 
    '#b3b3b3':"Gray70", 
    '#9f0500':"Dark Red", 
    '#c45100':"Burt Orange", 
    '#fb9e00':"Orange Peel", 
    '#808900':"Olive", 
    '#194d33':"Green", 
    '#0c797d':"Teal", 
    '#0062b1':"Blue", 
    '#653294':"Purple", 
    '#ab149e':"Dark Magenta"
}

const colorContrastMap = {
    '#4d4d4d':"white",
    '#999999':"black", 
    '#ffffff':"black", 
    '#f44e3b':"black", 
    '#fe9200':"black", 
    '#fcdc00':"black", 
    '#dbdf00':"black", 
    '#a4dd00':"black", 
    '#68ccca':"black", 
    '#73d8ff':"black", 
    '#aea1ff':"black", 
    '#fda1ff':"black", 

    '#333333':"white", 
    '#808080':"black", 
    '#cccccc':"black", 
    '#d33115':"black", 
    '#e27300':"black", 
    '#fcc400':"black", 
    '#b0bc00':"black", 
    '#68bc00':"black", 
    '#16a5a5':"black", 
    '#009ce0':"black", 
    '#7b64ff':"black", 
    '#fa28ff':"black", 

    '#000000':"white", 
    '#666666':"white", 
    '#b3b3b3':"black", 
    '#9f0500':"white", 
    '#c45100':"white", 
    '#fb9e00':"black", 
    '#808900':"white", 
    '#194d33':"white", 
    '#0c797d':"white", 
    '#0062b1':"white", 
    '#653294':"white", 
    '#ab149e':"white",
    '#8B4513':"white",
    '#D2B48C':"black",
}

function nameEncode(str) {
    str = str.toLowerCase();
    str = encodeURIComponent(str);
    str = str.replace(/-/g, "D");
    str = str.replace(/\./g, "P");
    str = str.replace(/!/g, "E");
    str = str.replace(/~/g, "T");
    str = str.replace(/\*/g, "B");
    str = str.replace(/'/g, "Q");
    str = str.replace(/\(/g, "L");
    str = str.replace(/\)/g, "R");
    str = str.replace(/%/g, "S");
    return str;
}

const spellcasterOptions = {
    "none":"None",
    "full":"Full Spellcaster",
    "half":"One Half Spellcaster",
    "halfplus":"One Half Spellcaster with 1st level spells",
    "third":"One Third Spellcaster",
    "pact":"Pact Magic",
    "halfpact":"One Half Pact Magic",
    "thirdpact":"One Third Pact Magic",
}
           
const abilityDCOptions = {
    "none":"None",
    "str":"Strength",
    "dex":"Dexterity",
    "con":"Constitution",
    "int":"Intelligence",
    "wis":"Wisdom",
    "cha":"Charisma"
}

const multiClassSpellSlots = [
    [2,0,0,0,0,0,0,0,0],
    [3,0,0,0,0,0,0,0,0],
    [4,2,0,0,0,0,0,0,0],
    [4,3,0,0,0,0,0,0,0],
    [4,3,2,0,0,0,0,0,0],
    [4,3,3,0,0,0,0,0,0],
    [4,3,3,1,0,0,0,0,0],
    [4,3,3,2,0,0,0,0,0],
    [4,3,3,3,1,0,0,0,0],
    [4,3,3,3,2,0,0,0,0],
    [4,3,3,3,2,1,0,0,0],
    [4,3,3,3,2,1,0,0,0],
    [4,3,3,3,2,1,1,0,0],
    [4,3,3,3,2,1,1,0,0],
    [4,3,3,3,2,1,1,1,0],
    [4,3,3,3,2,1,1,1,0],
    [4,3,3,3,2,1,1,1,1],
    [4,3,3,3,3,1,1,1,1],
    [4,3,3,3,3,2,1,1,1],
    [4,3,3,3,3,2,2,1,1]
];

const fullSpellInfo = [
    {"spellSlots": [2,0,0,0,0,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [3,0,0,0,0,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [4,2,0,0,0,0,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,0,0,0,0,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,2,0,0,0,0,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,0,0,0,0,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,1,0,0,0,0,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,2,0,0,0,0,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,3,1,0,0,0,0],"maxSpellLevel": 5},
    {"spellSlots": [4,3,3,3,2,0,0,0,0],"maxSpellLevel": 5},
    {"spellSlots": [4,3,3,3,2,1,0,0,0],"maxSpellLevel": 6},
    {"spellSlots": [4,3,3,3,2,1,0,0,0],"maxSpellLevel": 6},
    {"spellSlots": [4,3,3,3,2,1,1,0,0],"maxSpellLevel": 7},
    {"spellSlots": [4,3,3,3,2,1,1,0,0],"maxSpellLevel": 7},
    {"spellSlots": [4,3,3,3,2,1,1,1,0],"maxSpellLevel": 8},
    {"spellSlots": [4,3,3,3,2,1,1,1,0],"maxSpellLevel": 8},
    {"spellSlots": [4,3,3,3,2,1,1,1,1],"maxSpellLevel": 9},
    {"spellSlots": [4,3,3,3,3,1,1,1,1],"maxSpellLevel": 9},
    {"spellSlots": [4,3,3,3,3,2,1,1,1],"maxSpellLevel": 9},
    {"spellSlots": [4,3,3,3,3,2,2,1,1],"maxSpellLevel": 9}
];

const halfSpellInfo = [
    {"spellSlots": [0,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [2,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [3,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [3,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [4,2,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,2,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,2,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,2,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,1,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,1,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,2,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,2,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,3,1],"maxSpellLevel": 5},
    {"spellSlots": [4,3,3,3,1],"maxSpellLevel": 5},
    {"spellSlots": [4,3,3,3,2],"maxSpellLevel": 5},
    {"spellSlots": [4,3,3,3,2],"maxSpellLevel": 5}
];

const halfPlusSpellInfo = [
    {"spellSlots": [2,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [2,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [3,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [3,0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [4,2,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,2,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,0,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,2,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,2,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,0,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,1,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,1,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,2,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,2,0],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,3,1],"maxSpellLevel": 5},
    {"spellSlots": [4,3,3,3,1],"maxSpellLevel": 5},
    {"spellSlots": [4,3,3,3,2],"maxSpellLevel": 5},
    {"spellSlots": [4,3,3,3,2],"maxSpellLevel": 5}
];

const thirdSpellInfo = [
    {"spellSlots": [0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [0,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [2,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [3,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [3,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [3,0,0,0],"maxSpellLevel": 1},
    {"spellSlots": [4,2,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,2,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,2,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,0,0],"maxSpellLevel": 2},
    {"spellSlots": [4,3,2,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,2,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,2,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,0],"maxSpellLevel": 3},
    {"spellSlots": [4,3,3,1],"maxSpellLevel": 4},
    {"spellSlots": [4,3,3,1],"maxSpellLevel": 4}
];

const pactSpellInfo = [
    {pactSlots: 1,pactLevel: 1, maxSpellLevel:1}, // 1
    {pactSlots: 2,pactLevel: 1, maxSpellLevel:1}, // 2
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 3
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 4
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 5
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 6
    {pactSlots: 2,pactLevel: 4, maxSpellLevel:4}, // 7
    {pactSlots: 2,pactLevel: 4, maxSpellLevel:4}, // 8
    {pactSlots: 2,pactLevel: 5, maxSpellLevel:5}, // 9
    {pactSlots: 2,pactLevel: 5, maxSpellLevel:5}, // 10
    {pactSlots: 3,pactLevel: 5, maxSpellLevel:5}, // 11
    {pactSlots: 3,pactLevel: 5, maxSpellLevel:5}, // 12
    {pactSlots: 3,pactLevel: 5, maxSpellLevel:5}, // 13
    {pactSlots: 3,pactLevel: 5, maxSpellLevel:5}, // 14
    {pactSlots: 3,pactLevel: 5, maxSpellLevel:5}, // 15
    {pactSlots: 3,pactLevel: 5, maxSpellLevel:5}, // 16
    {pactSlots: 4,pactLevel: 5, maxSpellLevel:5}, // 17
    {pactSlots: 4,pactLevel: 5, maxSpellLevel:5}, // 18
    {pactSlots: 4,pactLevel: 5, maxSpellLevel:5}, // 19
    {pactSlots: 4,pactLevel: 5, maxSpellLevel:5}, // 20
];

const halfPactSpellInfo = [
    {pactSlots: 0,pactLevel: 0, maxSpellLevel:0}, // 1
    {pactSlots: 1,pactLevel: 1, maxSpellLevel:1}, // 2
    {pactSlots: 1,pactLevel: 1, maxSpellLevel:1}, // 3
    {pactSlots: 2,pactLevel: 1, maxSpellLevel:1}, // 4
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 5 
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 6
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 7
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 8
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 9
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 10
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 11
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 12
    {pactSlots: 2,pactLevel: 4, maxSpellLevel:4}, // 13
    {pactSlots: 2,pactLevel: 4, maxSpellLevel:4}, // 14
    {pactSlots: 2,pactLevel: 4, maxSpellLevel:4}, // 15
    {pactSlots: 2,pactLevel: 4, maxSpellLevel:4}, // 16
    {pactSlots: 2,pactLevel: 5, maxSpellLevel:5}, // 17
    {pactSlots: 2,pactLevel: 5, maxSpellLevel:5}, // 18
    {pactSlots: 2,pactLevel: 5, maxSpellLevel:5}, // 19
    {pactSlots: 2,pactLevel: 5, maxSpellLevel:5}, // 20
];

const thirdPactSpellInfo = [
    {pactSlots: 0,pactLevel: 0, maxSpellLevel:0}, // 1
    {pactSlots: 0,pactLevel: 0, maxSpellLevel:0}, // 2
    {pactSlots: 1,pactLevel: 1, maxSpellLevel:1}, // 3
    {pactSlots: 1,pactLevel: 1, maxSpellLevel:1}, // 4
    {pactSlots: 1,pactLevel: 1, maxSpellLevel:1}, // 5 
    {pactSlots: 2,pactLevel: 1, maxSpellLevel:1}, // 6
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 7
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 8
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 9
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 10
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 11
    {pactSlots: 2,pactLevel: 2, maxSpellLevel:2}, // 12
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 13
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 14
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 15
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 16
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 17
    {pactSlots: 2,pactLevel: 3, maxSpellLevel:3}, // 18
    {pactSlots: 2,pactLevel: 4, maxSpellLevel:4}, // 19
    {pactSlots: 2,pactLevel: 4, maxSpellLevel:4}, // 20
];

const spellPointsByLevel = [
    0,2,3,5,6,7,9,10,11,13
]

const characterLevels = [
    0,
    0,
    300,
    900,
    2700,
    6500,
    14000,
    23000,
    34000,
    48000,
    64000,
    85000,
    100000,
    120000,
    140000,
    165000,
    195000,
    225000,
    265000,
    305000,
    355000,
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
    "max",
];

const rarityLevels=[
    "None",
    "Common",
    "Uncommon",
    "Rare",
    "Very Rare",
    "Legendary",
    "Artifact",
    "Unknown"
];

const defaultSettings=[
    "Any Setting",
    "Aihrde",
    "Artanga",
    "Midgard",
    "Wagadu"
];

const defaultStorylines=[
    "Fate of Artanga"
];

const packageGenres = [
    "Aquatic",
    "Arctic",
    "Dungeon",
    "Planar",
    "Underdark",
    "Urban",
    "Wilderness",
    "Comedy",
    "Family Oriented",
    "Historical",
    "Holiday",
    "Horror",
    "Intrigue",
    "Modern",
    "Mystery",
    "Science Fiction",
    "Western",
    "Miscellaneous"
];

const packageTypes = [
    "Adventure",
    "Bestiary",
    "Character Options",
    "Gamemaster Options",
    "Map Collection",
    "Setting Guide",
    "Dice Pack",
    "Token Elements",
    "Audio Pack"
];

const defaultRuleset = "SRD 5.1";
const defaultRulesets = [defaultRuleset];


const sourceMap = {
    "ndbuzvonu71wjrk4":"Imported",
    "imported":"Imported",
    "CoS":"Curse of Strahd",
    "DMG":"Dungeon Master's Guide",
    "EEPC":"Elemental Evil Player's Companion",
    "HotDQ":"Hoard of the Dragon Queen",
    "LMoP":"Lost Mine of Phandelver",
    "MM":"Monster Manual",
    "OotA":"Out of the Abyss",
    "PHB":"Player's Handbook",
    "PotA":"Princes of the Apocalypse",
    "RoT":"The Rise of Tiamat",
    "RoTOS":"The Rise of Tiamat Online Supplement",
    "SCAG":"Sword Coast Adventurer's Guide",
    "SKT":"Storm King's Thunder",
    "ToA":"Tomb of Annihilation",
    "ToD":"Tyranny of Dragons",
    "TTP":"The Tortle Package",
    "TftYP":"Tales from the Yawning Portal",
    "TftYP-AtG":"Tales from the Yawning Portal",
    "TftYP-DiT":"Tales from the Yawning Portal",
    "TftYP-TFoF":"Tales from the Yawning Portal",
    "TftYP-THSoT":"Tales from the Yawning Portal",
    "TftYP-TSC":"Tales from the Yawning Portal",
    "TftYP-ToH":"Tales from the Yawning Portal",
    "TftYP-WPM":"Tales from the Yawning Portal",
    "VGM":"Volo's Guide to Monsters",
    "XGE":"Xanathar's Guide to Everything",
    "MTF":"Mordenkainen's Tome of Foes",
    "WDH":"Waterdeep: Dragon Heist",
    "WDMM":"Waterdeep: Dungeon of the Mad Mage",
    "GGR":"Guildmasters' Guide to Ravnica",
    "KKW":"Krenko's Way",
    "LLK":"Lost Laboratory of Kwalish",
    "AL":"Adventurers' League",
    "UAWGE":"Wayfinder's Guide to Eberron",
    "UATheMysticClass":"UA: The Mystic Class",
    "UAElfSubraces":"UA: Elf Subraces",
}

export {
    sourceMap,
    levelXPTarget,
    levelList,
    crLevelMap,
    crStats,
    conditionList,
    conditionExtraList,
    damageTypesList,
    extraEffectsList,
    extraEffectsDamageList,
    sensesList,
    skillVals,
    abilitiesList,
    perceptionValuesList,
    armorClassValuesList,
    languagesList,
    sizeList,
    sizeSelectList,
    allignmentTypeList,
    environmentTypeList,
    initiativeValuesList,
    conditionDurationValuesList,
    conditionLevelsList,
    sizeScaleMap,
    colorNameMap,
    nameEncode,
    skillLookup,
    abilities,
    abilities as abilitiesValues,
    abilitiesPlus,
    abilityNames,
    abilityNamesScores,
    itemTypeFromAbbreviation,
    armorTypeFromAbbreviation,
    armorOrder,
    bonus,
    bonus as bonusList,
    proficiencyTypes,
    armorTypes,
    multiClassSpellSlots,
    characterLevels,
    skillsList,
    rarityLevels,
    abilitiesListWithZero,
    usageTypes,
    coinNames,
    allignmentTypes,
    monsterSkillsMap,
    simpleMonsterTypes,
    fullSpellInfo,
    halfSpellInfo,
    halfPlusSpellInfo,
    thirdSpellInfo,
    pactSpellInfo,
    halfPactSpellInfo,
    thirdPactSpellInfo,
    proficiencyMap,
    getProficiencyString,
    toolShortProficiencies,
    joinCommaAnd,
    pluralString,
    signedNum,
    zeroTo20,
    oneTo30,
    oneTo50,
    oneTo20,
    getNumRange,
    itemTypeSelect,
    itemTypes,
    itemProperties,
    colorContrastMap,
    bonusTen,
    defaultSettings,
    defaultStorylines,
    packageGenres,
    packageTypes,
    getWeaponProficiency,
    weaponSpecificProficiencies,
    spellDCAttack,
    spellPointsByLevel,
    abilityNamesFull,
    abilityLongNames,
    abilityModFromSpellDC,
    durationSuggestions,
    simpleMonsterTypes as monsterBasicTypeList,
    actionTypeMap,
    upperActionTypeMap,
    baseCoins,
    defaultRuleset,
    defaultRulesets,
    gamesystemOptions,
    gamesystemRaceOptions,
    gamesystemAnyOptions,
    upperFirst,
    abilityNamesScoresVals,
    defaultShapeAbilities,
    spellcasterOptions,
    abilityDCOptions,
}