require("@babel/polyfill")
const {firebase, getAuth, mismatchedPersistence} = require("./firebase.jsx");
const React = require('react');
import { createRoot } from 'react-dom/client';
import { MuiThemeProvider, createMuiTheme, StylesProvider } from '@material-ui/core/styles';
const {Authenticate} = require('./authenticate.jsx');
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {displayMessage, snackMessage, ShowMessage} = require('./notification.jsx');
const {Renderclass, RenderClasses,ClassesHeader} = require('./renderclass.jsx');
const {PopupMap,openFullscreen} = require('./map.jsx');
const {campaign,globalDataListener,addCampaignToPath} = require('../lib/campaign.js');
const {Renderraces, Race, RacesHeader} = require("./renderraces.jsx");
const {RenderPregens, RenderCharacters, CharactersHeader, getMatchingCampaigns} = require("./renderplayers.jsx");
const {Character,extensionsFromSaved} = require('../lib/character.js');
const {RenderMonsters, MonsterHeader} = require("./rendermonster.jsx");
const {CharacterSheetView} = require('./charactersheet.jsx');
const {Renderspells, SpellBlock,SpellsHeader} = require("./renderspell.jsx");
const {Items, Item, ItemsHeader, ItemHeader} = require("./items.jsx");
const {GenericList, Generic} = require("./generic.jsx");
const {CustomList, CustomItem, CustomListHeader, CustomItemHeader} = require("./customtable.jsx");
const {EncountersHistoryList} = require("./renderhistory.jsx");
const {RenderBuild} = require("./renderbuild.jsx");
const {BookHeader,RenderBooks,BooksHeader} = require('./book.jsx');
const {RenderFeats, Feat, FeatsHeader} = require('./renderfeats.jsx');
const {RenderExtensions, Extension, ExtensionsHeader} = require('./renderextensions.jsx');
const {RenderRandomTables, RandomTablesHeader} = require('./renderrandomtables.jsx');
const {RenderArtList, ArtHeader} = require('./renderart.jsx');
const {RenderAudioList, AudioHeader} = require('./renderaudio.jsx');
const {RenderMapList, MapsHeader, MapHeader} = require('./rendermaps.jsx');
const {RenderBackgrounds, Background, BackgroundsHeader} = require('./renderbackgrounds.jsx');
const {HomePage, AccountSettings, AdminOnly, HeaderMenu, CampaignsHeader, CampaignsList,GamePlayReferenceList} = require("./homepage.jsx");
const {PlayCampaign, PlayCampaignHeader} = require('./playcampaign.jsx');
const {WatchCampaign} = require('./watchcampaign.jsx');
const {AdventureView, AdventureHeader} = require("./adventure.jsx");
const {EncounterView, EncounterHeader,RenderEncounters} = require('./encounterview.jsx');
const {MyCreations,MyCreationsHeader} = require('./mycreations.jsx');
const {MyPackagesHeader, MyPackagesList, AddSharedPackage} = require('./packages.jsx');
const {JoinedDialog} = require('./invite.jsx');
const {getLocationInfo} = require('./renderhref.jsx');
const EventEmitter = require('events');
const {SearchEverything} = require('./search.jsx');
const {TextBasicEdit} = require('./stdedit.jsx');
const {nameEncode} = require('../lib/stdvalues.js');
const {ProductsList, ProductsHeader,ProductDetails,AddProductKey,AddGift} = require('./products.jsx');
const {ProcessingCharge} = require('./purchase.jsx');
const {RenderPins} = require('./pins.jsx');
const {ShowTour} = require('./tours.jsx');

require('./startmarketplace.jsx');

import sizeMe from 'react-sizeme';
const {theme,themeDark} = require('./theme.jsx');

class AppRoot extends React.Component {
    constructor(props) {
        super(props);
        var defaults={};

        defaults.page = getLocationInfo(location.hash);
        defaults.drawer=false;
        defaults.isSignedIn=false;
        defaults.knownSigninState = false;
        defaults.selectpackages=false;
        defaults.selectMonitorSettings = false;
        defaults.showCampaignNew=false;
        defaults.showWelcome=false;
        defaults.online = navigator.onLine;

        this.changeCampaignSettingsFn = this.changeCampaignSettings.bind(this);
        this.stopWatchFn = this.stopWatch.bind(this);
        this.eventSync = new EventEmitter();
        this.eventSync.setMaxListeners(100);
        globalDataListener.setPageSync(this.eventSync);

        this.state= defaults;
        this.referrer = document.referrer || null;
        if (defaults.page.ref) {
            this.firstref=defaults.page.ref;
            gtag("set",{campaign:{source:defaults.page.ref,medium:"reference",name:defaults.page.ref}, 'page_referrer':this.referrer});
        } else if (defaults.page.page=="join") {
            gtag("set",{campaign:{source:"join",medium:"reference",name:"join"}, 'page_referrer':this.referrer});
        } else {
            gtag({'page_referrer':this.referrer});
        }

        window.addEventListener('online',  this.updateOnline.bind(this));
        window.addEventListener('offline', this.updateOnline.bind(this));
        globalDataListener.onGeneric("network slow update", this.networkSlowUpdate.bind(this));
	}

    networkSlowUpdate() {
        this.setState({networkBackedup:campaign.networkBackedup});
    }

    updateOnline() {
        this.setState({online:navigator.onLine})
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV != "production") {
            console.log("catch error", errorInfo);
            const now=Date.now();
            if ((now-(this.lastErrorTime||0)>60000)) {
                this.lastErrorTime=now;
                window.alert("error:"+error?.stack);
            }
        }
    }

    pageChange() {
        const t = this;
        if (mismatchedPersistence()) {
            reloadPage();
        }
        const page=getLocationInfo(location.hash);
        if (getAuth(firebase).currentUser) {
            if (page.page == "join") {
                t.setState({page:page, dataLoaded:false});
                campaign.joinCampaign(page.key).then(function (jc){
                    t.setState({joinedCampaign:jc});
                    campaign.setCampaign("emptycampaign", "emptycampaign", false, doneLoading);
                }, function (err) {
                    console.log("error joining",err);
                    doneLoading(err);
                });
            } else if ((page.page=="watch") && (page.id != campaign.getWatchKey())) {
                t.setState({page:page, dataLoaded:false});
                campaign.setWatchCampaign(page.id, doneLoading);
            } else if (page.campaign && ((campaign.getCurrentCampaign()||"").toLowerCase() != page.campaign.toLowerCase())) {
                t.setState({page:page, dataLoaded:false});
                campaign.setCampaign(page.campaign, null, false, doneLoading);                
            } else if (page.cid && ((campaign.getCurrentCampaign()||"").toLowerCase() != page.cid.toLowerCase())) {
                t.setState({page:page, dataLoaded:false});
                campaign.setCampaign(page.cid, null, false, doneLoading);
            } else if (!page.campaign && !page.cid && ((campaign.getCurrentCampaign() != "emptycampaign") || !t.state.dataLoaded)) {
                t.setState({page:page, dataLoaded:false});
                campaign.setCampaign("emptycampaign", "emptycampaign", false, doneLoading);
            } else {
                t.setState({page:page});
            }

            if (gtag) {
                if (page.page != t.state.page) {

                    gtag('config', 'UA-159662255-1', {
                        'page_title' : page.page||"home",
                        'page_path' : "/"+(page.page||"home"),
                        'page_location' : "https://"+location.hostname+"/"+(page.page||"home"),
                        'user_id': getAuth(firebase).currentUser.uid,
                        'page_referrer':t.referrer
                    });
                }
            } else {
                console.log("no gtag")
            }

            someActivity();
        } else {
            t.setState({page:page});
        }

        function doneLoading(err){
            if (err){
                t.loadingError(err);
            } else {
                t.setState({dataLoaded:true});
                t.changeDarkMode();
    
                //console.log("new?",campaign.isNew, (t.state.page.ref || (t.state.page.page == 'join')),t.state.page.ref,t.state.page.page == 'join');
                if (t.state.page.ref || ( campaign.isNew && (t.state.page.page == 'join'))) {
                    //console.log("got a new user with a ref param", t.state.page.ref);
                    campaign.registerNewUser(t.state.page.ref||"join", campaign.isNew);
                }
                if (t.state.page.code) {
                    campaign.updateCouponInCart(t.state.page.code);
                }
                someActivity();
                if (!incrementTimer) {
                    doActivityIncrement();
                }
    
                if (t.state.page.page == 'join') {
                    if (!t.state.joinedCampaign) {
                        console.log("why did join not set a campaign");
                    }
                    t.setState({showJoinedCampaign:true});
                    window.location.href = "#home?campaign="+encodeURIComponent(t.state.joinedCampaign);
                }
            }
        }
    }

    loadingError(err) {
        const {page}=this.state;

        if ((page.page != "home")||page.cid) {
            console.log("got an error loading")
            if (this.state.page.page == "join") {
                window.location.href = "/#home"
                displayMessage("Could not join campaign.\n"+err.toString());
            } else if (this.state.page.page=="watch") {
                window.location.href = "/#home"
                displayMessage("Could not load watch viewer.\n"+err.toString());
            } else {
                console.log("could not load changing location", window.location.href, err.code,err);
                window.location.href = "/#home"
                if (err.code == "unavailable") {
                    displayMessage("Could not load campaign.  The network may be offline.");
                } else {
                    //displayMessage("Could not load campaign.\n"+err.toString());
                }
                //window.location.href = "#home";
                //location.reload();
            }
        } else {
            displayMessage("Error loading data.\nMessage: "+err.toString()/*+" at "+err.stack*/);
            getAuth(firebase).signOut();
        }
    }
	
	componentDidMount() {
        var t = this;

        globalDataListener.onChangeCampaignSettings(this.changeCampaignSettingsFn);
        globalDataListener.onChangeUserSettings(this.changeUserSettings.bind(this));
        globalDataListener.setStopWatch(this.stopWatchFn);
		
		window.addEventListener('hashchange', function (event) {
            t.pageChange();
        });
        
        this.unregisterAuthObserver = getAuth(firebase).onAuthStateChanged(
            function (user) {
                t.setState({isSignedIn: !!user, knownSigninState:true, dataLoaded:false});
                if (user) {
                    campaign.login().then(function() {
                        t.pageChange();
                    },function (err) {
                        if (err) {
                            console.log("error loading",err);
                            t.loadingError(err);
                            return;
                        }
                    });
                }
            }
        );

        globalDataListener.setErrorListener(function (msg) {
            displayMessage(msg, function () {
                campaign.resetData();
            });
        }, fatalError);
        
        function fatalError(message) {
            displayMessage(message, function (){
                console.log("got an error going home");
                window.location.href = "#home";
            });
        }
    }
    
    componentWillUnmount() {
        if (this.eventSync) {
            this.eventSync.removeAllListeners();
        }
        globalDataListener.setPageSync(null);
        this.unregisterAuthObserver();
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.serverVersion != this.state.serverVersion) && 
            this.state.serverVersion && 
            (this.state.serverVersion!=campaign.codeVersion)) 
        {
            console.log("set version mismatch")
            this.setState({showReloadPopup:true, versionMismatch:true});
        }
    }

	render() {
        let render;
        let title;
        let maxh=false;
        let full=false;
        let defaultBackground=true;
        let big=false;
        const page = this.state.page;
        let hideHeader = page.hideheader;
        const playerMode = campaign.getPrefs().playerMode;
        const {font} = campaign.getUserSettings();
        const adminLevel = campaign.adminStatus.level;
        let getCharacter;
        let tourSet;


        if (!this.state.isSignedIn){
            if (this.state.knownSigninState) {
                render = <div>
                    <Authenticate doCreate={page.page=="createaccount"}/>
                    {page.ref?<iframe src="https://shardtabletop.com/doingauth" height="0" width="0"/>:null}
                </div>;
                title = <span className="seriffont">Shard Tabletop</span>;
            } else {
                render = <div></div>;
                title = <span className="pa1">Checking sign in status...</span>;
            }
        } else if (!this.state.dataLoaded) {
            render = <div></div>;
            title = "Loading data ..."
        } else {
            if (campaign.getPrefs().playerMode == "ruleset") {
                lastRuleset = campaign.getCurrentCampaign();
            }
            if (page.page == 'book') {
                if (page.id) {
                    const bookinfo = campaign.getBookInfo(page.id);
                    if (!bookinfo) {
                        render = <div>Unknown book {page.id}</div>;
                        title = "Unknown book";
                    } else {
                        render = <RenderBuild bookname={page.id} editable={(page.mode =="edit")} chapter={page.chapter} section={page.section} subsection={page.subsection} fragment={page.fragment} pageSync={this.eventSync}/>;
                        title = <BookHeader bookname={page.id} editable={(page.mode =="edit")} pageSync={this.eventSync}/>;
                        full=true;
                        defaultBackground=false;
                    }
                } else {
                    render = <div className="pa1">                       <RenderBooks pageSync={this.eventSync}/>
                    </div>;
                    title=<BooksHeader pageSync={this.eventSync}/>
                }
            } else if (page.page == 'class') {
                if (!page.class) {
                    render = <div>
                        <RenderClasses headerOutline/>
                    </div>;
                    title=<ClassesHeader pageSync={this.eventSync} mode={page.mode}/>
                    big=true;
                    defaultBackground=false;
                } else {
                    var cls = campaign.getClassInfo(page.class);
                    if (!cls) {
                        render = <div>Unknown class {page.class}</div>;
                        title="Unknown class"
                    } else {
                        render = <div className="pa1">
                            <Renderclass cclass={page.class} subclass={page.subclass} search={page.search}/>
                        </div>;
                        title = "Class/Subclass";
                    }
                }
            } else if (page.page == 'race') {
                if (page.id && campaign.getRaceInfo(page.id)) {
                    render = <div className="pa1">
                        <Race race={page.id}/>
                    </div>;
                    title = "Race";
                } else {
                    render = <div>
                        <Renderraces headerOutline/>
                    </div>;
                    big=true;
                    defaultBackground=false;
                    title = <RacesHeader/>;
                }
            } else if (page.page == 'mycreations') {
                render = <MyCreations key="mycreations" pageSync={this.eventSync}/>;
                title = <MyCreationsHeader pageSync={this.eventSync}/>;
            } else if (page.page == 'plannedencounter') {
                if (page.id) {
                    render = <EncounterView key="plannedencounter" pageSync={this.eventSync} encounter={page.id}/>;
                    title = <EncounterHeader pageSync={this.eventSync} id={page.id}/>;
                    full=true;
                }
            } else if (page.page == 'log' && !playerMode) {
                render = <EncountersHistoryList/>;
                title = "Campaign Log";
            } else if (page.page == 'adventure' && !playerMode) {
                render = <AdventureView key="adventuring" pageSync={this.eventSync}/>;
                title =  <AdventureHeader mode="adventure" pageSync={this.eventSync}/>;
                full=true;
                defaultBackground=false;
                tourSet="adventure";
            } else if (page.page == 'play' && page.campaign) {
                render = <PlayCampaign key="sharedadventuring" campaignName={page.campaign} characterName={page.character} companion={page.companion} mode={page.mode} pageSync={this.eventSync} hideheader={page.hideheader}/>;
                title = <PlayCampaignHeader campaignName={page.campaign} characterName={page.character} mode={page.mode} pageSync={this.eventSync} showMax={page.showmax}/>;
                if (page.character) {
                    getCharacter = getCharacterObj.bind(null, page.character,"players");
                }
                tourSet = "playCampaign";
                full=true;
                defaultBackground=false;
            } else if (page.page == 'watch' && page.id) {
                render = <WatchCampaign key="watch" pageSync={this.eventSync}/>;
                hideHeader = true;
                full=true;
            } else if (page.page == 'mycharacters') {
                const charInfo = campaign.getMyCharacterInfo(page.id);
                if (charInfo) {
                    const {pregen} = charInfo;

                    render = <CharacterSheetView key="mycharacter" includeDiceTray type="mycharacters" name={page.id} eventSync={this.eventSync}/>;
                    title = pregen?"Pregenerated Character":"My Character";
                    if (page.id) {
                        getCharacter = getCharacterObj.bind(null, page.id,"mycharacters");
                    }
                    if (!pregen) {
                        tourSet = "character";
                    }
                    maxh=true;
                    full=true;
                    defaultBackground=false;
                } else {
                    render = <div><RenderCharacters key="mycharacters" doNav type="mycharacters" pageSync={this.eventSync}/></div>;
                    title = <CharactersHeader pageSync={this.eventSync}/>;
                    defaultBackground=false;
                    big=true;
                }
            } else if (page.page == 'pregen') {
                render = <div className="br3 shadow-3 defaultbackground">
                    <RenderPregens key="mycharacters" doNav type="mycharacters" selectedPlayer={page.id} pageSync={this.eventSync}/>
                </div>;
                title = <CharactersHeader pageSync={this.eventSync} pregen/>;
                defaultBackground=false;
                if (page.id) {
                    getCharacter = getCharacterObj.bind(null, page.id,"mycharacters");
                }
            } else if (page.page == 'monster') {
                if (page.id){
                } else {
                    render = <div>
                        <RenderMonsters key="list" headerOutline pageSync={this.eventSync} />
                    </div>;
                    defaultBackground=false;
                    big=true;
                    title = <MonsterHeader pageSync={this.eventSync}/>;
                }
            } else if (page.page == 'spell') {
                if (page.id){
                    render = <div className="pa1">
                        <SpellBlock spell={page.id}/>
                    </div>;
                    title = "Spell";
                } else {
                    render = <div className="br3 shadow-3 defaultbackground">
                        <Renderspells/>
                    </div>;
                    defaultBackground=false;
                    title = <SpellsHeader/>;
                }
            } else if (page.page == 'pins') {
                render = <div className="pa1">
                    <RenderPins/>
                </div>;
                title = "Pins";
            } else if (page.page == 'plannedencounters') {
                render = <div className="pa1">
                    <RenderEncounters/>
                </div>;
                title = "Encounters";
            } else if (page.page == 'feats') {
                if (page.id){
                    render = <div className="pa1">
                        <Feat feat={page.id}/>
                    </div>;
                    title = "Feat";
                } else {
                    render = <div>
                        <RenderFeats headerOutline/>
                    </div>;
                    title = <FeatsHeader/>;
                    big=true;
                    defaultBackground=false;
                }
            } else if (page.page == 'extensions') {
                render = <div className="pa1">
                    <RenderExtensions/>
                </div>;
                title = <ExtensionsHeader/>;
            } else if (page.page == 'randomtables') {
                render = <div className="pa1">
                    <RenderRandomTables/>
                </div>;
                title = <RandomTablesHeader/>;
            } else if (page.page == 'backgrounds') {
                if (page.id){
                    render = <div className="pa1">
                        <Background background={page.id}/>
                    </div>;
                    title = "Background";
                } else {
                    render = <div>
                        <RenderBackgrounds headerOutline/>
                    </div>;
                    big=true
                    defaultBackground=false;
                    title = <BackgroundsHeader/>;
                }
            } else if (page.page == 'art') {
                render = <RenderArtList headerOutline/>;
                title = <ArtHeader/>;
                big=true
                defaultBackground=false;
            } else if (page.page == 'audio') {
                render = <RenderAudioList/>;
                title = <AudioHeader/>;
            } else if (page.page == 'maps') {
                if (page.id) {
                    full=true;
                    defaultBackground=false;
                    render = <RenderBuild mapOnly editable map={page.id} pageSync={this.eventSync}/>;
                    title=<MapHeader id={page.id} pageSync={this.eventSync}/>
                } else {
                    render = <RenderMapList doNav headerOutline/>;
                    title = <MapsHeader/>;
                    big=true
                    defaultBackground=false;
                }
            } else if (page.page == 'item') {
                if (page.id){
                    render = <div className="pa1">
                        <Item id={page.id}/>
                    </div>;
                    title = <ItemHeader id={page.id} pageSync={this.eventSync}/>;
                } else {
                    render = <div className="br3 shadow-3 defaultbackground">
                        <Items headerOutline/>
                    </div>;
                    defaultBackground=false;
                    title = <ItemsHeader pageSync={this.eventSync}/>;
                }
            } else if (page.page == 'mypackages') {
                render = <MyPackagesList pageSync={this.eventSync}/>;
                title = <MyPackagesHeader pageSync={this.eventSync}/>;
            } else if (page.page == 'settings') {
                render = <AccountSettings pageSync={this.eventSync}/>;
                title = <span>My Account</span>;
            } else if (page.page == 'admin') {
                render = <AdminOnly pageSync={this.eventSync}/>;
                title = <span>Admin</span>;
            } else if ((page.page == 'conditions') ||
                    (page.page == 'skills') ||
                    (page.page == 'actions') ||
                    (page.page == 'senses')) {
                if (page.id){
                    render = <div className="pa1">
                        <Generic type={page.page} id={page.id}/>
                    </div>;
                    title = page.id;
                } else {
                    render = <div className="pa1">
                        <GenericList type={page.page}/>
                    </div>;
                    title = <span className="ttc">{page.page}</span>;
                }
            } else if ((page.page == 'customtable')) {
                if (page.id){
                    render = <div className="pa1">
                        <CustomItem type={page.type} id={page.id} pageSync={this.eventSync}/>
                    </div>;
                    title = <CustomItemHeader type={page.type} id={page.id} pageSync={this.eventSync}/>;
                } else {
                    render = <div>
                        <CustomList type={page.type} pageSync={this.eventSync} editable headerOutline/>
                    </div>;
                    big=true;
                    defaultBackground=false;
                    title = <CustomListHeader type={page.type} pageSync={this.eventSync}/>;
                }
            } else if (page.page == 'products') {
                if (page.id){
                    title="Product Details"
                    render = <ProductDetails product={page.id} pageSync={this.eventSync}/>
                    full=true;
                } else {
                    render = <div className="pa1">
                        <ProductsList pageSync={this.eventSync}/>
                    </div>;
                    title = <ProductsHeader pageSync={this.eventSync}/>;
                }
            } else if (page.page == 'campaigns') {
                title=<CampaignsHeader pageSync={this.eventSync}/>
                render = <CampaignsList pageSync={this.eventSync}/>
            } else if (page.page == 'reference') {
                title="Reference"
                render = <GamePlayReferenceList pageSync={this.eventSync}/>
            } else {
                if (page.page != "home") {
                    console.log("forcing home due to unknown page???", page.page)
                    window.location.href = "#home";
                }
                render = null;
                tourSet="homepage";
            }
        }

        if ((this.firstref||campaign.isNew)&&this.state.isSignedIn&&this.state.dataLoaded){
            this.showiframe = true;
        }

        const dark =this.state.darkMode;
        const wallpaper=this.state.dataLoaded && campaign.getWallpaper();
        const doRootNav = this.state.isSignedIn&&this.state.dataLoaded;
        const rootLink =doRootNav && getRootLink().path;
        const showHomeMenu = (rootLink!="#home");
        
		return(
            <StylesProvider injectFirst><MuiThemeProvider theme={dark?themeDark:theme}>
                <div className={"flex flex-column h-100 w-100 overflow-hidden absolute "} style={{backgroundSize:"cover", backgroundImage:"url("+(wallpaper||"/hero.png")+")"}} onTouchStart={someActivity} onMouseMove={someActivity}>
                    <ShowMessage/>
                    {!hideHeader?<div className="flex f3 headerstyle darkT" id="shardheader">
                        <a href={doRootNav&&!showHomeMenu?rootLink:null} onClick={doRootNav&&showHomeMenu?this.homeMenu.bind(this):null}><img src="/logo.svg" height="24px" className="ph1"/></a>
                        <span className="flex-auto">
                            {(!title || (typeof title =="string"))?<span className="pb--1 pt--3 dib"><span>{title||<span className="seriffont">Shard Tabletop</span>}</span></span>:title}
                        </span>
                        {this.getReload()}
                        {!render&&!this.state.versionMismatch?<a href="/marketplace" className="pb--1 pt--3 dib mr1">Marketplace</a>:null}
                        <ProcessingCharge/>
                        {tourSet?<ShowTour tourSet={tourSet} pageSync={this.eventSync}/>:null}
                        {this.state.isSignedIn&&this.state.dataLoaded?<SearchEverything getCharacter={getCharacter}/>:null}
                        <HeaderMenu includeMarketplace/>
                        {this.state.isSignedIn?<PopupMap/>:null}
                    </div>:null}
                    <div className={"pa0 w-100 h2 relative flex-auto overflow-y-hidden overflow-x-hidden "+(dark||true?" darkT":" lightT")} key="approot">
                        <div className={"h-100 w-100 flex overflow-y-auto bg-black-30"}>
                            <div className="flex-auto"/>
                            {render?full?
                                <div className={(defaultBackground?"defaultbackground ":"")+"h-100 w-100 overflow-hidden"}>
                                    {render}
                                </div>
                                :
                                <div className={(big?"w-100 mw9":"w-100 mw8")}>
                                    <div className={(defaultBackground?"defaultbackground shadow-2":"")+(maxh?" h-100":"")}>
                                        {render}
                                    </div>
                                </div>
                            :
                                <div className="h-100 w-100 overflow-hidden">
                                    <HomePage pageSync={this.eventSync}/>
                                </div>
                            }
                            <div className="flex-auto"/>
                        </div>
                    </div>
                </div>
                <JoinedDialog open={this.state.showJoinedCampaign} joinedCampaign={this.state.joinedCampaign} onClose={this.closeJoinedCampaign.bind(this)}/>
                <AddSharedPackage 
                    open={page.sharepackage&&this.state.isSignedIn&&this.state.dataLoaded} 
                    sharepackage={page.sharepackage} 
                    sharepackageuser={page.sharepackageuser}
                    sharekey={page.sharekey}
                />
                <AddProductKey open={page.productkey&&this.state.isSignedIn&&this.state.dataLoaded} productkey={page.productkey} secret={page.secret}/>
                <AddGift open={page.giftkey&&this.state.isSignedIn&&this.state.dataLoaded} giftkey={page.giftkey}/>
                {this.showiframe?<iframe src={campaign.isNew?"https://shardtabletop.com/playnewuser":"https://shardtabletop.com/playactive"} height="0" width="0"/>:null}
                {this.getNavMenu()}
            </MuiThemeProvider></StylesProvider>);
    }

    closeJoinedCampaign(){
        this.setState({showJoinedCampaign:false});
    }

    homeMenu(evt) {
        this.setState({homeMenu:true, homeAnchor:evt.target});
    }

    navHomeMenu(nav) {
        this.setState({homeMenu:false});
        if (nav) {
            window.location.href = nav;
        }
    }

    getNavMenu() {
        const {homeMenu,homeAnchor} = this.state;
        if (homeMenu) {
            const cinfo = campaign.getPrefs();
            const isRuleset = (cinfo.playerMode=="ruleset");
            const {path,name} = getRootLink();
    
            return <Menu anchorEl={homeAnchor} open onClose={this.navHomeMenu.bind(this,null)}>
                <MenuItem onClick={this.navHomeMenu.bind(this,"#home")}>Show all your content</MenuItem>
                <MenuItem onClick={this.navHomeMenu.bind(this,path)}>Ruleset {name}</MenuItem>
            </Menu>
        }
    }

    toggel(){
        this.setState({drawer:(!this.state.drawer && this.state.isSignedIn)});
    }

    back(){
        history.back();
    }

    getReload() {
        if (!this.state.online) {
            return <span className="red hoverhighlight truncate">
                Internet Unavailable!
            </span>;
        } else if (campaign.networkBackedup) {
            return <span className="red hoverhighlight truncate">
                Network Slow!
            </span>;
        } else if (this.state.versionMismatch) {
            return <span className="red hoverhighlight truncate" onClick={reloadPage}>
                <span className="pa1 f2 fas fa-sync"/> Get New Version
            </span>;
        }
        return null;
    }

    changeCampaignSettings() {
        this.setState({serverVersion:campaign.serverVersion});
    }

    changeUserSettings() {
        this.changeDarkMode();
    }

    changeDarkMode() {
        const darkMode = campaign.isDarkMode;
        const classList = document.body.classList;

        document.body.style.removeProperty("backgound-color");
        if (darkMode) {
            classList.remove("lightT");
            classList.add("darkT");
        } else {
            classList.remove("darkT");
            classList.add("lightT");
        }
        const {font} = campaign.getUserSettings();
        let foundFontClass;
        classList.forEach(function (token){if (token.startsWith("font")) {foundFontClass=token}});
        if (foundFontClass != font) {
            classList.remove(foundFontClass);
            if (font) {
                classList.add(font);
            }
        }
        this.setState({darkMode});
    }

    stopWatch(id) {
        if ((this.state.page.page=="watch") && (id == this.state.page.id)) {
            displayMessage("Watch link no longer available.");
            window.location.href = "#home";
        }
    }
}

function getRootLink() {
    let path = "#home"
    if (campaign.isDefaultCampaign()) {
        return {path};
    }

    let cid;
    const cinfo = campaign.getPrefs();
    let name=cinfo.displayName;
    if (cinfo.playerMode == "ruleset") {
        cid = campaign.getCurrentCampaign();
    } else {
        const extensions = extensionsFromSaved(campaign.getGameState()?.extensions)
        let found;
        for (let i in extensions) {
            const e=extensions[i];
            const ex = campaign.getExtensionInfo(extensions[i]);
            found |= ex?.template;
        }
        if (found) {
            // if found an extension template look for a matching ruleset
            const matchingCampaigns = getMatchingCampaigns(extensions);
            for (let i in matchingCampaigns) {
                const cInfo = campaign.getCampaignInfo(i);
                if ((cInfo.playerMode == "ruleset") && (!cid ||(lastRuleset == i))) {
                    cid = i;
                    name= cInfo.displayName;
                }
            }
        }
    }

    if (cid) {
        return {path:path+"?cid="+nameEncode(cid), name};
    }
    return {path};
}

function getCharacterObj(name, type) {
    let c;
    switch (type) {
        case "mycharacters":
            c=campaign.getMyCharacterInfo(name);
            break;
        case "players":
        default:
            c=campaign.getPlayerInfo(name);
            break;
    }
    if (!c) {
        return null;
    }
    const character = new Character(c, type);
    return character;

}

function reloadPage() {
    location.reload();
}

var incrementTimer=null;

var activity=false;
function someActivity() {
    activity=true;
}

function doActivityIncrement() {
    // try to fix odd scrolling
/*
    const element = document.getElementById("shardheader");
    if (element) {
        window.scrollTo(0,0);
        element.scrollIntoView();
    }
*/
    if (activity || !incrementTimer) {
        if (getAuth(firebase).currentUser) {
            //console.log("log activity", new Date());
            gtag("event", "active", {"metric1":1});
            if (campaign.getWatchKey()) {
                // watch event
                gtag("event", "watchactive", {"metric4":1});
            } else if (campaign.isDefaultCampaign()) {
                // nothing for default
            } else if (campaign.isPlayerMode()){
                // play event
                gtag("event", "playactive", {"metric3":1});
            } else {
                // gm event
                gtag("event", "gmactive", {"metric2":1});
            }
            campaign.incrementTime();
        } else {
            incrementTimer=null;
            activity=false;
            return;
        }
    } else {
        //console.log("no activity", new Date())
    }
    activity=false;
    incrementTimer = setTimeout(doActivityIncrement, 60000);
}

let lastRuleset;

const el = document.getElementById('shardplay');
if (el) {
    try {
        const appRoot = React.createElement(sizeMe({monitorHeight:true, monitorWidth:true})(AppRoot), null);
        const appval = createRoot(el);
        appval.render(appRoot)
    } catch(err){
        window.alert("Create failed"+err.message);
    }
}
