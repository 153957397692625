const React = require('react');
const {campaign,globalDataListener,getExtensionEntryCheckFn} = require('../lib/campaign.js');
const {displayMessage} = require('./notification.jsx');
const Parser = require("../lib/dutils.js").Parser;
const stdvalues = require('../lib/stdvalues.js');
import TextField from '@material-ui/core/TextField';
const {Rendersource} = require("./rendersource.jsx");
const {SpellPicker,getAllSpellSources} = require('./renderspell.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {RenderFeature, getClassTable,EquipmentList, FeatureListEdit, CheckPick, AbilityProficiency, AbilityRequirements, CustomLevelsListEdit, DescriptionOptionsListEdit, PickCountsByLevel, renderPrintFeatures,printDescriptionOptions} = require('./features.jsx');
const {SelectVal, TextPlusEdit, TextVal, PickVal, CheckVal, defaultSourceFilter,defaultGamesystemFilter, classBookFilter, DeleteEntry, SelectMultiVal,alwaysArray,nullDefault} = require('./stdedit.jsx');
const {getFeaturesTable} = require('../lib/character.js');
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {ExtraArtList,ArtZoomList,FloatArt,FloatCardArt,printFloatArt} = require('./renderart.jsx');
const {AddChatEntry,getClassHref} = require('./renderhref.jsx');
import {htmlFromEntry} from "../lib/entryconversion.js";
const {spellcasterOptions,abilityDCOptions} = stdvalues;

class RenderClasses extends React.Component {
    constructor(props) {
        super(props);
        this.state= {list:this.getList()};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "classes");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "classes");
    }

    onDataChange() {
        this.setState({list:this.getList()});
    }

    getList() {
        const list= campaign.getClassesListByName();
        return list;
    }

    render() {
        return <div>
            <ListFilter 
                list={this.state.list}
                showCards
                showAll
                largeCard
                render={this.renderCard.bind(this)}
                filters={classFilters}
                getListRef={this.saveRef.bind(this)}
                entryCheckFn={getExtensionEntryCheckFn(null,true)}
                headerOutline={this.props.headerOutline}
            />
            <ShowClass open={this.state.showClass} cclass={this.state.selectedClass}  extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.showClass.bind(this, false,null)}/>
        </div>;
    }

    showClass(show, cclass){
        this.setState({showClass:show, selectedClass:cclass});
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(cname) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(cname))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.showClass.bind(this,true,prev.className):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.showClass.bind(this,true, next.className):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    renderCard(it, width, bc) {
        return <div key={it.name} style={{width:width-10}} className="defaultbackground shadow-3 br3 ma1 stdcontent overflow-hidden" onClick={this.showClass.bind(this,true,it.className)}>
            <div className="h-100 hoverhighlight pa1">
                <div className="f1 titlecolor">{it.displayName}</div>
                <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={it}/></div>
                <FloatCardArt art={it.defaultArt} width={width}/>
                <Renderentry className="ll-8"  entry={it.summary||it.description}/>
                {it.abilitiesHint?<div className="ll-4">
                    <div className="b">Traits</div>
                    <Renderentry entry={it.abilitiesHint}/>
                </div>:null}
            </div>
        </div>
    }
}

const classFilters=[
    defaultSourceFilter,
    classBookFilter,
    defaultGamesystemFilter
];

class ClassSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({selectedClass:null});
        }
    }

    handleClose(savechanges) {
        if (savechanges){
            this.props.onClose(this.state.selectedClass);
        } else {
            this.props.onClose(null);
        }
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        const classes = campaign.getClassesListByName();
        const character = this.props.character;
        const extensionEntryCheckFn = getExtensionEntryCheckFn(character,true);

        return  <Dialog
            open
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Select Class
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={classes}
                    showCards
                    showAll
                    largeCard
                    render={this.renderCard.bind(this)}
                    filters={[defaultSourceFilter,classBookFilter,defaultGamesystemFilter]}
                    getListRef={this.saveRef.bind(this)}
                    entryCheckFn={extensionEntryCheckFn}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ShowClass open={this.state.selectedClass} cclass={this.state.selectedClass} character={character} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.clickClass.bind(this, null)} classLevel={this.state.classLevel} disableEdit/>
        </Dialog>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(name) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(name))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.clickClass.bind(this,prev.className):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickClass.bind(this,next.className):null} color="primary"><span className="b fas fa-step-forward"/></Button>
            <Button onClick={this.handleClose.bind(this,true)} color="primary">Select</Button>
        </span>
    }

    renderCard(cls, width,bc) {
        const character = this.props.character;
        let prereq = null;
        let level = null;
        if (character) {
            const maxC = character.getMaxClassLevel(cls.className);
            if (maxC && maxC.level) {
                level = " Level "+(maxC.level+1);
            } else if (character.level > 0) {
                prereq = missingRequirements();
            }
        }
        return <div key={cls.name} style={{width:width-10}} className={"shadow-3 br3 ma1 pa1 stdcontent hoverhighlight overflow-hidden "+(bc||"")} onClick={this.clickClass.bind(this,cls.className)}>
            <div className="f1 titlecolor">{cls.displayName}{level}</div>
            {prereq?<div className="light-red">{prereq}</div>:null}
            <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={cls}/></div>
            <FloatCardArt art={cls.defaultArt} width={width}/>
            <Renderentry className="ll-8" entry={cls.summary||cls.description}/>
            {cls.abilitiesHint?<div className="ll-4">
                <div className="b">Traits</div>
                <Renderentry entry={cls.abilitiesHint}/>
            </div>:null}
        </div>

        function missingRequirements() {
            let ret = "";

            if (cls.multiclassing && cls.multiclassing.requirements) {
                for (let i in cls.multiclassing.requirements) {
                    let r = cls.multiclassing.requirements[i];
                    if (i == "or") {
                        let foundOr = false;
                        let orString = null;

                        r = r[0];
                        for (let x in r) {
                            if (character.getAbility(x).score >= r[x]) {
                                foundOr = true;
                                break;
                            }

                            if (orString) {
                                orString = orString + " or "+stdvalues.abilityNames[x]+" "+r[x];
                            } else {
                                orString = stdvalues.abilityNames[x]+" "+r[x];
                            }
                        }
                        if (!foundOr) {
                            if (!ret) {
                                ret = "missing ";
                            } else {
                                ret = ret+", ";
                            }
                            ret = ret + orString;
                        }
                    } else if (character.getAbility(i).score < r) {
                        if (!ret) {
                            ret = "missing ";
                        } else {
                            ret = ret+", ";
                        }
                        ret = ret + stdvalues.abilityNames[i] + " "+r;
                    }
                }
            }
            if (ret != "") {
                ret= " ("+ret+")"
            }
            return ret;
        }
    }

    clickClass(selectedClass){
        const character = this.props.character;

        const maxC = character && character.getMaxClassLevel(selectedClass);

        this.setState({selectedClass, classLevel:(maxC&&maxC.level)||0});
    }
}

class SubclassSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({selectedSubclass:null});
        }
    }

    handleClose(savechanges) {
        if (savechanges){
            this.props.onClose(this.state.selectedSubclass);
        } else {
            this.props.onClose(null);
        }
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        const subclasses = campaign.getSubclasses(this.props.cclass);
        const character=this.props.character;
        const extensionEntryCheckFn = getExtensionEntryCheckFn(character);

        return  <Dialog
            open
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                {this.props.title||"Select Subclass"}
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={subclasses}
                    showCards
                    showAll
                    largeCard
                    render={this.renderCard.bind(this)}
                    filters={[defaultSourceFilter,classBookFilter,defaultGamesystemFilter]}
                    getListRef={this.saveRef.bind(this)}
                    entryCheckFn={extensionEntryCheckFn}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    {!this.props.noSelect?"Cancel":"Close"}
                </Button>
            </DialogActions>
            <ShowSubclass open={this.state.selectedSubclass} cclass={this.props.cclass}  subclass={this.state.selectedSubclass} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.clickSubclass.bind(this, null)} disableEdit={!this.props.editable}/>
        </Dialog>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(name) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(name))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.clickSubclass.bind(this,prev.subclassName):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickSubclass.bind(this,next.subclassName):null} color="primary"><span className="b fas fa-step-forward"/></Button>
            {!this.props.noSelect?<Button onClick={this.handleClose.bind(this,true)} color="primary">Select</Button>:null}
        </span>
    }

    renderCard(cls, width,bc) {
        return <div key={cls.name} style={{width:width-10}} className={"shadow-3 br3 ma1 pa1 stdcontent hoverhighlight overflow-hidden "+(bc||"")} onClick={this.clickSubclass.bind(this,cls.subclassName)}>
            <div className="f1 titlecolor">{cls.displayName}</div>
            <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={cls}/></div>
            <FloatCardArt art={cls.defaultArt} width={width}/>
            <div className="ll-10">
                <Renderentry  entry={cls.summary||cls.description}/>
            </div>
        </div>
    }

    clickSubclass(selectedSubclass){
        this.setState({selectedSubclass});
    }
}


class Renderclass extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {expanded:false};
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.id=campaign.newUid();
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "classes");
        this.scrollToLevel();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.cclass != prevProps.cclass) || (this.props.entry != prevProps.entry) || (this.props.classLevel!= prevProps.classLevel)) {
            this.scrollToLevel();
        }
    }

    scrollToLevel() {
        if (this.props.classLevel){
            const t=this;
            setTimeout(function (){
                const id = t.id+"."+t.props.classLevel+".0";
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            },20);
        }
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "classes");
    }

    onDataChange() {
        this.setState({allClasses:campaign.getClasses()});
    }

	render() {
        var entry = this.props.entry;
        let sub = null;
        let subclass = null;

        if (!entry && this.props.cclass) {
            //console.log("class:"+this.props.cclass);
            entry = campaign.getClassInfo(this.props.cclass);
            //console.log("entry:"+JSON.stringify(entry));
        }
        if (entry && this.props.subclass) {
            sub = campaign.getSubclassInfo(this.props.subclass);
            subclass = sub?sub.subclassName:this.props.subclass;
        }
        const translate= new ClassTranslate(entry, sub);

        switch (this.props.details){
            default:
            case "full":
                if (!entry) {
                    return <div>Unknown class {this.props.cclass}</div>;
                }
                if (this.props.level) {
                    return <div className="stdcontent" key={entry.className + (subclass||"")}>
                        <ClassTable classInfo={entry} subclassInfo={sub} translate={translate} level={this.props.level} idBase={this.id+"."}/>
                        {this.getAllFeaturesBlock(entry, subclass, this.props.level, translate)}
                    </div>;
                }
                return <div className="stdcontent" key={entry.name}>
                    {this.props.noTitle?null:<h1>The {entry.displayName}{sub?(" - "+sub.displayName):null}</h1>}
                    {this.props.noDescription?null:<Renderentry entry={entry.description}/>}
                    {this.props.noDescription||!sub?null:<Renderentry entry={sub.description}/>}
                    <ClassTable classInfo={entry} subclassInfo={sub} translate={translate} idBase={this.id+"."}/>
                    <FloatArt art={entry.defaultArt} artList={entry.artList} defaultArt={entry.defaultArt}/>
                    {this.getAllFeaturesBlock(entry, subclass,0,translate)}
                    {(entry.descriptionOptions||(sub&&sub.descriptionOptions))?<h2>Character Description Options</h2>:null}
                    {entry.descriptionOptions?<DescriptionOptionsListEdit descriptions={entry.descriptionOptions} noBackground/>:null}
                    {(sub && sub.descriptionOptions)?<DescriptionOptionsListEdit descriptions={sub.descriptionOptions} noBackground/>:null}
                    {this.props.noSource?null:<Rendersource entry={sub||entry}/>}
                </div>;
            case "table":
                return <ClassTable className="stdcontent" classInfo={entry} subclassInfo={sub} idBase={this.id+"."}/>;
        }
    }
    
    getAllFeaturesBlock(cls,subclass, level,translate) {
        const search=this.props.search;
        var cf = [];
        const {table} = getFeaturesTable(cls, subclass);

        if (level) {
            const r = table[level-1];
            for (let x in r) {
                cf.push(<RenderFeature key={x} feature={r[x]} translate={translate}/>);
            }
            return <div>
                <h1>Class Features Level {level}</h1>
                {cf}
            </div>;
        }

        for (let i in table) {
            const r = table[i];

            for (let x in r) {
                const f = r[x];
                const id = this.id+"."+i+"."+x;
                if (search && (f.name == search)) {
                    if (!this.searchTimer) {
                        const t=this;
                        this.searchTimer = setTimeout(function (){
                            const element = document.getElementById(id);
                            if (element) {
                                element.scrollIntoView();
                            }
                        },20);
                    }
                }
                cf.push(<RenderFeature key={i+"."+x} id={id} feature={r[x]} translate={translate}/>);
            }
        }

        return <div>
            <h1>Class Features</h1>
            <div>{"As a "+(cls.displayName||"").toLowerCase()+", you gain the following class features."}</div>
            <Renderentry depth={0} entry={getFeatureBlock(cls)}/>
            <h3>Equipment</h3>
            <EquipmentList equipment={cls.startingEquipment}/>
            <div className="cb"/>
            {cf}
        </div>;
    }


}

function getFeatureBlock(cls) {
    const cname = (cls.displayName||"").toLowerCase();
    var entry = {
        depth:1,
        entries:[
            {
                name:"Hit Points",
                entries:[
                    "{@b Hit Dice:} {@dice "+cls.hd.number+"d"+cls.hd.faces+"} per "+cname+" level",
                    "{@b Hit Points at 1st Level:} "+cls.hd.faces+" + your Constitution modifier",
                    "{@b Hit Points at Highter Levels:} {@dice "+cls.hd.number+"d"+cls.hd.faces+"} (or "+(cls.hd.faces*cls.hd.number/2 + cls.hd.number)+ 
                            ") + your Consitution modifier per "+cname+" level after 1st",
                ]
            },
            {
                name:"Proficiencies",
                entries:[
                    "{@b Armor:} "+stdvalues.getProficiencyString(cls.startingProficiencies.armor),
                    "{@b Weapons:} "+stdvalues.getProficiencyString(cls.startingProficiencies.weapons),
                    "{@b Tools:} "+stdvalues.getProficiencyString(cls.startingProficiencies.tools),
                    " ",
                    "{@b Saving Throws:} "+(cls.proficiency||[]).map(function(a){return Parser.attAbvToFull(a)||a}).join(", "),
                    "{@b Skills:} "+stdvalues.getProficiencyString(cls.startingProficiencies.skills),
                ]
            }
        ]
    }
    return entry;
}


function printClass(className,subclassName,noTitle,header) {
    const list=[];
    const it = subclassName?campaign.getSubclassInfo(subclassName):campaign.getClassInfo(className);
    if (!it) {
        return;
    }
    if (!noTitle) {
        list.push(`<h${header}>${it.displayName}</h${header}>`);
        header++;
    }

    if (campaign.getSourcePreventEmbedding(it.source)) {
        list.push("<p>Not allowed to publish.</p>");
    } else {
        if (!noTitle) {
            header++;
        }
        if (it.description) {
            list.push(`<div>${htmlFromEntry(it.description)}</div>`);
        }

        if (!subclassName) {
            list.push(printClassTable(it));
            list.push(printFloatArt(it.defaultArt));
            list.push('<div class="itemHeader">Class Features</div>')
            list.push(`<div>As a ${(it.displayName||"").toLowerCase()}, you gain the following class features.</div>`);
            list.push(replaceHeaders(htmlFromEntry(getFeatureBlock(it))));
            list.push('<div class="itemHeader">Equipment</div>')
            //<EquipmentList equipment={cls.startingEquipment}/>
        } else {
            list.push(printFloatArt(it.defaultArt));
        }

        for (let l in it.classFeatures) {
            const scl = it.classFeatures[l];
            if (scl) {
                list.push(renderPrintFeatures(scl,header));
            }
        }

        if (it.descriptionOptions) {
            list.push(`<h${header}>Character Description Options</h${header}>`);
            list.push(printDescriptionOptions(it.descriptionOptions));
        }
    }
    return list.join("\n");
}

function printClassTable(classInfo,translate) {
    const ti = getClassTable(classInfo,null,translate);
    let rows=[];
    let grow=[]
    let hrow=[];

    if (ti.grow.spellLabel) {
        grow.push(`<th style="text-align: center" colSpan="${ti.grow.baseCols}"/>`);
        grow.push(`<th style="text-align: center;white-space: nowrap" colSpan="${ti.grow.spellLabelSpan}"><b>${ti.grow.spellLabel}</b></th>`);
    }
    for (let i in ti.hrow) {
        const l = ti.hrow[i];
        if (l=="Features") {
            hrow.push(`<th>${l}</th>`)
        } else {
            hrow.push(`<th style="text-align: center">${l}</th>`)
        }
    }

    for (let i in ti.rows) {
        const row=[];
        const cols =ti.rows[i];
        for (let c in cols) {
            const col = cols[c];
            if (Array.isArray(col)) {
                const features=[];
                for (let x in col) {
                    const f=col[x];
                    features.push(f.name);
                }
                row.push(`<td width="50%">${(features.length>0)?features.join(", "):"-"}</td>`);

            } else {
                row.push(`<td style="text-align: center">${col}</td>`);
            }
        }
        rows.push(`<tr>${row.join("\n")}</tr>`);
    }

    return `<table><thead><tr>${grow.join("")}</tr><tr>${hrow.join("")}</tr></thead><tbody>${rows.join("\n")}</tbody></table>`;
}

function replaceHeaders(str) {
    str = str.replace(/<h\d>/g,'<div class="itemHeader">');
    return str.replace(/<\/h\d>/g,'</div>');
}

class ClassTable extends React.Component {
    render() {
        const {classInfo,subclassInfo,translate, level,idBase} = this.props;

        const ti = getClassTable(classInfo,subclassInfo,translate, level);
        let rows=[];
        let grow=[]
        let hrow=[];

        if (ti.grow.spellLabel) {
            grow.push(<th className="tc" key="std" colSpan={ti.grow.baseCols}/>);
            grow.push(<th className="tc bb nowrap" key="spells" colSpan={ti.grow.spellLabelSpan}>{ti.grow.spellLabel}</th>);
        }
        for (let i in ti.hrow) {
            const l = ti.hrow[i];
            if (l=="Features") {
                hrow.push(<th className="tl" key={i}>{l}</th>)
            } else {
                hrow.push(<th className="tc" key={i}>{l}</th>)
            }
        }

        for (let i in ti.rows) {
            const row=[];
            const cols =ti.rows[i];
            for (let c in cols) {
                const col = cols[c];
                if (Array.isArray(col)) {
                    const features=[];
                    for (let x in col) {
                        const f=col[x];
                        if (features.length) {
                            features.push(", ")
                        }
                        features.push(<a key={x} onClick={onClickFeature.bind(null, f.r, f.num)}>{f.name}</a>);
                    }
                    row.push(<td className="tl w-50" key={c}>{(features.length>0)?features:"-"}</td>);

                } else {
                    row.push(<td className="tc" key={c}>{col}</td>);
                }
            }
            rows.push(<tr key={i}>{row}</tr>);
        }

        return <blockquote>
            <h1>{this.props.title}</h1>
            <div className="overflow-x-auto">
                <table>
                    <thead>
                        <tr>{grow}</tr>
                        <tr>{hrow}</tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
            
        </blockquote>;

        function onClickFeature(level, num, evt) {
            evt.stopPropagation();
            evt.preventDefault();
            const element = document.getElementById(idBase+level+"."+num);
            if (element) {
                element.scrollIntoView();
            } else {
                const element = document.getElementById(idBase+level);
                if (element) {
                    element.scrollIntoView();
                }    
            }
        }
    }
}

const subclassPickOptions = {
    "-1":"never",
    1:1,
    2:2,
    3:3
}
class EditClass extends React.Component {
    constructor(props) {
        super(props);

        const classInfo = props.subclass?campaign.getSubclassInfo(props.subclass):campaign.getClassInfo(props.cclass);
	    this.state= {classInfo};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.cclass != prevProps.cclass) || (this.props.subclass != prevProps.subclass)||(this.props.open && this.props.open!= prevProps.open)) {
            const classInfo = this.props.subclass?campaign.getSubclassInfo(this.props.subclass):campaign.getClassInfo(this.props.cclass);
            this.setState({classInfo,hideBase:false});
        }
    }

    render() {
        const {classInfo,hideBase} = this.state;
        if (!this.props.open || !classInfo) {
            return null;
        }

        const idBase = classInfo.className+".edit."
        const classFeatures = classInfo.classFeatures;
        const baseClass = this.props.subclass?campaign.getClassInfo(this.props.cclass):null;
        if (this.props.subclass && !baseClass) {
            return <Dialog
                open
                maxWidth="md"
                fullWidth
            >
                <DialogTitle onClose={this.props.onClose}></DialogTitle>
                <DialogContent className="stdcontent">
                    Missing base class
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        }
        const levels = [];
        const editBase = (!this.props.subclass);
        const baseClassInfo = baseClass || classInfo;
        const {weaponProficiencies,toolProficiencies} = campaign.getItemExtensions();
        const skillsList = campaign.getAllSkills();
        const variantBase = campaign.getClassInfo(baseClassInfo.variantBase);
        const translate= new ClassTranslate(this.props.subclass?baseClass:classInfo, this.props.subclass?classInfo:null);

        for (let i =0; i<20; i++) {
            levels.push(<div key={idBase+i} id={idBase+i}>
                <h2>Level {i+1}</h2>
                <FeatureListEdit 
                    editable 
                    idBase={idBase+i+"."} 
                    baseFeatures={!hideBase&&baseClass&&baseClass.classFeatures[i]} 
                    features={classFeatures[i]}
                    displayType={editBase?"Class":"Subclass"} type={baseClassInfo.displayName} subtype={editBase?null:classInfo.displayName} level={i+1} name={classInfo.name}
                    onChange={this.onChangeFeatures.bind(this, i)}
                    translate={translate}
                />
            </div>);
        }
        return  <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            {this.props.subclass?<DialogTitle onClose={this.props.onClose}>
                <div className="w-100 flex items-center">
                    <div className="mr2">{baseClassInfo.displayName}</div>
                    <div className="flex-auto ml2">
                        <TextVal    
                            text={classInfo.displayName}
                            fullWidth
                            inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                            onChange={this.onChangeField.bind(this,"displayName")}
                        />
                    </div>
                </div>
            </DialogTitle>: <DialogTitle onClose={this.props.onClose}>
               <TextVal    
                    text={classInfo.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeField.bind(this,"displayName")}
                />
            </DialogTitle>}
            <DialogContent className="stdcontent">
                {variantBase?<div className="mb1 b i near-black">Variant of {variantBase.displayName}</div>:null}
                {!editBase&&!hideBase?<div className="bg-gray-50"><Renderentry entry={baseClass&&baseClass.description}/></div>:null}
                <EntityEditor onChange={this.onChangeField.bind(this,"description")} entry={classInfo.description} placeholder="Description"/>
                <h3>Summary</h3>
                <EntityEditor onChange={this.onChangeField.bind(this,"summary")} entry={classInfo.summary} placeholder="Summary text"/>
                {!hideBase?<ClassTable classInfo={this.props.subclass?baseClass:classInfo} subclassInfo={this.props.subclass?classInfo:null} translate={translate} idBase={idBase}/>:null}
                {!hideBase?<div className={editBase?"":"bg-gray-50"}>
                    <h3>Abilities Hint</h3>
                    <div className="mb1">
                        <EntityEditor placeholder="Hint text" onChange={this.onChangeField.bind(this, "abilitiesHint")} entry={baseClassInfo.abilitiesHint}/>
                    </div>
                    <h3>Subclasses</h3>
                    <PickVal values={subclassPickOptions} isNum noEdit={!editBase} onClick={editBase?this.onChangeField.bind(this, "startSubclass"):null}><div className="mt1 mb2"><span className={editBase?"hover-bg-contrast":""}><b>Select at level: {subclassPickOptions[baseClassInfo.startSubclass||1]}</b></span></div></PickVal>
                    {(baseClassInfo.startSubclass||1)>0?<div className="mb1"><TextPlusEdit label="Title" text={baseClassInfo.subclassTitle} editable={editBase} onChange={this.onChangeField.bind(this, "subclassTitle")}/></div>:null}
                    <h3>Hit Points</h3>
                    <PickVal values={hitDiceOptions} noEdit={!editBase} onClick={editBase?this.onChangeHD.bind(this):null}><div className="mb2"><span className={editBase?"hover-bg-contrast":""}><b>Hit Dice: 1d{baseClassInfo.hd && baseClassInfo.hd.faces}</b> per level</span></div></PickVal>
                    <h3>Proficiencies</h3>
                    <CheckPick label="Armor:" value={baseClassInfo.startingProficiencies.armor||{}} options={stdvalues.armorTypes} editable={editBase} onChange={this.onChangeProficiency.bind(this, "armor")}/>
                    <CheckPick label="Weapons:" value={baseClassInfo.startingProficiencies.weapons||{}} options={weaponProficiencies} editable={editBase} onChange={this.onChangeProficiency.bind(this, "weapons")}/>
                    <CheckPick label="Tools:" value={baseClassInfo.startingProficiencies.tools||{}} options={toolProficiencies} editable={editBase} onChange={this.onChangeProficiency.bind(this, "tools")}/>
                    <AbilityProficiency editable={editBase} proficiency={baseClassInfo.proficiency} onChange={this.onChangeField.bind(this, "proficiency")}/>
                    <CheckPick label="Skills:" showProficiency value={baseClassInfo.startingProficiencies.skills||{}} options={skillsList} editable={editBase} onChange={this.onChangeProficiency.bind(this, "skills")}/>
                    <h3>Multiclassing</h3>
                    <AbilityRequirements editable={editBase} requirements={baseClassInfo.multiclassing && baseClassInfo.multiclassing.requirements} onChange={this.onChangeMulticlassing.bind(this, "requirements")}/>
                    <CheckPick label="Armor:" value={(baseClassInfo.multiclassing && baseClassInfo.multiclassing.proficienciesGained && baseClassInfo.multiclassing.proficienciesGained.armor)||{}} options={stdvalues.armorTypes} editable={editBase} onChange={this.onChangeMulticlassingProficiencies.bind(this, "armor")}/>
                    <CheckPick label="Weapons:" value={(baseClassInfo.multiclassing && baseClassInfo.multiclassing.proficienciesGained && baseClassInfo.multiclassing.proficienciesGained.weapons)||{}} options={weaponProficiencies} editable={editBase} onChange={this.onChangeMulticlassingProficiencies.bind(this, "weapons")}/>
                    <CheckPick label="Tools:" value={(baseClassInfo.multiclassing && baseClassInfo.multiclassing.proficienciesGained && baseClassInfo.multiclassing.proficienciesGained.tools)||{}} options={toolProficiencies} editable={editBase} onChange={this.onChangeMulticlassingProficiencies.bind(this, "tools")}/>
                    <CheckPick label="Skills:" showProficiency value={(baseClassInfo.multiclassing && baseClassInfo.multiclassing.proficienciesGained && baseClassInfo.multiclassing.proficienciesGained.skills)||{}} options={skillsList} editable={editBase} onChange={this.onChangeMulticlassingProficiencies.bind(this, "skills")}/>
                    <h3>Equipment</h3>
                    <EquipmentList editable={editBase} equipment={baseClassInfo.startingEquipment} onChange={this.onChangeField.bind(this,"startingEquipment")}/>
                </div>:null}
                <h3>Spellcasting</h3>
                <div className="mb2">
                    <SelectMultiVal className="dib mr2" helperText="Spell List" values={this.getSpellClassOptions()} onChange={this.onChangeField.bind(this,"spellList")} value={alwaysArray(nullDefault(classInfo.spellList,baseClassInfo.displayName))}/>
                    <SelectMultiVal className="dib mr2" helperText="Spell Sources" values={getAllSpellSources()} onChange={this.onChangeField.bind(this,"spellSources")} value={classInfo.spellSources}/>
                    <Button className="ml2" onClick={this.showSpellPicker.bind(this, classInfo.assignedSpellList)} color="primary" variant="outlined" size="small">Assign Spells to Class</Button> {classInfo.assignedSpellList?(classInfo.assignedSpellList.length+" assigned spells"):null}
                </div>
                {(!baseClass || !baseClass.spellcaster)?<div>
                    <PickVal values={spellcasterOptions} onClick={this.onChangeField.bind(this,"spellcaster")}><div className="mb2"><span className="hover-bg-contrast"><b>Spellcaster</b> {spellcasterOptions[classInfo.spellcaster||"none"]}</span></div></PickVal>
                    <PickVal values={abilityDCOptions} onClick={this.onChangeField.bind(this,"abilityDC")}><div className="mb2"><span className="hover-bg-contrast"><b>Ability DC</b> {abilityDCOptions[classInfo.abilityDC]||"None"}</span></div></PickVal>
                    {classInfo.spellcaster?<div>
                        <PickCountsByLevel levelsCount={classInfo.knownCantrips} label="Cantrips" onChange={this.onChangeField.bind(this,"knownCantrips")}/>
                        <PickCountsByLevel levelsCount={classInfo.knownSpells} label="Known Spells" onChange={this.onChangeField.bind(this,"knownSpells")}/>
                        <PickCountsByLevel levelsCount={classInfo.knownRituals} label="Known Rituals" onChange={this.onChangeField.bind(this,"knownRituals")}/>
                        <PickCountsByLevel levelsCount={classInfo.preparedSpellCounts} label="Prepared Spells" onChange={this.onChangeField.bind(this,"preparedSpellCounts")}/>
                        <div>
                            <CheckVal label="Cast using spell points" value={classInfo.useSpellPoints||false} onChange={this.onChangeField.bind(this,"useSpellPoints")}/>
                        </div>
                        <CheckVal label="Prepare Spells by ability DC + class level" value={classInfo.prepareSpells||false} onChange={this.onChangeField.bind(this,"prepareSpells")}/>
                        <div>
                            <CheckVal label="Ritual Caster" value={classInfo.ritualCaster||false} onChange={this.onChangeField.bind(this,"ritualCaster")}/> &nbsp;
                            {(classInfo.prepareSpells || classInfo.preparedSpellCounts)&&classInfo.ritualCaster?<CheckVal label="Ritual cast unprepared spells" value={classInfo.ritualCastUnprepared||false} onChange={this.onChangeField.bind(this,"ritualCastUnprepared")}/>:null}
                        </div>
                        {this.getSpellTranslations(classInfo)}
                    </div>:null}
                </div>:null}
                <h3>Custom Level Attributes</h3>
                <CustomLevelsListEdit customLevels={classInfo.customLevels} onChange={this.onChangeField.bind(this, "customLevels")}/>
                <h1>Class Features</h1>
                {levels}
                <h2>Character Description Options</h2>
                {baseClass?<DescriptionOptionsListEdit descriptions={baseClass.descriptionOptions}/>:null}
                <DescriptionOptionsListEdit descriptions={classInfo.descriptionOptions} onChange={this.onChangeField.bind(this, "descriptionOptions")} editable/>
                <h2>Artwork</h2>
                <ExtraArtList artList={classInfo.artList} onChange={this.onChangeArtwork.bind(this)} pickArt defaultArt={classInfo.defaultArt}/>
                <SpellPicker 
                    open={this.state.showSpellPicker} 
                    maxSpellLevel={20}
                    selectedSpells={this.state.selectedSpells}
                    hideCounts 
                    onClose={this.closeSpellPicker.bind(this)}
                />
                {editBase?<div className="pt1">
                    <SelectVal value={classInfo.gamesystem||"5e"} values={stdvalues.gamesystemOptions} onClick={this.onChangeField.bind(this,"gamesystem")} helperText="Game System"/>
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showExtraArt.bind(this)} color="primary">
                    Select Artwork
                </Button>
                <DeleteEntry type="classes" entry={classInfo} onClose={this.props.onClose}/>
                {baseClass?<Button onClick={this.toggelBase.bind(this)} color="primary">
                    {hideBase?"Show Base":"Hide Base"}
                </Button>:null}
                <Button onClick={this.saveData.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.props.onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ArtZoomList editable open={this.state.showExtraArtDialog} artList={classInfo.artList} onClose={this.onSaveArtwork.bind(this)} pickArt defaultArt={classInfo.defaultArt} defaultSearch={classInfo.displayName}/>
        </Dialog>;
    }

    toggelBase() {
        this.setState({hideBase:!this.state.hideBase});
    }

    showExtraArt() {
        this.setState({showExtraArtDialog:true});
    }

    onSaveArtwork(artList, defaultArt, defaultToken) {
        if (artList) {
            this.onChangeArtwork(artList, defaultArt,defaultToken);
        }
        this.setState({showExtraArtDialog:false})
    }

    showSpellPicker(spellList) {
        const spells = spellList||[];
        const selectedSpells = {};
        for (let i in spells) {
            selectedSpells[spells[i].toLowerCase()] = true;
        }

        this.setState({showSpellPicker:true, selectedSpells});
    }

    closeSpellPicker(spells) {
        this.setState({showSpellPicker:false});
        if (spells) {
            const ne = [];
            for (let i in spells) {
                if (spells[i]) {
                    ne.push(i);
                }
            }
            this.onChangeField("assignedSpellList", ne.length?ne:null);
        }
    }


    getSpellClassOptions() {
        const {classInfo} = this.state;
        const spellList = campaign.getSpellListByName();
        const className = classInfo.className;
        const baseClass = campaign.getClassInfo(className);
        const classes = [(baseClass?.name==classInfo.name)?classInfo.displayName:baseClass?.displayName];

        for (var i in spellList){
            const s=spellList[i];
            for (let x in s.classes) {
                const className = s.classes[x];
                if (!classes.includes(className)) {
                    classes.push(className);
                }
            }
        }

        classes.sort();
        return classes;
    }

    saveData() {
        campaign.updateCampaignContent("classes", this.state.classInfo);
        this.props.onClose();
    }

    onChangeFeatures(level, features) {
        const classFeatures = this.state.classInfo.classFeatures.concat([]);

        classFeatures[level] = features;
        this.onChangeField("classFeatures", classFeatures);
    }

    onChangeField(field,val) {
        const classInfo = Object.assign({}, this.state.classInfo);
        if (val == "none") {
            val = null;
        } else if (val == "true") {
            val=true;
        } else if (val == "false") {
            val=false;
        }
        if (field == "displayName" && classInfo.spellList == classInfo.displayName) {
            classInfo.spellList = val
        }
        classInfo[field] = val;

        if (classInfo.spellList == classInfo.className) {
            delete classInfo.spellList;
        }
        this.setState({classInfo});
    }

    onChangeArtwork(artList, defaultArt) {
        const classInfo = Object.assign({}, this.state.classInfo)
        classInfo.artList = artList;
        if (defaultArt) {
            classInfo.defaultArt = defaultArt;
        } else {
            delete classInfo.defaultArt;
        }
        this.setState({classInfo})
    }

    onChangeHD(faces) {
        this.onChangeField("hd", {number:1, faces});
    }

    onChangeProficiency(field,val) {
        const startingProficiencies = Object.assign({} , this.state.classInfo.startingProficiencies);
        startingProficiencies[field]=val;
        this.onChangeField("startingProficiencies", startingProficiencies);
    }

    onChangeMulticlassing(field,val) {
        const multiclassing = Object.assign({} , this.state.classInfo.multiclassing || {});
        multiclassing[field]=val;
        this.onChangeField("multiclassing", multiclassing);
    }

    onChangeMulticlassingProficiencies(field,val) {
        const profs = Object.assign({} , (this.state.classInfo.multiclassing && this.state.classInfo.multiclassing.proficienciesGained) || {});
        profs[field]=val;
        this.onChangeMulticlassing("proficienciesGained", profs);
    }

    getSpellTranslations(classInfo) {
        if (!classInfo.translations) {
            return <div><CheckVal label="Rename spell terminology" value={false} onChange={this.onChangeField.bind(this,"translations",{})}/></div>
        }

        const translations=getTranslations(classInfo);
        const list = [];

        for (let t of translations) {
            list.push(<tr key={t}>
                <td className="nowrap">{t}</td>
                <td className="w-100"><TextVal text={classInfo.translations[t]||""} fullWidth onChange={this.onChangeTranslation.bind(this,t)} placeholder={"Rename-"+t}/></td>
            </tr>)
        }

        return <div>
            <div>
                <CheckVal label="Rename spell terminology" value={true} onChange={this.onChangeField.bind(this,"translations",null)}/>
                <table>
                    <tbody>
                        {list}
                    </tbody>
                </table>
            </div>
        </div>
    }

    onChangeTranslation(t,value){
        const translations = Object.assign({},this.state.classInfo.translations);
        translations[t] = value;
        const tl=getTranslations(this.state.classInfo);
        for (let i in translations){
            if (!tl.includes(i)){
                delete translations[i];
            }
        }

        this.onChangeField("translations",translations);
    }
}

function getTranslations(classInfo) {
    let translations=spellGlobalTranslations;

    switch (classInfo.spellcaster){
        case "full":
        case "half":
        case "halfplus":
        case "third":
            translations = translations.concat(classInfo.useSpellPoints?spellPointsTranslations:spellSlotTranslations);
            break;
        case "pact":
        case "halfpact":
        case "thirdpact":
            translations = translations.concat(spellPactTranslations);
            translations = translations.concat(classInfo.useSpellPoints?spellPointsTranslations:spellPactSlotsTranslations);
            break;
    }
    return translations;
}

const hitDiceOptions = {
    4:"d4",
    6:"d6",
    8:"d8",
    10:"d10",
    12:"d12", 
    20:"d20" 
}

class RenderSubclass extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const sc = this.props.subclass;
        const features=[];
        if (!sc) {
            return null;
        }

        const clsInfo = campaign.getClassInfo(sc.className);
        const translate= new ClassTranslate(clsInfo, sc);

        for (let l in sc.classFeatures) {
            const scl = sc.classFeatures[l];
            for (let r in scl) {
                const scf = scl[r];
                features.push(<RenderFeature key={l+"."+r} feature={scf} translate={translate}/>);
            }
        }
        return <div>
            {this.props.noTitle?null:<div className="f1 bb titlebordercolor titletext titlecolor">
                {sc.displayName}
            </div>}
            <FloatArt art={sc.defaultArt} artList={sc.artList} defaultArt={sc.defaultArt} pageSync={this.props.pageSync}/>
            {this.props.noDescription?null:<div className="stdcontent"><Renderentry entry={sc.description}/></div>}
            {features}
            {(sc.descriptionOptions)?<h2>Character Description Options</h2>:null}
            {sc.descriptionOptions?<DescriptionOptionsListEdit descriptions={sc.descriptionOptions} noBackground/>:null}
            {this.props.noSource?null:<Rendersource entry={sc}/>}
            <div className="cb"/>
            {this.props.editable?<EditClass cclass={sc.className} subclass={sc.subclassName} open={this.state.showEdit} onClose={this.doneEditing.bind(this)} onDelete={function(){}}/>:null}
        </div>;
    }

    doneEditing() {
        this.setState({showEdit:false});
    }

    doEdit(event) {
        event.stopPropagation();
        event.preventDefault();
        this.setState({showEdit:true});
    }

    toggleExpanded() {
        this.setState({expanded:!this.state.expanded});
    }
}

class ClassTranslate {
    constructor(classInfo,subclassInfo) {
        this.translations = (subclassInfo&&subclassInfo.translations) || (classInfo&&classInfo.translations) ||{};
    }

    t(str) {
        return this.translations[str]||str;
    }
}

class ShowClass extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({editable:false});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        if (this.state.editable) {
            return <EditClass open cclass={this.props.cclass} subclass={this.props.subclass} onClose={this.props.onClose}/>;
        }
        const baseClass = campaign.getClassInfo(this.props.cclass);
        const subclass = campaign.getSubclassInfo(this.props.subclass);
        const extraButtonsFn = this.props.extraButtonsFn;
        const showEdit = (!campaign.isSharedCampaign()&&!this.props.disableEdit);

        return  <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
                {baseClass && baseClass.displayName} {subclass && subclass.displayName}
            </DialogTitle>
            <DialogContent key={(subclass?.name||"")+(baseClass?.name||"")}>
                <Renderclass cclass={this.props.cclass} subclass={this.props.subclass} noSubclasses search={this.props.search} classLevel={this.props.classLevel}/>
            </DialogContent>
            <DialogActions>
                <AddChatEntry type={subclass?"Subclass":"Class"} displayName={subclass?subclass.displayName:baseClass&&baseClass.displayName} href={getClassHref(this.props.cclass, this.props.subclass)}/>
                {!this.props.subclass&&!this.props.hideSubclasses&&baseClass?<Button onClick={this.showSubclasses.bind(this, true)} color="primary">
                    Subclasses
                </Button>:null}
                {showEdit && !this.props.subclass&&!this.props.hideSubclasses&&baseClass?<Button onClick={this.showNewSubclass.bind(this)} color="primary">
                    New Subclass
                </Button>:null}
                {extraButtonsFn && baseClass && extraButtonsFn(baseClass.name)}
                {showEdit?<DeleteEntry type="classes" entry={subclass || baseClass} onClose={this.props.onClose}/>:null}
                {showEdit?<Button onClick={this.clickEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <SubclassSelector open={this.state.showSubclasses} showNew={showEdit} cclass={baseClass.variantBase||baseClass.className} onClose={this.showSubclasses.bind(this,false)} title={(baseClass.displayName||"")+" "+(baseClass.subclassTitle)} character={this.props.character} noSelect editable={!this.props.disableEdit}/>
            <NewSubclass show={this.state.showNewSubclass} baseClass={this.props.cclass} onClose={this.closeNewSubclass.bind(this)}/>
            <EditClass cclass={this.props.cclass} subclass={this.state.newSubclass} open={this.state.newSubclass} onClose={this.doneEditing.bind(this)}/>
        </Dialog>;
    }

    showNewSubclass() {
        this.setState({showNewSubclass:true});
    }

    closeNewSubclass(cclass, newSubclass) {
        this.setState({showNewSubclass:false, newSubclass});
    }

    doneEditing() {
        this.setState({newSubclass:null});
    }

    showSubclasses(showSubclasses) {
        this.setState({showSubclasses});
    }

    clickEdit() {
        this.setState({editable:true});
    }
}

class ShowSubclass extends React.Component {
    constructor(props) {
        super(props);

	    this.state= { };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({editable:false,showPickClass:false});
        }
    }

    render() {
        const {extraButtonsFn,disableEdit,onClose, open, cclass, subclass:subclassName} = this.props;
        if (!open) {
            return null;
        }
        const {editable, showPickClass} = this.state;
        if (editable) {
            return <EditClass open cclass={cclass} subclass={subclassName} onClose={onClose}/>;
        }

        const baseClass = campaign.getClassInfo(cclass);
        const subclass = campaign.getSubclassInfo(subclassName);
        const allowEdit = (!campaign.isSharedCampaign()&&!disableEdit);

        return  <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <DialogTitle onClose={onClose}>
                {baseClass && baseClass.displayName} {subclass && subclass.displayName}
            </DialogTitle>
            <DialogContent key={(subclass&&subclass.name)}>
                <RenderSubclass subclass={subclass} noExpand/>
            </DialogContent>
            <DialogActions>
                <AddChatEntry type={subclass?"Subclass":"Class"} displayName={subclass?subclass.displayName:baseClass&&baseClass.displayName} href={getClassHref(cclass, subclass)}/>
                {extraButtonsFn && extraButtonsFn(subclass && subclass.name)}
                {allowEdit?<Button onClick={this.showPickClass.bind(this)} color="primary">
                    copy to
                </Button>:null}
                {allowEdit?<DeleteEntry type="classes" entry={subclass} onClose={onClose}/>:null}
                {allowEdit?<Button onClick={this.clickEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
            <ClassSelector open={showPickClass} onClose={this.closePickClass.bind(this, subclass)}/>
        </Dialog>;
    }

    clickEdit() {
        this.setState({editable:true});
    }

    showPickClass() {
        this.setState({showPickClass:true});
    }

    closePickClass(subclass, baseClass) {
        const clsInfo = campaign.getClassInfo(baseClass);
        if (clsInfo) {
            const newClass = Object.assign({}, subclass);
            newClass.name = campaign.newUid();
            newClass.className = clsInfo.variantBase || clsInfo.className;
            newClass.subclassName = campaign.newUid();
            campaign.updateCampaignContent("classes", newClass);

            displayMessage("New subclass created")
            return this.props.onClose();

        }
        this.setState({showPickClass:false});
    }

}

const emptyClass = {
    hd:{faces:6, number:1},
    proficiency:[],
    startingProficiencies:{},
    classFeatures:[]
}

const emptySubClass = {
    classFeatures:[],
    spellList:null,
    spellSources:null
}

class NewClass extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const {name, baseClass, variant} = this.state;
            let classBase = campaign.getClassInfo(baseClass) || emptyClass;

            const newClass = Object.assign({}, classBase);
            newClass.name = campaign.newUid();
            newClass.displayName=name;
            if (variant) {
                newClass.variantBase = newClass.variantBase||newClass.className;
                if (!classBase.spellList && (classBase.spellList !== null)) {
                    newClass.spellList = classBase.displayName;
                }
            } else {
                delete newClass.variantBase;
                newClass.gamesystem = campaign.defaultGamesystem;
            }
            newClass.className = campaign.newUid();
            campaign.updateCampaignContent("classes", newClass);

            return this.props.onClose(newClass.className, newClass.subclassName);
        }
        this.props.onClose();
    };

    onChange(name) {
        this.setState({name});
    }

    componentDidUpdate(prevProps) {
        if (this.props.show != prevProps.show) {
            this.setState({name:"",createType:"subclass",baseClass:null, baseSubclass:null, variant:false});
        }
    }

    render() {
        if (!this.props.show)
            return null;

        const {name, baseClass, showPickClass,variant} = this.state;
        const classInfo = campaign.getClassInfo(baseClass);

    
        return <Dialog
            open={this.props.show||false}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Create Class
            </DialogTitle>
            <DialogContent>
                <TextVal
                    text={name||""} 
                    onChange={this.onChange.bind(this)}
                    helperText="Class Name"
                    fullWidth
                />
                <div className="mv1">
                    {classInfo?("Based on "+classInfo.displayName):null} <Button className="ml2" size="small" onClick={this.showPickClass.bind(this)} color="primary" variant="outlined">
                        {classInfo?"Change":"Pick"} Template Class
                    </Button>
                </div>
                {classInfo?<div className="mv1">
                    <CheckVal label="Create as a variant (will use same subclasses as base)" value={variant||false} onChange={this.onChangeVariant.bind(this)}/>
                </div>:null}
            </DialogContent>
            <DialogActions>
                <Button disabled={!name || name==""} onClick={this.handleClose.bind(this, true)} color="primary">
                    OK
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ClassSelector open={showPickClass} onClose={this.closePickClass.bind(this)}/>
        </Dialog>;
    }

    closePickClass(baseClass) {
        const {name} = this.state;
        const classInfo = campaign.getClassInfo(baseClass);
        if (classInfo) {
            this.setState({baseClass,name:name||(classInfo.displayName+" copy")});
        }
        this.setState({showPickClass:false});
    }

    onChangeVariant(variant) {
        this.setState({variant})
    }

    showPickClass() {
        this.setState({showPickClass:true});
    }
}

class NewSubclass extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const {name,baseSubclass, baseClass} = this.state;
            const clsInfo = campaign.getClassInfo(baseClass);
            const classBase = campaign.getSubclassInfo(baseSubclass) || emptySubClass;

            if (clsInfo) {
                const newClass = Object.assign({}, classBase);
                newClass.name = campaign.newUid();
                newClass.displayName=name;
                newClass.className = clsInfo.variantBase || clsInfo.className;
                newClass.subclassName = campaign.newUid();
                campaign.updateCampaignContent("classes", newClass);

                return this.props.onClose(newClass.className, newClass.subclassName);
            }
        }
        this.props.onClose();
    };

    onChange(name) {
        this.setState({name});
    }

    componentDidUpdate(prevProps) {
        if (this.props.show != prevProps.show) {
            this.setState({name:"",baseClass:this.props.baseClass, baseSubclass:null});
        }
    }

    render() {
        if (!this.props.show)
            return null;

        const {name,baseClass,baseSubclass, showSubclassPick} = this.state;
        const classInfo = campaign.getClassInfo(baseClass);

        if (!classInfo) {
            return <ClassSelector open onClose={this.closePickClass.bind(this)}/>;
        }
        const subclassBase = campaign.getSubclassInfo(baseSubclass);

        return <Dialog
            open={this.props.show||false}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Create Subclass of {classInfo?.displayName}
            </DialogTitle>
            <DialogContent>
                <TextVal
                    text={name||""}
                    onChange={this.onChange.bind(this)}
                    helperText="Subclass Name"
                    fullWidth
                />
                <div className="mv1">
                    {subclassBase?("Based on "+subclassBase.displayName):null} <Button className="ml2" size="small" onClick={this.showPickSubclass.bind(this)} color="primary" variant="outlined">
                        {subclassBase?"Change":"Pick"} Template Subclass
                    </Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={!name || name==""} onClick={this.handleClose.bind(this, true)} color="primary">
                    OK
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <SubclassSelector open={showSubclassPick} cclass={baseClass} onClose={this.closePick.bind(this)}/>
        </Dialog>;
    }

    showPickSubclass() {
        this.setState({showSubclassPick:true});
    }

    closePick(baseSubclass) {
        const classInfo = campaign.getSubclassInfo(baseSubclass);
        if (classInfo) {
            this.setState({baseSubclass,name:name||(classInfo.displayName+" copy")});
        }
        this.setState({showSubclassPick:false});
    }

    closePickClass(baseClass) {
        if (baseClass) {
            this.setState({baseClass});
        } else {
            this.handleClose(false);
        }
    }
}

const createClassOptions = {
    class:"Copy of the class",
    variant:"Variant of the class",
    subclass:"Subclass for the class"
}

class ClassesHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state={};
    }

    onNew() {
        this.setState({showNew:true});
    }

    onNewSubclass() {
        this.setState({showNewSubclass:true});
    }

    onClose(className, subclassName) {
        if (className) {
            this.setState({showEdit:true, className, subclassName});
        }
        this.setState({showNew:false, showNewSubclass:false});
    }

    doneEditing() {
        this.setState({showEdit:false});
    }

    render () {
        return <span>Classes&nbsp;
            {!campaign.isSharedCampaign()?<Button className="minw2 mr1" color="primary" variant="outlined" size="small" onClick={this.onNew.bind(this, false)}>New Class</Button>:null}
            {!campaign.isSharedCampaign()?<Button className="minw2" color="primary" variant="outlined" size="small" onClick={this.onNewSubclass.bind(this, false)}>New Subclass</Button>:null}
            <NewClass show={this.state.showNew} onClose={this.onClose.bind(this)}/>
            <NewSubclass show={this.state.showNewSubclass} onClose={this.onClose.bind(this)}/>
            <EditClass cclass={this.state.className} subclass={this.state.subclassName} open={this.state.showEdit} onClose={this.doneEditing.bind(this)}/>
        </span>;
    }
}

const spellGlobalTranslations=[
    "Spell",
    "Spells",
    "Cantrip",
    "Cantrips",
];

const spellSlotTranslations=[    
    "Spell Slots per Spell Level",
];

const spellPactTranslations=[
    "Pact",
];

const spellPactSlotsTranslations=[
    "Pact Slots",
    "Slot Level",
];

const spellPointsTranslations=[
    "Spell Points",
];

export {
    RenderClasses,
    Renderclass,
    ClassesHeader,
    ShowClass,
    ShowSubclass,
    NewClass,
    NewSubclass,
    RenderSubclass,
    EditClass,
    ClassSelector,
    SubclassSelector,
    printClass
}