const {campaign,globalDataListener,newUid,upgradeItem,getExtensionEntryCheckFn} = require('../lib/campaign.js');
const React = require('react');
const {getCharacterFromId, removeItemSuffix,adjustEquipmentCoins} = require('../lib/character.js');
const {displayMessage} = require('./notification.jsx');
const {Rendersource} = require("./rendersource.jsx");
const {Chat}= require('../lib/chat.js');
const Parser = require("../lib/dutils.js").Parser;
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {htmlFromEntry} from "../lib/entryconversion.js";
const {EntityEditor,Renderentry,Renderstring} = require('./entityeditor.jsx');
const {TextVal, NumberAdjust,NumberAdjustPlusMinus,escapeRegExp, TextLabelEdit,PickVal, MaxNumberAdjust, SelectVal, SelectTextVal, CheckVal, TextBasicEdit,DeleteWithConfirm, defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter} = require('./stdedit.jsx');
const {getWeaponProficiency, itemTypeFromAbbreviation,abilityNames,rarityLevels,abilitiesListWithZero, bonusList, languagesList,usageTypes,sensesList, joinCommaAnd,pluralString, itemTypeSelect, itemTypes, itemProperties, coinNames,baseCoins, weaponSpecificProficiencies,gamesystemOptions} = require("../lib/stdvalues.js");
const {Character,itemMatchFilter,getItemType} = require('../lib/character.js');
const {ListFilter} = require('./listfilter.jsx');
const {ArtPicker, ExtraArtList,ArtZoomList,FloatArt,printFloatArt} = require('./renderart.jsx');
const {getDiceFromString} = require('../src/diceroller.jsx');
const {AddChatEntry,LinkHref,getItemHref} = require('./renderhref.jsx');

class Items extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnDataChange = this.onDataChange.bind(this);
        this.state= {};
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "items");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "items");
    }

    onDataChange() {
        this.setState({items:campaign.getSortedItemsList()});
    }

	render() {
        return <div className="notecontent" key="items">
            <ListFilter 
                list={campaign.getSortedItemsList()}
                render={getItemListRender}
                filters={itemListFilters}
                headerOutline={this.props.headerOutline}
                noResort
                onClick={this.showItem.bind(this,true)}
                getExtraSearchFn={getItemExtraSearch}
                entryCheckFn={getExtensionEntryCheckFn()}
            />
            <ItemDialog open={this.state.showItem} editable item={this.state.showItemInfo} showGamesystem onClose={this.closeItem.bind(this)}/>
        </div>;
    }

    closeItem(item) {
        if (item) {
            campaign.updateCampaignContent("items", item);
        }
        this.setState({showItem:false});
    }

    showItem(show, name) {
        this.setState({showItem:show, showItemInfo:(show?campaign.getItem(name):null)})
    }
}

const ignoreRarity = ["None", "Unknown", "Varies"];

function getAttunement(it) {
    if (it.reqAttune) {
        if (typeof it.reqAttune == "string") {
            return "(requires attunement "+it.reqAttune+")";
        } else {
            return "(requires attunement)";
        }
    }
    return "";
}

function getBasicItemType(it) {
    const type = getItemType(it);
    return it.nametype||itemTypeFromAbbreviation[type]||"";
}

function getFullItemType(it) {
    const type = getItemType(it);
    const ft = it.nametype||Parser.ITEM_TYPE_JSON_TO_ABV[type] || "";

    if (it.weaponCategory) {
        return it.weaponCategory + " " + ft.toLowerCase();
    }
    return ft;
}

function getPropertyInfo(ip) {
    const ipm = itemProperties;

    for (let i in ipm) {
        if (ipm[i].abbreviation == ip) {
            return ipm[i];
        }
    }
    return null;
}

function getTypeInfo(ip) {
    const ipm = itemTypes;

    for (let i in ipm) {
        if (ipm[i].abbreviation == ip) {
            return ipm[i];
        }
    }
    return null;
}

function getItemPropertyList(it, join) {
    return getItemPropertyListFromProperty(it.property,join);
}

function getItemPropertyListFromProperty(property,join) {
    const list = [];

    for (let i in property||[]) {
        const p = property[i];
        if (typeof p == "object") {
            list.push((p.displayName||"").toLowerCase());
        } else {
            const pi = getPropertyInfo(p);
            if (pi) {
                list.push(pi.entries[0].name.toLowerCase());
            }
        }
    }

    if (join) {
        return joinCommaAnd(list, join);
    }
    return list.join(", ");
}

function getItemPropertyEntries(it) {
    const list= getItemPropertyEntriesRaw(it);
    if (list.length > 0) {
        return <Renderentry entry={{entries:list}} depth={2}/>;
    }

    return null;
}

function getItemPropertyEntriesRaw(it) {
    const list = [];

    const itinfo = getTypeInfo(it.type);
    if (itinfo) {
        list.push(itinfo.entries);
    }

    for (let i in it.property||[]) {
        const p = it.property[i];
        if (typeof p=="object") {
            const entries=[].concat(p.features||[]);
            if (p.description) {
                let desc=(p.description.html||"");
                if (desc.startsWith("<p>")) {
                    desc = desc.replace(/^<p>/,"").replace(/<\/p>$/,"");
                }
                entries.unshift({html:desc, type:"html"});
            }
            list.push({name:p.displayName, type:"entries", depth:2, entries});
        } else {
            const pi = getPropertyInfo(p);
            if (pi) {
                list.push(pi.entries);
            }
        }
    }

    return list;
}

function getACDexMod(itemtype) {
    switch (itemtype) {
        case "LA":
            return " + Dex modifier";
        case "MA":
            return " + Dex modifier (max 2)";
    }
    return "";
}

function getItemListDetails(it) {
    const details = [];
    const type = getItemType(it);
    let fullItemType = getFullItemType(it);

    if (it.rarity && !ignoreRarity.includes(it.rarity)) {
        details.push(<span key="hdr">{type!="WON"?"magical ":null}{fullItemType}, {it.rarity}</span>);
    } else {
        details.push(<span key="hdr">{fullItemType}</span>);
    }

    if (it.dmg1) {
        details.push(<span key="d">, dmg <Renderstring entry={it.dmg1}/>{it.dmg2?<span> (<Renderstring entry={it.dmg2}/>)</span>:null}</span>);
    }

    if (it.range) {
        details.push(<span key="r">, range {it.range}</span>);
    }

    if (it.ac) {
        if (it.type == "S") {
            details.push(<span key="a">, AC +{it.ac}</span>);
        } else {
            details.push(<span key="a">, AC {it.ac}</span>);
        }
    }

    const vExtra = getSimpleCoinString(it);

    if (it.value||vExtra) {
        details.push(<span key="c">, {it.value} {vExtra}</span>);
    }
    return <div className="i f8 pl1 ttl">{details}</div>;
}

class Item extends React.Component {
    constructor(props) {
        super(props);

        var it = props.id && campaign.getItem(props.id);
        this.handleOnDataChange = this.onDataChange.bind(this);
        this.state= {item:it};
    }

    componentDidUpdate(prevProps) {
        if (this.props.id && (this.props.id != prevProps.id)) {
            var it = campaign.getItem(this.props.id);
            this.setState({item:it});
        }
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "items");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "items");
    }

    onDataChange() {
        var it = campaign.getItem(this.props.id);
        this.setState({item:it});
    }

	render() {
        const it = this.props.item||this.state.item;
        const {showItem} = this.state;
        let details = [];
        let extra = []; 
        if (!it) {
            return <div>item not found</div>;
        }

        upgradeItem(it);

        const type = getItemType(it);
        const fullItemType = getFullItemType(it);

        if (it.rarity && !ignoreRarity.includes(it.rarity)) {
            details.push(<div key="hdr" className="mb2 i">{type!="WON"?"Magical ":null}{fullItemType}, {it.rarity} {getAttunement(it)}</div>);
        } else {
            details.push(<div key="hdr" className="mb2 i">{fullItemType}  {getAttunement(it)}</div>);
        }

        if (it.hidden && !campaign.isSharedCampaign()) {
            const hideItem = it.hideItem??it.name;
            const hit = campaign.getItem(hideItem);
            if (hit) {
                details.push(<div key="hide" className="mb1 red"><LinkHref href={getItemHref(hideItem)}>Unidentified item</LinkHref></div>);
            }
        }

        const containedWeight = getContainedWeight(it);
        if (containedWeight) {
            const totalWeight = getTotalWeight(it);
            details.push(<div key="w"><span className="notetext b mr1">Weight</span>{it.weight||0} lb. + holding {containedWeight} lb. {(totalWeight != (it.weight||0))?(" = "+totalWeight+" lb."):null}</div>)
        } else {
            details.push(<TextLabelEdit key="w" label="Weight" size="md" number text={it.weight}>lb.</TextLabelEdit>);
        }

        const cval = getSimpleCoinString(it);
        if (cval) {
            details.push(<div key="v" className="mb1"><span className="notetext b mr1">Value</span>{it.value} {cval}</div>);
        } else {
            details.push(<TextLabelEdit key="v" label="Value" size="md" text={it.value}/>);    
        }
        details.push(<TextLabelEdit key="q" label="Quantity" size="md" number text={it.quantity}/>);
        if (["M", "R","S"].includes(type) || it.dmg1) {
            if (it.dmg1) {
                details.push(<div key="d" className="mb1"><b>Damage </b>{getDamageString(it.dmg1)} {it.dmg2?<span>({getDamageString(it.dmg2)}) </span>:null}{it.dmgType?(Parser.DMGTYPE_JSON_TO_FULL[it.dmgType]||""):null}</div>);
            }
        }
        if ((type == "R") || (it.property && it.property.includes("T"))) {
            details.push(<TextLabelEdit key="r" label="Range" size="md" text={it.range}/>);
        }
        if (["LA", "MA", "HA"].includes(type)) {
            details.push(<TextLabelEdit key="ac" label="Armor Class" text={it.ac||"none"}>{getACDexMod(type)}</TextLabelEdit>);
            if (it.stealth) {
                details.push(<div key="sth" className="mb1"><b>Stealth </b>{it.stealth?"disadvantage":"no disadvantage"}</div>);
            }
        } else if (type == "S") {
            details.push(<TextLabelEdit key="ac" label="Armor Class +" text={it.ac||0}/>);
        }
        details.push(<TextLabelEdit key="s" label="Strength" text={it.strength}/>);
        details.push(<TextLabelEdit key="sp" label="Speed" size="md" text={it.speed}/>);
        details.push(<TextLabelEdit key="cc" label="Carrying Capacity" size="md" text={it.carryingcapacity}/>);

        if (it.extraFeatures) {
            for (let i in it.extraFeatures) {
                const ef = it.extraFeatures[i];
                extra.push(<div key={i}>
                    <div className="flex">
                        <div className="flex-auto"><b>{ef.name}</b> {this.props.onChange?<ItemFeatureUsageSlots feature={ef} onChange={this.onChangeExtraFeature.bind(this, i)}/>:null}</div>
                        {ef.newCreation&&this.props.onDelete?
                            <DeleteWithConfirm useButton onClick={this.props.onDelete} color="primary" variant="outlined" size="small"/>:
                            this.props.onChange?<Button onClick={this.onRemoveExtraFeature.bind(this, i)} color="primary" variant="outlined" size="small">Remove</Button>:null
                        }
                    </div>
                    <Renderentry noTitle entry={ef.entries} depth="2" showInstantRoll doRoll={this.props.doSubRoll?this.doTextRoll.bind(this):null} doSubRoll={this.props.doSubRoll?this.doSubRoll.bind(this):null} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} character={this.props.character}/>
                </div>);
            }
        }

        return <div className="stdcontent f4">
            {this.props.noTitle?null:<h3>
                {it.displayName}
            </h3>}
            <FloatArt art={it.defaultArt} artList={it.artList} defaultArt={it.defaultArt}/>
            <div className="mb2">
                {details}
                <PickProperties property={it.property}/>
            </div>
            {this.props.onChange?<ItemFeatureUsageSlots feature={it.feature} onChange={this.onChangeVal.bind(this, "feature")}/>:null}
            <Renderentry entry={it.entries} depth="2" showInstantRoll doRoll={this.props.doSubRoll?this.doTextRoll.bind(this):null} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} character={this.props.character}/>
            {this.getContainedItems(it)}
            {extra}
            {getItemPropertyEntries(it)}
            {this.props.noSource?null:<Rendersource entry={it}/>}
            <div className="cb"/>
            {showItem?<ItemDialog open item={showItem} onClose={this.showContained.bind(this,null)} noAddToEquipment/>:null}
        </div>;
    }

    getContainedItems(baseit) {
        if (!baseit.container) {
            return;
        }
        const contained = baseit.contained||{};
        const keys = getSortedEquipment(contained);

        const list = [];
        for (let id of keys) {
            const it = contained[id];
            list.push(<li key={id}><a onClick={this.showContained.bind(this,it)}>{((it.quantity??1)>1)?it.quantity:""} {it.displayName}</a></li>);
        }

        if (!list) {
            return null;
        }

        return <div className="mv1">
            <ul>
                {list}
            </ul>
        </div>;
    }

    showContained(showItem) {
        this.setState({showItem});
    }

    doTextRoll(text){
        const it = this.props.item||this.state.item;
        return this.doSubRoll(null,text);
    }

    doSubRoll(name,text) {
        if (this.props.doSubRoll) {
            return this.props.doSubRoll(name, text);
        }
    }

    onChangeExtraFeature(i, feature) {
        const it = this.props.item;
        const extraFeatures = Object.assign({},it.extraFeatures);
        extraFeatures[i]=feature;
        this.onChangeVal("extraFeatures", extraFeatures);
    }

    onRemoveExtraFeature(i) {
        const it = Object.assign({}, this.props.item);
        let extraFeatures = Object.assign({},it.extraFeatures);
        delete extraFeatures[i];

        if (!Object.keys(extraFeatures).length) {
            it.reqAttune = it.origAttune||false;
            delete it.origAttune;
            extraFeatures = null;
        }
        it.extraFeatures=extraFeatures;
        this.props.onChange(it);
    }

    onChangeVal(prop, value) {
        var newit = Object.assign({}, this.props.item);

        if (prop == "dmg1" || prop == "dmg2") {
            if (value && value != "") {
                value="{@dice "+value+"}";
            } else {
                value=null;
            }
        }

        if (prop == "entry") {
            delete newit.entries;
        }

        newit[prop]=value;

        const type = getItemType(newit);

        if (!newit.type) {
            newit.type = type;
        }
        if (!(["M", "R","S"].includes(type))) {
            delete newit.weapon;
            delete newit.weaponCategory;
            delete newit.dmg1;
            delete newit.dmg2;
        }
        if (!(["LA", "MA", "HA", "S"].includes(type))) {
            delete newit.ac;
        }
        if (!(["LA", "MA", "HA"].includes(type))) {
            delete newit.stealth;
        }
        if ((type != "R") && !(newit.property && newit.property.includes("T"))) {
            delete newit.range;
        }

        this.props.onChange(newit);
    }
}

function printItem(id,noTitle,header) {
    const list=[];
    const it = campaign.getItem(id);
    if (!it) {
        return;
    }
    if (!noTitle) {
        list.push(`<h${header}>${it.displayName}</h${header}>`);
    }

    if (campaign.getSourcePreventEmbedding(it.source)) {
        list.push("<p>Not allowed to publish.</p>");
    } else {
        list.push(printFloatArt(it.defaultArt));

        const type = getItemType(it);
        const fullItemType = getFullItemType(it);

        if (it.rarity && !ignoreRarity.includes(it.rarity)) {
            list.push(`<div><i>${type!="WON"?"Magical ":""}${fullItemType}, ${it.rarity} ${getAttunement(it)}</i></div>`);
        } else {
            list.push(`<div><i>${fullItemType} ${getAttunement(it)}</i></div>`);
        }

        const containedWeight = getContainedWeight(it);
        if (containedWeight) {
            const totalWeight = getTotalWeight(it);
            list.push(`<div><b>Weight</b> ${it.weight||0} lb. + holding ${containedWeight} lb. ${(totalWeight != (it.weight||0))?(" = "+totalWeight+" lb."):""}</div>`);
        } else if (it.weight) {
            list.push(`<div><b>Weight</b> ${it.weight||0} lb.</div>`);
        }

        const cval = getSimpleCoinString(it);
        if (it.val || cval) {
            list.push(`<div><b>Value</b> ${it.value||0} ${cval||""}</div>`);
        }

        if (it.quantity) {
            list.push(`<div><b>Quantity</b> ${it.quantity}</div>`);
        }

        if (["M", "R","S"].includes(type) || it.dmg1) {
            if (it.dmg1) {
                list.push(`<div><b>Damage </b>${getDamageString(it.dmg1)} ${it.dmg2?`${getDamageString(it.dmg2)}`:""} ${it.dmgType?(Parser.DMGTYPE_JSON_TO_FULL[it.dmgType]||""):""}</div>`);
            }
        }
        if (it.range && ((type == "R") || (it.property && it.property.includes("T")))) {
            list.push(`<div><b>Range</b> ${it.range||""}</div>`);
        }
        if (["LA", "MA", "HA"].includes(type)) {
            list.push(`<div><b>Armor Class</b> ${it.ac||"none"} ${getACDexMod(type)}</div>`);
            if (it.stealth) {
                list.push(`<div><b>Stealth </b> disadvantage</div>`);
            }
        } else if ((type == "S")&&it.ac) {
            list.push(`<div><b>Armor Class</b> ${it.ac||0}</div>`);
        }
        if (it.strength) {
            list.push(`<div><b>Strength</b> ${it.strength}</div>`);
        }
        if (it.speed) {
            list.push(`<div><b>Speed</b> ${it.speed}</div>`);
        }
        if (it.carryingcapacity) {
            list.push(`<div><b>Carrying Capacity</b> ${it.carryingcapacity}</div>`);
        }

        const props = getItemPropertyListFromProperty(it.property);
        if (props) {
            list.push(`<div><b>Properties</b> ${props}</div>`);
        }

        if (it.entries) {
            list.push(htmlFromEntry(it.entries));
        }

        if (it.container) {
            const contained = it.contained||{};
            const keys = getSortedEquipment(contained);
    
            const sublist = [];
            for (let id of keys) {
                const cit = contained[id];
                sublist.push(`<li>${((cit.quantity??1)>1)?cit.quantity:""} ${cit.displayName}</li>`);
            }
    
            if (sublist.length) {
                list.push(`<div><ul>${sublist.join("\n")}</ul></div>`);
            }
        }

        const propDetails=getItemPropertyEntriesRaw(it);
        if (propDetails.length) {
            list.push(htmlFromEntry({entries:propDetails},2));
        }
        list.push('<div style="clear: both"></div>');

    }
    return list.join("\n");
}

function printItemList(contentList) {
    const list =contentList.concat([]);
    const general= [];
    const armor=[];
    const weapon=[];
    const carry=[];
    const currency=[];
    const res = [];

    list.sort(function (a, b) {
        const ait  = (campaign.getItem(a.contentId)||{}).displayName||"";
        const bit  = (campaign.getItem(b.contentId)||{}).displayName||"";
        return ait.toLowerCase().localeCompare(bit.toLowerCase())
    });

    for (let i in list) {
        const it  = campaign.getItem(list[i].contentId);
        if (it) {
            switch (getBasicItemType(it)) {
                case "Weapon":{
                    weapon.push(`<tr><td>${it.displayName}</td><td>${it.value||""}&nbsp;</td><td>${getDamageString(it.dmg1)||"-"} ${Parser.DMGTYPE_JSON_TO_FULL[it.dmgType]||""}</td><td>${it.weight?(it.weight+" lb."):""}</td><td>${getItemPropertyList(it)}${it.range?(" (range "+it.range+")"):""}</td></tr>`);
                    break;
                }
                case "Armor":{
                    armor.push(`<tr><td>${it.displayName}</td><td>${it.value||""}&nbsp;</td><td>${(it.ac<10)?("+"+it.ac):it.ac}${(it.type=="LA")?"+ Dex modifier":((it.type=="MA")?"+ Dex modifier (max 2)":"")}</td><td>${it.strength?("Str "+it.strength):"-"}</td><td>${it.stealth?"Disadvantage":"-"}</td><td>${it.weight?(it.weight+" lb."):null}</td></tr>`);
                    break;
                }
                case "Mount":
                case "Vehicle":{
                    carry.push(`<tr><td>${it.displayName}</td><td>${it.value||""}&nbsp;</td><td>${it.carryingcapacity}&nbsp;</td></tr>`);
                    break;
                }
                case "Currency": {
                    currency.push(`<tr><td>${it.displayName}</td><td>${it.weight?(it.weight+" lb."):""}</td></tr>`);
                    break;
                }

                default: {
                    general.push(`<tr><td>${it.displayName}</td><td>${it.value||""}&nbsp;</td><td>${it.weight?(it.weight+" lb."):""}</td></tr>`);
                    break;
                }
            }
        }
    }

    if(weapon.length) {
        res.push(`<table width="100%"><tr><td><b>Weapon</b></td><td><b>Cost</b>&nbsp;</td><td><b>Damage</b></td><td><b>Weight&nbsp;</b></td><td><b>Properties</b></td></tr>${weapon.join("\n")}</table><p/>`);
    }

    if (armor.length) {
        res.push(`<table width="100%"><tr><td><b>Armor</b></td><td><b>Cost&nbsp;</b></td><td><b>Armor Class AC</b></td><td><b>Strength</b></td><td><b>Stealth</b></td><td><b>Weight&nbsp;</b></td></tr>${armor.join("\n")}</table><p/>`);
    }

    if (carry.length) {
        res.push(`<table width="100%"><tr><td><b>Name</b></td><td><b>Cost&nbsp;</b></td><td><b>Carrying Capacity</b></td></tr>${carry.join("\n")}</table><p/>`);
    }

    if (currency.length) {
        res.push(`<table width="100%"><tr><td><b>Currency</b></td><td><b>Weight&nbsp;</b></td></tr>${currency.join("\n")}</table><p/>`);
    }

    if (general.length) {
        res.push(`<table width="100%"><tr><td><b>Name</b></td><td><b>Cost&nbsp;</b></td><td><b>Weight&nbsp;</b></td></tr>${general.join("\n")}</table><p/>`);
    }

    return res.join("\n");
}

const containerTypes = {
    "none":"None",
    "include":"Hold items",
    "noweight": "Hold items with no additional weight"
}

function getDamageString(dmg){
    if (!dmg || dmg==""){
        return "";
    }
    if (!dmg.startsWith("{") || !dmg.endsWith("}")) {
        return dmg;
    }
    dmg = dmg.substr(1, dmg.length-2);
    var [tag, text] = splitFirstSpace(dmg);

    if (tag != "@dice") {
        console.log("malformed damage string", dmg);
    }
    return text;
}

function splitFirstSpace (string) {
	const firstIndex = string.indexOf(" ");
	return firstIndex === -1 ? [string, ""] : [string.substr(0, firstIndex), string.substr(firstIndex + 1)];
};


class ItemDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state= this.getState(props);
    }

    getState(props) {
        let {item,characterId, itemId, name, openEditable} = props;

        if (item && !item.name) {
            item = Object.assign({}, item);

            item.name = newUid();
        } else if (!item && name) {
            item =campaign.getItem(name);
        } else if (!item && characterId && itemId) {
            const character = getCharacterFromId(characterId);
            if (character) {
                item = character.getEquipmentItem(itemId);
            }
        }
        upgradeItem(item);
        return {item, editable:openEditable};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState(this.getState(this.props));
        }
    }

    onEdit() {
        this.setState({editable:true});
    }

    onSaveItem(itemNew, save) {
        if (save) {
            const {name, characterId, itemId,item} = this.props;
            if (item) {
                this.props.onClose(itemNew);
            } else if (name) {
                campaign.updateCampaignContent("items", itemNew);
                this.setState({item:itemNew,editable:false});
                return;
            } else if (characterId && itemId) {
                const character = getCharacterFromId(characterId);
                if (character) {
                    character.setEquipmentItem(itemNew, itemId);
                    this.setState({item:itemNew,editable:false});
                    return;
                }
            }
        } else {
            this.props.onClose();
        }
    }

    onSave(save) {
        this.onSaveItem(this.state.item, save);
    }

    onCloseEdit(item) {
        this.onSaveItem(item, item);
    }

	render() {
        const item = this.state.item;
        if (!this.props.open || !item) {
            return null;
        }
        const {character,noAddToEquipment,editable, onDelete, doSubRoll, addToEncounter, addSpellToken, name, characterId, itemId, getDiceRoller,showGamesystem} = this.props;

        if (this.state.editable) {
            return <EditItem open item={item} showGamesystem={showGamesystem} onClose={this.onCloseEdit.bind(this)}/>
        }
        const {PickRandomEncounterInfoDialog,PickRandomCount} = require('./renderrandomtables.jsx');
        const hideItemDetails = item.hidden && campaign.isSharedCampaign();
        let hit;
        if (editable && item.hidden && !campaign.isSharedCampaign()) {
            const hideItem = item.hideItem??item.name;
            hit = campaign.getItem(hideItem);
        }
        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.onSave.bind(this, false)}>
                {item.displayName}
            </DialogTitle>
            <DialogContent>
                <Item noTitle item={this.state.item} onDelete={onDelete} doSubRoll={doSubRoll} addSpellToken={addSpellToken} getDiceRoller={getDiceRoller} character={character}/>
            </DialogContent>
            <DialogActions>
                {getDiceRoller?getDiceRoller():null}
                {name||(characterId && itemId)?<AddChatEntry character={character} type="Item" displayName={item.displayName} href={getItemHref(name, characterId, itemId)}/>:null}
                {hit?<Button onClick={this.onReveal.bind(this,hit)} color="primary">
                    identify
                </Button>:null}
                {character&&!noAddToEquipment?<Button onClick={this.clickAddEquipment.bind(this)} color="primary">
                    Add to equipment
                </Button>:null}
                {addToEncounter?<Button onClick={this.clickAddItem.bind(this)} color="primary">
                    ADD TO ENCOUNTER
                </Button>:null}
                {editable&&!hideItemDetails?<Button onClick={this.onEdit.bind(this)} color="primary">
                    Edit Attributes
                </Button>:null}
                <Button onClick={this.onSave.bind(this, false)} color="primary">
                    Close
                </Button>
            </DialogActions>
            <PickRandomEncounterInfoDialog open={this.state.showAddItem} row={this.state.addItemRow} onClose={this.closeAddItem.bind(this)} addToEncounter={this.props.addToEncounter}/>
            <PickRandomCount open={this.state.showAddEquipment} dice={this.state.addDice} onClose={this.closeAddEquipment.bind(this)} title="Add Equipment">
                {item.displayName}
            </PickRandomCount>

        </Dialog>;
    }

    clickAddItem() {
        const row = {items:{}};
        const {previousDice} = this.props;
        const dice=getDiceFromString(previousDice||"1",0,true);
        row.items[campaign.newUid()]={dice, item:this.state.item};
        this.setState({showAddItem:true, addItemRow:row});
    }

    clickAddEquipment() {
        const {previousDice} = this.props;
        const dice = getDiceFromString(previousDice||this.state.item.quantity||"1",0,true);
        this.setState({showAddEquipment:true, addDice:dice});
    }

    closeAddEquipment(count) {
        if (count) {
            const {previousDice,character} = this.props;
            const equip = Object.assign({}, this.state.item);
            const added = {};
            if (previousDice || !equip.quantity) {
                equip.count = count;
            } else {
                removeItemSuffix(equip);
                equip.quantity = count;
            }
            character.mergeEquipment({a:equip}, added);
            Chat.addEquipment(character, "added", added, null);
            displayMessage("Successfully added to equipment");
            this.props.onClose();
        }
        this.setState({showAddEquipment:false});
    }

    closeAddItem(row) {
        if (row){
            this.props.addToEncounter(row);
            this.props.onClose();
        }
        this.setState({showAddItem:false, addItemRow:null});
    }
    
    onReveal(hit) {
        const {item}=this.state;
        const it = Object.assign({}, hit)
        if (item.carried) {
            it.carried = item.carried;
        } else {
            delete it.carried;
        }
        if (item.equip) {
            it.equip = item.equip;
        } else {
            delete it.equip;
        }
        if (item.quantity) {
            it.quantity=item.quanity;
        }
        if (item.name) {
            it.name = item.name;
        }

        this.onSaveItem(it,true);
    }
}

class EditItem extends React.Component {
    constructor(props) {
        super(props);
        let item = props.item;

        if (item && !item.name) {
            item = Object.assign({}, props.item);

            item.name = newUid();
        } 
        if (!item.weaponProficiency) {
            item = Object.assign({},item);
            item.weaponProficiency=getWeaponProficiency(item);
        }
        this.state= {item};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            let item = this.props.item;

            if (item && !item.name) {
                item = Object.assign({}, this.props.item);
    
                item.name = newUid();
            }
            if (!item.weaponProficiency) {
                item = Object.assign({},item);
                item.weaponProficiency=getWeaponProficiency(item);
            }
            this.setState({item,showAdvanced:false});
        }
    }

    onChangeItem(newVal) {
        this.setState({item:newVal})
    }

    onSave(save) {
        if (save) {
            this.props.onClose(this.state.item);
        } else {
            this.props.onClose();
        }
    }

	render() {
        const it = this.state.item;
        if (!this.props.open || !it) {
            return null;
        }

        //prevent require loop
        const {EditItemFeatureOptions} = require('./features.jsx');
        const {showAdvanced} = this.state;

        upgradeItem(it);

        var details = [];

        const type = getItemType(it);
        const fullItemType = getFullItemType(it);
        const {allWeaponProficiencies,weaponCategories,allItemTypes} = campaign.getItemExtensions();

        if (it.rarity && !ignoreRarity.includes(it.rarity)) {
            details.push(<div key="hdr" className="mb2 i">{type!="WON"?"Magical ":null}{fullItemType}, {it.rarity} {getAttunement(it)}</div>);
        } else {
            details.push(<div key="hdr" className="mb2 i">{fullItemType}  {getAttunement(it)}</div>);
        }

        details.push(<div key="tn" className="mb1"><b className="notetext nudge-down--5 dib mr1">Item Type</b><SelectTextVal className="w6 dib" text={it.nametype || Parser.ITEM_TYPE_JSON_TO_ABV[type]} values={allItemTypes} onChange={this.onChangeVal.bind(this,"type")}/></div>);
        if (type != "coin") {
            const containedWeight = getContainedWeight(it);
            if (containedWeight) {
                const totalWeight = getTotalWeight(it);
                details.push(<div key="totalw"><span className="notetext b mr1">Weight</span>{it.weight||0} lb. + holding {containedWeight} lb. {(totalWeight != (it.weight||0))?(" = "+totalWeight+" lb."):null}</div>)
            }
            const cval = getSimpleCoinString(it);
            if (cval) {
                details.push(<div key="totoalv" className="mb1"><span className="notetext b mr1">Value</span>{it.value} {cval}</div>);
            }    
    
            details.push(<TextLabelEdit key="rare" label="Rarity" text={it.rarity} editable values={rarityLevels} onChange={this.onChangeVal.bind(this,"rarity")}/>);
            details.push(<TextLabelEdit key="ra" label="Requires Attunement" text={it.reqAttune} editable tristate onChange={this.onChangeVal.bind(this,"reqAttune")}/>);
            if (it.nametype || ["M", "R"].includes(type) || it.dmg1) {
                details.push(<div key="wc" className="mb1"><b className="notetext nudge-down--5 dib mr1">Weapon Category</b><SelectTextVal className="w6 dib" text={it.weaponCategory} values={weaponCategories} onChange={this.onChangeVal.bind(this,"weaponCategory")}/></div>);
                details.push(<div key="wp" className="mb1"><b className="notetext nudge-down--5 dib mr1">Weapon Proficiency</b><SelectTextVal className="w6 dib" text={it.weaponProficiency} values={allWeaponProficiencies} onChange={this.onChangeVal.bind(this,"weaponProficiency")}/></div>);
            }

            details.push(<TextLabelEdit key="w" label="Weight" size="lg" number text={it.weight} editable onChange={this.onChangeVal.bind(this, "weight")}>lb.</TextLabelEdit>);
            details.push(<TextLabelEdit key="v" label="Value" size="lg" text={it.value} editable onChange={this.onChangeVal.bind(this, "value")}/>);
            details.push(<TextLabelEdit key="q" label="Quantity" size="lg" number text={it.quantity} editable onChange={this.onChangeVal.bind(this, "quantity")}/>);
            details.push(<TextLabelEdit key="d" label="Damage" size="lg" text={getDamageString(it.dmg1)||""} editable onChange={this.onChangeVal.bind(this, "dmg1")}/>);
            if (it.dmg2 || (it.property && it.property.includes("V"))) {
                details.push(<TextLabelEdit key="d2" size="lg" label="Damage two-handed" text={getDamageString(it.dmg2)||""} editable onChange={this.onChangeVal.bind(this, "dmg2")}/>);
            }
            details.push(<TextLabelEdit key="dt" label="Damage Type" text={Parser.DMGTYPE_JSON_TO_FULL[it.dmgType]||""} values={Parser.DMGTYPE_JSON_TO_FULL} editable onChange={this.onChangeVal.bind(this,"dmgType")}/>);
            details.push(<TextLabelEdit key="r" label="Range" size="lg" text={it.range} editable onChange={this.onChangeVal.bind(this, "range")}/>);
            if (it.nametype || ["LA", "MA", "HA"].includes(type)) {
                details.push(<TextLabelEdit key="ac" label="Armor Class" text={it.ac||"none"} editable values={abilitiesListWithZero} onChange={this.onChangeVal.bind(this, "ac")}>{getACDexMod(type)}</TextLabelEdit>);
                details.push(<div key="sth" className="mb1" onClick={this.onChangeVal.bind(this, "stealth", !it.stealth)}><span className="hover-bg-contrast"><b>Stealth </b>{it.stealth?"disadvantage":"no disadvantage"}</span></div>);
            } else if (type == "S") {
                details.push(<TextLabelEdit key="ac" label="Armor Class +" text={it.ac||0} values={abilitiesListWithZero} editable onChange={this.onChangeVal.bind(this, "ac")}/>);
            }
            details.push(<TextLabelEdit key="s" label="Minimum Strength" text={it.strength} editable values={abilitiesListWithZero} onChange={this.onChangeVal.bind(this, "strength")}/>);
            if (it.nametype || ["SHP", "VEH", "MNT"].includes(type)) {
                details.push(<TextLabelEdit key="sp" label="Speed" size="lg" text={it.speed} editable onChange={this.onChangeVal.bind(this, "speed")}/>);
            }
            if (it.container || it.carryingcapacity) {
                details.push(<TextLabelEdit key="cc" label="Carrying Capacity" size="lg" text={it.carryingcapacity} editable onChange={this.onChangeVal.bind(this, "carryingcapacity")}/>);
            }
            details.push(<TextLabelEdit key="ct" label="Container Type" text={it.container||"none"} editable values={containerTypes} onChange={this.onChangeVal.bind(this, "container")}/>);

            details.push(<div key="cons"><CheckVal value={it.consumable} label="Consumable: using the item will decrease the quantity or the uses" onChange={this.onChangeVal.bind(this,"consumable")}/></div>);
            if (!campaign.isSharedCampaign()) {
                details.push(<div key="hidden"><CheckVal value={it.hidden} label="Hidden: there is hidden information about the item" onChange={this.onChangeVal.bind(this,"hidden")}/></div>);
                const hideItem = it.hideItem??it.name;
                const hit = campaign.getItem(hideItem);
                if (it.hidden) {
                    details.push(<div key="hiddenc" className="mb1">
                        <Button onClick={this.onPickHidden.bind(this)} color="primary" variant="outlined" size="small">Pick</Button>&nbsp;
                        {hit?<span>
                            <Button onClick={this.onReveal.bind(this,hit)} color="primary" variant="outlined" size="small">Identify</Button>&nbsp;
                            <LinkHref href={getItemHref(hideItem)}>{hit.displayName}</LinkHref>
                            <div className="mv1 hk-well">Identifying a hidden item will change item into the hidden item.</div>
                        </span>:null}
                        <ItemPicker single noNew saveLabel="Select" open={this.state.showHiddenPicker} onClose={this.closeHiddenPicker.bind(this)}/>
                    </div>);
                }
            }
        } else {
            details.push(<TextLabelEdit key="ca" label="Short Name" size="lg" text={it.coinType} editable onChange={this.onChangeVal.bind(this, "coinType")}/>);
            details.push(<TextLabelEdit key="w" label="Weight" size="lg" number text={it.weight} editable onChange={this.onChangeVal.bind(this, "weight")}>lb.</TextLabelEdit>);
            details.push(<TextLabelEdit key="gpc" label="Count per GP" size="lg" number text={it.countPerGP} editable onChange={this.onChangeVal.bind(this, "countPerGP")}></TextLabelEdit>);
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.onSave.bind(this, false)}>
                <TextVal    
                    text={it.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeVal.bind(this,"displayName")}
                    placeholder="Item Name"
                />
            </DialogTitle>
            <DialogContent>
                <div className="mb2 stdcontent f4">
                    {details}
                    {this.getContainedItems(it)}
                    {(type!="coin")?<PickProperties property={it.property} useButton onChange={this.onChangeVal.bind(this, "property")} editable/>:null}
                </div>
                <div className="stdcontent">
                    <h2>Description</h2>
                    <EntityEditor onChange={this.onChangeVal.bind(this,"entries")} entry={it.entries} depth="2"/>
                    {getItemPropertyEntries(it)}
                    {(type!="coin")?<h2 className={showAdvanced?null:"b--transparenti"}>Special Abilities<span className={showAdvanced?"pa1 far fa-minus-square hoverhighlight":"pa1 far fa-plus-square hoverhighlight"} onClick={this.toggleShowAdvanced.bind(this)}/></h2>:null}
                    {showAdvanced&&(type!="coin")?<EditItemFeatureOptions allowAlt feature={it.feature} onChange={this.onChangeVal.bind(this,"feature")}/>:null}
                    <h2>Artwork</h2>
                    <ExtraArtList artList={it.artList} onChange={this.onChangeArtwork.bind(this)} pickArt defaultArt={it.defaultArt}/>
                    {this.props.showGamesystem?<div className="pt2">
                        <SelectVal value={it.gamesystem||"5e"} values={gamesystemOptions} onClick={this.onChangeVal.bind(this,"gamesystem")} helperText="Game System"/>
                    </div>:null}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showExtraArt.bind(this)} color="primary">
                    Select Artwork
                </Button>
                <Button onClick={this.onSave.bind(this, true)} color="primary" disabled={!(it.displayName && (it.displayName!=""))}>
                    Save
                </Button>
                <Button onClick={this.onSave.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ArtZoomList editable open={this.state.showExtraArtDialog} artList={it.artList} onClose={this.onSaveArtwork.bind(this)} pickArt defaultArt={it.defaultArt} defaultSearch={it.displayName||it.name}/>
        </Dialog>;
    }

    getContainedItems(baseit) {
        if (!baseit.container) {
            return;
        }
        const {showItem,showItemPicker} = this.state;
        const contained = baseit.contained||{};
        const keys = getSortedEquipment(contained);

        const list = [];
        for (let id of keys) {
            const it = contained[id];
            list.push(<tr key={id}>
                <td className="tc w4">
                    <NumberAdjustPlusMinus positive value={it.quantity ?? 1} paramA={id} onChange={this.onAdjustQuantity.bind(this)}/>
                </td>
                <td className="vat hoverhighlight w-100" onClick={this.onClickItem.bind(this,id, it)}>
                    {it.displayName}
                </td>
                <td className="tc vat w2">
                    <span className="pa1 hoverhighlight fas fa-trash" onClick={this.onAdjustQuantity.bind(this,0,0,id)}/>
                </td>
            </tr>);
        }

        return <div className="overflow-x-auto mb1">
            <Button onClick={this.onShowItemPicker.bind(this)} color="primary" variant="outlined" size="small">Add Container Items</Button>
            {list.length?<table className="w-100 stdcontent mv1">
                <tbody>
                    {list}
                </tbody>
            </table>:null}
            <ItemPicker 
                saveLabel="Add"
                open={showItemPicker} 
                onClose={this.closeItemPicker.bind(this)}
                equipment={{}} 
            />
            <ItemDialog editable open={!!showItem} item={showItem} onClose={this.closeItem.bind(this)} noAddToEquipment/>
        </div>;
    }

    onClickItem(id,it) {
        this.setState({showItemId:id, showItem:it});
    }

    closeItem(item) {
        if (item) {
            const newContained = Object.assign({}, this.state.item.contained||{});
            newContained[this.state.showItemId]=item;
            this.onChangeVal("contained", newContained);
        }
        this.setState({showItem:null});
    }

    onShowItemPicker() {
        this.setState({showItemPicker:true});
    }

    closeItemPicker(equipment) {
        if (equipment) {
            const newContained = mergeItemList(equipment, this.state.item.contained||{});
            this.onChangeVal("contained", newContained);
        }
        this.setState({showItemPicker:false});
    }

    onAdjustQuantity(quantity, adjust, id) {
        const newContained = Object.assign({}, this.state.item.contained||{});
        if (quantity) {
            const newit = Object.assign({}, newContained[id]);
            newit.quantity = quantity;
            newContained[id]=newit;
        } else {
            delete newContained[id];
        }
        this.onChangeVal("contained", newContained);
    }

    toggleShowAdvanced() {
        this.setState({showAdvanced:!this.state.showAdvanced});
    }

    showExtraArt() {
        this.setState({showExtraArtDialog:true});
    }

    onSaveArtwork(artList, defaultArt) {
        if (artList) {
            this.onChangeArtwork(artList, defaultArt);
        }
        this.setState({showExtraArtDialog:false})
    }

    onChangeVal(prop, value) {
        var newit = Object.assign({}, this.state.item);
        if (prop == "dmg1" || prop == "dmg2") {
            if (value && value != "") {
                value="{@dice "+value+"}";
            } else {
                value=null;
            }
        } else if (prop == "reqAttune") {
            delete newit.origAttune;
        } else if (prop == "entry") {
            delete newit.entries;
        } else if (prop == "type") {
            const type = Parser.ITEM_AVB_TO_TYPE[value];
            if (type) {
                value=type;
                delete newit.nametype;
            } else {
                delete newit.type;
                prop = "nametype";
            }
        } else if ((prop=="container") && (value=="none")) {
            value=null;
        }

        newit[prop]=value;

        const type = getItemType(newit);

        if (!newit.type && !newit.nametype) {
            newit.type = type;
        }
        if (!(["M", "R","S"].includes(type))) {
            delete newit.weapon;
            delete newit.weaponCategory;
            delete newit.dmg1;
            delete newit.dmg2;
        }
        if (!(["LA", "MA", "HA", "S"].includes(type))) {
            delete newit.ac;
        }
        if (!(["LA", "MA", "HA"].includes(type))) {
            delete newit.stealth;
        }
        if ((type != "R") && !(newit.property && newit.property.includes("T"))) {
            delete newit.range;
        }
        if (type == "coin") {
            delete newit.features;
            delete newit.range;
            delete newit.container;
            delete newit.contained;
            delete newit.property;
            delete newit.consumable;
            delete newit.speed;
            delete newit.carryingcapacity;
            delete newit.strength;
            delete newit.rarity;
            delete newit.reqAttune;
        }

        this.onChangeItem(newit);
    }

    onChangeArtwork(artList, defaultArt) {
        const item = Object.assign({}, this.state.item)
        item.artList = artList;
        if (defaultArt) {
            item.defaultArt = defaultArt;
        } else {
            delete item.defaultArt;
        }
        this.onChangeItem(item);
    }

    onChangeDescription(entries) {
        const item = this.state.item;
        const feature = Object.assign({}, item.feature||{});
        feature.entries = entries;
        this.onChangeVal("feature", feature);
    }

    onPickHidden() {
        this.setState({showHiddenPicker:true});
    }

    closeHiddenPicker(items) {
        if (items) {
            let item;
            for (let i in items) {
                item = items[i];
            }
            if (item) {
                this.onChangeVal("hideItem", item.name);
            }
        }
        this.setState({showHiddenPicker:false});
    }

    onReveal(hit) {
        const {item}=this.state;
        const it = Object.assign({}, hit)
        if (item.carried) {
            it.carried = item.carried;
        } else {
            delete it.carried;
        }
        if (item.equip) {
            it.equip = item.equip;
        } else {
            delete it.equip;
        }
        if (item.quantity) {
            it.quantity=item.quanity;
        }
        if (item.name) {
            it.name = item.name;
        }

        this.props.onClose(it);
    }

}

class ItemFeatureUsageSlots extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }
    
    render() {
        const feature = this.props.feature;
        if (!feature || !feature.usage) {
            return null;
        }
        const usage=feature.usage;
        var max=0;

        if (usage.baseCount) {
            max=usage.baseCount;
        }
        if (!max) {
            return null;
        }
        let value = feature.uses;
        if (value === undefined) {
            value = max;
        }

        return <MaxNumberAdjust maxPip={this.props.small?3:null} max={max} value={value} onAdjustValue={this.setValue.bind(this)}>
            {this.props.children}
        </MaxNumberAdjust>
    }

    setValue(value) {
        const feature = Object.assign({}, this.props.feature);
        feature.uses = value;
        this.props.onChange(feature);
    }
}


class ItemPicker extends React.Component {
    constructor(props) {
        super(props);


        this.state= {equipment:props.equipment||{}, mode:"list", openNew:false, dirty:false};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({equipment:this.props.equipment||{}, dirty:false, mode:"list", openNew:false});
        }
    }

    handleClose(save) {
        if (save) {
            const selected = this.state.equipment;
            const equipment = {};
            for (let i in selected) {
                const it = campaign.getItem(i);
                if (it) {
                    const nit = Object.assign({}, it);
                    nit.count = selected[i];
                    equipment[i]=nit;
                }
            }
            if (save=="buy") {
                this.props.onBuy(equipment);
            } else {
                this.props.onClose(equipment);
            }
        } else {
            this.props.onClose();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        if (this.state.openNew) {
            return <NewItem open onClose={this.saveNew.bind(this)}/>
        }

        let inside = null;
        const mode = this.state.mode;
        const {character, restrictItems} = this.props;
        const extensionEntryCheckFn = restrictItems?this.getItemRestrict(restrictItems):getExtensionEntryCheckFn(character);

        switch (mode) {
            case "list":{
                inside = <ListFilter 
                    list={campaign.getSortedItemsList()}
                    render={getItemListRender}
                    filters={itemListFilters}
                    select={this.props.single?"list":"counted"}
                    single={this.props.single}
                    selected={this.state.equipment}
                    onClick={this.onClickItem.bind(this)}
                    hideSelected
                    onSelectedChange={this.selectChange.bind(this)}
                    entryCheckFn={extensionEntryCheckFn}
                    noResort
                    getExtraSearchFn={getItemExtraSearch}
                />;

                break;
            }

            case "selected":{
                inside = this.getSelectedList();
                break;
            }
            
            case "weapons":{
                inside = this.getWeaponsList(extensionEntryCheckFn);
                break;
            }
            
            case "armor":{
                inside = this.getArmorList(extensionEntryCheckFn);
                break;
            }
            
        }

        return <Dialog
            maxWidth="md"
            fullWidth
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                {!this.props.single?<div className="notecontent ignoreDrag">
                    <div className="tc mb2">
                        <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                            <Button variant={(mode=="list")?"contained":null} onClick={this.clickMode.bind(this, "list")}>List</Button>
                            <Button variant={(mode=="selected")?"contained":null} onClick={this.clickMode.bind(this, "selected")}>Selected</Button>
                            <Button variant={(mode=="weapons")?"contained":null} onClick={this.clickMode.bind(this, "weapons")}>Weapons Table</Button>
                            <Button variant={(mode=="armor")?"contained":null} onClick={this.clickMode.bind(this, "armor")}>Armor Table</Button>
                        </ButtonGroup>
                    </div>
                </div>:"Select Item"}
            </DialogTitle>
            <DialogContent>
                <div className="notecontent minvh-80">
                    {inside}
                </div>
            </DialogContent>
            <DialogActions>
                {(!this.state.dirty&&!this.props.noNew)?<Button onClick={this.openNew.bind(this)} color="primary">
                    Create
                </Button>:null}
                {this.props.onBuy?<Button disabled={!this.state.dirty} onClick={this.handleClose.bind(this, "buy")} color="primary">
                    Buy
                </Button>:null}
                <Button disabled={!this.state.dirty} onClick={this.handleClose.bind(this, true)} color="primary">
                    {this.props.saveLabel || "Save"}
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ItemDialog open={this.state.showItemDetails} name={this.state.showItemName} onClose={this.closeItem.bind(this)}/>
        </Dialog>;
    }

    getItemRestrict(items) {
        return function (it) {
            return items[it.name.toLowerCase()]?0:100;
        }
    }

    selectChange(equipment) {
        this.setState({equipment, dirty:Object.keys(equipment).length > 0});
    }

    clickMode(mode){
        this.setState({mode});
    }

    getSelectedList() {
        var ret = [];
        const equipment = this.state.equipment;
    
        for (let i in equipment) {
            const e = equipment[i];
            const it = campaign.getItem(i);

            if (it) {
                ret.push(<tr key={i}>
                    <td className="w2 tc"><div className={"pa1 hoverhighlight "+(e?"far fa-check-square":"far fa-square")} onClick={this.toggleItem.bind(this, i)}/></td>
                    <td className="tc w4">{e?<NumberAdjustPlusMinus value={e || 1} paramA={i} onChange={this.onAdjustCount.bind(this)}/>:null}</td>
                    <td className="hoverhighlight" onClick={this.onClickItem.bind(this, i)}>{it.displayName || it.name}{getItemListDetails(it)}</td>
                </tr>);
            }
        }

        if (!ret.length) {
            return <span>No equipment selected.</span>
        }
    
        return <div className="f3" key="selectedlist">
            <table className="stdlist w-100  w-100">
                <tbody>
                    {ret}
                </tbody>
            </table>
        </div>;
    }

    getWeaponsList(extensionEntryCheckFn) {
        const ret = [];
        const equipment = this.state.equipment;
        const weaponsTable = calculateWeaponsTable();

        for (let wg in weaponsTable) {
            const wtl = weaponsTable[wg];

            ret.push(<tr key={wg}>
                <td className="i b" colSpan={6}>{wg}</td>
            </tr>);

            for (let i in wtl) {
                const it = campaign.getItem(wtl[i]);
                if (it && (!extensionEntryCheckFn || (extensionEntryCheckFn(it)<=1))) {
                    const e = equipment[it.name.toLowerCase()];

                    ret.push(<tr key={it.name}>
                        <td className="w4 tc">
                            {e?<NumberAdjustPlusMinus value={e || 1} paramA={it.name} onChange={this.onAdjustCount.bind(this)}/>:
                            <span className={"pa1 hoverhighlight "+(e?"far fa-check-square":"far fa-square")} onClick={this.toggleItem.bind(this, it.name)}/>}
                        </td>
                        <td className="hoverhighlight" onClick={this.onClickItem.bind(this, it.name)}>{it.displayName}</td>
                        <td className="tr">{it.value}&nbsp;</td>
                        <td>{getDamageString(it.dmg1)||"-"} {Parser.DMGTYPE_JSON_TO_FULL[it.dmgType]||""}</td>
                        <td className="tr">{it.weight?(it.weight+" lb."):null}</td>
                        <td>{getItemPropertyList(it)}{it.range?(" (range "+it.range+")"):null}</td>
                    </tr>);
                } else {
                    console.log("could not find weapon", wtl[i]);
                }
            }

        }
    
        return <div key="weaponslist" className="f3">
            <table className="stdlist minw7 w-100">
                <tbody>
                    <tr>
                        <td className="tc f6 w4"></td>
                        <td className="b">Name</td>
                        <td className="b tr">Cost&nbsp;</td>
                        <td className="b">Damage</td>
                        <td className="b tr">Weight&nbsp;</td>
                        <td className="b">Properties</td>

                    </tr>
                    {ret}
                </tbody>
            </table>
        </div>;
    }

    getArmorList(extensionEntryCheckFn) {
        const ret = [];
        const equipment = this.state.equipment;
        const armorTable = calculateArmorTable();

        for (let wg in armorTable) {
            const wtl = armorTable[wg];

            ret.push(<tr key={"key"+wg}>
                <td className="i b" colSpan={7}>{wg}</td>
            </tr>);

            for (let i in wtl) {
                const it = campaign.getItem(wtl[i]);
                if (it && (!extensionEntryCheckFn || (extensionEntryCheckFn(it)<=1))) {
                    const e = equipment[it.name.toLowerCase()];

                    ret.push(<tr key={it.name}>
                        <td className="w4 tc">
                            {e?<NumberAdjustPlusMinus value={e || 1} paramA={it.name} onChange={this.onAdjustCount.bind(this)}/>:
                                <span className={"ml1 pa1 hoverhighlight "+(e?"far fa-check-square":"far fa-square")} onClick={this.toggleItem.bind(this, it.name)}/>}
                        </td>
                        <td className="hoverhighlight" onClick={this.onClickItem.bind(this, it.name)}>{it.displayName}</td>
                        <td className="tr">{it.value}&nbsp;</td>
                        <td>{(it.ac<10)?("+"+it.ac):it.ac}{(wg=="Light Armor")?"+ Dex modifier":((wg=="Medium Armor")?"+ Dex modifier (max 2)":"")}</td>
                        <td>{it.strength?("Str "+it.strength):"-"}</td>
                        <td>{it.stealth?"Disadvantage":"-"}</td>
                        <td className="tr">{it.weight?(it.weight+" lb."):null}</td>
                    </tr>);
                } else {
                    console.log("could not find armor", wtl[i]);
                }
            }

        }
    
        return <div key="armorlist" className="f3">
            <table className="stdlist minw75  w-100">
                <tbody>
                    <tr>
                        <td className="tc f6 w4"></td>
                        <td className="b">Armor</td>
                        <td className="b tr">Cost&nbsp;</td>
                        <td className="b">Armor Class AC</td>
                        <td className="b">Strength</td>
                        <td className="b">Stealth</td>
                        <td className="b tr">Weight&nbsp;</td>
                    </tr>
                    {ret}
                </tbody>
            </table>
        </div>;
    }

    onClickItem(name) {
        this.setState({showItemDetails:true, showItemName:name});
    }

    closeItem() {
        this.setState({showItemDetails:false});
    }

    openNew() {
        this.setState({openNew:true});
    }

    saveNew(value) {
        if (value) {
            if (this.props.createNew) {
                const newItem = Object.assign({}, value);
                delete newItem.count;
                campaign.updateCampaignContent("items", newItem);
            }
            
            this.props.onClose({newItem:value});
        } else {
            this.props.onClose();
        }
    }

    toggleItem(name) {
        const equipment = Object.assign({}, this.state.equipment);
        const lowerName = name.toLowerCase();

        if (equipment[lowerName]) {
            delete equipment[lowerName];
        } else {
            equipment[lowerName] = 1;
        }
        this.setState({equipment, dirty:true});
    }

    onAdjustCount(count, adjust, name) {
        const equipment = Object.assign({}, this.state.equipment);
        const lowerName = name.toLowerCase();

        if (count > 0) {
            equipment[lowerName] = count;
        } else {
            delete equipment[lowerName];
        }
        this.setState({equipment, dirty:true});
    }
}

function getItemListRender(it) {
    return <div>{it.displayName||it.name}{getItemListDetails(it)}</div>;
}
const itemListFilters = [
    {
        filterName:"Rarity",
        fieldName:"rarity",
        convertField:function (v) {
            return v || "None";
        }
    }, 
    {
        filterName:"Type",
        fieldName:"type",
        convertField: function (v,it) {
            return it.nametype || itemTypeFromAbbreviation[getItemType(it)] || "";
        }
    },
    {
        filterName:"Requires Attunement",
        fieldName:"reqAttune",
        advancedOnly:true,
        convertField: function (reqAttune) {
            return reqAttune?"yes":"no";
        }
    },
    defaultSourceFilter,
    defaultBookFilter,
    defaultGamesystemFilter
];

function getItemExtraSearch(it) {
    return it.weaponCategory || "";
}

function calculateWeaponsTable() {
    const weaponsTable={
        "Simple Melee Weapons":[],
        "Simple Ranged Weapons":[],
        "Martial Melee Weapons":[],
        "Martial Ranged Weapons":[]
    }

    const equipment = campaign.getSortedItemsList();
    for (let i in equipment) {
        const it = equipment[i];
        if ((it.rarity=="None"||!it.rarity) && !it.extraFeatures && it.value) {
            let group;
            const type = it.type;
            const wc = it.weaponCategory;

            if (type=="M") {
                if (wc == "Simple") {
                    group = "Simple Melee Weapons";
                } else if (wc == "Martial") {
                    group = "Martial Melee Weapons";
                }
            } else if (type=="R") {
                if (wc == "Simple") {
                    group = "Simple Ranged Weapons";
                } else if (wc == "Martial") {
                    group = "Martial Ranged Weapons";
                }
            }
            if (group) {
                weaponsTable[group].push(it.name);
            }
        }
    }
    return weaponsTable;
}

function calculateArmorTable() {
    const armorTable={
        "Light Armor":[],
        "Medium Armor":[],
        "Heavy Armor":[],
        "Shield":[]
    }

    const equipment = campaign.getSortedItemsList();
    for (let i in equipment) {
        const it = equipment[i];
        if ((it.rarity=="None"||!it.rarity) && !it.extraFeatures && it.value) {
            let group;
            const type = it.type;

            switch (type) {
                case "LA":
                    group="Light Armor";
                    break;
                case "MA":
                    group="Medium Armor";
                    break;
                case "HA":
                    group="Heavy Armor";
                    break;
                case "S":
                    group="Shield";
                    break;
            }
            if (group) {
                armorTable[group].push(it.name);
            }
        }
    }
    return armorTable;
}

const scaleOptions=[
    {name:"10% of value", value:0.1},
    {name:"25% of value", value:0.25},
    {name:"50% of value", value:0.5},
    {name:"75% of value", value:0.75},
    {name:"90% of value", value:0.9},
    {name:"95% of value", value:0.95},
    {name:"Standard value", value:1},
    {name:"5% extra", value:1.05},
    {name:"10% extra", value:1.1},
    {name:"25% extra", value:1.25},
    {name:"50% extra", value:1.5},
    {name:"2X value", value:2},
    {name:"3X value", value:3},
    {name:"4X value", value:4},
    {name:"5X value", value:5},
    {name:"10X value", value:10},
];

class ItemBuyPicker extends React.Component {
    constructor(props) {
        super(props);


        this.state= {showItemPicker:props.open};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({showItemPicker:true});
        }
    }

    handleClose(save) {
        if (save) {
            const {character} = this.props;
            const {buy, spend, change} = this.state;
            const knownCoins = getKnown(character.findCoins());

            const buyCoins = {};
            const added = Object.assign({}, buy);
            let found;

            for (let i in added) {
                if (!added[i].quantity) {
                    delete added[i];
                } else {
                    found=true;
                }
            }

            if (found) {
                for (let coin in change) {
                    const cc = change[coin];
                    const sc = Object.assign({},knownCoins[coin]);
                    sc.quantity = cc;
                    added[coin]=sc;
                }

                let equipment = mergeItemList(added, character.equipment, null, character.attuned);
                for (let coin in spend) {
                    const cc = spend[coin];
                    const sc = Object.assign({},knownCoins[coin]);
                    sc.quantity = cc;
                    buyCoins[coin]=sc;

                    equipment = adjustEquipmentCoins(equipment, coin, -cc, sc).equipment;
                }

                //console.log("do purchase", buy, spend, change, added, buyCoins, equipment, character.equipment);
                character.equipment = equipment;
                Chat.addEquipment(character, "purchase", added, null,null, buyCoins);
            }
        }
        this.props.onClose();
    }

    closeItemPicker(doBuy, equipment) {
        //console.log("doBuy?", doBuy, equipment);
        if (equipment) {
            const {character} = this.props;
            if (doBuy) {
                const buy = mergeItemList(equipment);
                const value = getCoinValue(buy);
                const {spend,change, sum} = computeBuy(value, character.findCoins());
                this.setState({buy, showItemPicker:false, value, spend, change, sum, scaleTarget:1});

                return; // don't close the dialog
            } else {
                const added = {};
                character.mergeEquipment(equipment, added);
                Chat.addEquipment(character, "added", added, null);
            }
        }
        this.props.onClose();
    }

	render() {
        const {character, open,restrictItems} = this.props;
        if (!open) {
            return null;
        }

        const {showItemPicker,buy, showItemInfo,spend, change, sum, scaleTarget,showAddItems} = this.state;
        if (showItemPicker) {
            return <ItemPicker 
                open 
                saveLabel="Add"
                onBuy={this.closeItemPicker.bind(this, true)}
                onClose={this.closeItemPicker.bind(this, false)}
                equipment={{}} 
                character={character}
                restrictItems={restrictItems}
                noNew={!!restrictItems}
            />;
        }

        const coins = character.findCoins();
        const knownCoins = getKnown(coins);
        const spendSum = computeGPEquiv(spend, knownCoins);
        const changeSum = computeGPEquiv(change, knownCoins);
        const percent = Math.round(Math.max(0, spendSum-changeSum)*100/(sum||1));
        //console.log("sums", sum, spendSum, changeSum, percent);

        const list=[];
        const items = getSortedEquipment(buy);
        for (let i of items) {
            const it =buy[i];
            list.push(<tr key={i}>
                <td className="tc">
                    <NumberAdjustPlusMinus positive value={it.quantity ?? 1} onChange={this.onAdjustQuantity.bind(this, i)}/>
                </td>
                <td className="w-100 hoverhighlight" onClick={this.showItem.bind(this, it, i)}>
                    {getItemListRender(it)}
                </td>
            </tr>);
        }

        const keys = Object.keys(coins||{});
        keys.sort(function (a, b) {return a.toLowerCase().localeCompare(b.toLowerCase())});

        list.push(<tr key="purchase">
            <td colSpan="2">
                <b>Purchase Amount</b> <PickVal values={scaleOptions} onClick={this.changeScale.bind(this)}><span className="f5 hoverhighlight ">({percent}% of value <span className="pa1 fas fa-caret-down"/>)</span></PickVal>
            </td>
        </tr>);

        for (let coin of keys) {
            const c = coins[coin];
            const cq = (spend||{})[coin]||0;
            list.push(<tr key={"P"+coin}>
                <td className="tc">
                    <MaxNumberAdjust 
                        className={cq>0?" ":"gray-80"}
                        value={cq}
                        max={c?.quantity||0}
                        onAdjustValue={this.onChangeCoin.bind(this, coin)}
                        useNumbers
                    />
                </td>
                <td className="w-100">
                    {coin} ({(c?.quantity||0).toLocaleString()})
                </td>
            </tr>);
        }

        const changeKeys = Object.keys(change||{});
        if (changeKeys.length) {
            changeKeys.sort(function (a, b) {return a.toLowerCase().localeCompare(b.toLowerCase())});

            list.push(<tr key="change">
                <td colSpan="2">
                    <b>Returned Change</b>
                </td>
            </tr>);

            for (let coin of changeKeys) {
                const cq = (change||{})[coin]||0;
                list.push(<tr key={"ch"+coin}>
                    <td className="tc">
                        <NumberAdjustPlusMinus positive value={cq} onChange={this.onChangeChange.bind(this, coin)}/>
                    </td>
                    <td className="w-100">
                        {coin}
                    </td>
                </tr>);
            }
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Shop
            </DialogTitle>
            <DialogContent>
                <table className="w-100 stdlist">
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <b>Buy</b> <Button onClick={this.showAddItems.bind(this)} color="primary" size="small">More...</Button>
                            </td>
                        </tr>
                        {list}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Purchase
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ItemDialog open={!!showItemInfo} editable item={showItemInfo} onClose={this.closeShowItem.bind(this)}/>
            <ItemPicker 
                open={showAddItems}
                saveLabel="Add"
                onClose={this.closeAddItemPicker.bind(this)}
                equipment={{}} 
                character={character}
                restrictItems={restrictItems}
                noNew={!!restrictItems}
            />
        </Dialog>;
    }

    showAddItems() {
        this.setState({showAddItems:true});
    }

    closeAddItemPicker(equipment) {
        if (equipment) {
            const {character} = this.props;
            const buy = mergeItemList(equipment, this.state.buy);

            const value = getCoinValue(buy);
            const {spend,change, sum} = computeBuy(value, character.findCoins(),this.state.scaleTarget);

            this.setState({buy, value, spend, change, sum});
        }
        this.setState({showAddItems:false});
    }

    changeScale(scaleTarget) {
        const {character} = this.props;
        const {value}= this.state;

        const {spend,change, sum} = computeBuy(value, character.findCoins(),scaleTarget);

        this.setState({spend, change, sum, scaleTarget});
    }

    onChangeCoin(coin, quantity) {
        const spend = Object.assign({}, this.state.spend||{});
        spend[coin]=quantity;
        this.setState({spend});
    }

    onChangeChange(coin, quantity) {
        const change = Object.assign({}, this.state.change||{});
        change[coin]=quantity;
        this.setState({change});
    }

    showItem(showItemInfo, showItemId){
        this.setState({showItemInfo,showItemId});
    }

    closeShowItem(item) {
        if (item) {
            const {showItemInfo, showItemId, buy} = this.state;
            const buyNew = Object.assign({}, buy);
            buy[showItemId] = item;
            this.setState({buy});
        }
        this.setState({showItemInfo:null});
    }

    onAdjustQuantity(id, quantity) {
        const {character} = this.props;
        const buy = Object.assign({}, this.state.buy);
        buy[id] = Object.assign({}, buy[id]);
        buy[id].quantity = quantity;

        const value = getCoinValue(buy);
        const {spend,change, sum} = computeBuy(value, character.findCoins(),this.state.scaleTarget);

        this.setState({buy, value, spend, change, sum});
    }

}


class ItemCreatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:{}, mode:(props.open && this.getEquipmentMatches(props.equipment, props.filter).length)?"equipment":"items"};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({selected:{}, mode:this.getEquipmentMatches(this.props.equipment, this.props.filter).length?"equipment":"items"});
        }
    }

    handleClose(save) {
        if (save) {
            const selected = this.state.selected;
            let item;
            for (let i in selected) {
                item = i
            }
            if (item) {
                this.props.onClose(item, this.state.type);
            } else {
                this.props.onClose();
            }
        } else {
            this.props.onClose();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        let inside = null;
        const mode = this.state.mode;
        const filter = this.props.filter;

        switch (mode) {
            case "items":{
                const list = this.getItemsMatches(filter);
                inside = <ListFilter 
                    list={list}
                    render={getItemListRender}
                    filters={itemListFilters}
                    select="list"
                    single
                    selected={this.state.selected}
                    hideSelected
                    onSelectedChange={this.selectItemsChange.bind(this)}
                    onClick={this.showItem.bind(this)}
                    getExtraSearchFn={getItemExtraSearch}
                    entryCheckFn={getExtensionEntryCheckFn()}
                />;
                break;
            }

            case "equipment":{
                const list = this.getEquipmentMatches(this.props.equipment, filter);
                list.sort(function (a,b){return (a.displayName||a.name).localeCompare(b.displayName||b.name)});
                inside = <ListFilter 
                    list={list}
                    render={getItemListRender}
                    filters={itemListFilters}
                    select="list"
                    single
                    selected={this.state.selected}
                    hideSelected
                    onSelectedChange={this.selectEquipmentChange.bind(this)}
                    onClick={this.showEquipment.bind(this)}
                    getExtraSearchFn={getItemExtraSearch}
                />;
                break;
            }
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                <div className="notecontent ignoreDrag">
                    <div className="tc mb2">
                        <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                            <Button variant={(mode=="equipment")?"contained":null} onClick={this.clickMode.bind(this, "equipment")}>Equipment</Button>
                            <Button variant={(mode=="items")?"contained":null} onClick={this.clickMode.bind(this, "items")}>All Items</Button>
                        </ButtonGroup>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="notecontent minvh-80">
                    {inside}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, true)} color="primary">
                    Create
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <ItemDialog open={this.state.showItem} item={this.state.showItem} onClose={this.closeItem.bind(this)}/>
        </Dialog>;
    }

    getItemsMatches(filter) {
        const equipment = campaign.getSortedItemsList();
        const list = [];
        for (let i in equipment) {
            const it = equipment[i];
            if (itemMatchFilter(it, filter) && (!filter || filter.rarity || (it.rarity=="None" || !it.rarity))) {
                list.push(it);
            }
        }
        return list;
    }

    getEquipmentMatches(equipment, filter) {
        const list = [];
        for (let i in equipment) {
            let it = equipment[i];
            if (itemMatchFilter(it, filter)) {
                it = Object.assign({}, it);
                it.name = i;
                list.push(it);
            }
        }
        return list;
    }

    selectItemsChange(selected) {
        this.setState({selected, type:"item"});
    }

    selectEquipmentChange(selected) {
        this.setState({selected, type:"equipment"});
    }

    clickMode(mode){
        this.setState({mode});
    }

    showEquipment(id) {
        const equipment = this.props.equipment;
        this.setState({showItem:equipment[id]});
    }

    showItem(id) {
        this.setState({showItem:campaign.getItem(id)});
    }

    closeItem() {
        this.setState({showItem:null});
    }
}

class PickProperties extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            showmenu:false, 
        };
    }

    showMenu(event){
        if (this.props.editable) {

            this.setState({ showmenu:true, property:this.props.property||[]});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            this.props.onChange(this.state.property);
        }
        this.setState({showmenu:false});
        event.stopPropagation();
    };

    onChange(p) {
        const o = this.state.property.indexOf(p);
        var property = this.state.property.concat([]);

        if (o >= 0) {
            property.splice(o,1);
        } else {
            property.push(p);
        }
        this.setState({property});
    }

    onChangeV(p) {
        const o = this.state.property.findIndex(function (v) {return p.name==v.name});
        var property = this.state.property.concat([]);

        if (o >= 0) {
            property.splice(o,1);
        } else {
            property.push(p);
        }
        this.setState({property});
    }

    render() {
        var property = this.props.property||[];
        var options = [];

        if (!this.props.editable && (property.length==0)) {
            return null;
        }

        if (this.state.showmenu) {
            const ipm = itemProperties;
            const newproperty = this.state.property;

            for (let i in ipm) {
                var o = ipm[i];
                const checked = newproperty.includes(o.abbreviation)

                options.push(<CheckVal className="ml2 w-20 minw45" key={o.abbreviation} value={checked} onChange={this.onChange.bind(this,o.abbreviation)} label={o.entries[0].name}/>);
            }

            const epList = campaign.getSortedCustomList("Item Properties");
            for (let i in epList) {
                const it = epList[i];
                const checked = newproperty.find(function (v) {return it.name==v.name});
                options.push(<CheckVal className="ml2 w-20 minw45" key={it.name}value={checked} onChange={this.onChangeV.bind(this,it)} label={it.displayName}/>);
            }
        }
        
        var r = <span>
            <span className={(this.props.editable&&!this.props.useButton)?"hover-bg-contrast":""} onClick={!this.props.useButton?this.showMenu.bind(this):null}>
                {(this.props.useButton&&this.props.editable)?<Button className="minw2" size="small" variant="outlined" color="primary" onClick={this.showMenu.bind(this)}>
                    {this.props.label||"Properties"}
                </Button>:
                <b>Properties</b>
                }
                &nbsp;{getItemPropertyListFromProperty(property)}
            </span>
            {this.props.children}
            <Dialog
                open={this.state.showmenu}
            >
                <DialogTitle onClose={this.handleClose.bind(this, false)}>Item Properties</DialogTitle>
                <DialogContent>
                    {options}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose.bind(this, true)} color="primary">
                        Save
                    </Button>
                    <Button onClick={this.handleClose.bind(this, false)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </span>;

        return <div className="mb1">{r}</div>
    }
}

class ItemListPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state= {equipment:props.equipment||{}, coins:props.coins||{}, selectedToken:props.pickToken?campaign.getPrefs().selectedTreasureToken:null};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({equipment:this.props.equipment||{}, coins:this.props.coins||{}, selectedToken:this.props.pickToken?campaign.getPrefs().selectedTreasureToken:null});
        }
    }

    onSave(save) {
        if (save) {
            this.props.onClose(Object.keys(this.state.equipment).length?this.state.equipment:null, Object.keys(this.state.coins).length?this.state.coins:null, true, this.state.selectedToken);
        } else {
            this.props.onClose();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        let token;
        if (this.props.pickToken) {
            token = campaign.getArtInfo(this.state.selectedToken);
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.onSave.bind(this, false)}>Treasure</DialogTitle>
            <DialogContent>
                <div className="w-100 minvh-60">
                    <table className="w-100 mv1">
                        <tbody>
                            <tr>
                                <td className="tc hoverhighlight ba titleborder w-20" onClick={this.clickCoins.bind(this, "cp")}><div className="b">copper</div>{this.getCoins("cp").toLocaleString()}</td>
                                <td className="tc hoverhighlight ba titleborder w-20" onClick={this.clickCoins.bind(this, "sp")}><div className="b">silver</div>{this.getCoins("sp").toLocaleString()}</td>
                                <td className="tc hoverhighlight ba titleborder w-20" onClick={this.clickCoins.bind(this, "ep")}><div className="b">electrum</div>{this.getCoins("ep").toLocaleString()}</td>
                                <td className="tc hoverhighlight ba titleborder w-20" onClick={this.clickCoins.bind(this, "gp")}><div className="b">gold</div>{this.getCoins("gp").toLocaleString()}</td>
                                <td className="tc hoverhighlight ba titleborder w-20" onClick={this.clickCoins.bind(this, "pp")}><div className="b">platinum</div>{this.getCoins("pp").toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </table>
                    {this.getTreasureList()}
                    {this.props.pickToken?<div>
                        {token?<img src={token.url} className="maxh3"/>:null}
                        <Button onClick={this.onPickToken.bind(this)} color="primary" className="ma2">
                            Pick Treasure Token
                        </Button>
                        <ArtPicker defaultType="Treasure Token" open={this.state.showPickToken||false} onClose={this.onSelectToken.bind(this)}/>
                    </div>:null}
                </div>
                <ItemPicker 
                    open={this.state.showItemPicker} 
                    saveLabel="Add"
                    onClose={this.closeItemPicker.bind(this)}
                    equipment={{}} 
                />
                <ItemDialog editable open={this.state.showItemDetails} item={this.state.showItemValue} onClose={this.closeItem.bind(this, this.state.showItemId)}/>
                <NumberAdjust open={this.state.showPickCoins} positive onClose={this.onChangeCoin.bind(this)} value={this.state.showCoinValue} noShowValue anchorEl={this.state.coinAnchor}/>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={this.showAddItem.bind(this)}>add items</Button>
                {!this.props.pickToken||token?<Button onClick={this.onSave.bind(this, true)} color="primary">
                    Save
                </Button>:null}
                <Button onClick={this.onSave.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }
    
    onPickToken() {
        this.setState({showPickToken:true});
    }

    onSelectToken(token) {
        if (token) {
            this.setState({showPickToken:false, selectedToken:token.name});
        } else {
            this.setState({showPickToken:false});
        }
    }

    onClickItem(id) {
        this.setState({showItemDetails:true, showItemValue:this.state.equipment[id], showItemId:id});
    }

    closeItem(id, value) {
        if (value) {
            const equipment = Object.assign({},this.state.equipment);
    
            equipment[id] = value;
            this.setState({showItemDetails:false, equipment});
        } else {
            this.setState({showItemDetails:false});
        }
    }

    showAddItem(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({showItemPicker:true});
    } 

    getCoins(coin) {
        return (this.state.coins || {})[coin] || 0;
    }

    clickCoins(coin, evt) {
        this.setState({showPickCoins:true, showCoinType:coin, showCoinValue:this.getCoins(coin), coinAnchor:evt.target});
    }

    onChangeCoin(quantity, adjust) {
        if (adjust) {
            const coins = Object.assign({}, this.state.coins||{});
            const coin = this.state.showCoinType;
    
            if (quantity) {
                coins[coin] = quantity;
            } else {
                delete coins[coin];
            }
            this.setState({showPickCoins:false, coins});
        } else {
            this.setState({showPickCoins:false});
        }
    }

    getTreasureList() {
        const eq = this.state.equipment||{};
        const el = Object.keys(eq);
        const ret=[];

        el.sort(function(a,b){return (eq[a].displayName || eq[a].name).toLowerCase().localeCompare((eq[b].displayName || eq[b].name).toLowerCase())});

        for (let i in el) {
            const id=el[i];
            const it=eq[id];
            ret.push(<tr key={id} >
                <td className="tc vat w4">
                    <NumberAdjustPlusMinus positive value={it.quantity ?? 1} paramA={id} onChange={this.onAdjustQuantity.bind(this)}/>
                </td>
                <td className="vat hoverhighlight" onClick={this.onClickItem.bind(this,id)}>
                    {it.displayName||it.name} 
                </td>
                <td>
                    {(it.usage && it.usage.baseCount)?<MaxNumberAdjust max={it.usage.baseCount} value={getItemUses(it)} onAdjustValue={this.onAdjustUses.bind(this, id)}/>:null}
                </td>
                <td className="tc vat mw2">
                    <span className="fas f4 cursor-context-menu black-70 hoverhighlight fa-trash" onClick={this.onAdjustQuantity.bind(this, 0, -1, id)}/>
                </td>
            </tr>);
        }

        if (ret.length == 0) {
            return <div className="stdcontent pv1">
                <p>To add treasure, select coins or add items using the <b>add items</b> button below.</p>
                {this.props.pickToken?<p>Select a token to represent the treasure on the map.</p>:null}
            </div>;
        }

        return <div className="mb2">
            <table className="w-100 stdcontent">
                <tbody>
                    <tr>
                        <td className="tc f6 mw3">#</td>
                        <td className="f6">Name</td>
                        <td className="f6">Uses</td>
                        <td className="tc f6 mw2"></td>
                    </tr>
                    {ret}
                </tbody>
            </table>
        </div>;
    }

    onAdjustQuantity(quantity, adjust, id) {
        const equipment = Object.assign({}, this.state.equipment);

        if (quantity > 0) {
            equipment[id] = Object.assign({}, equipment[id]);
            equipment[id].quantity = quantity;
        } else {
            delete equipment[id];
        }
        this.setState({equipment});
    }

    onAdjustUses(id, uses) {
        const equipment = Object.assign({}, this.state.equipment);

        equipment[id] = Object.assign({}, equipment[id]);
        equipment[id].uses = uses;
        this.setState({equipment});
    }

    closeItemPicker(equipment) {
        if (equipment) {
            this.setState({showItemPicker:false, equipment:mergeItemList(equipment, this.state.equipment)})
        } else {
            this.setState({showItemPicker:false});
        }
    }
}

class SharedTreasurePicker extends React.Component {
    constructor(props) {
        super(props);

        this.handleOnDataChange = this.datachange.bind(this);
        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({sharedTreasure:campaign.getSharedTreasure().treasure||{}, selected:{}});
        }
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "adventure");
    }

    datachange() {
        this.setState({sharedTreasure:campaign.getSharedTreasure().treasure||{}});
    }
  
    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "adventure");
    }

    onSave() {
        this.props.onClose();
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const selected = this.state.selected||{};
        const nothingSelected = !Object.keys(selected).length;

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.onSave.bind(this)}>Shared Treasure</DialogTitle>
            <DialogContent>
                <div className="w-100">
                    {this.getTreasureList()}
                </div>
                <ItemDialog editable={!campaign.isSharedCampaign()} open={this.state.showItemDetails} item={this.state.showItemValue} onClose={this.closeItem.bind(this)}/>
            </DialogContent>
            <DialogActions>
                {this.props.showGrantTreasure?<Button onClick={this.showGrantTreasure.bind(this, true)} color="primary">
                    Grant Treasure
                </Button>:null}
                <Button disabled={nothingSelected} onClick={this.showPickPlayers.bind(this)} color="primary">
                    Distribute
                </Button>
                {this.props.character?<Button disabled={nothingSelected} onClick={this.onClaimSelected.bind(this)} color="primary">
                    Claim
                </Button>:null}
                <Button onClick={this.onSave.bind(this)} color="primary">
                    Close
                </Button>
            </DialogActions>
            <PickPlayers open={this.state.showPickPlayers} onClose={this.closePickPlayers.bind(this)}/>
            <TreasurePicker open={this.state.showTreasurePicker} onClose={this.showGrantTreasure.bind(this,false)}/>
        </Dialog>;
    }    

    showGrantTreasure(show){
        this.setState({showTreasurePicker:show});
    }

    onClickItem(id) {
        this.setState({showItemDetails:true, showItemValue:this.state.sharedTreasure[id], showItemId:id});
    }

    closeItem(item) {
        if (item) {
            const {showItemId,sharedTreasure} = this.state;
            const sharedTreasureNew = Object.assign({},sharedTreasure);
            sharedTreasureNew[showItemId] = item;
            campaign.updateCampaignContent("adventure", {name:"sharedtreasure", treasure:sharedTreasureNew});
        }
        this.setState({showItemDetails:false});
    }

    getTreasureList() {
        const selected = this.state.selected||{};
        const eq = this.state.sharedTreasure||{};
        const el = Object.keys(eq);
        const ret=[];

        el.sort(function(a,b){return ((eq[a].displayName||eq[a].name).toLowerCase().localeCompare((eq[b].displayName||eq[b].name).toLowerCase()) || ((eq[a].quantity??1)-(eq[b].quantity??1)))});

        for (let i in el) {
            const id=el[i];
            const it=eq[id];
            ret.push(<tr key={id} >
                <td className="tc vat">
                    <span className={"far pa1 hoverhighlight "+(selected[id]?"fa-check-square":"fa-square")} onClick={this.onToggleSelected.bind(this, id)}/>
                </td>
                <td className="tc vat">
                    {(it.quantity??1)?<span className="w2 tr">{it.quantity??1}</span>:null}
                </td>
                <td className="vat hoverhighlight" onClick={this.onClickItem.bind(this,id)}>
                    {it.displayName||it.name} 
                </td>
                <td className="tc vat">
                    <DeleteWithConfirm name={it.displayName} onClick={this.onDelete.bind(this, id)}/>
                </td>
            </tr>);
        }

        if (ret.length == 0) {
            if (this.props.character){
                return <div className="stdcontent">No shared treasure to claim</div>;
            } else {
                return <div className="stdcontent">
                    <p>No shared treasure for players to claim.</p>
                    <p>You can add to the shared treasure for characters, by clicking the <b>Grant Treasure</b> button below.</p>
                </div>;
            }
        }

        return <div>
            <table className="w-100 stdlist">
                <tbody>
                    <tr>
                        <td className="tc f6"></td>
                        <td className="tc f6">#</td>
                        <td className="f6 w-100">name</td>
                        <td className="tc f6"></td>
                    </tr>
                    {ret}
                </tbody>
            </table>
        </div>;
    }

    onToggleSelected(id) {
        const selected = Object.assign({}, this.state.selected);
        if (selected[id]) {
            delete selected[id];
        } else {
            selected[id] = true;
        }
        this.setState({selected});
    }

    onClaimSelected() {
        const sharedTreasure = Object.assign({}, this.state.sharedTreasure);
        const character = this.props.character;
        const selected = this.state.selected;
        const added = {};
        const merge = {};

        for (let id in selected) {
            const it = sharedTreasure[id];

            if (it) {
                merge[id] = it;
                delete sharedTreasure[id];
            }
        }

        character.mergeEquipment(merge, added);
        Chat.addEquipment(character, "added", added);
        this.setState({selected:{}});
        campaign.updateCampaignContent("adventure", {name:"sharedtreasure", treasure:sharedTreasure});
    }

    showPickPlayers() {
        this.setState({showPickPlayers:true});
    }

    closePickPlayers(players){ 
        const selected = this.state.selected;

        if (players && players.length > 0) {
            const pc = players.length;
            const added={};
            let didAdd;
            const sharedTreasure = Object.assign({}, this.state.sharedTreasure);

            for (let id in selected) {
                const it = sharedTreasure[id];
                if (it) {
                    var count = it.quantity||1;
            
                    var unit = Math.trunc(count/pc);
                    if (unit > 0) {
                        const newit = Object.assign({}, it);
                        newit.quantity=unit;
                        added[id] = newit;
                        didAdd = true;
                    }            
                    const total = unit*pc;

                    if (total >= count) {
                        delete selected[id];
                        delete sharedTreasure[id];
                    } else {
                        const setit = Object.assign({}, it);
                        setit.quantity = count-total;
                        sharedTreasure[id]=setit;
                    }
                } else {
                    delete selected[id];
                }
            }    

            if (didAdd) {
                const names=[];

                for (let i in players) {
                    const character = new Character(campaign.getPlayerInfo(players[i]), null);
                    names.push(character.displayName);
                    character.mergeEquipment(added);
                }

                Chat.addEquipment(this.props.character, "distribute", added, null, names.join(", "));

                campaign.updateCampaignContent("adventure", {name:"sharedtreasure", treasure:sharedTreasure});
            }
        }
        this.setState({showPickPlayers:false, selected});
    }

    onDelete(id) {
        const sharedTreasure = Object.assign({}, this.state.sharedTreasure);
        delete sharedTreasure[id];
        campaign.updateCampaignContent("adventure", {name:"sharedtreasure", treasure:sharedTreasure});
    }

    onSplit(id) {
        const sharedTreasure = Object.assign({}, this.state.sharedTreasure);
        const toSplit = sharedTreasure[id];
        var count = toSplit.quantity;
        var pc = campaign.getPlayers().length || 1;
        pc = pc || 1;

        var unit = Math.trunc((count+pc-1)/pc)||1;
        while (count > 0) {
            if (count < unit) {
                unit = count;
            }
            const newSel = Object.assign({}, toSplit);
            newSel.quantity = unit;
            sharedTreasure[campaign.newUid()] = newSel;
            count = count - unit;
        }

        delete sharedTreasure[id];
        campaign.updateCampaignContent("adventure", {name:"sharedtreasure", treasure:sharedTreasure});
    }
}

class TreasurePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state= {selected:[]};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({selected:[], equipment:{}, coins:{}});
        }
    }

    onSave(save) {
        if (save) {
            this.onClosePickTreasure(this.state.selected);
        } else {
            this.props.onClose();
        }
    }

    onClosePickTreasure(selected) {
        if (selected || pickTreasure) {
            const encounter = Object.assign({}, campaign.getAdventure());
            const newEM = (encounter.combatants||[]).concat([]);
            encounter.combatants = newEM;
            const newShared = {};

            if (selected) {
                for (let i in newEM) {
                    const s= selected[i];
                    if (s) {
                        const allSelected = s&&(typeof s != "object");
                        const e = Object.assign({}, newEM[i]);
                        const treasure = e.treasure;
                        let setNew=false;
                        const newTreasure={coins:{}, items:{}};

                        for (let c in treasure.coins) {
                            if (allSelected || s["coin~"+c]) {
                                newShared[campaign.newUid()] = {quantity:treasure.coins[c], coin:true, coinType:c, displayName:coinNames[c]};
                            } else {
                                setNew=true;
                                newTreasure.coins[c] = treasure.coins[c];
                            }
                        }

                        for (let e in treasure.items){
                            if (allSelected || s[e]) {
                                newShared[campaign.newUid()] = treasure.items[e];
                            } else {
                                setNew=true;
                                newTreasure.items[e] = treasure.items[e];
                            }
                        }

                        if (!setNew) {
                            delete e.treasure;
                        } else {
                            e.treasure=newTreasure;
                        }
                        newEM[i]=e;
                    }
                }
            }

            for (let c in this.state.coins) {
                newShared[campaign.newUid()] = {quantity:this.state.coins[c], coin:true, coinType:c, displayName:coinNames[c]};
            }

            for (let e in this.state.equipment){
                newShared[campaign.newUid()] = this.state.equipment[e];
            }

            const newst =  mergeItemList(newShared, campaign.getSharedTreasure().treasure);

            campaign.updateCampaignContent("adventure", {name:"sharedtreasure", treasure: newst});
            campaign.updateCampaignContent("adventure", encounter);
        }
        this.props.onClose();
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open            
        >
            <DialogTitle onClose={this.onSave.bind(this, false)}>Grant Treasure</DialogTitle>
            <DialogContent>
                <div className="w-100">
                    {this.getTreasureList()}
                </div>
            </DialogContent>
            <DialogActions>
                {this.props.showTreasure?<Button onClick={this.onShowTreasure.bind(this, true)} color="primary">
                    Shared Treasure
                </Button>:null}
                <Button onClick={this.onSave.bind(this, true)} color="primary">
                    Grant
                </Button>
                <Button onClick={this.onSave.bind(this, false)} color="primary">
                    Close
                </Button>
            </DialogActions>
            <ItemListPicker 
                open={this.state.showItemListPicker} 
                onClose={this.closeItemListPicker.bind(this)}
                equipment={this.state.equipment}
                coins={this.state.coins}
            />
            <SharedTreasurePicker open={this.state.showTreasure} onClose={this.onShowTreasure.bind(this,false)}/>
        </Dialog>;
    }

    onShowTreasure(showTreasure) {
        this.setState({showTreasure});
    }
    
    onPickTreasure() {
        this.setState({showItemListPicker:true});
    }

    closeItemListPicker(equipment, coins, save) {
        if (save) {
            this.setState({showItemListPicker:false, equipment, coins})
        }
        this.setState({showItemListPicker:false});
    }

    onClickItem(id) {
        this.setState({showItemDetails:true, showItemValue:this.state.equipment[id], showItemId:id});
    }

    getTreasureList() {
        const encounter = campaign.getAdventure();

        const el = encounter.combatants||[];
        const ret=[];
        let someTreasure=false;

        for (let i in el) {
            const m = el[i];
            if (m.treasure) {
                const s = this.state.selected[i];
                const allSelected = s&&(typeof s != "object");
                someTreasure=true;
                ret.push(<tr key={i} >
                    <td className="tc vat mw2">
                        <span className={"far pa1 hoverhighlight "+(allSelected?"fa-check-square":"fa-square")} onClick={this.onToggleSelected.bind(this, i, null)}/>
                    </td>
                    <td className="vat w-100" colSpan="2">
                        {m.name} 
                    </td>
                </tr>);
                for (let c in m.treasure.coins) {
                    ret.push(<tr key={i+"coin"+c} >
                        <td/>
                        <td className="tc vat mw2">
                            <span className={"far pa1 hoverhighlight "+((allSelected||(s&&s["coin~"+c]))?"fa-check-square":"fa-square")} onClick={this.onToggleSelected.bind(this, i, "coin~"+c)}/>
                        </td>
                        <td className="vat w-100">
                            {m.treasure.coins[c]} {coinNames[c]} 
                        </td>
                    </tr>)
                }
                for (let x in m.treasure.items) {
                    const it = m.treasure.items[x];
                    ret.push(<tr key={i+"item"+x} >
                        <td/>
                        <td className="tc vat mw2">
                            <span className={"far pa1 hoverhighlight "+((allSelected||(s&&s[x]))?"fa-check-square":"fa-square")} onClick={this.onToggleSelected.bind(this, i, x)}/>
                        </td>
                        <td className="vat w-100">
                            {(it.quantity && it.quantity>1)?it.quantity+" ":null}{it.displayName||it.name} 
                        </td>
                    </tr>)
                }
            }
        }

        ret.push(<tr key="dm~" >
            <td className="vat w-100" colSpan="3">
                <Button onClick={this.onPickTreasure.bind(this, true)} variant="outlined" className="mv1" color="primary">
                    Pick Additional Treasure
                </Button>
            </td>
        </tr>);
        for (let c in this.state.coins) {
            someTreasure=true;
            ret.push(<tr key={"dmcoin~"+c} >
                <td/>
                <td className="vat w-100" colSpan="2">
                    {this.state.coins[c]} {coinNames[c]} 
                </td>
            </tr>)
        }
        for (let x in this.state.equipment) {
            someTreasure=true;
            const it = this.state.equipment[x];
            ret.push(<tr key={"dmitem~"+x} >
                <td/>
                <td className="vat w-100" colSpan="2">
                    {(it.quantity && it.quantity>1)?it.quantity+" ":null}{it.displayName||it.name}
                </td>
            </tr>)
        }

        if (!someTreasure) {
            return <div className="stdcontent">
                <p>No treasure has been defined for this encounter.  To prepare treasure add treasure to creatures or treasure tokens in planned enounters.</p>
                <p>You can directly add treasure now. <Button onClick={this.onPickTreasure.bind(this, true)} variant="outlined" className="pv1" color="primary">
                    Pick Treasure
                </Button></p>
            </div>;
        }

        return <div>
            <table className="w-100 stdlist">
                <tbody>
                    <tr>
                        <td className="tc f6 mw3">grant</td>
                        <td className="f6 w-100" colSpan="2">name</td>
                    </tr>
                    {ret}
                </tbody>
            </table>
        </div>;
    }

    onToggleSelected(id,v) {
        const selected = this.state.selected.concat([]);

        if (!v) {
            selected[id] = !selected[id];
        } else {
            let s = selected[id];
            if (typeof s == "object") {
                s = Object.assign({},s);
                s[v] = !s[v];
            } else {
                if (s) {
                    s={};
                    const encounter = campaign.getAdventure();
                    const el = encounter.combatants||[];
                    const m = el[id];
                    if (m.treasure) {
                        for (let c in m.treasure.coins) {
                            s["coin~"+c]=true;
                        }
                        for (let x in m.treasure.items) {
                            s[x]=true;
                        }
                    }
                    s[v] = !s[v];
                } else {
                    s={};
                    s[v]=true;
                }
            }
            selected[id]=s;
        }
        this.setState({selected});
    }
}


class PickPlayers extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            const players = campaign.getPlayers();
            const selected = {};
            for (let i in players) {
                const p=players[i];
                if (!p.claimable) {
                    selected[p.name]=true;
                }
            }
            this.setState({selected});
        }
    }

    onSave(save) {
        this.props.onClose(save?Object.keys(this.state.selected):null);
    }

	render() {
        if (!this.props.open) {
            return null;
        }

        const players = campaign.getPlayers();
        const selected = this.state.selected||{};
        const list = [];
        let all=true;

        for (let i in players) {
            const p = players[i];
            if (!p.claimable) {
                if (!selected[p.name]){
                    all=false;
                }
                list.push(<tr key={p.name} >
                    <td className="tc">
                        <span className={"far pa1 hoverhighlight "+(selected[p.name]?"fa-check-square":"fa-square")} onClick={this.onToggleSelected.bind(this, p.name)}/>
                    </td>
                    <td className="w-100">
                        {p.displayName} 
                    </td>
                </tr>);
            }
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open
        >
            <DialogTitle onClose={this.onSave.bind(this, false)}>Pick Characters</DialogTitle>
            <DialogContent>
                <table className="w-100 stdlist">
                    <tbody>
                    <tr>
                        <td className="tc">
                            <span className={"far pa1 hoverhighlight "+(all?"fa-check-square":"fa-square")} onClick={this.onToggleAll.bind(this, !all)}/>
                        </td>
                        <td className="w-100">
                        </td>
                    </tr>
                    {list}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onSave.bind(this, true)} color="primary">
                    Distribute
                </Button>
                <Button onClick={this.onSave.bind(this,false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }    

    onToggleSelected(id) {
        const selected = Object.assign({}, this.state.selected);
        if (selected[id]) {
            delete selected[id];
        } else {
            selected[id] = true;
        }
        this.setState({selected});
    }

    onToggleAll(set) {
        const players = campaign.getPlayers();
        const selected = {};
        if (set) {
            for (let i in players) {
                const p=players[i];
                if (!p.claimable) {
                    selected[p.name]=true;
                } 
            }
        }
        this.setState({selected});
    }

}

class StartingEquipmentPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state= {equipment:{}};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.open != prevProps.open) && this.props.open) {
            const selectedOptions = [];
            const character = this.props.character;
            const firstLevel = (character.levels||[])[1];
            const startingClass = firstLevel && firstLevel.cclass;
            const clsInfo = campaign.getClassInfo(startingClass);
            const background = campaign.getBackgroundInfo(character.background);
    
            let equipment = clsInfo && clsInfo.startingEquipment||{};
            let options = equipment.options||[];
            if (background && background.startingEquipment) {
                // merge in options from background
                equipment = Object.assign({}, equipment);
                options = options.concat(background.startingEquipment.options||[]);
                equipment.options = options;
                equipment.goldAlt2 = background.startingEquipment.goldAlt;
            }

            for (let i in options) {
                const o = (options[i] && options[i][0]) || {};
                const selection = [];
                const items = o.items||{};
                const itemList = o.itemList||[];

                for (let x in items) {
                    let itemInfo = campaign.getItem(x);
                    if (itemInfo) {
                        itemInfo = Object.assign({}, itemInfo);
                        itemInfo.count = items[x];
                        selection.push(itemInfo);
                    }
                }

                for (let x in itemList) {
                    selection.push({name:itemList[x], type:"OTH"});
                }

                if (o.pickCount && (o.type == "martial weapon and shield")) {
                    const itemShield = campaign.getItem("shield");
                    if (itemShield) {
                        selection[1] = Object.assign({}, itemShield);
                    }
                }
                selectedOptions.push({optionNum:0, gp:o.gp||0, selection});
            }
            this.setState({equipment, selectedOptions, pickGoldAlt:false});
        }
    }

    onSave(save) {
        if (save) {
            const selectedOptions = this.state.selectedOptions;
            const character = this.props.character;
            let gpSum = 0;
            const addEquip = [];

            for (let i in selectedOptions) {
                const so = selectedOptions[i];

                for (let x in so.selection) {
                    if (so.selection[x]) {
                        addEquip.push(so.selection[x]);
                    }
                }
                gpSum += so.gp||0;
            }

            if (gpSum) {
                character.adjustCoins("gp", gpSum);
            }
            if (addEquip.length){
                character.mergeEquipment(addEquip);
            }

        }
        this.props.onClose();
    }

    onPickGoldAlt(gp) {
        this.setState({pickGoldAlt:false});
        if (gp) {
            const {character} = this.props;
            character.adjustCoins("gp", gp);
            this.props.onClose();
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {PickGoldAlternative} = require("./features.jsx");
        const {character} = this.props;
        const equipment = this.state.equipment;
        const selectedOptions = this.state.selectedOptions;
        const optionList = [];
        const options = equipment.options||[];

        for (let i in options) {
            optionList.push(<StartingEquipmentOptions key={i} options={options[i]} selected={selectedOptions[i]} onChange={this.onChangeOption.bind(this, i)}/>);
        }

        return <Dialog
            maxWidth="sm"
            fullWidth
            open            
        >
            <DialogTitle onClose={this.onSave.bind(this, false)}>Starting Equipment</DialogTitle>
            <DialogContent>
                {optionList}
            </DialogContent>
            <DialogActions>
                {equipment.goldAlt?<Button onClick={this.showGoldAlt.bind(this)} color="primary">
                    Pick Gold Alternative
                </Button>:null}
                <Button onClick={this.onSave.bind(this, true)} color="primary">
                    Add
                </Button>
                <Button onClick={this.onSave.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <PickGoldAlternative character={character} open={this.state.pickGoldAlt} goldAlt={equipment.goldAlt} goldAlt2={equipment.goldAlt2} onClose={this.onPickGoldAlt.bind(this)}/>
        </Dialog>;
    }

    showGoldAlt() {
        this.setState({pickGoldAlt:true});
    }

    onChangeOption(i, selected) {
        const selectedOptions = this.state.selectedOptions.concat([]);

        selectedOptions[i] = selected;
        this.setState({selectedOptions});
    }
}

const classWidthValues = ["w-100", "w-100", "w-50", "w-33"];
class StartingEquipmentOptions extends React.Component {
    constructor(props) {
        super(props);
	    this.state= { };
    }

    render() {
        const options = this.props.options||[];
        const selected = this.props.selected;
        const olist = [];

        for (let i in options) {
            const match = (i==selected.optionNum);
            olist.push(<div key={i} className={(classWidthValues[options.length]||"")+((i>0)?" bl":"")}>
                {(options.length > 1)?<Checkbox checked={match} onChange={this.onChangeSelection.bind(this, Number(i))}/>:null}
                <StartingEquipmentOption key="edit" option={options[i]} selected={match} selection={match?selected.selection:null} onChange={this.onChangeOption.bind(this)}/>
            </div>);
        }
        return <div className="hk-well flex mv1">
            {olist}
        </div>;
    }

    onChangeSelection(i) {
        const options = this.props.options||[];
        const selected = this.props.selected;
        if (i!=selected.optionNum) {
            const newSelected = {optionNum:i};
            const selection = [];
            const o = options[i]||{};
            const items = o.items||{};
            const itemList = o.itemList||[];

            for (let x in items) {
                let itemInfo = campaign.getItem(x);
                if (itemInfo) {
                    itemInfo = Object.assign({}, itemInfo);
                    itemInfo.count = items[x];
                    selection.push(itemInfo);
                }

            }
            for (let x in itemList) {
                selection.push({name:itemList[x], type:"OTH"});
            }

            if (o.pickCount && (o.type == "martial weapon and shield")) {
                const itemShield = campaign.getItem("shield");
                if (itemShield) {
                    selection[1] = Object.assign({}, itemShield);
                }
            }

            newSelected.selection = selection;
            newSelected.gp = o.gp||0;
            this.props.onChange(newSelected);
        }
    }

    onChangeOption(selection) {
        const selected = Object.assign({}, this.props.selected);
        selected.selection = selection;
        this.props.onChange(selected);
    }
}

const countMap = ["", " ", "two ", "three ", "four ", "five ", "six ", "seven ", "eight ", "nine "];
const countAnyMap = ["", "any", "two", "three", "four", "five", "six", "seven", "eight", "nine"];

class StartingEquipmentOption extends React.Component {
    constructor(props) {
        super(props);
	    this.state= { };
    }

    render() {
        const option = this.props.option||{};
        let typeDescription;
        let selectionOptions;

        if (option.pickCount) {
            typeDescription = <span>{countAnyMap[Number(option.pickCount)]||option.pickCount} {option.type}</span>;
            if (this.props.selected) {
                //show select options if selected option
                selectionOptions = this.getSelectionOptions();
            }
        } else if (option.gp) {
            typeDescription = <span>{option.gp} gp</span>
        } else if (option.itemList) {
            typeDescription = <span>{joinCommaAnd(option.itemList)}</span>
        } else if (option.items) {
            const itemList = [];
            const len = Object.keys(option.items).length;
            let cnt=1;

            for (let i in option.items) {
                const count  = Number(option.items[i]);
                const it = campaign.getItem(i) || {};
                itemList.push(<span key={i}>{(cnt>1)?(len == cnt?" and ":", "):""}{pluralString("", count, countMap)}<Renderstring entry={"{@item "+i+"| |"+pluralString(it.displayName||i, count)+"}"}/></span>);
                cnt++;
            }
            typeDescription = <span>{itemList}</span>;
        }

        return <div>
            {typeDescription}
            {selectionOptions}
        </div>;
    }

    getSelectionOptions() {
        const option = this.props.option||{};
        const selection = this.props.selection||[];
        const ret = [];
        const selectList =[];
        const items = campaign.getSortedItemsList();
        let selectFn;
        let description = option.type;

        switch (option.type) {
            case "simple weapon":
                selectFn = function (it) {
                    return (((it.rarity||"none").toLowerCase() == "none") && (it.weaponCategory == "Simple") && ["R","M"].includes(getItemType(it))); 
                }
                break;

            case "martial weapon and shield":
                description = "martial weapon";
            case "martial weapon":
                selectFn = function (it) {
                    return (((it.rarity||"none").toLowerCase() == "none") && (it.weaponCategory == "Martial") && ["R","M"].includes(getItemType(it))); 
                }
                break;

            case "simple melee weapon":
                selectFn = function (it) {
                    return (((it.rarity||"none").toLowerCase() == "none") && (it.weaponCategory == "Simple") && (getItemType(it)=="M")); 
                }
                break;
    
            case "martial melee weapon":
                selectFn = function (it) {
                    return (((it.rarity||"none").toLowerCase() == "none") && (it.weaponCategory == "Martial") && (getItemType(it)=="M")); 
                }
                break;
    
            case "gaming set":
                selectFn = function (it) {
                    return (((it.rarity||"none").toLowerCase() == "none") && getItemType(it)=="GS"); 
                }
                break;

            case "artisan's tools":
                selectFn = function (it) {
                    return (((it.rarity||"none").toLowerCase() == "none") && getItemType(it)=="AT"); 
                }
                break;
    
            case "musical instrument":
                selectFn = function (it) {
                    return (((it.rarity||"none").toLowerCase() == "none") && getItemType(it)=="INS"); 
                }
                break;
        }

        if (!selectFn) {
            console.log("unknown select type", option.type);
            return null;
        }

        for (let i in items) {
            const it = items[i];
            if (selectFn(it)) {
                selectList.push({value:it.name, name:it.displayName});
            }
        }

        for (let i=0; i<option.pickCount; i++) {
            ret.push(<div key={i} className="ma1 notetext tl"><SelectVal noteText fullWidth label={"pick "+description} value={(selection[i]&&selection[i].name)||""} values={selectList} onClick={this.onChangeSelection.bind(this, i)}/></div>);
        }
        return ret;
    }

    onChangeSelection(i, val) {
        const selection = (this.props.selection||[]).concat([]);
        const item = Object.assign({}, campaign.getItem(val));

        selection[i] = item;
        this.props.onChange(selection);
    }
}

class ItemsHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onNewItem() {
        this.setState({showItemDetails:true});
    }

    render() {
        return <span>
            Items
            <Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={this.onNewItem.bind(this)}>New</Button>
            <NewItem open={this.state.showItemDetails} onClose={this.closeItem.bind(this)}/>
        </span>;
    }

    closeItem(value) {
        if (value && value.displayName && value.displayName!="") {
            campaign.updateCampaignContent("items", value);
        }
        this.setState({showItemDetails:false});
    }
}


class ItemHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onEditItem(newItem) {
        this.setState({showItemDetails:true, newItem});
    }

    render() {
        return <span>
            {this.props.id}
            <Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={this.onEditItem.bind(this, false)}>Edit</Button>
            <Button className="ml1 minw2" color="primary" variant="outlined" size="small" onClick={this.onEditItem.bind(this, true)}>Copy</Button>
            <ItemDialog openEditable open={this.state.showItemDetails} item={this.state.showItemDetails && campaign.getItem(this.props.id)} onClose={this.closeItem.bind(this)}/>
        </span>;
    }

    closeItem(value) {
        if (value) {
            campaign.updateCampaignContent("items", value);
        }
        this.setState({showItemDetails:false});
    }
}



class ItemBookList extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

	render() {
        const list = this.props.list.concat([]);
        const general= [];
        const armor=[];
        const weapon=[];
        const carry=[];
        const currency=[];

        list.sort(function (a, b) {
            const ait  = (campaign.getItem(a.contentId)||{}).displayName||"";
            const bit  = (campaign.getItem(b.contentId)||{}).displayName||"";
            return ait.toLowerCase().localeCompare(bit.toLowerCase())
        })

        for (let i in list) {
            const it  = campaign.getItem(list[i].contentId);
            if (it) {
                switch (getBasicItemType(it)) {
                    case "Weapon":{
                        weapon.push(<tr key={it.name} className="hoverhighlight" onClick={this.showItem.bind(this, it.name)}>
                            <td>{it.displayName}</td>
                            <td className="tr">{it.value}&nbsp;</td>
                            <td>{getDamageString(it.dmg1)||"-"} {Parser.DMGTYPE_JSON_TO_FULL[it.dmgType]||""}</td>
                            <td className="tr">{it.weight?(it.weight+" lb."):null}</td>
                            <td>{getItemPropertyList(it)}{it.range?(" (range "+it.range+")"):null}</td>
                        </tr>);
                        break;
                    }
                    case "Armor":{
                        armor.push(<tr key={it.name} className="hoverhighlight" onClick={this.showItem.bind(this, it.name)}>
                            <td>{it.displayName}</td>
                            <td className="tr">{it.value}&nbsp;</td>
                            <td>{(it.ac<10)?("+"+it.ac):it.ac}{(it.type=="LA")?"+ Dex modifier":((it.type=="MA")?"+ Dex modifier (max 2)":"")}</td>
                            <td>{it.strength?("Str "+it.strength):"-"}</td>
                            <td>{it.stealth?"Disadvantage":"-"}</td>
                            <td className="tr">{it.weight?(it.weight+" lb."):null}</td>
                        </tr>);
                        break;
                    }
                    case "Mount":
                    case "Vehicle":{
                        carry.push(<tr key={it.name} className="hoverhighlight" onClick={this.showItem.bind(this, it.name)}>
                            <td>{it.displayName}</td>
                            <td className="tr">{it.value}&nbsp;</td>
                            <td className="tr">{it.carryingcapacity}&nbsp;</td>
                        </tr>);
                        break;
                    }
                    case "Currency": {
                        currency.push(<tr key={it.name} className="hoverhighlight" onClick={this.showItem.bind(this, it.name)}>
                            <td>{it.displayName}</td>
                            <td className="tr">{it.weight?(it.weight+" lb."):null}</td>
                        </tr>);
                        break;
                    }

                    default: {
                        general.push(<tr key={it.name} className="hoverhighlight" onClick={this.showItem.bind(this, it.name)}>
                            <td>{it.displayName}</td>
                            <td className="tr">{it.value}&nbsp;</td>
                            <td className="tr">{it.weight?(it.weight+" lb."):null}</td>
                        </tr>);
                        break;
                    }
                }
            }
        }
        return <div>
            {weapon.length?<table className="w-100 mb2">
                <tbody>
                    <tr>
                        <td className="b">Weapon</td>
                        <td className="b tr">Cost&nbsp;</td>
                        <td className="b">Damage</td>
                        <td className="b tr">Weight&nbsp;</td>
                        <td className="b">Properties</td>
                    </tr>
                    {weapon}
                </tbody>
            </table>:null}
            {armor.length?<table className="w-100 mb2">
                <tbody>
                    <tr>
                        <td className="b">Armor</td>
                        <td className="b tr">Cost&nbsp;</td>
                        <td className="b">Armor Class AC</td>
                        <td className="b">Strength</td>
                        <td className="b">Stealth</td>
                        <td className="b tr">Weight&nbsp;</td>
                    </tr>
                    {armor}
                </tbody>
            </table>:null}
            {carry.length?<table className="w-100 mb2">
                <tbody>
                    <tr>
                        <td className="b">Name</td>
                        <td className="b tr">Cost&nbsp;</td>
                        <td className="b tr">Carrying Capacity</td>
                    </tr>
                    {carry}
                </tbody>
            </table>:null}
            {currency.length?<table className="w-100 mb2">
                <tbody>
                    <tr>
                        <td className="b">Currency</td>
                        <td className="b tr">Weight&nbsp;</td>
                    </tr>
                    {currency}
                </tbody>
            </table>:null}
            {general.length?<table className="w-100 mb2">
                <tbody>
                    <tr>
                        <td className="b">Name</td>
                        <td className="b tr">Cost&nbsp;</td>
                        <td className="b tr">Weight&nbsp;</td>
                    </tr>
                    {general}
                </tbody>
            </table>:null}
            <ItemDialog name={this.state.showName} open={this.state.showItem} onClose={this.closeItem.bind(this)}/>
        </div>;
    }

    showItem(name) {
        this.setState({showItem:true, showName:name});
    }

    closeItem() {
        this.setState({showItem:false});
    }
}

class DeleteItemFromCharacter extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

    render() {
        const {it,character,id} = this.props;
        const {action,open,showMenu,anchorEl, quantity, container, coins,vcoins}=this.state;
        let containers;
        let coinList=[];
        let percent, vsum, csum,vExtra;

        if (open || showMenu) {
            const knownCoins = getKnown(character.findCoins());
    
            containers = getContainers(character.equipment,id);
            const ci = getCoinInfo(it.value);
            let coinTypes = ["cp","sp", "gp", "pp"];
            if (ci && !coinTypes.includes(ci.coin)) {
                const cInfo = findCoin(ci.coin);
                if (cInfo) {
                    coinTypes.unshift(ci.coin);
                }
            }
            for (let coin of coinTypes) {
                const q = (coins||{})[coin]||0;
                coinList.push(<td key={coin} className="tc hoverhighlight ba titleborder w-20"><NumberAdjust positive onClose={this.onChangeCoin.bind(this,coin)} value={q} altText={<div><div className="b pa1">{coin}</div>{q}</div>}/></td>);
            }

            vsum = computeGPEquiv(vcoins, knownCoins);
            csum = computeGPEquiv(coins, knownCoins);
            percent = Math.round(Math.max(0, csum)*100/(vsum||1));

            vExtra = getSimpleCoinString(it);
        }


        return <span onClick={function(e){e.preventDefault();e.stopPropagation();}}>
            <span className="pa1 fas fa-caret-down hoverhighlight" onClick={this.showMenu.bind(this, true)}/>
            {open?<Dialog 
                open
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle onClose={this.closeDialog.bind(this)}>{it.displayName}</DialogTitle>
                <DialogContent>
                    {action=="sell"?<div>
                        <table className="w-100 mv1">
                            <tbody>
                                <tr>
                                    {coinList}
                                </tr>
                            </tbody>
                        </table>
                        {(it.value||vExtra)?<div>
                            <div className="mt1"><b>Standard value</b> {it.value} {vExtra}</div>
                            {vsum?<div className="mt1"><b>Sell Value</b> <PickVal values={scaleOptions} onClick={this.changeScale.bind(this)}><span className="f5 hoverhighlight ">({percent}% of value <span className="pa1 fas fa-caret-down"/>)</span></PickVal></div>:null}
                        </div>:null}
                    </div>:null}
                    {action == "move"?<div>
                        <SelectVal noteText helperText="Move to Container" value={container||containers[0].value||"none"} values={containers} onClick={this.onChangeContainer.bind(this)}/>
                    </div>:null}
                    {((it.quantity??1) > 1)?<div className="mv1">
                        Quantity <NumberAdjustPlusMinus positive value={quantity ?? 0} onChange={this.onAdjustQuantity.bind(this)}/> / {it.quantity??1}
                    </div>:null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onDeleteItem.bind(this,action, quantity)} color="primary">
                        OK
                    </Button>
                    <Button onClick={this.closeDialog.bind(this)} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>:null}
            {showMenu?<Menu open anchorEl={anchorEl} anchorOrigin={{vertical: 'bottom',horizontal: 'center'}} transformOrigin={{vertical: 'top',horizontal: 'center'}} getContentAnchorEl={null} onClose={this.showMenu.bind(this, false)} disableAutoFocusItem>
                <MenuItem onClick={this.onDelete.bind(this, "drop")}>Drop</MenuItem>
                {!(it.coin||it.type=="coin")?<MenuItem onClick={this.onDelete.bind(this, "sell")}>Sell</MenuItem>:null}
                {containers?<MenuItem onClick={this.onDelete.bind(this, "move")}>Change Container</MenuItem>:null}
                {!campaign.isDefaultCampaign()?<MenuItem onClick={this.onDelete.bind(this, "put")}>Put in Shared Treasure</MenuItem>:null}
            </Menu>:null}
        </span>
    }

    changeScale(scaleTarget) {
        const {it} = this.props;
        const coins = getTotalValue(it, null, this.state.quantity*(scaleTarget||1));
        this.setState({coins, scaleTarget});
    }

    onChangeContainer(container) {
        if (container == "none") {
            continer = null;
        }
        this.setState({container});
    }

    showMenu(showMenu,evt) {
        this.setState({showMenu, anchorEl:showMenu?evt?.target:null});
    }

    onDelete(action, e) {
        const {it} = this.props;
        const quantity = it.quantity||1;

        if (!["sell","move"].includes(action) && (quantity==1)) {
            this.onDeleteItem(action,1);
            this.setState({showMenu:false});
        } else {
            const coins = getTotalValue(it, null, quantity);
            this.setState({open:true, action, showMenu:false, coins, vcoins:coins, quantity, scaleTarget:1});
        }
    }

    onAdjustQuantity(quantity) {
        const {it} = this.props;
        if (quantity <1){
            quantity=1;
        }
        if (quantity > it.quantity) {
            quantity = it.quantity;
        }
        const coins = getTotalValue(it, null, quantity*(this.state.scaleTarget||1));
        this.setState({quantity, coins,vcoins:coins});
    }

    onChangeCoin(coin, val) {
        const coins=Object.assign({}, this.state.coins);
        coins[coin]=val;
        this.setState({coins});
    }

    onDeleteItem(action, quantity) {
        const {character,id}=this.props;
        const {coins,container} =this.state;
        const removedEquipment={};
        let sellCoins;
        let addActionName="Dropped";
        let targetId;

        if (quantity) {
            if (action=="put") {
                const sharedTreasure = Object.assign({}, campaign.getSharedTreasure().treasure);
                const it = Object.assign({},character.getEquipmentItem(id));
                it.quantity = quantity;
                delete it.equip;

                sharedTreasure[campaign.newUid()] = it;
                campaign.updateCampaignContent("adventure", {name:"sharedtreasure", treasure:sharedTreasure});
                addActionName = "Add to shared equipment";
            } else if (action=="sell") {
                sellCoins={};
                for (let i in coins) {
                    const cnt = coins[i];
                    if (cnt) {
                        const ci = Object.assign({}, findCoin(i));
                        ci.quantity = cnt;
                        sellCoins[i]=ci;
                        character.adjustCoins(i, cnt, ci);
                    }
                }
                addActionName="Sell equipment";
            } else if (action == "move") {
                if (container && container != "none") {
                    targetId = container;
                } else {
                    const containers = getContainers(character.equipment,id);
                    targetId = containers[0].value;
                }
                if (targetId && targetId!="none") {
                    targetId = targetId+"."+campaign.newUid();
                } else {
                    targetId = campaign.newUid();
                }
            }
            
            let deleteItem,remItem;
            if ((this.props.it.quantity??1)<=quantity) {
                remItem = Object.assign({}, character.getEquipmentItem(id));
                deleteItem = null;
            } else {
                deleteItem = Object.assign({},character.getEquipmentItem(id));
                remItem = Object.assign({},deleteItem);
                deleteItem.quantity = deleteItem.quantity-quantity;
                remItem.quantity = quantity;
            }

            if (remItem) {
                delete remItem.equip;
            }
            if (targetId && deleteItem) {
                delete deleteItem.equip;
            }
            character.setEquipmentItem(deleteItem, id, remItem, targetId);

            if (!targetId) {
                Chat.addEquipment(character, addActionName, {a:remItem}, null, null, sellCoins)
            }
        }

        this.setState({open:false});
    }

    closeDialog() {
        this.setState({open:false});
    }
}

function getContainers(equipment, ignoreId) {
    const list = [];
    const split = ignoreId.split(".");
    split.pop();
    const ignoreContainer = split.join(".");
    if (split.length) {
        list.push({name:"Directly Carried", value:"none"});
    }

    getSubContainers(equipment, null, null);
    if (!list.length) {
        return null;
    }
    return list;

    function getSubContainers(contained, name, baseid) {
        const keys = getSortedEquipment(contained);
        for (let id of keys) {
            const it = contained[id];
            if (it.container) {
                let newList;

                if (baseid) {
                    newList = {name:name+"->"+it.displayName, value:baseid+"."+id};
                } else {
                    newList = {name:it.displayName, value:id};
                }
                if (newList.value != ignoreId) {
                    if (newList.value != ignoreContainer) {
                        list.push(newList);
                    }

                    getSubContainers(it.contained, newList.name, newList.value);
                }
            }
        }
    }
}



class NewItem extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
            text:"", itemName:null, showDetails:false, newItem:null
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({text:"", itemName:null, showDetails:false, newItem:null});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges) {
            const newItem = {};
            const templateItem = campaign.getItem(this.state.itemName);

            if (templateItem) {
                Object.assign(newItem, templateItem);
            } else {
                Object.assign(newItem, blankItem);
            }

            newItem.displayName = this.state.text;
            newItem.name=campaign.newUid();
            this.setState({showDetails:true, newItem});
        } else {
            this.props.onClose();
        }

        event.stopPropagation();
    };

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({text:event.target.value});
        }
    }

    closeItem(newItem) {
        this.props.onClose(newItem);
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        if (this.state.showDetails) {
            return <ItemDialog openEditable open item={this.state.newItem} onClose={this.closeItem.bind(this)}/>;
        }

        const name = this.state.text;
        const baseItem = campaign.getItem(this.state.itemName);
        
        return <Dialog
            open
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>Create Item</DialogTitle>
            <DialogContent>
                <TextField
                    fullWidth
                    value={name||""}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    helperText="New Item Name"
                />
                {baseItem?<div className="mv1">based on {baseItem.displayName}</div>:null}
                <ItemPicker saveLabel="Select" onClose={this.pickItem.bind(this)} open={this.state.showPicker||false} single noNew/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.showPicker.bind(this)} color="primary">
                    Pick Item to Copy
                </Button>
                <Button disabled={!name || name==""} onClick={this.handleClose.bind(this, true)} color="primary">
                    Create
                </Button>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    showPicker() {
        this.setState({showPicker:true});
    }

    pickItem(items) {
        if (items) {
            const names = Object.keys(items);
            let itemName;
            if (!names || !names.length) {
                itemName=null;
            } else {
                itemName=names[0];
            }
            if (!this.state.text) {
                const baseItem = campaign.getItem(itemName);
                this.setState({text:baseItem.displayName||""});
            }
            this.setState({showPicker:false, itemName});
        } else {
            this.setState({showPicker:false});
        }
    }
}


function mergeItemList(newItems, currentItems, added, attuned, noCopy) {
    const ne = noCopy?currentItems:Object.assign({}, currentItems||{});

    for (let i in newItems) {
        const e=Object.assign({},newItems[i]);
        upgradeItem(e);
        removeItemSuffix(e);

        var count = e.count || 1;
        delete e.count;

        {
            let q = (e.quantity??1)*count;
            count=1;

            for (let y in ne) {
                const nev = ne[y];
                upgradeItem(nev);
                if (!(attuned??{})[y] && !nev.equip && itemsEquivalent(nev, e)) {
                    if (added) {
                        const add = Object.assign({}, e);
                        add.quantity = q;
                        added[campaign.newUid()]=add;
                    }

                    const sq = nev.quantity;
                    ne[y] = Object.assign({}, nev);
                    ne[y].quantity = (isNaN(sq)?1:Number(sq)) + q;
                    q=0;
                    count=0;
                    break;
                }
            }
            e.quantity = q;
        }
        if (count) {
            if (added) {
                added[campaign.newUid()]=e;
            }
            ne[campaign.newUid()] = e;
        }
    }

    return ne;
}

const ignoreItemEquiv = ["id","quantity","name","carried", "timestamp","edited","source", "weaponProficiency"];
function itemsEquivalent(a,b) {
    if ((a == b) || (Number.isNaN(a) && Number.isNaN(b))) {
        return true;
    }

    if ((typeof a != 'object') || (typeof b != 'object')) {
        return false;
    }
    if (a?.coinType && b?.coinType && (a?.coin || (a?.type=="coin")) && (b?.coin || (b?.type=="coin")) && (a.coinType==b.coinType)) {
        return true;
    }

    const aisArray = Array.isArray(a);
    const bisArray = Array.isArray(b);
    if (aisArray != bisArray) {
        return false;
    }
    if (aisArray) {
        if (a.length != b.length){
            return false;
        }
    } else {
        if (!a || !b) {
            return false;
        }
    }

    for (let i in a) {
        if (!(ignoreItemEquiv.includes(i)) && !itemsEquivalent(a[i], b[i])){
            return false;
        }
    }
    for (let i in b) {
        if (!(ignoreItemEquiv.includes(i)) && !itemsEquivalent(a[i], b[i])){
            return false;
        }
    }
    return true;
}

function getItemUses(e) {
    var uses;
    if (e.usage && e.usage.baseCount){
        if (e.uses == null) {
            uses = e.usage.baseCount;
        } else {
            uses=e.uses;
        }
    }
    return uses;
}

function getSortedEquipment(equipment) {
    const keys = Object.keys(equipment||{});
    keys.sort(function (a,b) {
        const ret = (equipment[a].displayName||"").toLowerCase().localeCompare((equipment[b].displayName||"").toLowerCase());
        if (!ret) {
            return a.localeCompare(b);
        }
        return ret;
    })
    return keys;
}

function getTotalWeight(it) {
    if (it.coin) {
        return 0.02;
    }
    const itw = isNaN(it.weight)?0:Number(it.weight||0);
    if (it.container != "include") {
        return itw;
    }
    return Number((itw + getContainedWeight(it)).toFixed(3));
}

function getContainedWeight(cit) {
    let weight = 0;
    for (let i in cit.contained) {
        const it = cit.contained[i];
        weight += (getTotalWeight(it)*(it.quantity??1));
    }
    return Number(weight.toFixed(3));
}

function getTotalValue(it,coins, quantityOverride) {
    if (!coins) {
        coins = {};
    }
    if (it.type=="coin" || it.coin) {
        if (it.coinType) {
            coins[it.coinType] = quantityOverride??it.quantity??1;
        }
    } else {
        const ci = getCoinInfo(it.value);
        if (ci) {
            coins[ci.coin] = Math.round((coins[ci.coin]||0)+ci.count*(quantityOverride??it.quantity??1));
        }
        if (it.container) {
            const ccoins = getContainedValue(it);
            for (let coin in ccoins) {
                coins[coin]=  Math.round((coins[coin]||0)+ccoins[coin]*(quantityOverride??it.quantity??1));
            }
        }
    }
    return coins;
}

function getContainedValue(cit) {
    const coins={};
    for (let i in cit.contained) {
        const it = cit.contained[i];
        getTotalValue(it, coins);
    }
    return coins;
}

function getSimpleCoinString(it) {
    if (!it.container) {
        return null;
    }
    const coins = getContainedValue(it)
    const {knownCoins} = campaign.getItemExtensions();
    let lres=[];
    let only;
    let onlyVal;
    let sum=0;
    for (let coin in coins) {
        const cInfo = knownCoins[coin];
        const cnt = coins[coin];
        if (cnt) {
            if (cInfo) {
                sum += coins[coin]/(cInfo.countPerGP||1);
            } else {
                lres.push(cnt+" "+coin);
            }
            if (!onlyVal) {
                onlyVal = cnt+" "+coin;
                only=true;
            } else {
                only=false;
            }
        }
    }
    if (only) {
        return "(contains "+onlyVal+")";
    }
    if (sum > 0) {
        if (sum < 0.1) {
            lres.push(Math.round(sum*baseCoins.cp.countPerGP) + " cp");
        } else if (sum < 1) {
            lres.push(Math.round(sum*baseCoins.sp.countPerGP)+" sp");
        } else {
            lres.push(Math.round(sum)+" gp");
        }
    } else {
        if (!lres.length) {
            return null;
        }
    }
    return "(contains "+lres.join(" + ")+")";
}

function getCoins(value, quantity) {
    const coins = {};
    const ci = getCoinInfo(value);
    if (ci) {
        const {coin,count} = ci;
        if (coinNames[coin] || findCoin(coin)) {
            coins[coin] = Math.trunc(count*(quantity??1));
        }
    }
    return coins;
}

function getCoinInfo(value) {
    if (value) {
        value=value.replace(",","").trim();
        const pos = value.search(/\D/);
        if (pos >0) {
            const count = Number(value.substring(0, pos));
            const coin = value.substr(pos).trim();
            
            return {coin,count};
        }
    }
    return null;
}

function findCoin(coinType) {
    if (coinType) {
        if (coinNames[coinType]) {
            return {coin:true, coinType, displayName:coinNames[coinType]};
        } else {
            const items = campaign.getSortedItemsList();
            if (items) {
                for (let it of items) {
                    if (it.coinType && (it.coin || it.type=="coin") && (coinType == coinType.toLowerCase())) {
                        return it;
                    }
                }
            }
        }
    }
}

function computeBuy(value, current, scaleTarget) {
    const knownCoins = getKnown(current);
    let spend = {};
    const change = {};
    const known = Object.assign({},baseCoins);
    delete known.ep; // don't automatically do ep

    //console.log("computeBuy", knownCoins, value, current);

    // check simple deduction
    let all = true;
    for (let coin in value) {
        const v = value[coin];
        const cdata = knownCoins[coin];

        if (v>0) {
            const cc = current[coin];
            const found = (cc?.quantity||0);
            if (found >= v) {
                spend[coin] = v;
            } else {
                if (found) {
                    spend[coin] = found;
                }
                all=false;
            }
            if (cdata && !known[coin]) {
                known[coin] = cdata;
            }
        }
    }
    const sum = computeGPEquiv(value, known);
    const target = sum*(scaleTarget||1);

    if (all && ((scaleTarget||1)==1)) {
        return {spend, sum};
    }

    // compute ordered list of currencies
    for (let i in current) {
        const coin = current[i].coinType;
        if (!known[coin]) {
            known[coin] = knownCoins[coin];
        }
    }
    const knownList = [];
    for (let i in known) {
        knownList.push(known[i]);
    }
    knownList.sort(function (a, b) {return (b.countPerGP||1)-(a.countPerGP||1)});
//    console.log("known", knownList);

    let top;
    let sumup=0;
    let topCount=0;
    spend = {};
    
    for (top=0; (top<knownList.length) && (sumup < target); top++) {
        const cinfo = knownList[top];
        const coin = cinfo.coinType;
        const last = sumup;
        sumup += (current[coin]?.quantity||0)/(cinfo.countPerGP||1);
        if (sumup>=target) {
            topCount = nextUp((target-last)*(cinfo.countPerGP||1));
            break;
        }
    }

    if (sumup < target) {
        // too little money return everything
        for (let coin in current) {
            spend[coin] = current[coin].quantity;
        }
        return {spend, sum};
    }

    //console.log("remaining", topCount, target, sumup, top);

    sumup=0;
    for (let ki=top;ki>=0; ki--) {
        const cinfo = knownList[ki];
        const coin = cinfo.coinType;
        let count = (ki==top)?topCount:nextUp((target-sumup)*(cinfo.countPerGP||1));
        //console.log("loop", ki, coin, count, target, sumup);
        if (count > 0) {
            const cur = current[coin]?.quantity||0;
            if (count > cur) {
                count = cur;
            }
            spend[coin]=count;
        } else if (count<0){
            change[coin] = -count;
        }
        sumup += count/(cinfo.countPerGP||1);
    }

    //console.log("converted", sumup, target, spend, change);
    return {spend, change, sum};
}

function nextUp(num) {
    const mnum = Number(Number(num).toFixed(5));
    return Math.ceil(mnum);
}

function getCoinValue(equipment) {
    const coins = {};
    for (let i in equipment) {
        const it = equipment[i];
        getTotalValue(it, coins);
    }
    return coins;
}

function getKnown(current) {
    const {knownCoins} = campaign.getItemExtensions();
    const known = Object.assign({}, knownCoins);
    for (let coin in current) {
        if (!known[coin]) {
            const c = current[coin];
            upgradeItem(c);
            known[coin] = c;
        }
    }
    return known;
}

function computeGPEquiv(coins, known) {
    let sum=0;
    for (let coin in coins) {
        sum += coins[coin]/((known[coin]||{}).countPerGP||1);
    }
    return sum;
}

const blankItem= {
    "displayName": "",
    "type": "M",
    "weaponCategory": "Simple",
    "rarity": "None",
    "dmg1": "{@dice 1d4}",
    "dmgType": "P",
};

export {
    Items,
    Item,
    ItemPicker,
    ItemBuyPicker,
    ItemDialog,
    NewItem,
    getItemPropertyList,
    getFullItemType,
    ItemsHeader,
    ItemHeader,
    mergeItemList,
    itemsEquivalent,
    ItemListPicker,
    SharedTreasurePicker,
    StartingEquipmentPicker,
    blankItem,
    getItemType,
    getBasicItemType,
    ItemBookList,
    TreasurePicker,
    ItemFeatureUsageSlots,
    ItemCreatePicker,
    itemListFilters,
    PickProperties,
    DeleteItemFromCharacter,
    getWeaponProficiency,
    getDamageString,
    getTotalWeight,
    getSortedEquipment,
    printItem,
    printItemList
}