const React = require('react');
const {campaign,globalDataListener,getExtensionEntryCheckFn} = require('../lib/campaign.js');
import TextField from '@material-ui/core/TextField';
const {Rendersource} = require("./rendersource.jsx");
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
import Button from '@material-ui/core/Button';
const {EntityEditor,Renderentry} = require('./entityeditor.jsx');
const {RenderFeature,FeatureListEdit,DescriptionOptionsListEdit,EquipmentList,renderPrintFeatures,printDescriptionOptions} = require('./features.jsx');
const {ExtraArtList,ArtZoomList, FloatArt,FloatCardArt,printFloatArt} = require('./renderart.jsx');
const {ListFilter} = require('./listfilter.jsx');
const {AddChatEntry,getBasicHref} = require('./renderhref.jsx');
import {htmlFromEntry} from "../lib/entryconversion.js";
const {gamesystemOptions} = require('../lib/stdvalues.js');

const {SelectVal, TextVal, defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter,DeleteEntry} = require('./stdedit.jsx');

class RenderBackgrounds extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {list:campaign.getSortedBackgroundsList()};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({list:campaign.getSortedBackgroundsList()})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "backgrounds");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "backgrounds");
    }

	render() {
        return <div>
            <ListFilter 
                list={this.state.list}
                showCards
                filters={[defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter]}
                onClick={this.doClick.bind(this)}
                render={renderBackgroundCard}
                getListRef={this.saveRef.bind(this)}
                entryCheckFn={getExtensionEntryCheckFn(null,true)}
                headerOutline={this.props.headerOutline}
            />
            <BackgroundDialog open={this.state.showBackground} background={this.state.showBackgroundName} onClose={this.hideBackground.bind(this)} extraButtonsFn={this.getExtraButtons.bind(this)}/>
        </div>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(background) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(background))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.doClick.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.doClick.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
        </span>
    }

    doClick(name) {
        this.setState({showBackground:true, showBackgroundName:name});
    }

    hideBackground(){
        this.setState({showBackground:false});
    }
}

function renderBackgroundCard(it, width, bc, sel, onClick) {
    let description = it.summary||it.description;
    if (!description) {
        for (let i in it.features) {
            const f = it.features[i];
            if (f.name && (f.name.toLowerCase() == "suggested characteristics") && f.entries) {
                description = {entries:f.entries};
            }
        }
    }
    return <div key={it.name} style={{width:width-10}} className="defaultbackground shadow-3 br3 ma1 stdcontent overflow-hidden" onClick={onClick}>
        <div className={"h-100 hoverhighlight pa1 "+(bc||"")}>
            <div className="f1 titlecolor">{it.displayName}</div>
            <div className="f6 near-black bb titleborder mb1"><Rendersource className=" " entry={it}/></div>
            <FloatCardArt art={it.defaultArt} width={width}/>
            <Renderentry className="ll-8" entry={description}/>
        </div>
    </div>
}

class Background extends React.Component {
    constructor(props) {
        super(props);
        this.state={};
        this.handleOnDataChange = this.onDataChange.bind(this);
    }

    onDataChange() {
        this.setState({background:campaign.getBackgroundInfo(this.props.background)})
    }

    componentDidMount() {
        globalDataListener.onChangeCampaignContent(this.handleOnDataChange, "backgrounds");
    }

    componentWillUnmount() {
        globalDataListener.removeCampaignContentListener(this.handleOnDataChange, "backgrounds");
    }

	render() {
        let background = campaign.getBackgroundInfo(this.props.background);
        if (!background){
            return <div>Could not find background {this.props.background}.</div>;
        }

        const features = [];
        let hideEquipment = !background.startingEquipment;

        for (let i in background.features) {
            const bf = background.features[i];
            if (bf.name == "Equipment") {
                hideEquipment = true;
            }
            features.push(<div key={i} className={bf?.noDiv?"mb1":null}><RenderFeature h3 noDiv={bf?.noDiv} feature={bf}/></div>);
        }

        return <div className="stdcontent" key={background.name} >
            {this.props.noTitle?null:<h3>{background.displayName}</h3>}
            <FloatArt art={background.defaultArt} artList={background.artList} defaultArt={background.defaultArt} pageSync={this.props.pageSync}/>
            {this.props.noDescription?null:<Renderentry entry={background.description}/>}
            {features}
            {!hideEquipment?<h3>Equipment</h3>:null}
            {!hideEquipment?<EquipmentList noBackground editable={false} equipment={background.startingEquipment}/>:null}
            {(background.descriptionOptions&&background.descriptionOptions.length)?<h3>Character Description Options</h3>:null}
            {background.descriptionOptions?<DescriptionOptionsListEdit descriptions={background.descriptionOptions} noBackground/>:null}
            {this.props.noSource?null:<Rendersource entry={background}/>}
        </div>;
    }
}

function printBackground(id,noTitle,header) {
    const list=[];
    const it = campaign.getBackgroundInfo(id);
    if (!it) {
        return;
    }
    if (!noTitle) {
        list.push(`<h${header}>${it.displayName}</h${header}>`);
    }

    if (campaign.getSourcePreventEmbedding(it.source)) {
        list.push("<p>Not allowed to publish.</p>");
    } else {
        list.push(printFloatArt(it.defaultArt))
        if (it.description) {
            list.push(`<div>${htmlFromEntry(it.description)}</div>`);
        }
        list.push(renderPrintFeatures(it.features, noTitle?header:header+1));
        list.push(printDescriptionOptions(it.descriptionOptions));
        list.push('<div style="clear: both"></div>');
    }
    return list.join("\n");
}

class BackgroundDialog extends React.Component {
    constructor(props) {
        super(props);

           this.state= { };
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.open!= prevProps.open) {
            this.setState({editable:false});
        }
    }

    render() {
        if (!this.props.open) {
            return null;
        }

        if (this.state.editable) {
            return <EditBackground open background={this.props.background} onClose={this.props.onClose}/>
        }
        const background = campaign.getBackgroundInfo(this.props.background);
        const extraButtonsFn = this.props.extraButtonsFn;
        const showEdit = !campaign.isSharedCampaign()&&!this.props.disableEdit;

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth

        >
            <DialogTitle onClose={this.props.onClose}>{background&&background.displayName}</DialogTitle>
            <DialogContent key={this.props.background}>
                <Background noTitle background={this.props.background}/>
            </DialogContent>
            <DialogActions>
                <AddChatEntry type="Background" displayName={background?.displayName} href={getBasicHref("backgrounds",background?.name)}/>
                {extraButtonsFn && extraButtonsFn(this.props.background)}
                {showEdit?<DeleteEntry type="backgrounds" entry={background} onClose={this.props.onClose}/>:null}
                {showEdit?<Button onClick={this.clickEdit.bind(this)} color="primary">
                    Edit
                </Button>:null}
                <Button onClick={this.props.onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }

    clickEdit() {
        this.setState({editable:true});
    }
}
    
class EditBackground extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {background:campaign.getBackgroundInfo(props.background)};
    }

    componentDidUpdate(prevProps) {
        if ((this.props.background != prevProps.background)||(this.props.open && this.props.open!= prevProps.open)) {
            this.setState({background:campaign.getBackgroundInfo(this.props.background)});
        }
    }

    render() {
        const background = this.state.background;
        if (!this.props.open || !background) {
            return null;
        }

        return  <Dialog
            open
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                <TextVal    
                    text={background.displayName}
                    fullWidth
                    inputProps={{className:"f1 titletext titlecolor ignoreDrag"}}
                    onChange={this.onChangeField.bind(this,"displayName")}
                />
            </DialogTitle>
            <DialogContent className="stdcontent">
                <EntityEditor onChange={this.onChangeField.bind(this,"description")} entry={background.description} placeholder="Description"/>
                <h3>Summary</h3>
                <EntityEditor onChange={this.onChangeField.bind(this,"summary")} entry={background.summary} placeholder="Summary"/>
                <FeatureListEdit 
                    editable 
                    compact
                    features={background.features}
                    displayType="Background" type={background.displayName} name={background.name}
                    onChange={this.onChangeField.bind(this, "features")}
                    equipment={background.startingEquipment}
                    onChangeEquipment={this.onChangeField.bind(this,"startingEquipment")}
                />
                <h3>Starting Equipment</h3>
                <EquipmentList editable noBackground equipment={background.startingEquipment} onChange={this.onChangeField.bind(this,"startingEquipment")}/>
                <h2>Character Description Options</h2>
                <DescriptionOptionsListEdit descriptions={background.descriptionOptions} onChange={this.onChangeField.bind(this, "descriptionOptions")} editable />
                <h2>Artwork</h2>
                <ExtraArtList artList={background.artList} onChange={this.onChangeArtwork.bind(this)} pickArt defaultArt={background.defaultArt}/>
                <ArtZoomList editable open={this.state.showExtraArtDialog} artList={background.artList} onClose={this.onSaveArtwork.bind(this)} pickArt defaultArt={background.defaultArt} defaultSearch={background.displayName}/>
                <div className="pt2">
                    <SelectVal value={background.gamesystem||"5e"} values={gamesystemOptions} onClick={this.onChangeField.bind(this,"gamesystem")} helperText="Game System"/>
                </div>
            </DialogContent>
            <DialogActions>
                <DeleteEntry type="backgrounds" entry={background} onClose={this.props.onClose}/>
                <Button onClick={this.showExtraArt.bind(this)} color="primary">
                    Select Artwork
                </Button>
                <Button onClick={this.saveData.bind(this)} color="primary">
                    Save
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onClose() {
        this.props.onClose();
    }

    saveData() {
        campaign.updateCampaignContent("backgrounds", this.state.background);
        this.props.onClose(true);
    }

    onChangeField(field,val) {
        const background = Object.assign({}, this.state.background);
        if (val == "none") {
            val = null;
        } else if (val == "true") {
            val=true;
        } else if (val == "false") {
            val=false;
        }
        background[field] = val;
        this.setState({background});
    }

    showExtraArt() {
        this.setState({showExtraArtDialog:true});
    }

    onSaveArtwork(artList, defaultArt, defaultToken) {
        if (artList) {
            this.onChangeArtwork(artList, defaultArt,defaultToken);
        }
        this.setState({showExtraArtDialog:false})
    }

    onChangeArtwork(artList, defaultArt, defaultToken) {
        const background = Object.assign({}, this.state.background)
        background.artList = artList;
        if (defaultArt) {
            background.defaultArt = defaultArt;
        } else {
            delete background.defaultArt;
        }
        if (defaultToken) {
            background.defaultToken = defaultToken;
        } else {
            delete background.defaultToken;
        }
        this.setState({background})
    }
}

const emptyBackground =     {
    "size": "M",
    "features": [
        {
            "name":"Skills Proficiencies",
            "entries": [],
            auto:true
        },
        {
            "name":"Tool Proficiencies",
            "entries": [],
            auto:true
        },
        {
            "name":"Equipment",
            "entries": [],
            auto:true
        },
        {
            "name":"Feature",
            "usage": {"type":"R"},
            "entries": [],
            auto:true
        },
        {
            "name": "Suggested Characteristics",
            "entries": [],
            auto:true
        }
    ]
}

class NewBackground extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    createBackground() {
        const name = this.state.name;
        const selectedBackground = this.state.selectedBackground;
        let newBackground = Object.assign({}, selectedBackground?campaign.getBackgroundInfo(selectedBackground):emptyBackground);

        newBackground.displayName=name;
        newBackground.name=campaign.newUid();
        newBackground.gamesystem = campaign.defaultGamesystem;
        campaign.updateCampaignContent("backgrounds", newBackground);
        this.props.onClose(newBackground.name);
    }

    onClose() {
        this.props.onClose();
    }

    onChange(event) {
        if (/[^\n]*/.exec(event.target.value) == event.target.value) {
            this.setState({name:event.target.value});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.open != prevProps.open) {
            this.setState({name:""});
        }
    }

    render() {
        if (!this.props.open)
            return null;

        const backgrounds = campaign.getSortedBackgroundsList();
        const backgroundsList=[];
        const selectedBackground = this.state.selectedBackground;
        const name=this.state.name||"";

        backgroundsList.push("Empty Template")
        for (var i in backgrounds){
            backgroundsList.push({name:backgrounds[i].displayName, value:backgrounds[i].name});
        }
    
        return <Dialog
            open
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={this.onClose.bind(this)}>
                Create Background
            </DialogTitle>
            <DialogContent>
                <TextField
                    value={name}
                    onChange={this.onChange.bind(this)}
                    margin="normal"
                    label="Background Name"
                    fullWidth
                />
                <div className="mv1">
                    <SelectVal fullWidth noteText label="Base Background" value={selectedBackground||"Empty Template"} values={backgroundsList} onClick={this.changeBackground.bind(this)}/>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={!name || name==""} onClick={this.createBackground.bind(this)} color="primary">
                    New
                </Button>
                <Button onClick={this.onClose.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    changeBackground(selectedBackground) {
        if (selectedBackground=="Empty Template") {
            selectedBackground = null;
        }
        this.setState({selectedBackground});
    }
}

class BackgroundsHeader extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {
        };
    }

    onNew() {
        this.setState({showNew:true});
    }

    render() {
        return <span>
            Backgrounds
            <Button className="ml2 minw2" color="primary" variant="outlined" size="small" onClick={this.onNew.bind(this)}>New</Button>
            <NewBackground open={this.state.showNew} onClose={this.closeNew.bind(this)}/>
            <EditBackground background={this.state.newBackground} open={this.state.showEdit} onClose={this.doneEditing.bind(this)}/>

        </span>;
    }

    closeNew(name) {
        this.setState({showNew:false});
        if (name) {
            this.setState({newBackground:name, showEdit:true})
        }
    }

    doneEditing(){
        this.setState({showEdit:false});
    }
}

class BackgroundSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    componentDidUpdate(prevProps) {
        if (this.props.open && (this.props.open != prevProps.open)) {
            this.setState({selectedBackground:null});
        }
    }

    handleClose(savechanges, event) {
        if (savechanges){
            this.props.onClose(this.state.selectedBackground);
        } else {
            this.props.onClose(null);
        }
    };

    render() {
        if (!this.props.open) {
            return null;
        }

        const backgrounds = campaign.getSortedBackgroundsList();
        const character=this.props.character;
        const extensionEntryCheckFn = getExtensionEntryCheckFn(character,true);

        return  <Dialog
            open
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle onClose={this.handleClose.bind(this, false)}>
                Select Background
            </DialogTitle>
            <DialogContent>
                <ListFilter 
                    list={backgrounds}
                    showCards
                    showAll
                    render={renderBackgroundCard}
                    onClick={this.clickBackground.bind(this)}
                    filters={[defaultSourceFilter,defaultBookFilter,defaultGamesystemFilter]}
                    getListRef={this.saveRef.bind(this)}
                    entryCheckFn={extensionEntryCheckFn}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose.bind(this, false)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
            <BackgroundDialog open={this.state.selectedBackground} background={this.state.selectedBackground} extraButtonsFn={this.getExtraButtons.bind(this)} onClose={this.clickBackground.bind(this,null)} disableEdit/>
        </Dialog>;
    }

    saveRef(listfilter){
        this.listfilter = listfilter;
    }

    getExtraButtons(background) {
        const {next,prev} = ((this.listfilter && this.listfilter.getNextPrev(background))||{});
        return <span>
            <Button disabled={!prev} onClick={prev?this.clickBackground.bind(this,prev.name):null} color="primary"><span className="b fas fa-step-backward"/></Button>
            <Button disabled={!next} onClick={next?this.clickBackground.bind(this,next.name):null} color="primary"><span className="b fas fa-step-forward"/></Button>
            <Button onClick={this.handleClose.bind(this,true)} color="primary">Select</Button>
        </span>
    }

    clickBackground(selectedBackground){
        this.setState({selectedBackground});
    }
}


export {
    RenderBackgrounds,
    Background,
    BackgroundDialog,
    NewBackground,
    BackgroundsHeader,
    EditBackground,
    BackgroundSelector,
    printBackground
}