const React = require('react');
const {campaign,globalDataListener} = require('../lib/campaign.js');
import Button from '@material-ui/core/Button';
const {displayMessage} = require('./notification.jsx');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {SelectVal, PickVal,TextBasicEdit,DeleteWithConfirm} = require('./stdedit.jsx');
const {GenericDialog} = require('./generic.jsx');
const {scrubName}= require('./features.jsx');
const {LinkHref}=require('./renderhref.jsx');
const {dicerandom} = require('./diceroller.jsx');
const {ChatButton} = require('./renderchat.jsx');
const {signedNum} = require('../lib/stdvalues.js');
const {ItemDialog} = require('./items.jsx');

class ShortRest extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {spentHitDice:{}, hitDiceRolls:[]};
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open != prevProps.open && this.props.open) {
            this.setState({spentHitDice:{}, hitDiceRolls:[]});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {spentHitDice,hitDiceRolls} = this.state;
        const {character}=this.props;
        const conBonus = character.getAbility("con").modifier;

        const hitDice = character.hitDice;
        const maxHitDice = character.maxHitDice;
        const list=[];
        const rolls=[];
        let sum = 0;

        for (let i in hitDiceRolls) {
            const r = hitDiceRolls[i];
            sum += (r.roll+conBonus);
            rolls.push(<div key={i} className="mb1">
                <b>d{r.faces}{conBonus?signedNum(conBonus):null}</b>= {(r.roll+conBonus)}
            </div>);
        }

        const needsHP = character.maxhp > (character.hp+sum);

        for (let faces in maxHitDice) {
            const hd = maxHitDice[faces];
            const shd = spentHitDice[faces]||0;
            const ahd = (hitDice[faces]||0)-shd;

            if (hd) {
                list.push(<div className="mb1" key={faces}>
                    <b>{hd}d{faces}</b> with {ahd} left 
                    {(needsHP && ahd)>0?<Button className="ml2" onClick={this.spendHitDice.bind(this, faces)} color="primary" variant="outlined" size="small">
                        Spend Hit Die
                    </Button>:null}
                </div>);
            }
        }

        return <Dialog
            open
            onClose={this.props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
                Short Rest
            </DialogTitle>
            <DialogContent>
                <div className="hk-well mb2">
                    A short rest is a period of downtime, at least 1 hour long, during which a character does nothing more strenuous than eating, drinking, reading, and tending to wounds.
                    <br/><br/>
                    A character can spend one or more Hit Dice at the end of a short rest. For each Hit Die, roll the die and add your Constitution modifier. Regain hit points equal to the total.
                </div>
                <div className="titlecolor titletext f3 b mb1">Hit Dice</div>
                {list}
                {rolls.length?<div className="mb2">
                    <div className="titlecolor titletext f3 b mb1">Gained Hit Points</div>
                    {rolls}
                    <div className="mb21">
                        Adding {sum} hit points for a total of {Math.min(character.hp+sum,character.maxhp)} hit points out of a maximum of {character.maxhp}.
                    </div>
                </div>:needsHP?<div className="mb2">
                        You have {character.hp} hit points out of a maximum of {character.maxhp}.
                </div>:<div className="mb2">You are at maximum hit points {character.maxhp}.</div>
                }
                <RecoverFeatures character={character}/>
            </DialogContent>
            <DialogActions>
                <ChatButton character={character}/>
                <Button onClick={this.rest.bind(this,spentHitDice, sum)} color="primary">
                    Do Short Rest
                </Button>
                <Button onClick={this.cancel.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    cancel() {
        this.props.onClose();
    }

    spendHitDice(faces){
        const character=this.props.character;
        const spentHitDice = Object.assign({}, this.state.spentHitDice);
        const hitDiceRolls = this.state.hitDiceRolls.concat([]);
        const roll = dicerandom(faces);
        const conBonus = character.getAbility("con").modifier;
        const dice = {};

        spentHitDice[faces] = (spentHitDice[faces]||0)+1;
        dice["D"+faces] = 1;
        dice.bonus = conBonus;
        hitDiceRolls.push({faces, roll:roll});
        character.addRoll({dice, rolls:[roll, conBonus], source:"Hit Dice", action:"Roll"})
        this.setState({spentHitDice,hitDiceRolls})
    }

    rest(spentHitDice, sum)  {
        const character=this.props.character;
        character.rest(false, false, spentHitDice, sum); //short rest
        this.props.onClose(true);
    }
}

class LongRest extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

    componentDidMount() {
    }
  
    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.open != prevProps.open && this.props.open) {
            this.setState({regainAllHitDice:this.props.character.state.defaultHitDice});
        }
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const {character}=this.props;

        return <Dialog
            open
            onClose={this.props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle onClose={this.props.onClose}>
                Long Rest
            </DialogTitle>
            <DialogContent>
                <div className="hk-well mb2">
                A long rest is a period of extended downtime, at least 8 hours long, during which a character sleeps or performs light activity: reading, talking, eating, or standing watch for no more than 2 hours.
                <br/><br/>
                At the end of a long rest, a character regains all lost hit points.
            </div>
            <SelectVal className="mb2" fullWidth value={this.state.regainAllHitDice||0} isNum values={hitDiceOptions} onClick={this.regainAllHitDice.bind(this)}/>
            <RecoverFeatures character={character} longRest/>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.rest.bind(this)} color="primary">
                    Do Long Rest
                </Button>
                <Button onClick={this.cancel.bind(this)} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>;
    }

    regainAllHitDice(regainAllHitDice) {
        this.setState({regainAllHitDice});
    }

    cancel() {
        this.props.onClose();
    }

    rest()  {
        const character=this.props.character;
        character.rest(true,this.state.regainAllHitDice); //long rest
        this.props.onClose(true);
    }
}

const hitDiceOptions = {
    0:"Regains up to half of your Hit Dice (rules as written)",
    1:"Regain all of your Hit Dice"
}

class RecoverFeatures extends React.Component {
    constructor(props) {
        super(props);
	    this.state= {};
    }

	render() {
        const {character, longRest}=this.props;
        const equipment=character.equipment;
        const {equipmentList, classColumns, features} = character.restFeatures(longRest);
        const {actions} = character.getActions();
        const elist=[];
        const clist=[];
        const flist=[];
        const alist=[];

        for (let i in classColumns) {
            clist.push(classColumns[i].name);
        }
        if (longRest) {
            if (character.maxPactSlots || ((character.spellSlots||[])[1])) {
                if (character.useSpellPoints) {
                    clist.push("Spells Points");
                } else {
                    clist.push("Spells Slots");
                }
            }
        } else {
            if (character.maxPactSlots) {
                if (character.useSpellPoints) {
                    clist.push("Pact Spell Points");
                } else {
                    clist.push("Pact Spell Slots");
                }
            }
        }

        const actionType = longRest?"long rest":"short rest";
        for (let i in actions) {
            const a=actions[i];
            if (a.actionTypeVal == actionType) {
                alist.push(<span key={i}>{alist.length>0?", ":null} <LinkHref href={a.href}>{scrubName(a.featureName)}</LinkHref></span>);
            }
        }

        for (let i in features) {
            flist.push(<span key={i}>{i>0?", ":null}<a onClick={this.showGeneric.bind(this,features[i])}>{scrubName(features[i].name||"feature")}</a></span>)
        }

        for (let i in equipmentList) {
            elist.push(<span key={i}>{i>0?", ":null}<a onClick={this.showItem.bind(this, equipment[equipmentList[i]])}>{equipment[equipmentList[i]].displayName||"item"}</a></span>);
        }

        return <div>
            {flist.length||clist.length?<div className="mb2">
                <div className="titlecolor titletext f3 b mb1">Features Recovered</div>
                {clist.join(", ")}
                {clist.length&&flist.length?", ":null}
                {flist}
            </div>:null}
            {elist.length?<div className="mb2">
                <div className="titlecolor titletext f3 b mb1">Equipment with Features Recovered</div>
                {elist}
            </div>:null}
            {alist.length?<div className="mb2">
                <div className="titlecolor titletext f3 b mb1">Check Character Sheet for Rest Actions</div>
                {alist}
            </div>:null}
            <GenericDialog open={this.state.showGenericDialog} feature={this.state.showGenericFeature} onClose={this.closeGenericDialog.bind(this)} depth={1}/>
            <ItemDialog open={this.state.showItem} item={this.state.showItem} onClose={this.showItem.bind(this,null)}/>
        </div>;
    }

    showItem(item) {
        console.log("show", item)
        this.setState({showItem:item});
    }

    showGeneric(feature) {
        this.setState({showGenericDialog:true, showGenericFeature:feature});
    }

    closeGenericDialog() {
        this.setState({showGenericDialog:false})
    }
}



export {
    ShortRest,
    LongRest
}