const React = require('react');
const {campaign,globalDataListener,areSameDeep} = require('../lib/campaign.js');
const {displayMessage,snackMessage} = require('./notification.jsx');
const Parser = require("../lib/dutils.js").Parser;
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {Renderentry} = require('./entityeditor.jsx');
const {MaxNumberAdjust, SelectVal, PickVal} = require('./stdedit.jsx');
const {ItemDialog} = require('./items.jsx');
const {SpellDetails,getSpellAttributes,getDurationFromSpell,spSchoolAbvToFull,hasClass} = require('./renderspell.jsx');
const {getDiceFromString, getStringFromDice, doRoll,damagesFromExtraDamage} = require('./diceroller.jsx');
const {getDurationFromText} = require('../src/conditions.jsx');
const {MonsterPicker, simplifyMonster} = require("./rendermonster.jsx");
const {Chat} = require('../lib/chat.js');
const {AddObject} = require('./objects.jsx');
const {getDamagesInfo} = require('./actionblock.jsx');


const {
    signedNum,
    spellPointsByLevel,
    abilityModFromSpellDC
} = require('../lib/stdvalues.js');

class CharacterSpells extends React.Component {

    constructor(props) {
        super(props);
        this.state={};
    }

    render() {
        const {character} = this.props;
        if (!character) {
            return null;
        }

        const readonly=this.props.readonly;
        const spellList = this.getSpellList();
        if (!spellList) {
            return null;
        }
        const levelShow = this.levelShow||{};
        const levelOpts = [];
        for (let i=0; i<=9; i++){
            if (levelShow[i.toString()]) {
                levelOpts.push({name:i==0?character.t("Cantrips"):Parser.spLevelToFull(i) + character.spellLevelName("-"), value:i.toString()});
            }
        }
        if (levelShow.concentration) {
            levelOpts.push({name:"Concentration",value:"concentration"});
        }
        if (levelShow.ritual) {
            levelOpts.push({name:"Ritual",value:"ritual"});
        }
        
        return <div className="spellBlock avoidBreak">
            <div>
                <div className="flex items-center theader">
                    <div className="flex-auto ttitle">{character.t("Spell")} List</div>
                    <div>
                        {this.props.spellButton}
                        {levelOpts.length?<SelectVal value={this.state.spellFilter||"all"} includeAll values={levelOpts} onClick={this.setSpellFilter.bind(this)}/>:null}
                    </div>
                </div>
                {character.useSpellPoints?<div>
                    <b>{character.t("Spell Points")}</b> <MaxNumberAdjust 
                        max={character.maxSpellPoints} 
                        value={character.availableSpellPoints} 
                        onAdjustValue={this.setSpellPoints.bind(this)}
                    />
                </div>:null}
                {spellList}
                <SpellDetails open={this.state.showSpell||false} spell={this.state.selectedSpell} character={character} onClose={this.hideSpell.bind(this)} getDiceRoller={this.props.getDiceRoller}  addSpellToken={this.props.addSpellToken?this.addSpellToken.bind(this):null} disableEdit/>
                {this.state.showItemDetails?<ItemDialog editable={!readonly} open characterId={character.name} itemId={this.state.showItemId} onClose={this.closeItem.bind(this)} doSubRoll={readonly?null:this.doItemTextRoll.bind(this)} getDiceRoller={readonly?null:this.props.getDiceRoller} addSpellToken={this.props.addSpellToken?this.addSpellToken.bind(this):null}/>:null}

            </div>
        </div>
    }

    setSpellPoints(value) {
        const character = this.props.character;
        character.availableSpellPoints = value;
    }

    setSpellFilter(spellFilter) {
        if (spellFilter=="all") {
            spellFilter = null;
        }
        this.setState({spellFilter});
    }

    getSpellList() {
        const {character, readonly,small} = this.props;
        const {spellFilter,actionFilter}=this.state;
        const levelFilter = ((spellFilter>="0") && (spellFilter<="9"))?spellFilter:null;
        const concentrationFilter = (spellFilter == "concentration");
        const ritualFilter = (spellFilter == "ritual");
        const spellmods = character.getSpellMods();
        let list= this.props.allSpells;
        let maxPip=small?3:null;

        list.sort(function(a, b) {
            let ret = (a.spell.displayName||"").toLowerCase().localeCompare(b.spell.displayName.toLowerCase());
            if (!ret) {
                ret = (a.recoveryType||"").toLowerCase().localeCompare(b.recoveryType||"");
            }
            if (!ret) {
                ret = (a.abilityDC||"").toLowerCase().localeCompare(b.abilityDC||"");
            }
            if (!ret) {
                ret = (a.usageKey?.usageId||"").toLowerCase().localeCompare(b.usageKey?.usageId||"");
            }
            if (!ret) {
                ret = (a.ritualOnly?1:0)-(b.ritualOnly?1:0);
            }
            return ret;
        });

        const spellSlots = character.spellSlots || [];
        let rows =[];
        let levelShow = {};
        this.levelShow = levelShow;
        let lastSpell;
        for (let i=0; i<=9; i++) {
            const ret=[];
            let slots=[];
            let castable;

            if (i>0){
                if (character.useSpellPoints) {
                    if (i <= character.maxSpellCastLevel) {
                        const points = spellPointsByLevel[i];
                        castable=(points <= character.availableSpellPoints) && (i <= character.maxSpellCastLevel);
                        if (points) {
                            slots.push(<b key="sp">{points} points</b>);
                        }
                    }
                } else {
                    for (let y=i; y<=9; y++) {
                        if (character.GetAvailableSpellSlots(y-1)) {
                            castable=true;
                        }
                    }
                    if ((spellSlots[i-1] > 0) || (character.pactLevel >= (i))) {
                        const availableSlots = character.GetAvailableSpellSlots(i-1);
                        if (availableSlots) {
                            castable=true;
                        }
                        slots.push(<span key="ss"><MaxNumberAdjust 
                            key="ss"
                            max={spellSlots[i-1]} 
                            value={availableSlots} 
                            onAdjustValue={this.setSpellSlots.bind(this,i-1)}
                        /></span>);
                        const pactLevel = character.pactLevel;
                        const pactSlots = character.pactSlots;
        
                        if (pactLevel >= i) {
                            if (pactSlots) {
                                castable=true;
                            }
                        }

                        if (pactLevel == (i)) {
                            if ((character.maxPactSlots > 0) && spellSlots[i]) {
                                slots.push(<b key="sep"> {character.t("Pact")} </b>);
                            } else {
                                slots.push(<b key="sep">{character.t("Pact")} </b>);
                            }
        
                            slots.push(<span key="ps"><MaxNumberAdjust
                                key="ps"
                                value={pactSlots} 
                                max={character.maxPactSlots} 
                                onAdjustValue={this.changeAttribute.bind(this, "pactSlots")}
                            /></span>);
                        }
                    }
                }
            }

            //console.log("spells", list);
            for (let x in list) {
                const sl = list[x];
                const s = sl.spell;
    
                if (s.level == i) {
                    const sa = getSpellAttributes(s);

                    if (s.level == i) {
                        levelShow[i.toString()]=true;
                    }
                    if (sa.concentration) {
                        levelShow.concentration=true;
                    }
                    if (s.ritual) {
                        levelShow.ritual=true;
                    }
                    
                    if ((!concentrationFilter || sa.concentration) && (!ritualFilter || s.ritual) && !areSameSpell(sl, lastSpell, sa)) {
                        let effect;
                        let usagefield=null;
                        const itemSpell = (sl.type=="item")||(sl.type=="itemextra");
                        const dnotes=[];
                        let notes=[];
                        const {saveAbility, saveVal, attackRoll, tempHp, damages, altdamages, extraNotesList, levelUp, range, duration} = getSpellActionInfo(character, spellmods, sl, sl)

                        const attack = (saveAbility || (attackRoll!==null))?<td>
                            {saveAbility?<div className="tc f7"><span className="ttu">{saveAbility}</span>&nbsp;{saveVal}</div>:null}
                            {attackRoll?<div className="tc hoverroll b" onClick={this.doAttack.bind(this, s, attackRoll)}>{attackRoll}</div>:null}
                        </td>:null;

                        if (damages || tempHp) {
                            const temphpV= tempHp&&character.resolveDice(tempHp.hp,true);
                            const dinfo = getDamagesInfo(damages);
                            const adinfo = getDamagesInfo(altdamages);
                            if (dinfo.damageTypes) {
                                dnotes.push(<i key="dt">{dinfo.damageTypes}</i>);
                            }
                            if (adinfo.damageTypes){
                                if (dinfo.damageTypes != adinfo.damageTypes) {
                                    dnotes.push(<i key="dta">; {adinfo.damageTypes} </i>);
                                }
                            }
                            if (tempHp) {
                                notes.push(<span key="thp">temp HP</span>);
                            }
                            effect = <td>
                                <div className="hoverroll tc" 
                                    onClick={this.doAction.bind(this, s, damages, null, duration,saveAbility, saveVal)}
                                ><b>{dinfo.dmgList}</b></div>
                                {temphpV?<div className="hoverroll b tc" onClick={this.doAction.bind(this, s, null, tempHp)}>{temphpV}</div>:null}
                                {adinfo.dmgList.length?<div className="hoverroll tc bt b--gray-50" 
                                    onClick={this.doAction.bind(this, s, altdamages, null, duration,saveAbility, saveVal)}
                                ><b>{adinfo.dmgList}</b></div>:null}
                            </td>;
                        }
                        for (let n in extraNotesList) {
                            notes.push(<Renderentry key={"en"+n} entry={extraNotesList[n]} doRoll={this.doTextRoll.bind(this,s.displayName)} doSubRoll={this.doTextRoll.bind(this)}  character={character} diceMap={character?.namedValues}/>);

                        }
                        let thisCastable = castable;
                        let atWill = false;
                        if (itemSpell) {
                            switch (sl.recoveryType) {
                                case "atwill":{
                                    atWill=true;
                                    usagefield=<span>(<a onClick={this.onClickItem.bind(this,sl.id.id||sl.id)}>{sl.itemName}</a>)</span>;
                                    break;
                                }
                                case "charges": {
                                    const fit = getItemFeature(character, sl.type, sl.id)
                                    const max = ((fit.usage && fit.usage.baseCount)||0);
                                    const uses = (fit.uses=== undefined)?max:fit.uses;
                                    
                                    thisCastable= (sl.charges <= uses) && (i>0 || sl.charges);
                                    if (sl.charges) {
                                        usagefield = <MaxNumberAdjust maxPip={maxPip} max={max} value={uses} onAdjustValue={this.setItemUsage.bind(this, sl.id)}> (<a onClick={this.onClickItem.bind(this,sl.id.id||sl.id)}>{sl.itemName}</a>) {sl.charges>1?(sl.charges+" charges"):null} </MaxNumberAdjust>;
                                    } else if (i==0){
                                        atWill=true;
                                    }
                                    break;
                                }
                                case "ritual": {
                                    thisCastable=true;
                                    usagefield=<span>(<a onClick={this.onClickItem.bind(this,sl.id.id||sl.id)}>{sl.itemName}</a>)</span>;
                                    break;
                                }
                                default:{
                                    const fit = getItemFeature(character, sl.type, sl.id);

                                    let uses = ((fit.itemUsage||{})[s.name]);
                                    if (uses === undefined) {
                                        uses=1;
                                    }
                                    thisCastable = !!uses;
                                    usagefield = <MaxNumberAdjust max={1} value={uses} onAdjustValue={this.setItemSpellUsage.bind(this, s.name, sl.id)}> (<a onClick={this.onClickItem.bind(this,sl.id.id||sl.id)}>{sl.itemName}</a>) </MaxNumberAdjust>;
                                    break;
                                }
                            }
                        } else if (!sl.usageKey && ((s.ritual && character.ritualCaster)||sl.ritualOnly)) {
                            thisCastable = true;
                        } else if (sl.usageKey) {
                            const usageVal = character.getUsageVal(sl.usageKey.usage, sl.usageKey.usageId);
                            thisCastable = sl.ritualOnly || (usageVal>= (sl.useCount||1));
                            if (!sl.ritualOnly) {
                                usagefield = <MaxNumberAdjust maxPip={maxPip} max={sl.maxUsage||1} value={usageVal} onAdjustValue={this.setSpellUsage.bind(this, sl.usageKey)}> {sl.usageKey.usage?.usageName||"innate"} </MaxNumberAdjust>;
                            }
                        } else if (["atwill","atwillslot"].includes(sl.recoveryType)|| (i==0)) {
                            atWill=true;
                        }
                        
                        if (dnotes.length) {
                            if (notes.length) {
                                dnotes.push(" | ");
                            }
                            notes = dnotes.concat(notes);
                        }

                        const spellTimeUnit = spellTimeUnitMap[s.time?.unit]||"";
                        if (!actionFilter || (spellTimeUnit == actionFilter)) {
                            const extra = <span>
                                {usagefield}                                 
                                {notes}
                            </span>;
                            ret.push(<tr key={i+"."+x}>
                                <td className="tc f8 w3">{((thisCastable||atWill)&&!readonly)?<span className="ba titleborder br1 ph--2 hoverhighlight nowrap" onClick={this.castSpell.bind(this, atWill, sl)}>{s.level&&atWill?"at will":"cast"}</span>:null}</td>
                                <td>
                                    <a onClick={this.showSpell.bind(this, s.name)}>{s.displayName}</a>{" "}
                                    <span className="dib">
                                        {s.ritual?<span className="inversespecial f7 ml--3 b">R</span>:null}
                                        {sa.concentration?<span className="inversespecial ml--3 f7 b">C</span>:null}
                                        {levelUp?<span className="inversespecial ml--3 f7 b">+L</span>:null}
                                        {(this.props.addSpellToken)?<span className="titlecolor ml--3 f7" key="aoe"><AddObject noSize color="primary" variant="text" defaultName={s.displayName} defaultShape={sa.shape} defaultLength={sa.length} character={character} spell={s.name} onAddObject={this.props.addSpellToken} useIcon defaultType="Spell Token" playerControlled/></span>:null}
                                    </span>
                                    {small?<div className="f8">{extra}</div>:null}
                                </td>
                                <td className="tc">{s.time?.number>1?s.time.number:null}{spellTimeUnit}</td>
                                <td className="tc f7">{range}</td>
                                {attack || <td className="tc"></td>}
                                {effect || <td className="tc"></td>}
                                {small?null:<td className="tl">
                                    {extra}
                                </td>}
                            </tr>);
                        }
                    }
                    lastSpell=sl;
                }
            }

            if ((ret.length || slots.length)&& (!levelFilter || (levelFilter==i.toString()))) {
                if (rows.length % 2) {
                    rows.push(<tr key={i+"s"}/>);
                }
                rows.push(<tr key={i+"t"} className="b f4">
                    <td colSpan="6">{i === 0 ?character.t("Cantrips"):(Parser.spLevelToFull(i) + character.spellLevelName("-"))} {slots}</td>
                </tr>);

                if (ret.length || (actionFilter && i<=1)) {
                    rows.push(<tr className="f7" key={i+"h"}>
                        <td/>
                        <td/>
                        <td className="tc b">
                            <PickVal onClick={this.onSetActionFilter.bind(this)} values={actionPickVals}>
                                <a>time{actionFilter?"*":null}</a>
                            </PickVal>
                        </td>
                        <td className="tc b f8">range</td>
                        <td className="tc b f8">hit/dc</td>
                        <td className="tc b f8">effect</td>
                        {small?null:<td className="tl b f8">notes</td>}
                    </tr>);
                }
                rows = rows.concat(ret);
            }
        }
        if (!rows.length) {
            return null;
        }
        return <div className="overflow-x-auto stdtight">
            <table className="w-100 stdcontent"><tbody>
                {rows}
            </tbody></table>
            {this.state.showCastSpellInfo?this.getCastSpellMenu():null}
            <MonsterPicker open={this.state.showSelectMonster} scrollToSelected restriction={this.state.selectMonsterRestriction} selected={this.state.lastSelected} onClose={this.selectMonster.bind(this)}/>
        </div>;
    }

    onSetActionFilter(actionFilter) {
        if (actionFilter == "all") {
            actionFilter=null;
        }
        this.setState({actionFilter});
    }

    getCastSpellMenu() {
        const character = this.props.character;
        const levelOpts = this.state.showCastLevelOpts;
        const spellInfo = this.state.showCastSpellInfo;
        const list=[];

        for (let i in levelOpts) {
            const levelOpt = levelOpts[i];
            let inside;
            switch (levelOpt.type) {
                case "pact":
                    inside=<span>{character.t("Pact")}</span>;
                    break;

                case "ritual":
                    inside=<span>(ritual {levelOpt.time})</span>;
                    break;

                case "uses":
                    inside=<span>{(levelOpt.useCount>1) && levelOpt.useCount} {levelOpt.useName}</span>;
                    break;

                case "charges":
                    inside=<span>{levelOpt.charges} charges</span>;
                    break;

                case "spellpoints":
                    inside=<span className="ttl">{levelOpt.points} {character.t("Spell Points")}</span>;
                    break;
                case "spellslot":
                    break;

                default:
                    console.log("unknown spell type", levelOpt.type, levelOpt);
                    break;
            }
            list.push(<MenuItem key={i} onClick={this.doCastSpell.bind(this, levelOpt, spellInfo)}>
                {Parser.spLevelToFull(levelOpt.level)+character.spellLevelName("-")}&nbsp;{inside}
            </MenuItem>);
        }

        return <Menu
            anchorEl={this.state.showCastAnchorEl||null}
            open
            onClose={this.hideCastSpellMenu.bind(this)}
        >
            {list}
        </Menu>;
    }

    hideCastSpellMenu() {
        this.setState({showCastSpellInfo:null, showCastLevelOpts:null});
    }

    castSpell(atWill, spellInfo, event) {
        const s = spellInfo.spell;
        const character = this.props.character;
        const sa = getSpellAttributes(s);
        let ritual;

        if (atWill) {
            this.chatCastSpell({level:s.level, itemType:spellInfo.type, id:spellInfo.id}, spellInfo);
        } else if (spellInfo.ritualOnly) {
            const levelOpts=[];
            levelOpts.push({level:s.level, type:"ritual", time:spellInfo.noExtraCastingTime?null:getRitualTime(s)});
            this.setState({showCastSpellInfo:spellInfo, showCastLevelOpts:levelOpts, showCastAnchorEl:event.target})
        } else if (spellInfo.usageKey) {
            const levelOpts=[];
            const uses = character.getUsageVal(spellInfo.usageKey.usage, spellInfo.usageKey.usageId);
            const useCount = spellInfo.useCount||1;
            const clevel = spellInfo.minLevel||s.level;
            if (["useslot","longslot"].includes(spellInfo.recoveryType)) {
                levelOpts.push({type:"uses",level:clevel, useCount:useCount, useName:spellInfo.usageKey?.usage?.usageName||"uses"});
            } else {
                for (let y=clevel; y<=9; y++) {
                    const usesNeeded = (useCount+y-clevel);
                    if (usesNeeded <= uses) {
                        levelOpts.push({type:"uses",level:y, useCount:usesNeeded, useName:spellInfo.usageKey?.usage?.usageName||"uses"});
                    }
                }
            }
            if ((!s.level && levelOpts.length) || ((levelOpts.length == 1)&&(clevel == s.level))) {
                this.doCastSpell(levelOpts[0],spellInfo);
            } else if (levelOpts.length){
                this.setState({showCastSpellInfo:spellInfo, showCastLevelOpts:levelOpts, showCastAnchorEl:event.target});
            }
        } else if (["item", "itemextra"].includes(spellInfo.type)) {
            switch (spellInfo.recoveryType) {
                case "charges": {
                    const levelOpts=[];
                    const fit = getItemFeature(character, spellInfo.type, spellInfo.id);
                    const max = ((fit.usage && fit.usage.baseCount)||0);
                    const uses = (fit.uses=== undefined)?max:fit.uses;
                    const clevel = spellInfo.minLevel||s.level;

                    for (let y=clevel; y<=9; y++) {
                        const chargesNeeded = (spellInfo.charges+y-clevel);
                        if (chargesNeeded <= uses) {
                            levelOpts.push({level:y, type:"charges", charges:chargesNeeded, itemType:spellInfo.type, id:spellInfo.id});
                        }
                    }
                    if ((!s.level && levelOpts.length) || (levelOpts.length == 1)) {
                        this.doCastSpell(levelOpts[0],spellInfo);
                    } else if (levelOpts.length){
                        this.setState({showCastSpellInfo:spellInfo, showCastLevelOpts:levelOpts, showCastAnchorEl:event.target});
                    }
                    break;
                }
                default:{
                    this.setItemSpellUsage(s.name, spellInfo.id,0);
                    this.chatCastSpell({level:spellInfo.minLevel||s.level, itemType:spellInfo.type, id:spellInfo.id}, spellInfo);
                    break;
                }
            }
        } else {

            if (character.ritualCaster && s.ritual) {
                ritual=true;
            }
            const levelOpts=character.getSpellUsageOptions(s.level, ritual, ritual?getRitualTime(s):null);
    
            if ((levelOpts.length>0) && (s.level == levelOpts[0].level) && !ritual && ((levelOpts.length==1)||!sa.extraSpellLevels)) {
                this.doCastSpell(levelOpts[0],spellInfo);
            } else {
                this.setState({showCastSpellInfo:spellInfo, showCastLevelOpts:levelOpts, showCastAnchorEl:event.target});
            }
        }
    }

    doCastSpell(levelOpt, spellInfo) {
        const character = this.props.character;
        //console.log("cast", levelOpt, spellInfo);
        this.hideCastSpellMenu();
        if (levelOpt.type == "spellslot") {
            const uses = character.GetAvailableSpellSlots(levelOpt.level-1)-1;
            if (uses >= 0) {
                this.setSpellSlots(levelOpt.level-1, uses);
            } else {
                return;
            }
        } else if (levelOpt.type == "pact"){
            let pactSlots = character.pactSlots - 1;
            if (pactSlots >= 0) {
                this.changeAttribute("pactSlots", pactSlots);
            } else {
                return;
            }
        } else if (levelOpt.type == "ritual") {
        } else if (levelOpt.type=="charges") {
            const fit = getItemFeature(character, levelOpt.itemType, levelOpt.id)
            const max = ((fit.usage && fit.usage.baseCount)||0);
            let uses = ((fit.uses=== undefined)?max:fit.uses)-levelOpt.charges;
            if (uses < 0){
                uses=0;
            }
            this.setItemUsage(levelOpt.id, uses);
        } else if (levelOpt.type=="uses") {
            const uses = character.getUsageVal(spellInfo.usageKey.usage, spellInfo.usageKey.usageId);
            const blevel = spellInfo.minLevel||spellInfo.spell.level;
            const useCount = (spellInfo.useCount||1)+(levelOpt.level-blevel);
            if (uses >= useCount) {
                this.setSpellUsage(spellInfo.usageKey, uses-useCount);
            }
        } else if (levelOpt.type == "spellpoints"){
            const availableSpellPoints = character.availableSpellPoints - levelOpt.points;
            if (availableSpellPoints >= 0) {
                character.availableSpellPoints = availableSpellPoints;
            } else {
                return;
            }
        } else {
            console.log("unknown spell cast", levelOpt, spellInfo);
            return;
        }
        this.chatCastSpell(levelOpt, spellInfo);
    }

    chatCastSpell(levelOpt, spellInfo) {
        const {character,spellBlockId} = this.props;
        const {spell} = spellInfo;
        const spellmods = character.getSpellMods();
        const {casterSpellSave, casterAttackRoll, spellcastingMod, saveAbility, saveVal, href, concentration, actionType, tempHp, attackRoll, damages, altdamages, castLevel, extraNotes, range,conditions,noShowDuration,areaOfEffect,duration} = getSpellActionInfo(character, spellmods, levelOpt, spellInfo);

        //Chat.castSpell(character, levelOpt, spellInfo);

        let actionTypeVal="Cast Spell";
        const conditionInfo = {};

        if (castLevel) {
            actionTypeVal = "Cast "+Parser.spLevelToFull(castLevel) + character.spellLevelName("-"," Spell");
        }

        const spellDuration = getDurationFromSpell(spell, castLevel);
        if (!noShowDuration && spellDuration && spell.displayName && !character.readOnly) {
            if (saveAbility) {
                conditionInfo.saveAbility = saveAbility;
                conditionInfo.saveVal = saveVal;
            }
            if (attackRoll) {
                conditionInfo.attackRoll = attackRoll;
            }
    
            conditionInfo.spellId = spell.name;
            conditionInfo.spellName = spell.displayName;
            if (extraNotes) {
                conditionInfo.extraNotes = extraNotes;
            }
            if (concentration) {
                conditionInfo.concentration=1;
            }
            if (!isNaN(range)) {
                conditionInfo.range=range;
            }
            if (actionType) {
                conditionInfo.actionType = actionType;
            }
            if (castLevel) {
                conditionInfo.spellLevel = castLevel;
            }
            if (damages){
                conditionInfo.damages = damages;
            }
            if (altdamages) {
                conditionInfo.altdamages=altdamages;
            }
            if (conditions) {
                conditionInfo.conditions=conditions;
            }
            const condition={duration:getDurationFromText(spellDuration), durationText:spellDuration, feature:spell.enableFeature||null, spell:conditionInfo,hideIndicator:true};
            const nc = Object.assign({}, character.conditions);
            nc[spell.displayName] = condition;

            character.conditions=nc;
            this.gotoSection("actionblock");
        }

        if (spell.summonMonsters) {
            const {calcMonRestriction} = require('./charactersheet.jsx');
            const selected = character.getUsageVal(null, "spellmonster."+spell.name);
            let lastSelected;
            if (selected) {
                lastSelected={};
                lastSelected[selected]=1;
            }
            const  selectMonsterRestriction = calcMonRestriction(spell.summonMonsters, castLevel||spell.level);

            this.setState({showSelectMonster:true, selectMonsterSpell:spell, lastSelected, spellAttributes:{spellLevel:castLevel||spell.level, additionalSpellLevels:(castLevel||spell.level)-spell.level, saveVal:casterSpellSave,attackRoll:casterAttackRoll,spellcastingMod}, selectMonsterRestriction});
        }

        Chat.addAction(character, spell.displayName, href, attackRoll, damages, saveAbility, saveVal, actionTypeVal, conditions, tempHp, altdamages&&altdamages.length?[{damages:altdamages}]:null, true, areaOfEffect||{},duration);
    }

    setSpellUsage(u,value) {
        this.props.character.setUsageVal(u.usage, u.usageId, value);
    }

    selectMonster(selection) {
        if (selection) {
            const character = this.props.character;
            const {selectMonsterSpell,selectMonsterRestriction,spellAttributes} = this.state;

            character.setUsageVal(null, "spellmonster."+selectMonsterSpell.name, selection.toLowerCase());
            if (selectMonsterSpell.summonMonsters){
                const selList = {};
                let count=1;
                if (selectMonsterRestriction?.countsByCr) {
                    const mon = campaign.getMonster(selection);
                    if (mon) {
                        const pos = Parser.crsortVals.indexOf(mon.crsort);
                        count = selectMonsterRestriction.countsByCr[pos]||1;
                    }
                }

                selList[selection.toLowerCase()]=count;
                character.addCompanions(selList, "spellmonster."+selectMonsterSpell.name,selectMonsterRestriction, spellAttributes);
                this.gotoSection("traits");
            }
        }
        this.setState({showSelectMonster:false});
    }

    gotoSection(section) {
        const t=this;
        //console.log("goto",section);
        if (this.doScroll) {
            //console.log("end");
            endTimeout(this.doScroll);
        }
        this.doScroll = setTimeout(function(){
            const element = document.getElementById(t.props.idBase+section);
            //console.log("do scroll now",section,t.props.idBase+section, element);
            if (element) {
                element.scrollIntoView({behavior: "smooth"});
            }
            t.doScroll=null;
        },100);
    }

    setItemSpellUsage(name,id,value) {
        const character = this.props.character;
        const it = Object.assign({},character.getEquipmentItem(id.id||id));
        let feature;
        if (id.id) {
            it.extraFeatures = Object.assign({},it.extraFeatures);
            feature = Object.assign({}, it.extraFeatures[id.createId]);
            it.extraFeatures[id.createId] = feature;
        } else {
            it.feature = Object.assign({},it.feature);
            feature = it.feature;
        }
        feature.itemUsage = Object.assign({},(feature.itemUsage||{}));
        feature.itemUsage[name]=value;
        character.setEquipmentItem(it, id.id||id);
    }

    setItemUsage(id,value) {
        const character = this.props.character;
        const it = Object.assign({},character.getEquipmentItem(id.id||id));
        let feature;
        if (id.id) {
            it.extraFeatures = Object.assign({},it.extraFeatures);
            feature = Object.assign({}, it.extraFeatures[id.createId]);
            it.extraFeatures[id.createId] = feature;
        } else {
            it.feature = Object.assign({},it.feature);
            feature = it.feature;
        }
        feature.uses = value;
        character.setEquipmentItem(it, id.id||id);
    }

    onClickItem(id) {
        this.setState({showItemDetails:true, showItemId:id});
    }

    closeItem() {
        this.setState({showItemDetails:false});
    }

    showSpell(spell) {
        this.setState({showSpell:true, selectedSpell:spell});
    }

    hideSpell() {
        this.setState({showSpell:false});
    }

    addSpellToken(ao) {
        this.props.addSpellToken(ao);
        this.setState({showSpell:false});
    }

    setSpellSlots(level, value) {
        this.props.character.SetAvailableSpellSlots(level, value);
    }

    changeAttribute(prop, value) {
        this.props.character.setProperty(prop, value);
    }

    doAttack(spell, bonus){
        const character = this.props.character;
        const dice = getDiceFromString("1d20"+bonus);
        const {rolls} = doRoll(dice);
        const roll = {dice, rolls, source:spell.displayName, sourceHref:"#spell?id="+encodeURIComponent(spell.name), action:"to hit"};
        Chat.addD20BonusRolls(character, roll, character.spellDice);

        character.addRoll(roll);
    }

    doAction(spell, damages, tempHp, duration,saveAbility, saveVal) {
        const character = this.props.character;
        if (damages) {
            Chat.addCharacterDamageRoll(character, spell.displayName, "#spell?id="+encodeURIComponent(spell.name), damages, duration,null,saveAbility, saveVal);
        }
        if (tempHp) {
            const dice = character.resolveDice(tempHp.hp,true);
            Chat.addCharacterTempHPRoll(character, displayName, href||null, dice, tempHp.duration);
        }
    }

    doTextRoll(name, text){
        const {character} = this.props;
        if (name && (typeof name == "object")) {
            // if name is an object then it actually contains the roll
            character.addRoll(name);
            return name;
        }

        const dice = getDiceFromString(text);

        character.doRoll(dice, name,null);
    }

    doItemTextRoll(name, text){
        const character = this.props.character;
        if (name && ((typeof name) == "object")) {
            // if name is an object then it actually contains the roll
            character.addRoll(name);
            return name;
        }
        const dice = getDiceFromString(text);
        if (!name) {
            const {rolls} = doRoll(dice);
            const id = this.state.showItemId;
            const it = character.getEquipmentItem(id);
            const roll = {dice, rolls, source:it.displayName||it.name, sourceHref:"#item?iid="+encodeURIComponent(id)+"&cid="+encodeURIComponent(character.name)};

            return character.addRoll(roll);
        }

        return character.doRoll(dice, name,null);
    }
}

function getRitualTime(spell) {
    let time=null;
    switch (spell.time?.unit) {
        case "action":
        case "bonus action":
        case "reaction":
        default:
            time ="10 min";
            break;

        case "minute":
            time = ((spell.time?.number||0)+10)+" min";
            break;
        case "hour":
            if (spell.time?.number) {
                time = spell.time?.number+" hrs & 10 min"
            } else {
                time = "10 min";
            }
            break;
    }
    return time;
}

function levelDice(levelBonus, dice, baseLevel, castLevel, perLevel) {
    if (!levelBonus || !dice || (baseLevel==castLevel)) {
        return dice;
    }
    for (let l=baseLevel+(perLevel||1); l<=castLevel; l+=(perLevel||1)) {
        dice=dice+"+"+levelBonus;
    }
    return dice;
}

function areSameSpell(curSpell, lastSpell, sa) {
    if (!lastSpell || !curSpell) {
        return false;
    }

    const cs = curSpell.spell;
    const ls = lastSpell.spell;
    if (cs.name != ls.name) {
        return false;
    }
    if ((sa.save ||
         sa.attack||
         (sa.action && sa.action.bonus)||
         (sa.altaction && sa.altaction.bonus)
        ) && 
        (curSpell.abilityDC != lastSpell.abilityDC)
    ) {
        return false;
    }

    if ((curSpell.id != lastSpell.id) || (curSpell.charges != lastSpell.charges) || (curSpell.type != lastSpell.type)) {
        return false;
    }

    if ((cs.level > 0) && (((curSpell.usageKey&&curSpell.usageKey.usageId)||"") != ((lastSpell.usageKey&&lastSpell.usageKey.usageId)||""))) {
        return false;
    }

    if ((curSpell.ritualOnly||false) != (lastSpell.ritualOnly||false)) {
        return false;
    }
    if (cs.level  && ((curSpell.recoveryType||"") != (lastSpell.recoveryType||""))) {
        return false;
    }
    //console.log("are same", curSpell, lastSpell, sa)
    return true;
}

function getAllSpells(character,demoMode) {
    const list = [];

    if (demoMode) {
        list.push({spell:demoCantrip, abilityDC:"int" });
        list.push({spell:demoSpell, abilityDC:"int",recoveryType:"atwill" });
    }

    for (let i in character.classes) {
        const cInfo = character.classes[i];
        const cls = campaign.getClassInfo(cInfo.cclass);
        if (!cls) continue;

        const subclass = campaign.getSubclassInfo(cInfo.subclass);
        const spellcls = (cls.spellcaster && cls) || subclass;
        const abilityDC = (subclass && subclass.abilityDC) || (cls && cls.abilityDC);

        if ((cls.spellcaster || (subclass && subclass.spellcaster)) && (cInfo.attributes.knownCantrips || cInfo.attributes.knownSpells || ((character.spellSlots && (character.spellSlots[0] || character.spellSlots[1])) || character.maxPactSlots))) {
            getSelectedSpells(cInfo, !!(spellcls.prepareSpells||spellcls.preparedSpellCounts), abilityDC, spellcls.ritualCastUnprepared);
        }
    }

    character.traverseFeatures(function (params) { 
        const {feature, fid, usageId, options, type, id, typeValue, level} = params;

        getFeatureSpells(feature, fid,options, usageId,type,id, typeValue, level)
    });
    //console.log("character spells", list)
    return list;

    function getFeatureSpells(feature, base,options, optionsBase, type, id, it, level) {
        const castableSpells = feature.castableSpells||{};
        const baseName=optionsBase+".spellPick.";

        if (type=="item" || type=="itemextra") {
            if (it.equip && (!it.reqAttune || character.attuned[id.id||id])) {
                const recoveryType = feature.recoveryType||"day";
                let spellCastDC = null;
                let abilityDC = null;;
                let minLevel=9;

                if (feature.spellCastDC=="character"){
                    let max = -100;
                    for (let ability of ["int", "cha", "wis"]) {
                        const a = character.getAbility(ability).modifier;
                        if (a > max) {
                            max = a;
                            abilityDC = ability;
                        }
                    }
                } else {
                    spellCastDC = (feature.spellCastDC||15);
                }

                if (recoveryType == "charges") {
                    for (let i in castableSpells) {
                        const s = castableSpells[i];
                        const spell = campaign.getSpell(s.name);
                        if (spell) {
                            minLevel = Math.min(s.castLevel||spell.level, minLevel);
                        } else {
                            //console.log("can't find charges spell", s);
                        }
                    }
                }
                for (let i in castableSpells) {
                    const s = castableSpells[i];
                    const spell = campaign.getSpell(s.name);
                    if (spell) {
                        const recoveryType = feature.recoveryType||"day";
                        const charges = (s.chargeCount=="none")?0:(s.chargeCount || (1+(s.castLevel||spell.level)-minLevel)); 
                        list.push({spell, spellCastDC, abilityDC, recoveryType, type, id, itemName:it.displayName, ritualOnly:(recoveryType=="ritual"), minLevel:s.castLevel||spell.level, charges});
                    } else {
                        //console.log("can't find item spell", s);
                    }
                }
            }
        } else {
            let abilityDC="int";
            let spellCastDC=null;
            const saveDC = feature.spellAbilityDC || (feature.usage&&feature.usage.saveDC);
            if (saveDC) {
                if (Array.isArray(saveDC)) {
                    abilityDC = null;
                    let lastVal = 0;
                    for (let i in saveDC) {
                        const s = saveDC[i];
                        const cv = isNaN(s)?((character.getAbility(s)||{}).modifier||0):Number(s);

                        if (!abilityDC || (cv > lastVal)) {
                            abilityDC = s;
                            lastVal = cv;
                        }
                    }
                } else {
                    abilityDC=saveDC;
                }
                if (!isNaN(abilityDC)) {
                    spellCastDC = Number(abilityDC);
                    abilityDC=null;
                }
            }
    
            for (let i in castableSpells) {
                const s = castableSpells[i];
                const spell = campaign.getSpell(s.name);
                if (spell && (!s.grantLevel || (s.grantLevel <= level))) {
                    const recoveryType = feature.ritualOnly?"ritual":(feature.recoveryType||"long");
                    let usageKey=null;
                    let maxUsage = 1;
                    let useCount = 1;
                    if (["long","short","longslot"].includes(recoveryType)) {
                        if (spell.level >0) {
                            usageKey = {usageId:baseName+spell.name.toLowerCase()};
                        }
                    } else if (["uses","useslot"].includes(recoveryType)) {
                        usageKey= {usage:feature.usage, usageId:optionsBase};
                        maxUsage = character.getUsageMax(feature.usage, level-1);
                        useCount = s.useCount || 1;
                    }
                    if (!["slot"].includes(recoveryType) || ((spell.level||0)<=(character.maxSpellCastLevel ||-1))) {
                        list.push({spell, abilityDC, spellCastDC, recoveryType, usageKey, maxUsage, minLevel:s.castLevel||spell.level, itemName:it?.displayName, ritualOnly:(recoveryType=="ritual"), useCount});
                    }
                    if (["longslot","useslot","atwillslot"].includes(recoveryType) && ((spell.level||0)<=(character.maxSpellCastLevel ||-1))) {
                        list.push({spell, abilityDC, spellCastDC, usageKey:null, ritualOnly:false});
                    }
                } else if (!spell) {
                    //console.log("can't find castable spell", s);
                }
            }

            if (feature.spellPick) {
                const recoveryType = feature.spellPick.ritualOnly?"ritual":(feature.spellPick.recoveryType||"long");
                const spellPick = (options||{})[base+".spellPick"]||[];
                //console.log("spellPick", spellPick, options, base, baseName, optionsBase, feature);
                for (let i in spellPick) {
                    const spell = campaign.getSpell(i);
                    if (spell) {
                        let usageKey=null;
                        let maxUsage = 1;
                        let useCount = 1;
                        if (["long","short","longslot"].includes(recoveryType)) {
                            if (spell.level >0) {
                                usageKey = {usageId:baseName+spell.name.toLowerCase()};
                            }
                        } else if (["uses","useslot"].includes(recoveryType)) {
                            usageKey= {usage:feature.usage, usageId:optionsBase};
                            maxUsage = character.getUsageMax(feature.usage,level-1);
                            useCount = feature.spellPick.useCount || 1;
    
                        }
                        if (!["slot"].includes(recoveryType) || ((spell.level||0)<=(character.maxSpellCastLevel ||-1))) {
                            list.push({spell, abilityDC, spellCastDC, recoveryType, usageKey, maxUsage, ritualOnly:(recoveryType=="ritual"), useCount});
                        }
                        if (["longslot","useslot","atwillslot"].includes(recoveryType) && ((spell.level||0)<=(character.maxSpellCastLevel ||-1))) {
                            list.push({spell, abilityDC, spellCastDC, usageKey:null, ritualOnly:false});
                        }
                    }else {
                        //console.log("can't find spell pick", i);
                    }
                }
            }
        }
    }

    function getSelectedSpells(cInfo, prepare, abilityDC,ritualCastUnprepared) {
        const selectedSpells = cInfo.selectedSpells||{};
        const {extraSpells} = getExtraSpells(character, cInfo);
        const needPrep = cInfo.attributes.knownSpells&&prepare;

        for (let i in selectedSpells) {
            const s = selectedSpells[i];
            const spell = campaign.getSpell(s.name);
            if (spell && (!s.level || !needPrep || s.prepared ||(spell.ritual && ritualCastUnprepared)) && (spell.level <= cInfo.attributes.maxSpellLevel)) {
                list.push({spell, abilityDC, ritualOnly:(s.level && needPrep && !s.prepared)});
            } else if (!spell) {
                //console.log("can't find selected spell", s);
            }
        }

        if (cInfo.attributes.knownRituals) {
            const selectedRituals = cInfo.selectedRituals;

            for (let i in selectedRituals) {
                const s = selectedRituals[i];
                const spell = campaign.getSpell(s.name);
                if (spell && (spell.level <= cInfo.attributes.maxSpellLevel)) {
                    list.push({spell, abilityDC, ritualOnly:true, noExtraCastingTime:true});
                } else if (!spell) {
                    //console.log("can't find selected spell", s);
                }
            }
        }

        for (let i in extraSpells) {
            if (!selectedSpells[extraSpells[i].name.toLowerCase()]) {
                const s = extraSpells[i];
                const spell = campaign.getSpell(s.name);
                if (spell) {
                    list.push({spell, abilityDC});
                } else {
                    //console.log("can't find extra spell", s);
                }
            }
        }
    }
}

function getSpellActionInfo(character, spellmods, levelOpt, spellInfo) {
    const {mergeExtraDamage} = require('./charactersheet.jsx');
    const {spell} = spellInfo;
    const sa = getSpellAttributes(spell);
    let damages, altdamages, effectType, alteffectType,modAttack="", modEffect="", modExtraDamage={}, extraNotesList=[];
    const ret = {};

    if (spell.extraNotes) {
        extraNotesList.push(spell.extraNotes);
    }

    for (let x in spellmods) {
        let it = spellmods[x];
        let matchOther=false;
        if (it.spellList || it.school || (it.maxSpellLevel!=null)) {
            matchOther=true;
            if (it.school) {
                const schoolList=spSchoolAbvToFull(spell.school,spell.schoolName).toLowerCase().split(",").map(function (a) { return a.trim()});
                if (!schoolList.includes(it.school.toLowerCase())) {
                    matchOther=false;
                }
            }
            if (it.spellList) {
                if (!hasClass(spell, it.spellList)) {
                    matchOther = false;
                }
            }
            if (it.maxSpellLevel!=null) {
                if (spell.level > Number(it.maxSpellLevel)) {
                    matchOther = false;
                }
            }
        }

        if ((it.selectedSpells&&it.selectedSpells[spell.name?.toLowerCase()]) || matchOther) {
            if (it.attackBonus) {
                modAttack = modAttack+("+"+it.attackBonus);
            }
            if (it.effect) {
                modEffect = modEffect+("+"+it.effect);
            }
            if (it.extraNotes) {
                extraNotesList.push(it.extraNotes);
            }
            mergeExtraDamage(modExtraDamage, it.extraDamage);
        }
    }
    //console.log("mods", modAttack, modEffect, modExtraDamage);

    if (spellInfo.itemName) {
        ret.itemHref = "#item?iid="+encodeURIComponent(spellInfo.id)+"&cid="+encodeURIComponent(character.name);
        ret.itemName = spellInfo.itemName;
    }

    if (sa.shape||sa.length) {
        ret.areaOfEffect = {};
        if (sa.shape) {ret.areaOfEffect.shape = sa.shape};
        if (sa.length) {ret.areaOfEffect.length = sa.length};
    }
    ret.href = "#spell?id="+encodeURIComponent(spell.name);

    let castLevel = (levelOpt?.level) || spell.level;

    const itemSpell = (levelOpt?.type=="item")||(levelOpt?.type=="itemextra");
    const ability = sa.save?saveToAbility[sa.save]:null;
    const abModifier =spellInfo.spellCastDC?(spellInfo.spellCastDC-8):((character.getAbility(spellInfo.abilityDC)||{}).modifier||0);
    const abCalc = (spellInfo.spellCastDC?abModifier:(abModifier+(character.proficiency||0)+character.spellAttackBonus));
    const ab = sa.attack?abCalc:null;
    const saveVal = spellInfo.spellCastDC || (abModifier+8+character.proficiency+character.spellDCBonus);
    const attackRoll = character.resolveDice(abCalc+(modAttack||""))
    ret.casterSpellSave = saveVal;
    ret.casterAttackRoll = attackRoll;

    if (ability) {
        ret.saveAbility = ability;
        ret.saveVal = saveVal;
        if (modAttack) {
            const aDice = getDiceFromString(character.resolveDice(modAttack), 0, true);
            ret.saveVal+=(aDice.bonus||0);
        }
    }
    if (ab !== null) {
        ret.attackRoll = attackRoll;
    }
    const estats = {"cast level":castLevel||spell.level, "extra cast level":(castLevel||spell.level)-spell.level, "spell level":spell.level};
    const bonus = spellInfo.spellCastDC?(abilityModFromSpellDC[spellInfo.spellCastDC]||0):((character.getAbility(spellInfo.abilityDC)||{}).modifier||0);
    ret.spellcastingMod = bonus;
    if (bonus) {
        estats["spellcasting mod"] = bonus;
    }
    if (sa.action) {
        let action = levelDice(sa.action.levelBonus, sa.action.dice, spell.level, castLevel, sa.action.levelCount);
        let altaction;
        if (sa.altaction) {
            altaction=levelDice(sa.altaction.levelBonus, sa.altaction.dice, spell.level, castLevel, sa.altaction.levelCount);
        }
        if (!spell.level && !itemSpell) { // only adjust by level for cantrips
            const cl = character.monsterSpellLevel || character.level;
            for (let i=1; i<=cl; i++) {
                action = (sa.action.levels||[])[i]||action;
                if (sa.altaction) {
                    altaction = (sa.altaction.levels||[])[i]||altaction;
                }
            }
        }
        if (sa.action.bonus && bonus) {
            action= action + ((bonus>0)?("+"+bonus):bonus);
        }
        if (sa.altaction && sa.altaction.bonus && bonus) {
            altaction= altaction + ((bonus>0)?("+"+bonus):bonus);
        }
        if (action) {
            action +=modEffect;
        }
        if (altaction) {
            altaction += modEffect;
        }
        if (sa.action.type) {
            effectType = sa.action.type;
        }

        const duration = getDurationFromSpell(spell, castLevel);
        if (duration) {
            ret.duration=duration;
        }
        if (effectType?.toLowerCase()=="temp hp") {
            ret.tempHp={hp:character.resolveDice(action)};
            if (duration) {
                ret.tempHp.duration = duration;
            }
            action=null;
            effectType=null;
        }
        const actionExtraDamage = {};
        mergeExtraDamage(actionExtraDamage, modExtraDamage);
        mergeExtraDamage(actionExtraDamage, sa.action?.extraDamage);
        damages = damagesFromExtraDamage(action, effectType||"", actionExtraDamage);
        character.resolveDamages(damages,estats);
        if (damages && damages.length) {
            ret.damages = damages;
        }

        if (sa?.altaction?.type){
            if (sa.action.type != sa.altaction.type) {
                alteffectType = sa.altaction.type;
            }
        }

        if (altaction) {
            const altactionExtraDamage = {};
            mergeExtraDamage(altactionExtraDamage, modExtraDamage);
            mergeExtraDamage(altactionExtraDamage, sa?.altaction?.extraDamage);
            altdamages = damagesFromExtraDamage(altaction, alteffectType||effectType||"", altactionExtraDamage);
            character.resolveDamages(altdamages,estats);
            if (altdamages && altdamages.length) {
                ret.altdamages = altdamages;
            }
        }
    }

    if (sa.extraSpellLevels){
        ret.levelUp = true;
    }
    ret.spellId = spell.name;
    ret.spellName = spell.displayName;
    if (sa.concentration) {
        ret.concentration=1;
    }
    if (spell.range) {
        ret.range=cleanRange(spell.range);
    }
    if ((spell.time?.number==1) && spell.time?.unit) {
        ret.actionType = spell.time.unit;
    }
    if (castLevel && castLevel!=spell.level) {
        ret.castLevel = castLevel;
    }
    if (sa.conditions) {
        ret.conditions=sa.conditions;
    }
    ret.extraNotesList = extraNotesList;
    ret.noShowDuration = sa?.noShowDuration;
    return ret;
}

function getItemFeature(character, type, id) {
    if (type=="item") {
        const it = character.getEquipmentItem(id);
        return it.feature;
    }
    const it= character.getEquipmentItem(id.id);
    return it.extraFeatures[id.createId];
}

function getExtraSpells(character, cInfo) {
    const cls = campaign.getClassInfo(cInfo.cclass);
    const subclassInfo = campaign.getSubclassInfo(cInfo.subclass);
    const extraSpells = {};
    const selectableSpells = {};
    const prepared = cls?.prepareMultiple || subclassInfo?.prepareMultiple;

    if (cls?.assignedSpellList) {
        for (let i in cls.assignedSpellList) {
            const spell = cls.assignedSpellList[i];
            const spellInfo = campaign.getSpell(spell);
            if (spellInfo && spellInfo.level <= cInfo.attributes.maxSpellLevel) {
                selectableSpells[spell.toLowerCase()] = {name:spellInfo.name, level:spellInfo.level};
            }
        }
    }

    if (subclassInfo?.assignedSpellList) {
        for (let i in subclassInfo.assignedSpellList) {
            const spell = subclassInfo.assignedSpellList[i];
            const spellInfo = campaign.getSpell(spell);
            if (spellInfo && spellInfo.level <= cInfo.attributes.maxSpellLevel) {
                selectableSpells[spell.toLowerCase()] = {name:spellInfo.name, level:spellInfo.level};
            }
        }
    }

    character.traverseFeatures(function (params) { 
        const {feature} = params;
        if (feature.extraSpells) {
            for (let i in feature.extraSpells) {
                const spell = feature.extraSpells[i];
                const spellInfo = campaign.getSpell(spell);
                if (spellInfo && spellInfo.level <= cInfo.attributes.maxSpellLevel) {
                    extraSpells[spell.toLowerCase()] = {name:spellInfo.name, level:spellInfo.level, prepared};
                }
            }
        }

        if (feature.selectableSpells) {
            for (let i in feature.selectableSpells) {
                const spell = feature.selectableSpells[i];
                const spellInfo = campaign.getSpell(spell);
                if (spellInfo && spellInfo.level <= cInfo.attributes.maxSpellLevel) {
                    selectableSpells[spell.toLowerCase()] = {name:spellInfo.name, level:spellInfo.level};
                }
            }
        }
    }, null, cInfo.cclass);

    return {extraSpells,selectableSpells};
}

function cleanRange(range) {
    if (!range) {
        return null;
    }
    range = range.split("(")[0];
    range = range.toLowerCase().replace("feet", "").trim();
    return range;
}

const spellTimeUnitMap={
    "action":"A",
    "bonus action":"BA",
    "reaction":"RA",
    "minute":"min",
    "hour":"hr"
}

const saveToAbility={
    "strength":"str",
    "dexterity":"dex",
    "constitution":"con",
    "intelligence":"int",
    "wisdom":"wis",
    "charisma":"cha"
}

const actionPickVals={
    all:"No Filter",
    A:"Actions",
    BA:"Bonus Actions",
    RA:"Reactions",
}

const demoCantrip={
    name:"demoCantrip",
    displayName:"Demo Cantrip",
    spellAttack:["R"],
    range:"120 feet",
    action:{
        dice:"1d10",
        type:"fire"
    },
    level:0,
    attack:true,
    manualSet:true
}
const demoSpell={
    name:"demoSpell",
    displayName:"Demo Spell",
    spellAttack:["R"],
    range:"120 feet",
    action:{
        dice:"2d4",
        type:"force"
    },
    level:1,
    attack:true,
    manualSet:true
}

export {
    CharacterSpells,
    getAllSpells,
    getExtraSpells,
    levelDice,
    cleanRange,
    saveToAbility,
    getSpellActionInfo,
}