const React = require('react');
const {campaign,getLastModified} = require('../lib/campaign.js');
const {MonObj,findFeatures,findDamageType,findAttack,findSpellcasting,findSpellSave,findSpellAttack, findAttackType} = require('../lib/monobj.js');
const Parser = require("../lib/dutils.js").Parser;
const {Renderentry} = require('./entityeditor.jsx');
const {Rendersource} = require("./rendersource.jsx");
import Button from '@material-ui/core/Button';
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {displayMessage} = require('./notification.jsx');
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const {getWeaponProficiency} = require('./items.jsx');
const {getAllSpellSources} = require('./renderspell.jsx');
const {matchFeatures} = require('./searchfs.jsx');
const {labelDieRolls} = require('./entityeditor.jsx');
const {getProficiency,getCRSortFromCR} = require('./rendermonster.jsx');

const {itemTypeFromAbbreviation,environmentTypeList,sizeList,simpleMonsterTypes,rarityLevels, abilities,abilitiesValues,abilityNamesFull} = require('../lib/stdvalues.js');
const {getBookContentTypes,contentTypeMap} = require('./contentmap.jsx');

class BookConvertMenus extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const bookinfo=this.props.bookinfo;
        if (!bookinfo) {
            return null;
        }
        const chapterSel = this.props.chapter;
        const sectionSel = this.props.section;
        const subsectionSel = this.props.subsection;
        const ret = [];
        let convert=true;
        let convertChildren=true;

        const chapter=bookinfo.chapters[chapterSel];
    
        if ((chapter.contentId || chapter.contentList) && sectionSel < 0) {
            convert=false;
            convertChildren=false;
        }

        const sections = chapter.sections||[];
        let scount = 0;

        for (let si in sections) {
            if ((sectionSel < 0) || (si == sectionSel)) {
                const section = sections[si];
                const subsections = section.subsections||[];
                let sscount = 0;

                if (section.contentId || section.contentList) {
                    if (sectionSel == si) {
                        convert=false;
                        convertChildren=false;
                    }
                } else {
                    scount++;
                }

                for (let ssi in subsections) {
                    if ((subsectionSel < 0) || (ssi == subsectionSel)) {
                        const subsection = subsections[ssi];

                        if (subsection.contentId || subsection.contentList) {
                            if (subsectionSel == si) {
                                convert=false;
                            }
                        } else {
                            sscount++;
                        }
                    }        
                }
                
                if ((!sscount && (sectionSel >=0)) || (subsectionSel >= 0)) {
                    convertChildren=false;
                }
            }
        }
        if (!scount && (sectionSel < 0)) {
            convertChildren=false;
        }

        if (convert) {
            ret.push(<MenuItem key="c" onClick={this.clickConvert.bind(this)}>Convert</MenuItem>);
        }
        if (convertChildren) {
            ret.push(<MenuItem key="cc" onClick={this.clickConvertChildren.bind(this)}>Convert Children</MenuItem>);
        }
        ret.push(this.getPickMenu());
        ret.push(this.getSubclassMenu());
        ret.push(this.getSpellclassMenu());
        ret.push(this.getSubraceMenu());
        ret.push(this.getCustomMenu());
        ret.push(this.getEnvironmentMenu())
        ret.push(this.getDupMenu());
        if (this.state.loading) {
            ret.push(<Dialog open key="l">
                <DialogContent>
                    Saving changes...
                </DialogContent>
            </Dialog>);
        }
        if (this.state.showVerify) {
            ret.push(<VerifyDialog key="v" open bookChangeList={this.state.bookChangeList} undoList={this.state.undoList} changeList={this.state.changeList} newFragment={this.state.newFragment} onClose={this.onCloseVerify.bind(this)}/>);
        }

        return ret;
    }

    onCloseVerify() {
        this.setState({showVerify:false});
        this.props.onClose();
    }

    clickConvert(e) {
        this.setState({showPick:true, convertType:"convert", anchorEl:e.target});
    }

    clickConvertChildren(e) {
        this.setState({showPick:true, convertType:"convertchildren", anchorEl:e.target});
    }

    getPickMenu() {
        if (!this.state.showPick) {
            return null;
        }
        const list = (this.state.convertType=="convert")?conversionList:childConversionList;

        const ml = [];
        for (let i in list) {
            ml.push(<MenuItem key={i} onClick={this.onPickConvert.bind(this, list[i], null, null, null)}>{list[i]}</MenuItem>);
        }

        ml.push(<MenuItem key="sc" onClick={this.showSubclass.bind(this)}>Subclass</MenuItem>);
        ml.push(<MenuItem key="sr" onClick={this.showSubrace.bind(this)}>Subrace</MenuItem>);
        ml.push(<MenuItem key="scust" onClick={this.showCustom.bind(this)}>Other</MenuItem>);
        if (this.state.convertType=="convert") {
            ml.push(<MenuItem key="spc" onClick={this.showSpellclass.bind(this)}>Spell List</MenuItem>);
            ml.push(<MenuItem key="e" onClick={this.showEnvironment.bind(this)}>Environment List</MenuItem>);
        }
        ml.push(<MenuItem key="dup" onClick={this.showDupMenu.bind(this)}>Duplicate Handling</MenuItem>);

        return <Menu 
            key="p" 
            open 
            anchorEl={this.state.anchorEl} 
            onClose={this.hideMenu.bind(this)}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            {ml}
        </Menu>;
    }
    
    getSubclassMenu() {
        if (!this.state.showSubclass) {
            return null;
        }
        const classes = campaign.getClassesListByName();
        const cl = [], found={};

        for (let i in classes) {
            const ln = (classes[i].displayName||"").toLowerCase();

            found[ln]= (found[ln]||0)+1;
        }

        for (let i in classes) {
            const it = classes[i];
            const ln = (it.displayName||"").toLowerCase();
            cl.push(<MenuItem key={i} onClick={this.onPickConvert.bind(this, "Class", it.className, null, null)}><span className="flex-auto">{it.displayName}</span>{found[ln]>1?<Rendersource className="ml2 f6" entry={it}/>:null}</MenuItem>);
        }

        return <Menu 
            key="sc" 
            open 
            anchorEl={this.state.subclassAnchorEl} 
            onClose={this.hideMenu.bind(this)}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            {cl}
        </Menu>;
    }

    showSubclass(e) {
        this.setState({showSubclass:true,subclassAnchorEl:e.target});
    }
    
    getSpellclassMenu() {
        if (!this.state.showSpellclass) {
            return null;
        }
        const classes = campaign.getClassesListByName();
        const spellSources = getAllSpellSources();
        const cl = [];
        for (let i in classes) {
            cl.push(<MenuItem key={i} onClick={this.onPickConvertList.bind(this, "Spellclass", classes[i].displayName)}>{classes[i].displayName}</MenuItem>);
        }
        for (let s of spellSources) {
            cl.push(<MenuItem key={"sc"+s} onClick={this.onPickConvertList.bind(this, "Spellsource", s)}>Source: {s}</MenuItem>);
        }

        return <Menu 
            key="sc" 
            open 
            anchorEl={this.state.spellclassAnchorEl} 
            onClose={this.hideMenu.bind(this)}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            {cl}
        </Menu>;
    }

    showSpellclass(e) {
        this.setState({showSpellclass:true,spellclassAnchorEl:e.target});
    }
    
    getEnvironmentMenu() {
        if (!this.state.showEnvironment) {
            return null;
        }
        const cl = [];
        for (let i in environmentTypeList) {
            cl.push(<MenuItem key={i} onClick={this.onPickConvertList.bind(this, "Environment", environmentTypeList[i])}>{environmentTypeList[i]}</MenuItem>);
        }

        return <Menu 
            key="env" 
            open 
            anchorEl={this.state.environmentAnchorEl} 
            onClose={this.hideMenu.bind(this)}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            {cl}
        </Menu>;
    }

    showEnvironment(e) {
        this.setState({showEnvironment:true,environmentAnchorEl:e.target});
    }

    getDupMenu() {
        if (!this.state.showDupMenu) {
            return null;
        }

        const convertDuplicates = campaign.getUserSettings().convertDuplicates || "ignore";

        return <Menu 
            key="dup" 
            open 
            anchorEl={this.state.dupAnchorEl} 
            onClose={this.hideMenu.bind(this)}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <MenuItem selected={convertDuplicates=="ignore"} onClick={this.onPickDup.bind(this, "ignore")}>Ignore</MenuItem>
            <MenuItem selected={convertDuplicates=="current"} onClick={this.onPickDup.bind(this, "current")}>Utilize Current</MenuItem>
            <MenuItem selected={convertDuplicates=="update"} onClick={this.onPickDup.bind(this, "update")}>Update</MenuItem>
        </Menu>;
    }

    onPickDup(convertDuplicates) {
        campaign.updateUserSettings({convertDuplicates});
        this.hideMenu();
    }

    showDupMenu(e) {
        this.setState({showDupMenu:true,dupAnchorEl:e.target});
    }
    
    getSubraceMenu() {
        if (!this.state.showSubrace) {
            return null;
        }
        const races = campaign.getRaces();
        const rl = [];
        for (let i in races) {
            if (!races[i].baserace) {
                rl.push(<MenuItem key={i} onClick={this.onPickConvert.bind(this, "Race", null, races[i].name, null)}>{races[i].displayName}</MenuItem>);
            }
        }

        return <Menu 
            key="sc" 
            open 
            anchorEl={this.state.subraceAnchorEl} 
            onClose={this.hideMenu.bind(this)}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            {rl}
        </Menu>;
    }

    showSubrace(e) {
        this.setState({showSubrace:true,subraceAnchorEl:e.target});
    }
    
    getCustomMenu() {
        if (!this.state.showCustom) {
            return null;
        }
        const custom = campaign.getCustomTablesList();
        const cl = [];
        for (let i in custom) {
            cl.push(<MenuItem key={i} onClick={this.onPickConvert.bind(this, "Custom", null, null, custom[i])}>{custom[i]}</MenuItem>);
        }

        return <Menu 
            key="cust" 
            open 
            anchorEl={this.state.customAnchorEl} 
            onClose={this.hideMenu.bind(this)}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            {cl}
        </Menu>;
    }

    showCustom(e) {
        this.setState({showCustom:true,customAnchorEl:e.target});
    }
    
    hideMenu() {
        this.setState({showPick:false, showSubclass:false, showSubrace:false, showCustom:false, showEnvironment:false, showSpellclass:false, showDupMenu:false});
    }

    onPickConvert(type, baseClass, baserace, customType) {
        const t=this;
        const chapter = this.props.chapter;
        const section = this.props.section;
        const subsection = this.props.subsection;
        const sel={};
        const selected={};
        
        let key="ch."+chapter;
        if (section >=0) {
            key += ".s."+section;
        }
        if (subsection >=0) {
            key+= ".ss."+subsection;
        }

        if (this.state.convertType=="convert") {
            sel.type = type;
        } else {
            sel.childtype = type;
        }
        sel.class=baseClass;
        sel.race = baserace;
        sel.customType=customType;

        selected[key]=sel;

        const {changeList, bookChangeList,undoList} = convertBook(this.props.bookinfo, selected);
        campaign.batchUpdateCampaignContent(changeList).then(function (){
            t.setState({loading:false, showVerify:true});
        }, function (err) {
            t.setState({loading:false});
            displayMessage("Error making conversions. "+err.toString());
        });
        this.setState({loading:true, showPick:false, showSubclass:false, showSubrace:false, showCustom:false, bookChangeList, undoList, changeList, newFragment:null});
    }

    onPickConvertList(type, target) {
        const t=this;
        const fragmentId = findFragment(this.props.bookinfo, this.props.chapter, this.props.section, this.props.subsection);
        const fragment = campaign.getBookFragment(fragmentId);

        const {changeList, undoList, newFragment} = convertList(fragment, type, target);
        campaign.batchUpdateCampaignContent(changeList).then(function (){
            t.setState({loading:false, showVerify:true});
        }, function (err) {
            t.setState({loading:false});
            displayMessage("Error making conversions. "+err.toString());
        });
        this.setState({loading:true, showPick:false, showSpellclass:false, showEnvironment:false, showSubrace:false, showCustom:false, bookChangeList:null, undoList, changeList, newFragment});
    }
}

function findFragment(bookinfo, chapter, section, subsection) {
    const chapterInfo = bookinfo.chapters[chapter];
    if (section < 0) {
        return chapterInfo.fragment;
    }
    const sectionInfo = chapterInfo.sections[section];
    if (subsection < 0) {
        return sectionInfo.fragment;
    }
    return sectionInfo.subsections[subsection].fragment;
}

class VerifyDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
        getBookContentTypes();  // make sure types are loaded
    }

	render() {
        if (!this.props.open) {
            return null;
        }
        const content = [];
        const changeList = this.props.changeList;
        const newFragment = this.props.newFragment;

        if (this.state.loading) {
            return <Dialog open>
                <DialogContent>
                    Saving changes...
                </DialogContent>
            </Dialog>;
        }

        if (newFragment) {
            content.push(<div key="frag" className="stdcontent">
                <Renderentry 
                    entry={newFragment.entry} 
                    depth={1} 
                />
            </div>);
        } else {
            for (let i in changeList) {
                const acl = changeList[i];
                const cl = acl.contentInfo;
                const cm = contentTypeMap[cl.contentType];
                if (cm) {
                    const editContent = cm.edit;
                    content.push(<div key={i} className={(editContent?"hoverborder ":"")+(acl.contentType?"":"bg-light-green")} onClick={editContent?this.onClickEditContent.bind(this,cl.contentType, cl.contentId):null}>
                        {contentTypeMap[cl.contentType].show(cl.contentId,null, false, false)}
                    </div>);
                }
            }
        }

        return <Dialog
            open
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Verify Converted Content</DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onClose.bind(this, "update")} color="primary" variant="outlined">
                    Keep and Update Book
                </Button>
                <Button onClick={this.onClose.bind(this, "done")} color="primary" variant="outlined">
                    Keep and Don't Update Book
                </Button>
                <Button onClick={this.onClose.bind(this, "discard")} color="primary" variant="outlined">
                    Discard Converted Content
                </Button>
            </DialogActions>
            {this.state.editContent}
        </Dialog>;
    }

    onClose(action) {
        const newFragment = this.props.newFragment;
        if ((action == "update") && newFragment) {
            campaign.updateCampaignContent("books", newFragment);
            this.setState({loading:false});
            this.props.onClose();
        } else if ((action == "update") || (action == "discard")) {
            const t=this;
            campaign.batchUpdateCampaignContent((action == "update")?this.props.bookChangeList:this.props.undoList).then(function (){
                t.setState({loading:false});
                t.props.onClose();
            }, function (err) {
                t.setState({loading:false});
                displayMessage("Error making conversions. "+err.toString());
            });
            this.setState({loading:true});
        } else {
            this.props.onClose();
        }
    }

    onClickEditContent(contentType, contentId) {
        this.setState({showLinkList:false, editContent:contentTypeMap[contentType].edit(contentId, this.doneEditContent.bind(this))});
    }

    doneEditContent() {
        this.setState({editContent:null});
    }
}

const conversionList = ["Background", "Class", "Feat", "Item", "Monster", "Non-Player Character", "Companion Creature","Race", "Spell"];
const childConversionList = ["Backgrounds", "Classes", "Feats", "Items", "Monsters", "Monsters (strict)", "Races", "Spells"];

function convertBook(bookinfoRaw, selected) {
    const bookinfo = Object.assign({},bookinfoRaw);
    const chapters = (bookinfo.chapters||[]).concat([]);
    const changeList=[];
    const bookChangeList=[];
    const undoList=[];
    let flist;

    bookinfo.chapters = chapters;

    for (let ci in chapters) {
        const chapter=Object.assign({},chapters[ci]);
        const sections = (chapter.sections||[]).concat([]);
        const ckey="ch."+ci;
        chapters[ci]=chapter;
        chapter.sections = sections;

        const csel = selected[ckey]||{};
        flist=[];
    
        flist.push(chapter);

        for (let si in sections) {
            const section = Object.assign({}, sections[si]);
            const subsections = (section.subsections||[]).concat([]);
            const skey=ckey+".s."+si;
            sections[si]=section;
            section.subsections=subsections;

            const ssel = selected[skey]||{};
            if (ssel.type || csel.childtype ) {
                flist = [];
            }

            flist.push(section);

            for (let ssi in subsections) {
                const subsection = Object.assign({}, subsections[ssi]);
                const sskey = skey+".ss."+ssi;
                subsections[ssi]=subsection;

                if (!subsection.contentId && !subsection.contentList) {
                    const sel = selected[sskey]||{};
                    if (sel.type || ssel.childtype) {
                        flist = [subsection];
                        const startssi = Number(ssi)+1;
                        let nextssi = startssi;
                        while ((nextssi<subsections.length) && (subsections[nextssi].depth > (subsection.depth||0))) {
                            const subsubsection = Object.assign({}, subsections[nextssi]);
                            flist.push(subsubsection);
                            nextssi++;
                        }
                        const add = addEntry(sel.type?sel:ssel, flist);
                        if (add) {
                            Object.assign(subsection, add);
                            subsections.splice(startssi,nextssi-startssi);
                        }
                        flist=[];
                    } else {
                        flist.push(subsection);
                    }
                }
            }
            if (section.contentId || section.contentList) {
                flist=[];
            } else if (ssel.type || csel.childtype) {
                const add = addEntry(ssel.type?ssel:csel, flist);
                if (add) {
                    Object.assign(section, add);
                    delete section.subsections;
                }
                flist=[];
            }
        }
        if (csel.type) {
            const add = addEntry(csel, flist);
            if (add) {
                Object.assign(chapter, add);
                delete chapter.sections;
            }
        }
    }
    bookChangeList.push({contentType:"books", value:bookinfo});

    return {changeList, bookChangeList,undoList};

    function addEntry(sel, list) {
        const convertDuplicates = campaign.getUserSettings().convertDuplicates || "ignore";
        const bookPartialChanges=[];
        let doUpdate = (convertDuplicates=="update");
        let features=[];
        let addIt;
        let addType;
        let contentInfo;
        let saveAbilities;
        let foundSaves;
        const type = sel.type || sel.childtype;

        for (let i in list) {
            bookPartialChanges.push({contentType:"books", value:{name:list[i].fragment, type:"fragment"}});
            const feature = sectionToFeature(list[i]);
            if (i == 0) {
                const html = feature.entries && feature.entries.length && feature.entries[0] && feature.entries[0].html;
                if (html) {
                    const {baseHTML, embededFeatures, abilities,saves} = listFromHtml(html, type);
                    feature.entries=[{type:"html", html:baseHTML}];
                    features.push(feature);
                    features = features.concat(embededFeatures);
                    if (abilities) {
                        saveAbilities =abilities;
                        foundSaves = saves;
                    }
                } else {
                    features.push(feature)
                }

            } else {
                features.push(feature);
            }
        }

        if (!features.length) {
            return;
        }
        switch (type) {
            case "Background":
            case "Backgrounds": {
                addIt = {name:campaign.newUid(), features, displayName:features[0].name, gamesystem:campaign.defaultGamesystem};
                addType = "backgrounds";
                if (features[0].entries && features[0].entries.length && features[0].entries[0]) {
                    addIt.description = features[0].entries[0];
                    features.shift();
                } else {
                    features.shift();
                }
                const equipment = matchFeatures(addIt.features, "Background", addIt.displayName, null, 0, null);
                if (equipment) {
                    addIt.startingEquipment = equipment;
                }

                contentInfo={contentId:addIt.name, contentType:"Backgrounds"}
                break;
            }

            case "Feat":
            case "Feats":{
                addIt = {name:campaign.newUid(), features, displayName:features[0].name, gamesystem:campaign.defaultGamesystem};
                addType = "feats";
                if (features[0].entries && features[0].entries.length && features[0].entries[0] && features[0].entries[0].html) {
                    delete features[0].name;
                } else {
                    features.shift();
                }
                // check for prerequisite
                const {match, index, value} = findFeature("prerequisite", features);
                if (match) {
                    addIt.prerequisites = value;
                    features.splice(index,1);
                } else {
                    const firstLine = getFirstLine(features[0]);
                    if (firstLine) {
                        const pre = parsePrerequisite(firstLine);
                        if (pre) {
                            addIt.prerequisites=pre.prerequisites;
                            if (pre.keywords) {
                                addIt.keywords = pre.keywords;
                            }
                            deleteFirstLine(features[0]);
                        }
                    }
                }

                matchFeatures(addIt.features, "Feat", addIt.displayName, null, 0, addIt.displayName);
               
                contentInfo={contentId:addIt.name, contentType:"Feats"}
                break;
            }

            case "Custom":{
                features = expandFeatures(features);
                addIt = {name:campaign.newUid(), id:campaign.newUid(), features, displayName:(features[0].name||"").trim(), type:sel.customType, gamesystem:campaign.defaultGamesystem};
                addType = "customTypes";
                if (features[0].entries && features[0].entries.length && features[0].entries[0] && features[0].entries[0].html) {
                    delete features[0].name;
                } else {
                    features.shift();
                }
                // check for prerequisite

                const {match, index, value} = findFeature("prerequisite", features);
                if (match) {
                    addIt.prerequisites = value;
                    features.splice(index,1);
                } else {
                    const firstLine = getFirstLine(features[0]);
                    if (firstLine) {
                        const pre = parsePrerequisite(firstLine);
                        if (pre) {
                            addIt.prerequisites=pre.prerequisites;
                            if (pre.keywords) {
                                addIt.keywords = pre.keywords;
                            }
                            deleteFirstLine(features[0]);
                        }
                    }
                }
                {
                    const firstLine = getFirstLine(features[0]);
                    if (firstLine) {
                        const keywords = parseKeywords(firstLine);
                        if (keywords) {
                            addIt.keywords = keywords.split(",").map(function (a){return a.trim()});
                            deleteFirstLine(features[0]);
                        }
                    }
                }
                matchFeatures(addIt.features, sel.customType, addIt.displayName, null, 0, addIt.displayName);
                contentInfo={contentId:{name:addIt.name, id:addIt.id}, contentType:sel.customType};
                break;
            }

            case "Race":
            case "Races":{
                const raceName = features[0].name;
                let description;
                if (features[0].entries && features[0].entries.length && features[0].entries[0] && features[0].entries[0].html) {
                    description = features[0].entries[0];
                    features.shift();
                } else {
                    features.shift();
                }
                const race = campaign.getRaceInfo(sel.race);
                matchFeatures(features, "Race", race?race.displayName:raceName, race?raceName:null, 0, null);
                if (race) {
                    addIt = Object.assign({size:"M"}, race);
                    addIt.name=campaign.newUid();
                    addIt.baserace = sel.race;
                    addIt.raceFeatures = (addIt.raceFeatures||[]).concat(features);
                    addIt.displayName=raceName;
                } else {
                    addIt = {name:campaign.newUid(),size:"M", raceFeatures:features, displayName:raceName, gamesystem:campaign.defaultGamesystem};
                }
                if (description) {
                    addIt.description = description;
                }
                addType = "races";
                contentInfo={contentId:addIt.name, contentType:"Races"}
                break;
            }

            case "Non-Player Character":
            case "Companion Creature":
            case "Monster":
            case "Monsters (strict)":
            case "Monsters":{
                //console.log("monster features", features.concat([]));
                const displayName=features[0].name;
                delete features[0].name;
                let trait, action, bonus, reaction, legendary, lairActions, regionalEffects;

                addIt = {
                    name:campaign.newUid(), 
                    displayName, 
                    size: "M",
                    type: {type: "humanoid"},
                    alignment: ["A"],
                    ac: [10],
                    hp: {average: 4,formula: "1d8"},
                    speed: {walk: 30},
                    str: 10, dex: 10, con: 10, int: 10, wis: 10, cha: 10,
                    passive: 10,
                    cr: "0",
                    gamesystem:campaign.defaultGamesystem
                };
                if (saveAbilities) {
                    Object.assign(addIt, saveAbilities);
                    addIt.passive = 5 + Math.floor(Number(addIt.wis)/2);
                }

                if (type == "Non-Player Character") {
                    addIt.unique = true;
                    addIt.hp={curHP:1, maxHP:1};
                } else if (type == "Companion Creature") {
                    addIt.companion=true;
                }

                // check for size type alignment
                {
                    const firstLine = getFirstLine(features[0]);
                    if (firstLine) {
                        const ms = parseMonsterSize(firstLine);
                        if (ms) {
                            addIt.size=ms.size;
                            addIt.type=ms.type;
                            addIt.alignment=ms.alignment;
                            if (ms.sizeNotes) {
                                addIt.sizeNotes = ms.sizeNotes;
                            }
                            deleteFirstLine(features[0]);
                        } else if (type=="Monsters (strict)") {
                            return null;
                        }
                    } else if (type=="Monsters (strict)") {
                        return null;
                    }
                }
                // check for ac
                {
                    const {match, index, value} = findFeature("armor class", features,"ac");
                    if (match) {
                        const ac = parseAC(value);
                        if (ac) {
                            if (ac.damageThreshold) {
                                addIt.damageThreshold=ac.damageThreshold;
                                delete ac.damageThreshold;
                            }
                            addIt.ac=[ac];
                            features.splice(index,1);
                        }
                    }
                }
                // check for fixed initiative
                {
                    const {match, index, value} = findFeature("initiative", features);
                    if (match) {
                        if (!isNaN(value)) {
                            addIt.fixedInitiative = Number(value);
                            features.splice(index,1);
                        }
                    }
                }
                // check for damage threshold
                {
                    const {match, index, value} = findFeature("damage threshold", features);
                    if (match) {
                        addIt.damageThreshold = value;
                        features.splice(index,1);
                    }
                }
                // check for hp
                {
                    const {match, index, value} = findFeature("hit points", features,"hp");
                    if (match) {
                        let hp;
                        if (type != "Non-Player Character") {
                            hp = parseHP(value);
                        } else {
                            if (!isNaN(value) && Number(value)) {
                               hp = {curHP:Number(value), maxHP:Number(value)};
                            } else {
                                const {average} = parseHP(value)||{};
                                if (average) {
                                    hp = {curHP:Number(average), maxHP:Number(average)};
                                }
                            }
                        }
                        if (hp) {
                            addIt.hp=hp;
                            features.splice(index,1);
                        }
                    }
                }

                // check for speed
                {
                    const {match, index, value} = findFeature("speed", features);
                    if (match) {
                        const speed = parseSpeed(value);
                        if (speed) {
                            addIt.speed=speed;
                            features.splice(index,1);
                        }
                    }
                }
                // check for senses
                {
                    const {match, index, value} = findFeature("senses", features);
                    if (match) {
                        const sret = parseSenses(value);
                        if (sret) {
                            addIt.senses=sret.senses;
                            addIt.passive=sret.passive;
                            features.splice(index,1);
                        }
                    }
                }
                // check for passive/stealth
                {
                    const {match, index, value} = findFeature("perception", features);
                    if (match) {
                        const sret = parsePassive(value);
                        if (sret) {
                            if (sret.passive) {
                                addIt.passive=sret.passive;
                            }
                            if (sret.stealth) {
                                addIt.stealth=sret.stealth;
                            }
                            if (sret.stealthNotes) {
                                addIt.stealthNotes=sret.stealthNotes;
                            }
                            features.splice(index,1);
                        }
                    }
                }
                // check for skills
                {
                    const {match, index, value} = findFeature("skills", features);
                    if (match) {
                        const skills = parseSkills(value);
                        if (skills) {
                            addIt.skill=skills;
                            features.splice(index,1);
                        }
                    }
                }
                // check for environment
                {
                    let {match, index, value} = findFeature("environment", features);
                    if (!match) {
                        const e = findFeature("environments", features);
                        match=e.match;
                        index=e.index;
                        value=e.value;
                    }
                    if (match) {
                        const environment = parseEnvironment(value);
                        if (environment) {
                            addIt.environment=environment;
                            features.splice(index,1);
                        }
                    }
                }
                // check for saving throws
                if (foundSaves && Object.keys(foundSaves).length) {
                    addIt.save = foundSaves;
                }
                {
                    const {match, index, value} = findFeature("saving throws", features);
                    if (match) {
                        const save = parseSavingThrows(value);
                        if (save) {
                            addIt.save=save;
                            features.splice(index,1);
                        }
                    }
                }
                addFeatureString(addIt, features, "languages", "languages");
                addFeatureStringArray(addIt, features, "damage vulnerabilities", "vulnerable");
                addFeatureStringArray(addIt, features, "damage resistances", "resist");
                addFeatureStringArray(addIt, features, "damage immunities", "immune");
                addFeatureStringArray(addIt, features, "condition immunities", "conditionImmune");

                addFeatureStringArray(addIt, features, "vulnerable", "vulnerable");
                addFeatureStringArray(addIt, features, "resistant", "resist");
                addFeatureStringArray(addIt, features, "immune", "immune");

                addFeatureStringArray(addIt, features, "vulnerabilities", "vulnerable");
                addFeatureStringArray(addIt, features, "resistances", "resist");
                addFeatureStringComboArray(addIt, features, "immunities", "immune", "conditionImmune");
                
                // check for challenge
                {
                    const {match, index, value} = findFeature("challenge", features,"cr");
                    if (match) {
                        const cr = parseCR(value);
                        if (cr) {
                            addIt.cr=cr;
                            features.splice(index,1);
                        }
                    } else {
                        checkMonsterNameForCR(addIt);
                    }
                }
                for (let i in features) {
                    const f = features[i];
                    let extraHtml="";
                    if (f.entries) {
                        f.entries.map(function (e) {if (e && e.html) {extraHtml=extraHtml+e.html}});
                    }

                    if (f.name) {
                        const lowerName = f.name.toLowerCase();

                        if (lowerName.startsWith("action")) {
                            action=(action||"")+extraHtml;
                        } else if (lowerName.startsWith("bonus action")) {
                            bonus=(bonus||"")+extraHtml;
                        } else if (lowerName.startsWith("reaction")) {
                            reaction=(reaction||"")+extraHtml;
                        } else if (lowerName.startsWith("legendary")) {
                            legendary=(legendary||"")+extraHtml;
                        } else if (lowerName.startsWith("lair")) {
                            lairActions=(lairActions||"")+extraHtml;
                        } else if (lowerName.startsWith("regional")) {
                            regionalEffects=(regionalEffects||"")+extraHtml;
                        } else if (lowerName.startsWith("trait")) {
                            trait=(trait||"")+extraHtml;
                        } else {
                            trait=(trait||"")+"<p><b>"+f.name+"</b></p>"+extraHtml;
                        }
                    } else {
                        trait = (trait||"")+extraHtml;
                    }
                }

                addIt.crsort = getCRSortFromCR(addIt.cr);

                if (addIt.guess) {
                    guessMonsterProficiencies(addIt,[trait,action]);
                }
                addIt.trait = {type: "html", html:boldInitial(trait||"")};

                if (action) {
                    addIt.action={type:"html", html:boldInitial(action)}
                }
                if (bonus) {
                    addIt.bonusaction={type:"html", html:boldInitial(bonus)}
                }
                if (reaction) {
                    addIt.reaction={type:"html", html:boldInitial(reaction)}
                }
                if (legendary) {
                    addIt.legendary={type:"html", html:boldInitial(legendary)}
                }
                if (lairActions) {
                    addIt.lairActions={type:"html", html:boldInitial(lairActions)}
                }
                if (regionalEffects) {
                    addIt.regionalEffects={type:"html", html:boldInitial(regionalEffects)}
                }
                addType="monsters";
                contentInfo={contentId:addIt.name, contentType:"Monsters", name:null}
                break;
            }
            
            case "Spell":
            case "Spells":{
                const displayName=features[0].name;
                delete features[0].name;
                addIt = {name:campaign.newUid(), displayName,level:0,
                    time:{number:1,unit:"action"},
                    range:"Touch",
                    school:"E",
                    components:{s:true, v:true},
                    duration:"Instantaneous",
                    classes:[],
                    gamesystem:campaign.defaultGamesystem
                };
                addType="spells";
                // check for level/school
                {
                    const firstLine = getFirstLine(features[0]);
                    if (firstLine) {
                        const ls = parseLevelSchool(firstLine);
                        if (ls) {
                            addIt.schoolName=ls.school;
                            addIt.level=ls.level;
                            if (ls.ritual){
                                addIt.ritual=true;
                            }
                            if (ls.classes) {
                                addIt.classes = ls.classes;
                            }
                            if (ls.spellSources) {
                                addIt.spellSources = ls.spellSources;
                            }
                            deleteFirstLine(features[0]);
                        }
                    }
                }
                // check for classes
                {
                    const {match, index, value} = findFeature("classes", features);
                    if (match) {
                        addIt.classes = (addIt.classes||[]).concat(value.split(",").map(function(a){return a.trim()}));
                        features.splice(index,1);
                    }
                }
                // check for duration
                {
                    const {match, index, value} = findFeature("duration", features);
                    if (match) {
                        addIt.duration = value;
                        features.splice(index,1);
                    }
                }
                // check for range
                {
                    const {match, index, value} = findFeature("range",features);
                    if (match) {
                        addIt.range = value;
                        features.splice(index,1);
                    }
                }
                // check for components
                {
                    const {match, index, value} = findFeature("components",features);
                    if (match) {
                        addIt.components = parseComponents(value);
                        features.splice(index,1);
                    }
                }
                // check for casting time
                {
                    const {match, index, value} = findFeature("casting time",features);
                    if (match) {
                        addIt.time = parseCastingTime(value);
                        if (addIt.time.ritual) {
                            addIt.ritual=true;
                            delete addIt.time.ritual;
                        }
                        features.splice(index,1);
                    }
                }
                addIt.entries=[{type: "html", html:combineHtml(features)}];
                contentInfo={contentId:addIt.name, contentType:"Spells"}
                break;
            }

            case "Item":
            case "Items":{
                const displayName=features[0].name;
                delete features[0].name;
    
                addIt = {name:campaign.newUid(), displayName, type: "G", rarity: "Common", gamesystem:campaign.defaultGamesystem};
                addType="items";


                // check for item type
                {
                    const firstLine = getFirstLine(features[0]);
                    if (firstLine) {
                        const itInfo = parseItemType(firstLine);
                        if (itInfo) {
                            Object.assign(addIt, itInfo);
                            deleteFirstLine(features[0]);
                        }
                    }
                }
                // check for Weapon Category
                {
                    const {match, index, value} = findFeature("weapon category",features);
                    if (match) {
                        const weaponCategory = matchType(value, ["Martial","Simple"]);
                        if (weaponCategory) {
                            addIt.weaponCategory = weaponCategory;
                            features.splice(index,1);
                        }
                    }
                }

                // check for weight
                {
                    const {match, index, value} = findFeature("weight",features);
                    if (match) {
                        addIt.weight = value.replace("lbs.","").replace("lbs","");
                        features.splice(index,1);
                    }
                }
                // check for value
                {
                    const {match, index, value} = findFeature("value",features);
                    if (match) {
                        addIt.value = value;
                        features.splice(index,1);
                    }
                }
                // check for damage
                {
                    const {match, index, value} = findFeature("damage",features);
                    if (match) {
                        if (parseItemDamage(addIt, value)) {
                            features.splice(index,1);
                        }
                    }
                }
                // check for quantity
                {
                    const {match, index, value} = findFeature("quantity",features);
                    if (match && !isNaN(value)) {
                        addIt.quantity = Number(value);
                        features.splice(index,1);
                    }
                }

                addIt.entries=[{type: "html", html:combineHtml(features)}];
                contentInfo={contentId:addIt.name, contentType:"Items"}

                break;
            }

            case "Class":
            case "Classes":{
                const classFeatures= [];
                addIt = {name:campaign.newUid(), displayName:features[0].name,hd:{faces:6, number:1},proficiency:[],startingProficiencies:{},classFeatures, gamesystem:campaign.defaultGamesystem};
                addType = "classes";
                let hint;
                const htmlDescription = features[0].entries && features[0].entries.length && features[0].entries[0] && features[0].entries[0].html;
                features.shift();
                if (htmlDescription) {
                    addIt.description={type:"html", html:htmlDescription};
                }
                if (sel.class) {
                    addIt.className=sel.class;
                    addIt.subclassName=addIt.name;
                } else {
                    addIt.className=addIt.name;
                }

                let curLevel=1;
                for (let i in features) {
                    const f = features[i];
                    let handled;
                    const nl = findLevelInFeature(f);
                    if (nl && (nl > curLevel)) {
                        curLevel = nl;
                    }

                    if (curLevel==1 && f.name) {
                        const lowerName = f.name.toLowerCase();

                        if (lowerName.startsWith("quick build") || lowerName.startsWith("creating a")) {
                            handled=true;
                            let extraHtml="";
                            if (f.entries) {
                                f.entries.map(function (e) {if (e && e.html) {extraHtml=extraHtml+e.html}});
                            }
                            hint=(hint||"")+"<p><b>"+f.name+"</b></p>"+extraHtml;
                        }
                    }

                    if (!handled) {
                        if (!classFeatures[curLevel-1]) {
                            classFeatures[curLevel-1]=[];
                        }
                        classFeatures[curLevel-1].push(f);
                    }
                }
                if (hint) {
                    addIt.abilitiesHint = {type:"html", html:hint};
                }

                const classInfo = addIt.subclassName?campaign.getClassInfo(addIt.className):null;
                for (let x=0; x < 20; x++) {
                    features = classFeatures[x];
                    matchFeatures(features, addIt.subclassName?"Subclass":"Class", classInfo?classInfo.displayName:addIt.displayName, classInfo?addIt.displayName:null, x+1, null);
                }

                contentInfo={contentId:{className:addIt.className, subclassName:addIt.subclassName||null}, contentType:"Classes"}
                break;
            }

            default:{
                break;
            }
        }

        const cm = contentTypeMap[contentInfo.contentId.className?"Classes":contentInfo.contentType];
        let dupIt;
        let dupId;
        if (cm && cm.dupCheck) {
            const {dup,id} = cm.dupCheck(addIt);
            if (dup) {
                switch (convertDuplicates) {
                    case "ignore":{
                        const copyAtt = ["tokenArt", "artList", "defaultArt", "defaultToken"];
                        for (let a of copyAtt) {
                            if (dup[a]) {
                                addIt[a]=dup[a];
                            }
                        }
                        break;
                    }
                    case "update":{
                        delete addIt.name;
                        delete addIt.id;
                        dupIt = Object.assign({}, dup);
                        Object.assign(dupIt, addIt);
                        undoList.push({contentType:addType, value:dup, name:dup.name});
                        break;
                    }
                    default:
                    case "current":{
                        addType=null;
                        dupIt=dup;
                        break;
                    }
                }
                if (convertDuplicates != "ignore") {
                    dupId =id;
                    contentInfo={contentId:dupId, contentType:contentInfo.contentType};
                }
            }
        }

        for (let p of bookPartialChanges) {
            bookChangeList.push(p);
        }

        if (dupIt) {
            changeList.push({contentType:addType, value:dupIt, contentInfo});
        } else {
            changeList.push({contentType:addType, value:addIt, contentInfo});
            undoList.push({contentType:addType, delete:true, name:addIt.name});
        }
        return contentInfo;
    }

    function sectionToFeature(section) {
        const feature = {name:replaceOddChars(section.name), usage:{type:"s"}};
        const bf = campaign.getBookFragment(section.fragment);
        if (bf) {
            feature.entries=[bf.entry];
        }
        return feature;
    }

    function combineHtml(features) {
        let html = "";
        for (let i in features) {
            const f = features[i];
            if (f.name) {
                html = html+"<p><b>"+f.name+"</b></p>";
            }
            if (f.entries) {
                f.entries.map(function (e) {if (e && e.html) {html=html+(e.html||"")}});
            }
        }
        return html;
    }
    
}


function convertList(fragment, type, target) {
    const wrapper= document.createElement('div');
    let baseHTML=""
    const changeList=[];
    const undoList=[];

    wrapper.innerHTML= fragment.entry.html;

    for ( const child of wrapper.childNodes ) {
        if (child.nodeType == 1 /*ELEMENT_NODE*/) {
            const htmlChild = child.outerHTML;
            const nodeName = child.nodeName;
            const innerText = child.innerText;
            if (innerText == "") {
                // skip empty lines
            } else if (nodeName=="P") {
                let text = replaceOddChars(innerText);
                let trail = "";
                const pPos = text.indexOf(" (");
                if (pPos >=0) {
                    trail=text.substr(pPos);
                    text = text.substr(0,pPos);
                }
                const href = checkList(text);
                if (href) {
                    baseHTML += '<p><a href="'+href+'">'+text+'</a>'+trail+'</p>';
                } else {
                    baseHTML+=htmlChild;
                }
            } else {{changeList, undoList, newFragment}
                baseHTML+=htmlChild;
            }
        } else if (child.nodeType == "3") {
            baseHTML+=child.nodeValue;
        }
    }
    const newFragment = Object.assign({}, fragment);
    newFragment.entry={type:"html", html:baseHTML}
    return {changeList, undoList, newFragment};

    function checkList(textFull) {
        const text = textFull.trim().toLowerCase();
        if (["Spellclass","Spellsource"].includes(type)) {
            const spells = campaign.getAllSpells();
            for (let i in spells) {
                const it = spells[i];
                if (it.displayName.toLowerCase().trim() == text) {
                    const newit = Object.assign({},it);
                    let lowerTarget = target.toLowerCase();
                    let changed;

                    if (type == "Spellsource") {
                        if (!it.spellSources || !it.spellSources.find(function (a){return (a||"").toLowerCase()==lowerTarget})) {
                            newit.spellSources = (it.spellSources||[]).concat([target]);
                            changed=true;
                        }
                    } else if (!it.classes || !it.classes.find(function (a){return (a||"").toLowerCase()==lowerTarget})) {
                        newit.classes = (it.classes||[]).concat([target]);
                        changed=true;
                    }
                    if (changed) {
                        changeList.push({contentType:"spells", value:newit});
                        undoList.push({contentType:"spells", value:it});
                    }
                    return "#spell?id="+encodeURIComponent(it.name);
                }
            }
        } else if (type == "Environment") {
            const monsters= campaign.getAllMonsters();
            for (let i in monsters) {
                const it = monsters[i];
                if (it.displayName.toLowerCase().trim() == text) {
                    if (!it.environment || !it.environment.find(function (a){return (a||"").toLowerCase()==target.toLowerCase()})) {
                        const newit = Object.assign({},it);
                        newit.environment = (it.environment||[]).concat([target]);
                        changeList.push({contentType:"monsters", value:newit});
                        undoList.push({contentType:"monsters", value:it});
                    }
                    return "#monster?id="+encodeURIComponent(it.name);
                }
            }
        }
        return null;
    }
}

function guessMonsterProficiencies(mon,texts) {
    const pb = Number(getProficiency(mon));
    const cr = Math.min(30,Math.trunc(mon.crsort));
    const ap = {};
    let maxScore = 0;

    for (let action of texts) {
        if (action) {
            const wrapper= document.createElement('div');
            wrapper.innerHTML= action;
            const features = findFeatures(wrapper);
            for (const i in  features) {
                const f = features[i];
                labelDieRolls(f.node);
                const text = f.node.textContent;
                const attackType = findAttackType(text);
                const spellAbility = findSpellcasting(text);
                let profAbility;
                if (attackType) {
                    const attack=findAttack(f.node,f);
                    const abilities = (attackType=="spell")?(spellAbility?[spellAbility]:["cha", "wis", "int"]):["dex","int"];
                    //console.log("found attack", attackType, attack, text);
                    if (attack.attackRoll != null) {
                        profAbility = attackAbilityIncludesProficiency(mon, attack.attackRoll, abilities);
                    }
                }
                
                if (spellAbility) {
                    const abilities = [spellAbility];
                    const save=findSpellSave(text);
                    const spellAttack = findSpellAttack(text);
                    //console.log("found spellcasting", spellAbility, save, spellAttack, text);
                    if (spellAttack) {
                        profAbility |= attackAbilityIncludesProficiency(mon, spellAttack, abilities);
                    }
                    if (save) {
                        profAbility |= dcAbilityIncludesProficiency(mon, save, abilities);
                    }
                }
                if (profAbility) {
                    maxScore = Math.max(maxScore, mon[profAbility]);
                    ap[profAbility]=true;
                }
            }
        }
    }

    for (let a of abilitiesValues) {
        const as = Number(mon[a]);
        if (as > 30){
            ap[a]=true;
        }
        if (((as-pb*2)>11) && ["con", "wis", "cha", "int"].includes(a) && (as > (maxScore-pb*2))) {
            ap[a]=true;
        }
    }

    for (let a in ap) {
        mon[a] = mon[a]-pb*2;
        if (!mon.save) {
            mon.save={};
        }
        mon.save[a]=Math.floor(mon[a]/2)-5+pb;
    }
}

function attackAbilityIncludesProficiency(mon, attack, abilities) {
    const pb = Number(getProficiency(mon));
    attack = Number(attack);
    for (let a of abilities) {
        const as = Number(mon[a]||10);
        const ab = Math.floor(as/2)-5;
        if (attack == ab) {
            return a;
        }
    }
}

function dcAbilityIncludesProficiency(mon, dc, abilities) {
    const pb = Number(getProficiency(mon));
    dc = Number(dc);
    for (let a of abilities) {
        const as = Number(mon[a]||10);
        const ab = Math.floor(as/2)-5;
        if (dc == (8+ab)) {
            return a;
        }
    }
}

function boldInitial(html) {
    html = labelSpells(html);
    html = labelConditions(html);
    const wrapper= document.createElement('div');
    let changed;
    wrapper.innerHTML= html;

    const list=wrapper.getElementsByTagName("p");
    for (let i=0; i<list.length; i++){
        const n = list[i];
        const fc = n.firstChild;
        if (fc && fc.nodeType == 3){
            const fullText = fc.nodeValue;
            const pos = fullText.indexOf(". ");
            if ((pos >=0) && (pos<40)) {
                fc.nodeValue = fullText.substr(pos+1);
                const add = document.createElement('b');
                const addI = document.createElement('i');
                addI.append(fullText.substr(0,pos+1))
                add.prepend(addI);
                n.prepend(add);
                changed=true;
            }
            //console.log("found a plain node", fc.nodeValue);
        }
    }
    if (changed) {
        //console.log("changed html", html, wrapper.innerHTML);
        return wrapper.innerHTML;
    }
    return html;
}

function labelSpells(html) {
    if (!(/spell/i).test(html)) {
        return html;
    }
    const {spellMap, spellRegex} = getSpellRegex();

    const wrapper= document.createElement('div');
    wrapper.innerHTML = html;
    internalLabel(wrapper);
    //console.log("modified html", wrapper.innerHTML);
    return wrapper.innerHTML;

    function internalLabel(node) {
        let child = node.firstChild;
        while (child) {
            let next = child.nextSibling;
            if (child.nodeType==3) {
                const text = child.textContent;
                if (text && text.length) {
                    const [sn] = (text).match(spellRegex)||[];
                    if (sn) {
                        //console.log("found spell", spell, child.textContent);

                        const pos = text.indexOf(sn);
                        let start = text.substr(0,pos);
                        let end = text.substr(pos+sn.length);
                        const spell = spellMap[sn.trim().toLowerCase()];
                        const link = document.createElement('a');
                        link.textContent = sn;
                        link.href = "#spell?id="+encodeURIComponent(spell.name);
                        node.insertBefore(new Text(start), child);
                        node.insertBefore(link, child);
                        child.textContent = end;
                        next = child;
                    }
                }
            } else if (!["A","B","STRONG"].includes(child.nodeName)) {
                internalLabel(child);
            }
            child = next;
        }
    }
}

function labelConditions(html) {
    const {conditionsMap, conditionsRegex} = getConditionsRegex();

    const wrapper= document.createElement('div');
    let isPossibleCondition;
    wrapper.innerHTML = html;
    internalLabel(wrapper);
    //console.log("modified html", wrapper.innerHTML);
    return wrapper.innerHTML;

    function internalLabel(node) {
        let child = node.firstChild;
        while (child) {
            let next = child.nextSibling;
            if (child.nodeType==3) {
                const text = child.textContent;
                if (text && text.length) {
                    const [sn] = (text).match(conditionsRegex)||[];
                    if (sn) {

                        const pos = text.indexOf(sn);
                        let start = text.substr(0,pos);
                        let end = text.substr(pos+sn.length);
                        if (isPossibleCondition || (start && (/(immune,resistant).{1,20}$/i).test(start)) || (end && ((/^\s*(for|until)/i).test(end)||(/^.{1,60}(condition)/i).test(end)))) {
                            const it = conditionsMap[sn.trim().toLowerCase()];
                            const link = document.createElement('a');
                            link.textContent = sn;
                            link.href = "#customlist?type=Conditions&id="+encodeURIComponent(it.id);
                            node.insertBefore(new Text(start), child);
                            node.insertBefore(link, child);
                            isPossibleCondition=true;
                            child.textContent = end;
                            next = child;
                        }
                    }
                }
            } else if (!["A","B","STRONG"].includes(child.nodeName)) {
                internalLabel(child);
                if (child.nodeName=="P") {
                    isPossibleCondition=false;
                }
            }
            child = next;
        }
    }
}

function getSpellRegex() {
    const spells = campaign.getAllSpells();
    const {listMap,listRegex} = getListRegex(spells)
    //console.log("spellregex", listMap, listRegex)
    return {spellMap:listMap,spellRegex:listRegex };
}

function getConditionsRegex() {
    const list = campaign.getSortedCustomList("Conditions");
    const {listMap,listRegex} = getListRegex(list)
    //console.log("conditionsregex", listMap, listRegex)
    return {conditionsMap:listMap,conditionsRegex:listRegex };
}

function getListRegex(list) {
    const gs = campaign.defaultGamesystem;
    const listMap = {};

    for (let i in list) {
        const s =list[i];
        if (s.displayName && !(/[^\w\s\.]/.test(s.displayName))) {
            // if no special characters
            const dn = s.displayName.trim().toLowerCase();
            const existing = listMap[dn];
            if (!existing || ((s.gamesystem||"5e")==gs)) {
                listMap[dn]=s;
            }
        }
    }
    const names = Object.keys(listMap).sort(function (a,b) {return b.length-a.length});
    const listRegex = new RegExp("\\b("+names.join("|")+")\\b","i");
    return {listMap,listRegex };
}

function findLevelInFeature(feature) {
    const html = feature.entries && feature.entries[0] && feature.entries[0].html;
    if (!html) {
        return 0;
    }
    if (feature.name) {
        const lmatch = feature.name.match(/^level\s+\d{1,2}:/i);
        if (lmatch) {
            const l = feature.name.match(/\d{1,2}/);
            //console.log("found level:", l[0]);
            return Number(l[0]);
        }
    }
    const text =html.substr(0,Math.min(100,html.length))
    for (let i in levelMatches) {
        const lm=levelMatches[i];
        if (text.match(lm.filter)) {
            return lm.level;
        }
    }
    return 0;
}

const levelMatches=[
    {filter:/at (the )?10th level/i, level:10},
    {filter:/10th-level/i, level:10},
    {filter:/reach 10th/i, level:10},
    {filter:/at (the )?11th level/i, level:11},
    {filter:/11th-level/i, level:11},
    {filter:/reach 11th/i, level:11},
    {filter:/at (the )?12th level/i, level:12},
    {filter:/12th-level/i, level:12},
    {filter:/reach 12th/i, level:12},
    {filter:/at (the )?13th level/i, level:13},
    {filter:/13th-level/i, level:13},
    {filter:/reach 13th/i, level:13},
    {filter:/at (the )?14th level/i, level:14},
    {filter:/14th-level/i, level:14},
    {filter:/reach 14th/i, level:14},
    {filter:/at (the )?15th level/i, level:15},
    {filter:/15th-level/i, level:15},
    {filter:/reach 15th/i, level:15},
    {filter:/at (the )?16th level/i, level:16},
    {filter:/16th-level/i, level:16},
    {filter:/reach 16th/i, level:16},
    {filter:/at (the )?17th level/i, level:17},
    {filter:/17th-level/i, level:17},
    {filter:/reach 17th/i, level:17},
    {filter:/at (the )?18th level/i, level:18},
    {filter:/18th-level/i, level:18},
    {filter:/reach 18th/i, level:18},
    {filter:/at (the )?19th level/i, level:19},
    {filter:/19th-level/i, level:19},
    {filter:/reach 19th/i, level:19},
    {filter:/at (the )?20th level/i, level:20},
    {filter:/20th-level/i, level:20},
    {filter:/reach 20th/i, level:20},
    {filter:/at (the )?1st level/i, level:1},
    {filter:/1st-level/i, level:1},
    {filter:/reach 1st/i, level:1},
    {filter:/at (the )?2nd level/i, level:2},
    {filter:/2nd-level/i, level:2},
    {filter:/reach 2nd/i, level:2},
    {filter:/at (the )?3rd level/i, level:3},
    {filter:/3rd-level/i, level:3},
    {filter:/reach 3rd/i, level:3},
    {filter:/at (the )?4th level/i, level:4},
    {filter:/4th-level/i, level:4},
    {filter:/reach 4th/i, level:4},
    {filter:/at (the )?5th level/i, level:5},
    {filter:/5th-level/i, level:5},
    {filter:/reach 5th/i, level:5},
    {filter:/at (the )?6th level/i, level:6},
    {filter:/6th-level/i, level:6},
    {filter:/reach 6th/i, level:6},
    {filter:/at (the )?7th level/i, level:7},
    {filter:/7th-level/i, level:7},
    {filter:/reach 7th/i, level:7},
    {filter:/at (the )?8th level/i, level:8},
    {filter:/8th-level/i, level:8},
    {filter:/reach 8th/i, level:8},
    {filter:/at (the )?9th level/i, level:9},
    {filter:/9th-level/i, level:9},
    {filter:/reach 9th/i, level:9},
];

const spellHeadings=["duration","classes","range","components","casting time"];
const monsterHeadings = ["armor class","ac","hit points","hp","speed","senses","perception","languages","damage vulnerabilities","vulnerabilities","vulnerable", "damage resistances","resistances", "resistant","damage immunities","immunities","immune","condition immunities","challenge","cr","traits","actions", "bonus actions", "reactions","legendary actions", "lair actions","regional effects", "skills", "saving throws", "environment","environments","initiative","damage threshold"];
const itemHeadings = ["weapon category","weight", "quantity", "value", "damage"];
const groupHeadings = ["traits","actions", "bonus actions", "reactions","legendary actions", "lair actions","regional effects"];
const typeHeadings = {
    "Spells":spellHeadings,
    "Spell":spellHeadings,
    "Non-Player Character":monsterHeadings,
    "Companion Creature":monsterHeadings,
    "Monsters (strict)":monsterHeadings,
    "Monster":monsterHeadings,
    "Monsters":monsterHeadings,
    "Item":itemHeadings,
    "Items":itemHeadings,
};

const abilityPattern = /\s*str\s+dex\s+con\s+int\s+wis\s+cha\s*/i;
const scoresPattern = /\d{1,2}\s*\(.{1,3}\)\s*/g;
const modifierPattern = /[\-\+]\d{1,2}\s*/g;

function listFromHtml(html,type) {
    const headingList = typeHeadings[type];
    const features = [];
    const abilities = {};
    const foundSaves = {};
    let foundAbilities = false;
    let baseHTML="";
    const wrapper= document.createElement('div');
    wrapper.innerHTML= html;

    for ( const child of wrapper.childNodes ) {
        if (child.nodeType == 1 /*ELEMENT_NODE*/) {
            const htmlChild = child.outerHTML;
            const nodeName = child.nodeName;
            const innerText = child.innerText;
            const saves = {};
            const foundAbilityList = (headingList==monsterHeadings) && !foundAbilities && checkForAbilityList(child,saves);

            if (foundAbilityList) {
                Object.assign(abilities, foundAbilityList);
                Object.assign(foundSaves, saves);
                foundAbilities=true;
            } else if (innerText == "") {
                // skip empty lines
            } else if (type == "Custom") {
                baseHTML+=htmlChild;
            } else if ((["P","DIV"].includes(nodeName)) && (headingList==monsterHeadings) && !foundAbilities && abilityPattern.test(innerText||"") && child.nextSibling) {
                const innerT = replaceOddChars((child.nextSibling.innerText||""));
                const scores = innerT.match(scoresPattern);
                if (scores && scores.length == 6) {
                    abilities.str=parseAbilityVal(scores[0])||0;
                    abilities.dex=parseAbilityVal(scores[1])||0;
                    abilities.con=parseAbilityVal(scores[2])||0;
                    abilities.int=parseAbilityVal(scores[3])||0;
                    abilities.wis=parseAbilityVal(scores[4])||0;
                    abilities.cha=parseAbilityVal(scores[5])||0;
                    foundAbilities=true;
                    child.nextSibling.remove();
                } else {
                    const scores = innerT.match(modifierPattern);
                    if (scores && scores.length == 6) {
                        abilities.str=parseModifierVal(scores[0])||0;
                        abilities.dex=parseModifierVal(scores[1])||0;
                        abilities.con=parseModifierVal(scores[2])||0;
                        abilities.int=parseModifierVal(scores[3])||0;
                        abilities.wis=parseModifierVal(scores[4])||0;
                        abilities.cha=parseModifierVal(scores[5])||0;
                        foundAbilities=true;
                        abilities.guess=true;
                        child.nextSibling.remove();
                    } else {
                        baseHTML+=htmlChild;
                    }
                }
            } else if (["P","DIV"].includes(nodeName)) {
                let hdr = child.firstChild;
                while (hdr && (hdr.nodeType==3 /*TEXT_NODE*/) && (hdr.nodeValue.trim()=="")) {
                    hdr.remove();
                    hdr = child.firstChild;
                }
                const cnn = hdr && hdr.nodeName;
                if (["B","STRONG","I"].includes(cnn)) {
                    const delList = [hdr];
                    let mergeText = hdr.innerText;
                    let next = hdr;
                    while (next.nextSibling && ["B","STRONG","I"].includes(next.nextSibling.nodeName)) {
                        next = next.nextSibling;
                        delList.push(next);
                        mergeText+=next.innerText;
                    }

                    const description = removeEndPeriod(mergeText);
                    if ((!headingList && (child.innerText != description)) || (headingList||[]).includes(replaceOddChars(description.toLowerCase()))) {
                        delList.map(function (hdr) {hdr.remove();});
                        
                        const firstChild = child.firstChild;
                        if (firstChild && (firstChild.nodeType==3 /*TEXT_NODE*/)) {
                            firstChild.nodeValue = firstChild.nodeValue.trim();
                        }

                        features.push({name:description, usage:{type:"s"},noDiv:true, entries:[{type:"html", html:child.outerHTML}]});
                    } else {
                        baseHTML+=htmlChild;
                    }
                } else if (headingList && (cnn=="#text")) {
                    let text = replaceOddChars(hdr.nodeValue);
                    let found = false;
                    for (let i in headingList) {
                        const h = headingList[i];
                        if (text.length >= h.length) {
                            const check = text.substr(0, h.length).toLowerCase();
                            if (check==h) {
                                const ctext = text.substr(h.length);
                                if (!ctext.length || ctext.match(/^\W/)) {
                                    text = ctext;
                                    if (text.startsWith(".") || text.startsWith(":")) {
                                        text = text.substr(1);
                                    }
                                    while (text.startsWith(" ")) {
                                        text = text.substr(1);
                                    }
                                    hdr.nodeValue = text;
                                    features.push({name:h, usage:{type:"s"}, entries:[{type:"html",html:child.outerHTML}]});
                                    if (groupHeadings.includes(h)) {
                                        const entries = features[features.length-1].entries;
                                        while (child.nextSibling) {
                                            const cnext = child.nextSibling;
                                            const innerT = (cnext.innerText||"").toLowerCase();
                                            let found;
                                            for (let gh of groupHeadings) {
                                                if (innerT.startsWith(gh)) {
                                                    found=true;
                                                }
                                            }
                                            if (!found) {
                                                entries.push({type:"html", html:cnext.outerHTML});
                                                cnext.remove();
                                            }else {
                                                break;
                                            }
                                        }
                                    }
                                    found=true;
                                    break;
                                }
                            }
                        }
                    }
                    if (!found) {
                        baseHTML+=htmlChild;
                    }
                } else {
                    baseHTML+=htmlChild;
                }

            } else if (((nodeName=="TABLE") || (nodeName=="FIGURE")) && (headingList==monsterHeadings) && !foundAbilities) {
                const rows = child.getElementsByTagName("tr");
                foundAbilities = true;

                if ((rows.length==2) && (rows.item(1).innerText.trim() != "")) {
                    let headers = rows.item(0).getElementsByTagName("td");
                    if (!headers.length) {
                        headers = rows.item(0).getElementsByTagName("th");
                    }
                    let vals = rows.item(1).getElementsByTagName("td");
                    if (!vals.length) {
                        vals = rows.item(1).getElementsByTagName("th");
                    }
                    if ((headers.length ==6) && (vals.length==6)) {
                        for (let ai in abilitiesValues) {
                            if (foundAbilities) {
                                const a = abilitiesValues[ai];
                                const colability = headers.item(ai).innerText;
                                if (colability && (a == colability.toLowerCase().trim())) {
                                    const colval = parseAbilityVal(vals.item(ai).innerText);
                                    if (colval != null) {
                                        abilities[a]=colval;
                                    } else {
                                        foundAbilities = false;
                                    }
                                } else {
                                    foundAbilities = false;
                                }
                            }
                        }
                    } else {
                        foundAbilities = false;
                    }
                } else if (rows.length>=1) {
                    const headers = rows.item(0).getElementsByTagName("td");
                    //check for case where ability and value are in the same table row
                    if ((headers.length ==6)) {
                        for (let ai in abilitiesValues) {
                            if (foundAbilities) {
                                const a = abilitiesValues[ai];
                                const colability = headers.item(ai).innerText.toLowerCase().trim();
                                if (colability && colability.startsWith(a)) {
                                    const colval = parseAbilityVal(colability.substr(3).trim());
                                    if (colval != null) {
                                        abilities[a]=colval;
                                    } else {
                                        foundAbilities = false;
                                    }
                                } else {
                                    foundAbilities = false;
                                }
                            }
                        }
                    } else {
                        foundAbilities = false;
                    }
                } else {
                    foundAbilities = false;
                }
                if (!foundAbilities) {
                    baseHTML+=htmlChild;
                }
            } else {
                baseHTML+=htmlChild;
            }
        } else if (child.nodeType == "3") {
            baseHTML+=child.nodeValue;
        }
    }
    //console.log("features",features,baseHTML)
    return {baseHTML, embededFeatures:features, abilities:foundAbilities?abilities:null,saves:foundSaves};
}

function  checkForAbilityList(child, updateSaves) {
    const abilities = {};
    const saves = {};
    let cnext = child;
    const alist =["str", "dex", "con", "int", "wis", "cha"];
    let num=0;

    if (child.nodeName == "P") {
        for (let a of alist){
            if (!cnext || cnext.nodeName != "P") {
                return null;
            }

            const inner = cnext.innerText.toLowerCase().trim();
            if (inner == a) {
                cnext = cnext.nextSibling;
                if (!cnext || cnext.nodeName != "P") {
                    return null;
                }
                abilities[a] = parseAbilityVal(cnext.innerText);
                if (abilities[a] == null) {
                    return null;
                }
                num+=2;
            } else {
                if (inner.match(/^mod\s+save\s*$/i)) {
                    num++;
                    cnext = cnext.nextSibling;
                    if (!cnext || cnext.nodeName != "P") {
                        return null;
                    }
                    inner = cnext.innerText.toLowerCase();
                }
                if (!inner.startsWith(a)) {
                    return null;
                }
                const post = replaceOddChars(inner.substr(3));
                const modSave = post.match(/^\d\d?\s*(\+|-)\d\d?\s+(\+|-)\d\d?$/);
                if (modSave) {
                    const [as, bonus, saveBonus]= post.match(/(\+|\-)?\d\d?/g);
                    abilities[a]=Number(as);
                    if ((Number(bonus) == (Math.trunc(abilities[a]/2)-5)) && (Number(bonus)!=Number(saveBonus))) {
                        updateSaves[a]=Number(saveBonus);
                    }

                } else {
                    abilities[a] = parseAbilityVal(post);
                }
                if (abilities[a] == null) {
                    return null;
                }
                num++;
            }
            cnext = cnext.nextSibling;
        }
    } else if (["TABLE","FIGURE"].includes(cnext.nodeName)) {
        num++;
        do {
            cnext = cnext.nextSibling;
            num++;
            if (!cnext || (!["TABLE","FIGURE"].includes(cnext.nodeName) && (cnext.innerText.trim() != ""))) {
                //console.log("extra stuff found", cnext.innerText);
                return null;
            }
        } while (!["TABLE","FIGURE"].includes(cnext.nodeName));
        
        const tables = [child, cnext];
        const drows = [];
        for (let t of tables) {
            const rows = t.getElementsByTagName("tr");
            for (let i=0; i<rows.length; i++) {
                const it = rows.item(i).innerText;
                if (!it.match(/\s*mod\s*save\s*/i)) {
                    drows.push(it);
                }
                //console.log("found row", rows.item(i), `"${it}"`)
            }
        }
        if (drows.length != 6) {
            return null;
        }
        //console.log("data rows", num, drows);

        for (let i in alist){
            const a = alist[i];
            const d = drows[i].toLowerCase().trim();
            if (!d.startsWith(a)) {
                return null;
            }
            const post = replaceOddChars(d.substr(3));
            const modSave = post.match(/^\d\d?\s*(\+|-)\d\d?\s*(\+|-)\d\d?$/);
            if (modSave) {
                const [as, bonus, saveBonus]= post.match(/(\+|\-)?\d\d?/g);
                abilities[a]=Number(as);
                if ((Number(bonus) == (Math.trunc(abilities[a]/2)-5)) && (Number(bonus)!=Number(saveBonus))) {
                    updateSaves[a]=Number(saveBonus);
                }

            } else {
                return null;
            }
        }
    }

    for (let i=1; i<num; i++) {
        child.nextSibling.remove();
    }
    return abilities;
}


function parseAbilityVal(val) {
    if (!val) {
        return null;
    }

    const split = val.split("(");
    if (split.length < 2) {
        if (val.match(/^\s*(\+|-)\d{1,2}\s*$/)) {
            return parseModifierVal(val);
        }
        const vm = val.match(/^\s*\d{1,2}\b/);
        if (vm) {
            const n = vm[0].trim();
            if (isNaN(n)) {
                return null;
            }
            return Number(n);
        }
        return null;
    }
    const n = split[0].trim();
    if (isNaN(n)) {
        return null;
    }
    return Number(n);
}

function parseModifierVal(val) {
    if (!val) {
        return null;
    }
    const n = val.trim();
    if (isNaN(n)) {
        return null;
    }
    const num = Number(n)*2+10;
    if (num <1) {
        return 1;
    }
    return num;
}

function splitFeatures(feature) {
    if (!feature) {
         return null;
    }
    const html = (feature?.entries?.length==1) && feature.entries[0] && feature.entries[0].html;
    if (!html) {
        return null;
    }
    const wrapper= document.createElement('div');
    wrapper.innerHTML= html;
    const list = [];

    let acc = "", accF= Object.assign({}, feature);
    delete accF.entries;
    for (const node of wrapper.childNodes) {
        if (node.nodeName=="P") {
            let first = node.firstChild;
            if (first && ["I","B","STRONG"].includes(first.nodeName) && (/[:\.]\s*$/.test(first.innerText) || (node.innerText != first.innerText))) {
                accF.entries = [{html:acc, type:"html"}];
                list.push(accF);

                let name="";
                while (first && ["I","B","STRONG"].includes(first.nodeName) && !(/[:\.]\s*$/.test(name))) {
                    name += first.innerText;
                    node.removeChild(first);
                    first = node.firstChild;
                    if (first?.nodeName=="#text") {
                        let inner = first.textContent;
                        if (inner.startsWith(".")) {
                            inner=inner.substr(1);
                            if (inner.startsWith(" ")) {
                                inner=inner.substr(1);
                            }
                            first.textContent = inner;
                        }
                    }
                }

                name = name.trim();
                if (name.endsWith(".")) {
                    name = name.substr(0,name.length-1);
                }
                acc="";
                accF={usage:{type:"s"}, noDiv:1, name, id:campaign.newUid()};
            }
        }
        acc += node.outerHTML;
    }
    accF.entries = [{html:acc, type:"html"}];
    list.push(accF);
    return list;
}

function expandFeatures(features) {
    if (!features) {
        return features;
    }

    let newF=[];
    for (let f of features ) {
        const exf = splitFeatures(f);
        if (exf) {
            newF = newF.concat(exf);
        } else {
            newF.push(f);
        }
    }
    return newF;
}

function getFirstLine(feature) {
    if (!feature) {
         return null;
    }
    const html = feature.entries && feature.entries.length && feature.entries[0] && feature.entries[0].html;
    if (!html) {
        return null;
    }
    const wrapper= document.createElement('div');
    wrapper.innerHTML= html;
    const first = wrapper.firstChild;
    if (first && (first.nodeType == 1) && (first.nodeName=="P")) {
        return first.innerText;
    }
    return null;
}

function deleteFirstLine(feature) {
    const html = feature.entries && feature.entries.length && feature.entries[0] && feature.entries[0].html;
    if (!html) {
        return;
    }
    const wrapper= document.createElement('div');
    wrapper.innerHTML= html;
    const first = wrapper.firstChild;
    if (first && (first.nodeType == 1) && (first.nodeName=="P")) {
        first.remove();
        feature.entries=[{type:"html", html:wrapper.innerHTML}];
    }
}

function findFeature(name, features, name2) {
    name=name.toLowerCase();
    const reg = new RegExp(name2?`^(${name}|${name2})(\\b|$)`:`^${name}(\\b|$)`,"i");
    const index= features.findIndex(function (feature) {return reg.test(replaceOddChars((feature.name||"")))});
    if (index >=0) {
        let value = replaceOddChars(getFeatureText(features[index]));
        if (value.startsWith(": ")) {
            value = value.substr(2);
        } else if (value.startsWith(":")) {
            value = value.substr(1);
        }
        return {match:true, index, value};
    } else {
        return {match:false, index, value:""};
    }
}

function replaceOddChars(s) {
    if (s) {
        return s.replaceAll(String.fromCharCode(160)," ").replaceAll(String.fromCharCode(8722),"-").replaceAll(String.fromCharCode(8211),"-").trim();
    }
    return s;
}

function dumpString(s) {
    const res=[];
    for (let i=0; i<s.length; i++) {
        res.push(s.charAt(i)+"("+s.charCodeAt(i)+")" );
    }
    return res;
}

function getFeatureText(feature) {
    const html = feature.entries && feature.entries.length && feature.entries[0] && feature.entries[0].html;
    if (html) {
        const wrapper= document.createElement('div');
        wrapper.innerHTML= html;
        return wrapper.innerText;
    } else {
        return "";
    }
}

function parseComponents(text) {
    const parts = text.split(",");
    const components = {};

    let pos = parts.findIndex(function (p){return p.trim()=="V"});
    if (pos >=0) {
        components.v=true;
        parts.splice(pos,1);
    }
    pos = parts.findIndex(function (p){return p.trim()=="S"});
    if (pos >=0) {
        components.s=true;
        parts.splice(pos,1);
    }
    if (parts.length) {
        let m = parts.join(",").trim();
        if (m.startsWith("M")) {
            m= m.substr(1).trim();
        }
        if (m.startsWith("(")) {
            m=m.substr(1).trim();
        }
        if (m.endsWith(")")) {
            m=m.substr(0,m.length-1).trim();
        }
        components.m=m;
    }
    return components;
}

function parseCastingTime(text) {
    const time = {};
    const rit = text.match(/,?\s*(or)?\s*ritual/i);
    if (rit) {
        time.ritual = true;
        text = text.replace(rit[0],"");
    }
    if (text.match(/^(action|bonus action|reaction)/i)) {
        text = "1 "+text;
    }

    const parts = text.split(" ");

    if ((parts.length > 1) && (/[\d]*/.exec(parts[0]) ==parts[0])) {
        let unit;
        let len=2;

        if ((parts.length > 2) && (parts[1].toLowerCase() == "bonus")) {
            if (["action", "action,"].includes((parts[2].toLowerCase()))) {
                unit="bonus action";
                len=3;
            }
        } else {
            switch (parts[1].toLowerCase()) {
                case "action":
                case "action,":
                    unit="action";
                    break;
                case "reaction":
                case "reaction,":
                    unit="reaction"
                    break;
                case "minute":
                case "minute,":
                case "minutes":
                case "minutes,":
                    unit="minute";
                    break;
                case "hour":
                case "hour,":
                case "hours":
                case "hours,":
                    unit="hour";
                    break;
            }
        }
        if (unit) {
            time.number = Number(parts[0]);
            time.unit = unit;
            parts.splice(0, len);
        }
        if (parts.length) {
            time.condition = parts.join(" ");
        }
    } else {
        time.condition=text;
    }

    return time;
}

const knownClasses=["artificer","bard","barbarian","cleric","druid","fighter","monk","paladin","ranger","rogue","sorcerer","warlock","wizard"];

function parseLevelSchool(text) {
    if (!text) {
        return null;
    }
    text = text.trim();
    let lowertext = text.toLowerCase();
    let ritual=false;
    let level=-1;
    let classes = null;
    const schools=[];
    let spellSources = null;

    if (text.includes("[")) {
        let sc = text.split("[");
        text = sc[0].trim();
        classes = sc[1].replace(/\]/,"").trim().split(",").map(function (a){return a.trim()});
    }
    if (text.includes("|")) {
        let sc = text.split("|");
        text = sc[0].trim();
        classes = sc[1].trim().split(",").map(function (a){return a.trim()});
    }
    const s1 = text.replace(/;/,"(").split("(");
    if (s1.length >1) {
        for (let i=1; i<s1.length; i++) {
            const vals = s1[i].replace(/\)/,"").trim().split(",");
            for (let x in vals) {
                const it = vals[x].trim();
                if (["ritual","ritual only"].includes(it.toLowerCase())) {
                    ritual=true;
                } else if (knownClasses.includes(it.toLowerCase())) {
                    if (!classes) {
                        classes = [];
                    }
                    classes.push(it);
                } else {
                    schools.push(it);
                }
            }
        }

        text = s1[0].trim();
        lowertext = text.toLowerCase();
    }

    if (lowertext.endsWith("cantrip")) {
        const s = text.substr(0, text.length-7).trim();
        if (s.length) {
            if (!schools.length || lowerSchools.includes(s.toLowerCase())) {
                schools.unshift(s);
            } else {
                spellSources = parseSpellSources(s);
            }
        }
        level=0;
    }

    for (let i in circleMap){
        if (lowertext.startsWith(i)) {
            level = circleMap[i];
            let s = text.substring(i.length).trim();
            if (s.length) {
                if (s.toLowerCase().endsWith("ritual")) {
                    ritual = true;
                    s = s.substr(0, s.length-6).trim();
                }
                spellSources = parseSpellSources(s);
            }
        }
    }

    for (let i in levelMap){
        if (lowertext.startsWith(i)) {
            level = levelMap[i];
            const s = text.substring(i.length).trim();
            if (s.length) {
                schools.unshift(s);
            }
        }
    }
    if ((level >=0) || schools.length || ritual) {
        return {level, school:schools.join(", "), ritual, classes, spellSources}
    }
    return null;
}

const lowerSchools = ["abjuration","evocation", "illusion", "divination", "necromancy", "transmutation", "conjuration", "psionic"];

function parseSpellSources(s) {
    const ret= s.replace(/ and /,",").trim().split(",").map(function(t){return t.trim()});
    //console.log("parse spell sources", s, ret);
    return ret;
}

const levelMap = {
    "1st-level":1,
    "1st level":1,
    "level 1":1,
    "2nd-level":2,
    "2nd level":2,
    "level 2":2,
    "3rd-level":3,
    "3rd level":3,
    "level 3":3,
    "4th-level":4,
    "4th level":4,
    "level 4":4,
    "5th-level":5,
    "5th level":5,
    "level 5":5,
    "6th-level":6,
    "6th level":6,
    "level 6":6,
    "7th-level":7,
    "7th level":7,
    "level 7":7,
    "8th-level":8,
    "8th level":8,
    "level 8":8,
    "9th-level":9,
    "9th level":9,
    "level 9":9,
}

const circleMap = {
    "1st-circle":1,
    "1st circle":1,
    "2nd-circle":2,
    "2nd circle":2,
    "3rd-circle":3,
    "3rd circle":3,
    "4th-circle":4,
    "4th circle":4,
    "5th-circle":5,
    "5th circle":5,
    "6th-circle":6,
    "6th circle":6,
    "7th-circle":7,
    "7th circle":7,
    "8th-circle":8,
    "8th circle":8,
    "9th-circle":9,
    "9th circle":9
}

function parseItemType(text) {
    const itInfo={rarity:"None"};

    if (text.startsWith("Magical ")) {
        // strip off magical
        text = text.substr(8);
        itInfo.rarity = "Common";
    } else if (text.startsWith("Magic ")) {
        // strip off magic
        text = text.substr(6);
        itInfo.rarity = "Common";
    }
    if (text.toLowerCase().startsWith("weapon (") || text.toLowerCase().startsWith("armor (")) {
        // find weapon / armor
        const lparen = text.indexOf("(");
        text = text.substr(lparen+1);
        const rparen = text.indexOf(")");
        if (rparen <0) {
            return null;
        }
        const base = text.substr(0,rparen).toLowerCase();
        text = text.substr(rparen+1).trim();
        const allItems = campaign.getAllItems();
        let found;

        for (let i in allItems) {
            const its = allItems[i];
            if ((its.displayName||"").toLowerCase()==base) {
                const itt = Object.assign({}, its);
                itt.weaponProficiency = getWeaponProficiency(itt);
                delete itt.displayName;
                delete itt.name;
                delete itt.source;
                delete itt.value;
                delete itt.entry;
                Object.assign(itInfo, itt);
                found=true;
                break;
            }
        }
        if (!found) {
            return null;
        }
    } else {
        if (text.toLowerCase().startsWith("simple")) {
            itInfo.weaponCategory = "Simple";
            text=text.substr(6).trim();
        }
        if (text.toLowerCase().startsWith("martial")) {
            itInfo.weaponCategory = "Martial";
            text=text.substr(7).trim();
        }
        
        // shield is armor but labeled shield in item type sometimes
        if (text.toLowerCase().startsWith("shield")) {
            itInfo.type="S";
            text=text.substr(6).trim();
        }
        
        if (text.toLowerCase().startsWith("melee")) {
            itInfo.type="M";
            text=text.substr(5).trim();
            if (text.toLowerCase().startsWith("weapon")) {
                text=text.substr(6).trim();
            }
        } 

        if (text.toLowerCase().startsWith("ranged")) {
            itInfo.type="R";
            text=text.substr(5).trim();
            if (text.toLowerCase().startsWith("weapon")) {
                text=text.substr(6).trim();
            }
        }
        for (let i in itemTypeFromAbbreviation) {
            const it = itemTypeFromAbbreviation[i].toLowerCase();
            if (text.toLowerCase().startsWith(it)) {
                itInfo.type = i;
                text=text.substr(it.length).trim();
                break;
            }
        }

        if (!itInfo.type) {
            const {allItemTypes} = campaign.getItemExtensions();

            for (let i in allItemTypes) {
                const it =allItemTypes[i];
                if (text.toLowerCase().startsWith(it.toLowerCase())) {
                    itInfo.nametype = it;
                    text=text.substr(it.length).trim();
                    break;
                }
            }
        }
    }

    if (!itInfo.type && !itInfo.nametype) {
        return null;
    }
    if (text.startsWith(",")) {
        text=text.substr(1).trim();
    }
    if (!text.length) {
        return itInfo;
    }
    const split = text.split("(");
    let rarity = split[0].trim();
    itInfo.rarity = matchType(rarity, rarityLevels) || rarity;

    if (split.length==2) {
        let attune = split[1].replace(")","");
        if (!attune.toLowerCase().startsWith("requires attunement")) {
            console.log("unknown attunement value", attune);
            return null;
        }
        attune = attune.substr(19).trim();
        if (attune.length) {
            itInfo.reqAttune=attune;
        } else {
            itInfo.reqAttune=true;
        }
    } else if (split.length>2) {
        console.log("unknown attunement value", text);
        return null;
    }
    return itInfo;
}

function parseItemDamage(it, value) {
    let found=false;

    value = value.trim();
    for (let dval in Parser.DMGTYPE_JSON_TO_FULL) {
        const d = Parser.DMGTYPE_JSON_TO_FULL[dval];
        if (value.toLowerCase().endsWith(d)) {
            it.dmgType = dval;
            value = value.substr(0, value.length-d.length).trim();
            found=true;
        }
    }
        
    if (value.endsWith(")")) {
        const pos = value.indexOf("(");
        if (pos > 0) {
            it.dmg2=value.substring(pos+1,value.length-1);
            value=value.substr(0,pos-1).trim();
        }
    }

    it.dmg1=value;

    return found;
}

function matchType(value, list) {
    for (let i in list) {
        const v=list[i];
        if (v.toLowerCase() == value.toLowerCase()) {
            return v;
        }
    }
    return null;
}


function parsePrerequisite(text) {
    const lowertext = text.toLowerCase();
    if (lowertext.startsWith("prerequisite:")) {
        return {prerequisites:text.substring(13).trim()};
    }
    if (lowertext.startsWith("prerequisite")) {
        return {prerequisites:text.substring(12).trim()};
    }
    let prereq,keywords;
    const endPre = text.match(/\(Prerequisite:.*\)\s*$/i)
    if (endPre) {
        prereq = endPre[0].replace(/(\(Prerequisite:|\)\s*$)/gi, "");
        text=text.replace(endPre[0],"");
    }
    if (text.match(/.{1,40}\s+feat\s*$/i)) {
        keywords = text.replace(/\s+feat\s*$/i,"");
    }
    if (keywords||prereq) {
        return {prerequisites:prereq, keywords}
    }
    return null;
}

function parseKeywords(text) {
    const lowertext = text.toLowerCase();
    if (lowertext.startsWith("keywords:")) {
        return text.substring(9).trim();
    }
    if (lowertext.startsWith("keywords")) {
        return text.substring(8).trim();
    }
    return null;
}

// order important since matched by end of string and last match wins
const allignmentTypeList = [
    {name:'Unaligned', value:["U"]},
    {name:'good', value:["G"]},
    {name:'evil', value:["E"]},
    {name:'Neutral', value:["N"]},
    {name:'Any alignment', value:["A"]},
    {name:'Any good alignment', value:["G"]},
    {name:'Any evil alignment', value:["E"]},
    {name:'Chaotic Evil', value:["C","E"]},
    {name:'Chaotic Good', value:["C","G"]},
    {name:'Chaotic Neutral', value:["C","N"]},
    {name:'Lawful Evil', value:["L","E"]},
    {name:'Lawful Good', value:["L","G"]},
    {name:'Lawful Neutral', value:["L","N"]},
    {name:'Neutral Evil', value:["N","E"]},
    {name:'Neutral Good', value:["N","G"]},
];

function parseMonsterSize(text) {
    let lowerText = text.toLowerCase();
    let sz;
    let alignment;
    let type;

    for (let i in sizeList) {
        const s=sizeList[i];
        if (lowerText.startsWith(s.name.toLowerCase())) {
            sz=s;
        }
    }
    if (!sz) {
        return null;
    }
    let sizeNotes = null;
    lowerText=lowerText.substring(sz.name.length).trim();

    const dimensionsMatch = lowerText.match(/\(\s*\d+\s*ft\.?\s*by\s*\d+\s*ft\.?\s*\)/);
    if (dimensionsMatch) {
        sizeNotes = dimensionsMatch[0];
        lowerText = lowerText.replace(sizeNotes,"");
    }

    for (let i in allignmentTypeList) {
        const a = allignmentTypeList[i];
        if (lowerText.endsWith(a.name.toLowerCase())) {
            alignment=a;
        }
    }

    if (alignment) {
        lowerText=lowerText.substr(0,lowerText.length-alignment.name.length);
    } // else {return null;}

    lowerText = lowerText.replace(")","").replace("(","").trim();
    if (lowerText.endsWith(",")) {
        lowerText = lowerText.substr(0,lowerText.length-1).trim();
    }

    for (let i in simpleMonsterTypes) {
        const s = simpleMonsterTypes[i];
        if (lowerText.startsWith(s)) {
            if (s==lowerText) {
                type = s;
            } else {
                type = {type:s, tags:[lowerText.substring(s.length).trim()]};
            }
        }
    }
    if (!type) {
        if (!lowerText) {
            return null;
        }
        type=lowerText;
    }
    return {size:sz.value, sizeNotes, alignment:alignment?.value||null, type };
}

function parseAC(text) {
    text = replaceOddChars(text);
    const ret={};
    const dtMatch = text.match(/\(\s*damage threshold\s*\d+\s*\)/);
    if (dtMatch) {
        text = text.replace(dtMatch[0],"");
        ret.damageThreshold = dtMatch[0].match(/\d+/)[0];
    }
    const initiativeMatch = text.match(/\s*initiative\s*(\+|-)\d+\s*.\s*\d+\s*./i);
    if (initiativeMatch) {
        text = text.replace(initiativeMatch[0],"");
        //ignore intiative currently
    }
    const parts = text.trim().split(" ");
    const ac = Number(parts[0].trim());
    if (Number.isNaN(ac)) {
        //console.log("not a num", parts[0], ac, text.charCodeAt(1), text.charCodeAt(2),text.charCodeAt(3));
        return null;
    }
    ret.ac=ac;

    parts.shift();
    let extra =parts.join(" ");
    if (extra.startsWith("(")) {
        extra = extra.substring(1);
    }
    if (extra.endsWith(")")) {
        extra = extra.substr(0,extra.length-1);
    }
    if (extra) {
        ret.from=[extra];
    }
    return ret;
}

function parseHP(text) {
    text=text.toLowerCase();
    const parts = text.trim().split(" ");
    const average=Number(parts[0].trim());
    if (Number.isNaN(average)) {
        return null;
    }
    parts.shift();
    let formula=parts.join(" ");
    if (formula.startsWith("(")) {
        formula = formula.substring(1);
    }
    if (formula.endsWith(")")) {
        formula = formula.substr(0,formula.length-1);
    }
    return {average, formula};
}

function parseCR(text) {
    const parts = text.trim().split(" ");
    const cr = parts[0];

    for (let i in Parser.XP_CHART_ALT) {
        if (i == cr) {
            return cr;
        }
    }
    return null;
}

function checkMonsterNameForCR(addIt) {
    const dn = addIt.displayName;
    if (!dn) {
        return;
    }
    const pos = dn.lastIndexOf("CR");
    if (pos > 0) {
        const possibleCr = dn.substr(pos+2).trim();
        const cr = parseCR(possibleCr);
        if (cr) {
            addIt.cr = cr;
            addIt.displayName = dn.substr(0, pos).trim();
        }
    }
}

function parseSpeed(text) {
    const parts = text.split(",");
    const speed = {};
    let found;
    let lastMt;

    for (let i in parts) {
        const p = parts[i].trim();
        const subparts = p.split(" ");
        let mt;
        let sp;
        let condition;

        if (isNaN(subparts[0]||"")||found) {
            if (["walk","burrow", "climb", "fly", "swim"].includes((subparts[0]||"").toLowerCase())) {
                mt=(subparts[0]||"").toLowerCase();
            } else if (lastMt && !speed[lastMt].condition) {
                speed[lastMt].condition = subparts.join(" ");
                continue;
            } else {
                return null;
            }
            subparts.shift();
        } else {
            mt="walk";
        }
        if (isNaN((subparts[0]||""))) {
            return null;
        }
        sp=Number((subparts[0]||""));
        subparts.shift();
        if (!(subparts[0]||"").toLowerCase().startsWith("ft")) {
            return null;
        }
        subparts.shift();
        if (subparts.length) {
            condition=subparts.join(" ");
        }
        speed[mt]={number:sp, condition:condition||null};
        lastMt = mt;
        found=true;
    }
    if (found) {
        return speed;
    }
    return null;
}

function parseSenses(text) {
    const parts = text.split(",");
    const lastPart = parts[parts.length-1].toLowerCase().trim();
    const ppStr = "passive perception";
    let passive=10;


    if (lastPart.startsWith(ppStr)) {
        const num=lastPart.substr(ppStr.length).trim();
        if (isNaN(num)) {
            return null;
        }
        passive=Number(num);
        parts.pop();
    }
    return {senses:parts.join(","), passive};
}

function parsePassive(text) {
    const parts =replaceOddChars(text).toLowerCase().split("stealth");
    let [p,s] = parts;
    if (p && s && !isNaN(p.trim())) {
        const ret = {passive:Number(p.trim())};
        s=s.trim();
        const pos = s.indexOf(" ");
        if (pos>=0) {
            ret.stealthNotes = s.substr(pos+1);
            s = s.substr(0,pos);
        }
        
        if (!isNaN(s)) {
            ret.stealth = Number(s);
        } else {
            return null;
        }
        return ret;
    }
    return null;
}

function parseSkills(text) {
    const parts = text.split(",");
    const skills = {};
    const skillsList = campaign.getAllSkills();
    
    for (let i in parts) {
        const p = parts[i].toLowerCase().trim();
        let found;

        for (let x in skillsList) {
            const s=skillsList[x].toLowerCase();
            if (p.startsWith(s)) {
                const num = p.substring(s.length).trim();
                if (isNaN(num)) {
                    return null;
                }
                skills[s]=num;
                found=true;
            }
        }
        if (!found) {
            return null;
        }
    }
    return skills;
}

function parseSavingThrows(text) {
    const parts = text.split(",");
    const save = {};
    
    for (let i in parts) {
        const p = parts[i].toLowerCase().trim();
        let found;

        for (let x in abilities) {
            const s=abilities[x];
            let c;
            if (p.startsWith(abilityNamesFull[x])){
                c = abilityNamesFull[x];
            } else {
                c = s;
            }
            if (p.startsWith(c)) {
                const num = p.substring(c.length).trim();
                if (isNaN(num)) {
                    return null;
                }
                save[s]=num;
                found=true;
            }
        }
        if (!found) {
            return null;
        }
    }
    return save;
}

function parseEnvironment(value) {
    const parts = value.toLowerCase().split(",");
    const environment=[];
    for (let i in parts) {
        const p=parts[i].trim();
        if (!environmentTypeList.includes(p)) {
            return null;
        }
        environment.push(p);
    }
    return environment;
}

function addFeatureString(addIt, features, name, prop) {
    const {match, index, value} = findFeature(name, features);
    if (match) {
        addIt[prop]=value;
        features.splice(index,1);
    }
}

function addFeatureStringArray(addIt, features, name, prop) {
    const {match, index, value} = findFeature(name, features);
    if (match) {
        addIt[prop]=[value];
        features.splice(index,1);
    }
}

function addFeatureStringComboArray(addIt, features, name, prop, prop2) {
    const {match, index, value} = findFeature(name, features);
    if (match) {
        let [a,b] = value.split(";",2).map(function (s){return s.trim()});
        if (a.length) {
            addIt[prop]=[a];
        }
        if (b && b.length){
            addIt[prop2]=[b];
        }
        features.splice(index,1);
    }
}

function removeEndPeriod(str) {
    str = str.trim();
    if (str.endsWith(".")) {
        return str.substr(0,str.length-1);
    }
    if (str.endsWith(":")) {
        return str.substr(0,str.length-1);
    }
    return str;
}


export {
    getFeatureText,
    BookConvertMenus
}