const React = require('react');
const {Dialog,DialogTitle,DialogActions,DialogContent} = require('./responsivedialog.jsx');
const {signedNum,extraEffectsDamageList,extraEffectsList,damageTypesList,pluralString} = require('../lib/stdvalues.js');
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Zoom from '@material-ui/core/Zoom';
import Button from '@material-ui/core/Button';
const {campaign,replaceMetawords} = require('../lib/campaign.js');
const {getAnchorPos,windowSize,alwaysArray} = require('./stdedit.jsx');
const {getDiceInfo} = require('../lib/entryconversion.js');
const {ArtPicker,isNoArt} = require('./renderart.jsx');
const {LinkHref,getLocationInfo} = require('./renderhref.jsx');
import Popover from '@material-ui/core/Popover';
const Parser = require("../lib/dutils.js").Parser;

const defaultDiceURL = "/dicebluegold.svg";
const animationTime = 500;
const dieTypes = ["D4", "D6", "D8", "D100", "D10", "D12", "D20"];
const dieSidesByType={
    D4:4,
    D6:6,
    D8:8,
    D10:10,
    D12:12,
    D20:20,
    D100:100,
}
const smallDieSize=32;

class DiceRollChat extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const {changeRoll, noDetails, roll, small, hwidth, hideDetails, extra, animate, onClickSum,onAddExtraDice,readonly} = this.props;
        const dice = roll.dice||{};
        let sum=getRollSum(roll);
        let rollColor = actionColors[(roll.action||"").toLowerCase()]||"";
        const {isDamage} = roll;

        const showClick = onClickSum;
        const dwidth = hwidth-(small?50:70)-(isDamage&&!roll.didCrit&&changeRoll?40:0);

        const diceInfo = <div className="flex justify-center items-center flex-wrap pb--2 overflow-hidden">
            {isDamage&&!roll.didCrit&&changeRoll?<div className="mr1 f6 light-orange tc" onClick={this.doCrit.bind(this)}>
                <a>Critical{small?" ":<br/>}Hit</a>
            </div>:null}
            {hideDetails?null:<div className="overflow-hidden flex items-center flex-wrap" style={{maxWidth:dwidth}} onClick={this.showDetails.bind(this, true)}>
                <DiceList roll={roll} hwidth={dwidth} scale={small?smallDieSize:null}/>
            </div>}
            <div className={(small?"f2":"f1")} >
                <span className={showClick?" hoverhighlight":null} onClick={showClick?this.onClickSum.bind(this, sum):null}>&nbsp;{hideDetails?null:"="}&nbsp;<span className={showClick?" underline":null}>{sum}</span></span>
                &nbsp;<AddRoll hideDetails={hideDetails} onAddExtraDice={onAddExtraDice} getActionCharacter={this.props.getActionCharacter} ignore={this.props.chat?.name} includeDamages readonly={readonly}/>
            </div>
        </div>;

        return <div className="tl w-100 flex">
            <DiceDetails roll={roll} open={this.state.showDetails} anchorPos={this.state.anchorPos} onClose={this.showDetails.bind(this, false)} changeRoll={changeRoll} deleteRoll={this.props.deleteRoll}/>
            <div className="w-20 flex-auto">
                {hideDetails||noDetails?null:<div className="f6 pb--1 tc">
                    {roll.playerDisplayName?<span>{roll.playerDisplayName} </span>:null}
                    {roll.source?<i>{roll.sourceHref?<LinkHref href={roll.sourceHref} addToEncounter={this.props.addToEncounter} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} character={this.props.character}>{roll.source}</LinkHref>:roll.source}: </i>:null}
                    {(roll.actionDetail && !extraEffectsDamageList.includes(roll.actionDetail))?<span>{roll.actionDetail} </span>:null}
                    <span className={rollColor+" ttu"}>{roll.action}</span>
                    <span > {getStringFromDice(dice)} </span>
                    {roll.didCrit?<span className="ml1">Critical Hit</span>:null}
                    {roll.pass?((sum>=roll.pass)?<span className="ml1 green">passed DC</span>:<span className="ml1 red">failed DC</span>):null}
                    {extra}
                </div>}
                {animate?<Zoom in timeout={animationTime}>{diceInfo}</Zoom>:diceInfo}
            </div>
        </div>
    }

    onClickSum(sum, evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        this.props.onClickSum(sum,evt);
    }

    showDetails(showDetails, evt) {
        if (evt){
            evt.preventDefault();
            evt.stopPropagation();
            this.setState({showDetails, anchorPos:getAnchorPos(evt)});
        } else {
            this.setState({showDetails});
        }
    }

    doCrit(e) {
        const roll = Object.assign({},this.props.roll);
        const dice = Object.assign({},roll.dice);

        delete dice.extraBonus;
        delete dice.bonus;

        const {rolls} = doRoll(dice);
        roll.didCrit=true;
        roll.rolls = rolls.concat(roll.rolls);

        this.props.changeRoll(roll);
        if (e) {
            e.stopPropagation();
        }
    }
}

class DiceRollD20 extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const {changeRoll, noDetails, roll, small, hwidth, hideDetails, extra, animate, onClickDamage,onAddExtraDice,readonly} = this.props;
        const dice = roll.dice||{};
        const {rolls, damages, otherDamages} = roll;
        let sum=rolls?getRollSum(roll):0;
        let rollColor = actionColors[(roll.action||"").toLowerCase()]||"";
        let damageInside;

        const dwidth = hwidth-(small?50:70)-(!roll.didAdvantage&&changeRoll?140:0);

        if ((damages || otherDamages) && onClickDamage && !hideDetails) {
            let dmgVal = ""
            if (damages && damages.length) {
                dmgVal = getDamagesStr(damages);
            }
            const canfit = !otherDamages && (dwidth>160)&&((dmgVal.length*9)<(dwidth-95));
            const dlist = [];
            if (dmgVal.length) {
                dlist.push(<a key="." className="orange" onClick={this.onClickDamage.bind(this, damages)}>Roll{canfit?<br/>:" "}{dmgVal}</a>)
            }
            for (let i in otherDamages) {
                const od = otherDamages[i];
                const d = od.damages;
                if (d && d.length) {
                    const dmgVal = getDamagesStr(d);
                    if (dlist.length) {
                        dlist.push(", ");
                    }
                    dlist.push(<a key={i} className={((d.length==1)&&(d[0].dmgType=="heal"))?"pink nowrap":"orange nowrap"} onClick={this.onClickDamage.bind(this, d)}>{dmgVal}</a>);
                }
            }

            damageInside = <div className="f6 mh1 pv--2 tc">
                {dlist}
            </div>;
        }

        const diceInfo = <div className="flex justify-center items-center flex-wrap pb--2 overflow-hidden">
            {!roll.didAdvantage&&changeRoll&&rolls?<div className="mr1 f6">
                <a className="green" onClick={this.doAdvantage.bind(this,true)}>Advantage</a> <a className="orange" onClick={this.doAdvantage.bind(this,false)}>Disadvantage</a>
            </div>:null}
            {noDetails&&roll.didAdvantage&&rolls?<div className="mr1 f6">
                    {(roll.didAdvantage=="A")?"Advantage":"Disadvantage"}
            </div>:null}
            {rolls?<div className="overflow-hidden flex items-center flex-wrap" style={{maxWidth:dwidth}} onClick={hideDetails?null:this.showDetails.bind(this, true)}>
                <DiceList roll={roll} hwidth={dwidth} scale={small?smallDieSize:null} hideBonus={hideDetails}/>
            </div>:null}
            {(hideDetails|| !rolls)?null:<div className={(small?"f2":"f1")}>
                &nbsp;=&nbsp;{sum}&nbsp;<AddRoll onAddExtraDice={onAddExtraDice} getActionCharacter={this.props.getActionCharacter} readonly={readonly}/>
            </div>}
            {damageInside}
        </div>;

        return <div className="tl w-100 flex">
            <DiceDetails roll={roll} open={this.state.showDetails} anchorPos={this.state.anchorPos} onClose={this.showDetails.bind(this, false)} changeRoll={changeRoll} deleteRoll={this.props.deleteRoll}/>
            <div className="w-20 flex-auto">
                {hideDetails||noDetails?null:<div className="f6 pb--1 tc">
                    {roll.playerDisplayName?<span>{roll.playerDisplayName} </span>:null}
                    {roll.source?<i>{roll.sourceHref?<LinkHref href={roll.sourceHref} addToEncounter={this.props.addToEncounter} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} character={this.props.character}>{roll.source}</LinkHref>:roll.source}: </i>:null}
                    {(roll.actionDetail && !extraEffectsDamageList.includes(roll.actionDetail))?<span>{roll.actionDetail} </span>:null}
                    <span className={rollColor+" ttu"}>{roll.action}</span>
                    <span > {getStringFromDice(dice)} </span>
                    {roll.didAdvantage?<span className="ml1">
                        {(roll.didAdvantage=="A")?"Advantage":"Disadvantage"}
                    </span>:null}
                    {roll.pass?((sum>=roll.pass)?<span className="ml1 green">passed DC</span>:<span className="ml1 red">failed DC</span>):null}
                    {roll.damageVal?<span className="ml1">damage {roll.damageVal}</span>:null}
                    {extra}
                </div>}
                {animate?<Zoom in timeout={animationTime}>{diceInfo}</Zoom>:diceInfo}
            </div>
        </div>
    }

    onClickDamage(damages, evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        this.props.onClickDamage(damages,evt);
    }

    showDetails(showDetails, evt) {
        if (evt){
            evt.preventDefault();
            evt.stopPropagation();
            this.setState({showDetails, anchorPos:getAnchorPos(evt)});
        } else {
            this.setState({showDetails});
        }
    }

    doAdvantage(advantage, e) {
        const roll = Object.assign({},this.props.roll);
        const rand = dicerandom(20);
        const altRoll = Math.max(Math.min(20,roll.dice.min||0), rand);

        roll.didAdvantage=advantage?"A":"D";
        roll.changes = Object.assign({}, roll.changes||{});
        let epos;

        if ((advantage && (altRoll>roll.rolls[0])) || (!advantage && (altRoll<roll.rolls[0]))) {
            roll.altRoll = roll.rolls[0];
            roll.rolls=roll.rolls.concat([]);
            roll.rolls[0]=altRoll;
            epos=0;
        } else {
            roll.altRoll=altRoll;
            epos=-1;
        }
        roll.changes[epos] = (roll.changes[epos]||0)+1;

        this.props.changeRoll(roll);
        if (e) {
            e.stopPropagation();
        }
    }
}

class AddRoll extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        if (this.props.readonly) {
            return null;
        }
        return <span onClick={function(evt){evt.preventDefault();evt.stopPropagation()}}>
            <b className="b-chat ba br1 hoverhighlight f3" onClick={this.showAddListOptions.bind(this,true)}>&nbsp;+&nbsp;</b>
            <DiceRollerPopup show={this.state.showAddOptions} anchorEl={this.state.anchorEl} onClose={this.closeAddOptions.bind(this)}/>
            {this.getAddList()}
            {this.getUseCountMenu()}
        </span>
    }

    showAddListOptions(showAddListOptions, evt) {
        if (evt){
            evt.preventDefault();
            evt.stopPropagation();
        }
        this.setState({showAddListOptions,anchorEl:evt?evt.target:null});
    }

    getAddList() {
        if (!this.state.showAddListOptions) {
            return null;
        }
        const {includeDamages, parentDmgType,ignore} = this.props;
        const ret=[];
        let lr = getRollMods(2, includeDamages, ignore);
        const character = this.props?.getActionCharacter();

        let excludeList, includeList;
        const lparentDmgType = parentDmgType?.toLowerCase();
        if (["max hp"].includes(lparentDmgType)){
            // don't show any extra options for these
            lr = [];
            includeList=[];
        } else if (includeDamages) {
            excludeList=["heal", "temp hp", "max hp", "bonus", "penalty"];
        } else {
            includeList=["bonus","penalty"]; 
        }
        if (character) {
            const {actions, elist} = character.getActions();
            for (let i in actions) {
                const a = actions[i];
                const {dmg, dmgType}=(a.damages||[])[0]||{};
                if (a.enableAction && (a.type == "action") && !a.attackRoll && !a.save && (a.damages?.length==1) && 
                    (!excludeList || !excludeList.includes(dmgType?.toLowerCase())) && 
                    (!includeList || includeList.includes(dmgType?.toLowerCase()))) 
                {
                    ret.push(<MenuItem key={"a"+i} onClick={this.selectActionNewRoll.bind(this, a,null)}><div className="flex-auto" ><span className="minw2 dib pr--2"><span className="ba titleborder br1 ph--2 f7">{a.actionType||"use"}</span></span>{a.featureName}</div><div className="ml2 tr"> {dmg}</div></MenuItem>);
                }
            }

            for (let i in elist) {
                const e = elist[i];
                const {params, active} = e;
                if (!active) {
                    const {feature} = params;
                    const {effects} = feature;
                    const {itemmod} = effects;
                    if (includeDamages && itemmod && (itemmod.damageBonus || itemmod.extraDamage)) {
                        const damages = damagesFromExtraDamage(itemmod.damageBonus, null, itemmod.extraDamage||{});
                        if (damages.length == 1) {
                            character.resolveDamages(damages);
                            const {dmg, dmgType}= damages[0];
                            ret.push(<MenuItem key={"e"+i} onClick={this.selectNewRoll.bind(this, dmg, dmgType)}><div className="flex-auto" >{effects.name}</div><div className="ml2 tr"> {dmg}</div></MenuItem>);
                        }
                    }
                    if (!includeDamages && itemmod?.attackBonus) {
                        const bonus = character.getBonusMod(itemmod.attackBonus);
                        const dice = getDiceFromString("",bonus,true);
                        const {rolls} = doRoll(dice);
                        let roll = {dice,rolls};
                
                        ret.push(<MenuItem key={"e"+i} onClick={this.selectAddRoll.bind(this, roll,false)}><div className="flex-auto" >{effects.name}</div><div className="ml2 tr"> {signedNum(bonus)}</div></MenuItem>);
                    }
                }
            }
        }

        if (ret.length && lr?.length) {
            ret.push(<Divider key="div"/>)
        }

        for (let i in lr) {
            const {chat, roll, sum} = lr[i];
            const actor = roll.source || roll.playerDisplayName || chat.actor;
            if (roll.action !="penalty") {
                ret.push(<MenuItem key={"p"+i} onClick={this.selectAddRoll.bind(this, roll,false)}><div className="flex-auto" >{actor}</div><div className="ml2 tr"> {signedNum(sum)}</div></MenuItem>);
            }
            if (roll.action !="bonus") {
                ret.push(<MenuItem key={i} onClick={this.selectAddRoll.bind(this, roll,true)}><div className="flex-auto" >{actor}</div><div className="ml2 tr"> {signedNum(sum*-1)}</div></MenuItem>);
            }
        }

        return <Menu open disableAutoFocusItem anchorEl={this.state.anchorEl} anchorReference="anchorEl" transitionDuration={0} onClose={this.showAddListOptions.bind(this, false)}>
            <MenuItem onClick={this.showAddOptions.bind(this,true)}>Custom Roll</MenuItem>
            {ret.length?<Divider/>:null}
            {ret}
        </Menu>;
    }

    showAddOptions(showAddOptions, evt) {
        if (evt){
            evt.preventDefault();
            evt.stopPropagation();
        }
        this.setState({showAddOptions,showAddListOptions:false});
    }

    selectAddRoll(roll, minus, evt) {
        if (evt){
            evt.preventDefault();
            evt.stopPropagation();
        }
        roll = Object.assign({}, roll);
        if (minus) {
            roll = inverseRoll(roll);
        }
        this.props.onAddExtraDice(roll);
        this.setState({showAddOptions:false,showAddListOptions:false, anchorEl:null});
    }

    selectActionNewRoll(action, usageOption, evt) {
        const character = this.props?.getActionCharacter();
        const {params} = action;
        const {feature, usageId} = params;
        if (!usageOption) {
            const opts = character.getActionUsageOptions(feature, usageId);

            if (opts.length<=1) {
                usageOption=opts[0]||null;
            } else {
                this.setState({showPickActionUses:opts, action, showAddOptions:false,showAddListOptions:false});
                return
            }
        }

        const {damages} = character.performAction(action.params, usageOption);
        const {dmg, dmgType} = damages[0];
        this.selectNewRoll(dmg, dmgType, evt);
        this.setState({showPickActionUses:false});
    }

    getUseCountMenu() {
        const {showPickActionUses, action, anchorEl} = this.state;
        if (!showPickActionUses) {
            return null;
        }
        const character = this.props?.getActionCharacter();

        const ret=[];
        for (let i in showPickActionUses) {
            const uo = showPickActionUses[i];
            let name;
            switch (uo.type) {
                case "uses": {
                    name = uo.level +" "+ (uo.usageName||pluralString("use",uo.level));
                    break;
                }
                case "hd":{
                    name = uo.level + "d"+uo.faces+" Hit Dice";
                    break;
                }
                case "spellpoints": {
                    name = uo.points+" "+character.t("Spell Points");
                    break;
                }
                case "spellslot":{
                    name =Parser.spLevelToFull(uo.level)+character.spellLevelName("-", " ")+character.t("Spell");
                    break;
                }
                case "pact":{
                    name =Parser.spLevelToFull(uo.level)+character.spellLevelName("-", " ")+character.t("Pact");
                    break;
                }
            }
            ret.push(<MenuItem key={i} onClick={this.selectActionNewRoll.bind(this, action, uo)}>{name}</MenuItem>);
        }
        return <Menu
            anchorEl={anchorEl||null}
            open
            onClose={this.hidePickActionUses.bind(this)}
        >
            {ret}
        </Menu>;
    }

    hidePickActionUses() {
        this.setState({showPickActionUses:false});
    }

    selectNewRoll(dmg, dmgType, evt) {
        if (evt){
            evt.preventDefault();
            evt.stopPropagation();
        }
        const dice = getDiceFromString(dmg,0,true)
        const {rolls} = doRoll(dice);
        let roll = {dice,rolls,dmgType:dmgType||null};
        if (["reduce damage", "penalty"].includes(dmgType?.toLowerCase())) {
            roll = inverseRoll(roll);
        }
        this.props.onAddExtraDice(roll);
        this.setState({showAddOptions:false,showAddListOptions:false, anchorEl:null});
    }

    closeAddOptions(dice) {
        if (dice){
            this.props.onAddExtraDice({dice});
        }
        this.setState({showAddOptions:false,showAddListOptions:false, anchorEl:null});
    }
}

function inverseRoll(roll) {
    roll = Object.assign({}, roll);
    if (roll.damages) {
        roll.damages = roll.damages.concat([]);
        for (let i in roll.damages) {
            roll.damages[i] = inverseRoll(roll.damages[i]);
        }
    } else {
        const dice = Object.assign({},roll.dice);
        for (let i in dice) {
            dice[i]*=-1;
        }
        delete dice.minus;

        roll.dice = dice;
        if (roll.rolls) {
            roll.rolls = roll.rolls.concat([]);
            for (let i in roll.rolls) {
                roll.rolls[i]*=-1;
            }
        }
    }
    return roll;
}

function getRollMods(count, includeDamages, ignore) {
    const {Chat} = require('../lib/chat.js');
    const chatList = Chat.getFilteredChatList(campaign.getChat());
    const found=[];
    const excludeList = includeDamages?["heal", "temp hp", "max hp"]:["damage", "heal", "temp hp", "max hp"];

    for (let i=(chatList.length-1); (i>=0) && (found.length < count); i--) {
        const c = chatList[i];
        if (c.name != ignore) {
            let {roll}=c;
            switch (c.type) {
                case "droll":{
                    const {action} = roll;
                    const sum = getRollSum(roll);
        
                    if (sum && (!excludeList.includes(action?.toLowerCase()||"roll"))) {
                        found.push({chat:c, roll, sum});
                    }
                    break;
                }
                default:
                case "roll":{
                    if (roll) {
                        if (roll.damages) {
                            roll = Object.assign({},roll);
                            delete roll.damages;
                        }
                        const {canAdvantage,action} = roll;
                        const sum = getRollSum(roll);

                        if (sum && (!excludeList.includes(action?.toLowerCase()||"roll")) && !canAdvantage) {
                            found.push({chat:c, roll, sum});
                        }
                    }
                    break;
                }
            }
        }
    }
    return found;
}

class DiceDamageRollChat extends React.Component {
    constructor(props) {
        super(props);

	    this.state= {};
    }

    render() {
        const {changeRoll, small, roll, chat, hwidth, hideDetails, extra, animate, onClickSum,onAddExtraDice,readonly} = this.props;
        const {damages} = roll;
        let tsum = 0;
        const dlist = [];
        let usesmall = small, inlineSum, useFlex;
        const bigSize= 64;
        const damageTypeW = 70;
        const partialSumW = 45;
        const pW = 18;

        if (damages.length < 4) {
            let smallw=0, bigw=0;
            for (let i in damages) {
                const d = damages[i];
                const {rolls} = d;
                smallw += damageTypeW + rolls.length * smallDieSize + partialSumW + pW;
                bigw += damageTypeW + rolls.length * bigSize + partialSumW + pW;
            }
            if (!small && (bigw < hwidth)) {
                useFlex = true;
                inlineSum=true;
            } else {
                if (smallw < hwidth) {
                    useFlex = true;
                    inlineSum=true;
                } else if (damages.length<3) {
                    useFlex = true;
                } 
                usesmall = true;
            }
        } else {
            usesmall = true;
        }

        const action = getActionFromDamages(damages);
        for (let i in damages) {
            const d = damages[i];
            const {rolls} = d;
            let sum=getRollSum({rolls});
            tsum+=sum;
            const dwidth = hwidth-partialSumW-damageTypeW;

            if (useFlex && (i >0)) {
                dlist.push(<div key={"p"+i} className="f1 mh1">+</div>);
            }
            dlist.push(<div key={i} className="flex items-center pb--2 overflow-hidden">
                <div className="flex-auto"/>
                {hideDetails?null:<div className="overflow-hidden flex items-center flex-wrap" onClick={this.showDetails.bind(this, true, i)}>
                    <DiceList roll={d} didCrit={roll.didCrit} diceUrl={roll.diceUrl} hwidth={dwidth} scale={usesmall?smallDieSize:null}/>
                </div>}
                <div className={(usesmall?"f3 ":"f1")+(onClickSum?" hoverhighlight":"")+" ml1"} onClick={onClickSum?this.onClickSum.bind(this, sum):null}>
                    {hideDetails?null:"="}&nbsp;<span className={onClickSum?" underline":null}>{sum}</span>
                </div>
                <div className="ml1 f6 tc i">
                    {d.dmgType}
                </div>
                <div className="flex-auto"/>
            </div>);
        }
        let inside,sumInside;

        if (damages.length==1) {
            sumInside=<span className="ml1"><AddRoll hideDetails={hideDetails} onAddExtraDice={onAddExtraDice} getActionCharacter={this.props.getActionCharacter} ignore={chat?.name} includeDamages parentDmgType={damages[0].dmgType} readonly={readonly}/></span>;
        } else {
            sumInside=<div className={(usesmall?"f3":"f1")+" tc ml1"} onClick={onClickSum?this.onClickSum.bind(this, tsum):null}>
                <span className={(onClickSum?" hoverhighlight underline":"")}>=&nbsp;{tsum} Total</span>&nbsp;<AddRoll hideDetails={hideDetails} onAddExtraDice={onAddExtraDice} getActionCharacter={this.props.getActionCharacter} ignore={this.props.chat?.name} includeDamages readonly={readonly}/>
            </div>
        }

        if (useFlex) {
            if (damages.length==1) {
                inlineSum=true;
            }
            inside = <div className="flex flex-wrap justify-center items-center">
                {dlist}
                {inlineSum?sumInside:null}
            </div>;
            if (inlineSum) {
                sumInside=null;
            }
        } else {
            inside = <div>{dlist}</div>
        }

        return <div className="tl w-100">
            <DiceDetails roll={this.state.fakeRoll} disableCrit open={this.state.showDetails} anchorPos={this.state.anchorPos} onClose={this.showDetails.bind(this, false,0)} changeRoll={changeRoll?this.onChangeRoll.bind(this):null}/>
            <div className="f6 pb--1 tc">
                {roll.source&&!hideDetails?<i>{roll.sourceHref?<LinkHref href={roll.sourceHref} addToEncounter={this.props.addToEncounter} doSubRoll={this.props.doSubRoll} addSpellToken={this.props.addSpellToken} getDiceRoller={this.props.getDiceRoller} character={this.props.character}>{roll.source}</LinkHref>:roll.source}: </i>:null}
                {chat?.saveAbility?<div className="green" onClick={readonly?null:this.onClickSave.bind(this,chat.saveAbility, chat.saveVal, chat.origin||chat.name, tsum)}> <a className="pa--2"><span className="ttu">Save {alwaysArray(chat.saveAbility).join("/")}</span>&nbsp;{hideDetails?"":chat.saveVal}</a> </div>:null}
                <span className={(actionColors[action||"damage"]||"")+" ttu"}>{action||"damage"}</span>
                {roll.didCrit&&!hideDetails?<span className="ml1">Critical Hit</span>:changeRoll&&!extraEffectsList.includes(action)&&!hideDetails?<a className="ml1 light-orange tc" onClick={this.doCrit.bind(this)}>Roll Critical</a>:null}
                {extra}
            </div>
            {animate?<Zoom in timeout={animationTime}><div>{inside}</div></Zoom>:inside}
            {sumInside}
        </div>
    }

    onChangeRoll(newroll) {
        const roll = Object.assign({},this.props.roll);
        const damages = roll.damages.concat([]);
        const i = this.state.index;

        const d = Object.assign({},damages[i]);
        damages[i]=d;
        d.rolls = newroll.rolls;
        d.changes = newroll.changes||{};
        roll.damages=damages;

        this.props.changeRoll(roll);
    }

    onClickSave(ability, val, name, tsum, evt) {
        this.props.onClickSave(ability,val,name,tsum, evt);
    }

    onClickSum(sum, evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        this.props.onClickSum(sum,evt);
    }

    showDetails(showDetails, index, evt) {
        let fakeRoll;
        if (showDetails) {
            const {roll} = this.props;
            const {damages} = roll;

            fakeRoll = Object.assign({},damages[index]);
            fakeRoll.didCrit=roll.didCrit;
            fakeRoll.diceUrl = roll.diceUrl
        }
        if (evt){
            evt.preventDefault();
            evt.stopPropagation();
            this.setState({showDetails, fakeRoll, index, anchorPos:getAnchorPos(evt)});
        } else {
            this.setState({showDetails});
        }
    }

    doCrit(e) {
        const roll = Object.assign({},this.props.roll);
        const damages = roll.damages.concat([]);

        for (let i in damages) {
            const d = Object.assign({},damages[i]);
            damages[i]=d;
            const dice = Object.assign({},d.dice);

            delete dice.extraBonus;
            delete dice.bonus;

            const {rolls} = doRoll(dice);
            d.rolls = rolls.concat(d.rolls);
        }
        roll.didCrit=true;
        roll.damages=damages;

        this.props.changeRoll(roll);
        if (e) {
            e.stopPropagation();
        }
    }
}

function getActionFromDamages(damages) {
    let action;
    for (let i in damages) {
        const d = damages[i];

        if (action != "damage") {
            if (extraEffectsList.includes(d.dmgType)) {
                action=d.dmgType;
            } else if (damageTypesList.includes(d.dmgType)) {
                action="damage";
            }
        } 
    }
    return action || "roll";
}

function getActionFromDmgType(dmgType) {
    if (extraEffectsList.includes(dmgType)) {
        return dmgType;
    } else if (damageTypesList.includes(dmgType)) {
        return "damage";
    }
    return "roll";
}

function getDieTypesFromDice(dice) {
    const dieTypesF = Object.keys(dice||{});
    for (let i = dieTypesF.length-1; i>=0; i--) {
        if (!dieTypesF[i].startsWith("D")) {
            dieTypesF.splice(i,1);
        }
    }
    dieTypesF.sort();
    return dieTypesF;
}

function getAdjustedDieType(dieType) {
    if (!dieSidesByType[dieType]) {
        const sides = sidesFromDieType(dieType);
        if (sides > 100) {
            dieType= "D101";
        }
        for (let i in dieSidesByType) {
            if (dieSidesByType[i]>sides) {
                dieType = i;
                break;
            }
        }
    }
    return dieType;
}


const actionColors={
    damage:"orange",
    roll:"light-orange",
    save:"green",
    heal:"pink",
    check:"purpleroll",
    "to hit":"blue"
}


class DiceDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state= {pos:-1};
    }

    componentDidMount() {
        this.componentDidUpdate({});
    }

    componentDidUpdate(prevProps) {
        const roll = this.props.roll||{};
        const proll = prevProps.roll||{};
        if (roll.rollId != proll.rollId) {
            this.setState({pos:-100});
        }
        if ((this.props.open != prevProps.open) && this.props.open) {
            this.setState({roll:this.props.roll,pendingRerolls:undefined})
        }
    }

    render() {
        const {roll,pendingRerolls} = this.state;
        if (!this.props.open || !roll){
            return null;
        }

        const dice = roll.dice||{};
        let sum=getRollSum(roll);
        const rollColor = actionColors[(roll.action||"roll").toLowerCase()]||"";
        const rollAction = <span className={rollColor}>{roll.action||"roll"}</span>;
        let {isDamage, canAdvantage} = roll;

        isDamage = isDamage && !this.props.disableCrit;

        return <Popover
            anchorPosition={this.props.anchorPos} 
            anchorReference="anchorPosition"
            open
            onClose={this.props.onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            classes={{paper:"whiteshadow"}}
        >
            <div className="minw5 pa1 bkdice white-80" onClick={function (evt){evt.preventDefault();evt.stopPropagation();}}>
                {roll.playerDisplayName?<div className="mb1 tc f4">{roll.playerDisplayName}</div>:null}
                <div className="mb1 tc f4">{roll.source}{roll.source?":":null} {rollAction}</div>
                <div className="mb1 tc b--light-gray bb colordice">{getStringFromDice(dice)}</div>
                <div className="mb1 flex flex-wrap justify-center items-center mwdice">
                    <DiceList rollid={this.state.rollid} highlightRolls={pendingRerolls} pos={this.state.pos} roll={roll} onReroll={this.props.changeRoll?this.onReroll.bind(this):null}/>
                </div>
                {this.props.changeRoll?<div className="hk-well">{pendingRerolls?<a className="ma1 white-80" onClick={this.doReroll.bind(this)}>Reroll&nbsp;Dice</a>:"Click any die to reroll"}</div>:null}
                <div className="tc f1 b--light-gray bt pt1">
                    {sum}
                </div>
                <div className="tc colordice">
                    {roll.didAdvantage?<span className="pr1 truncate">
                        {(roll.didAdvantage=="A")?"Advantage":"Disadvantage"}
                    </span>:null}
                    {roll.didCrit?<span className="pr1 truncate">
                        Critical Hit
                    </span>:null}
                </div>
                {this.props.changeRoll?<div className="tc mv1">
                    {canAdvantage&&!roll.didAdvantage?<a className="ma1 green" onClick={this.doAdvantage.bind(this,true)}>Advantage</a>:null}
                    {canAdvantage&&!roll.didAdvantage?<a className="ma1 orange" onClick={this.doAdvantage.bind(this,false)}>Disadvantage</a>:null}
                    {isDamage&&!roll.didCrit?<a className="ma1 light-orange" onClick={this.doCrit.bind(this)}>Critical Hit</a>:null}
                    {this.props.deleteRoll?<a className="ma1" onClick={this.deleteRoll.bind(this)}>Delete</a>:null}
                </div>:null}
            </div>
        </Popover>
    }

    deleteRoll(evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }

        this.props.onClose();
        this.props.deleteRoll(this.state.roll);
    }

    doAdvantage(advantage,e) {
        const roll = Object.assign({},this.state.roll);
        const altRoll = dicerandom(20);

        roll.didAdvantage=advantage?"A":"D";

        roll.changes = Object.assign({}, roll.changes||{});
        let epos;

        if ((advantage && (altRoll>roll.rolls[0])) || (!advantage && (altRoll<roll.rolls[0]))) {
            roll.altRoll = roll.rolls[0];
            roll.rolls=roll.rolls.concat([]);
            roll.rolls[0]=altRoll;
            epos=0;
        } else {
            roll.altRoll=altRoll;
            epos=-1;
        }
        roll.changes[epos] = (roll.changes[epos]||0)+1;
        this.setState({pos:-100});
        this.props.changeRoll(roll);
        this.setState({roll});
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    doCrit(e) {
        const roll = Object.assign({},this.state.roll);
        const dice = Object.assign({},roll.dice);

        delete dice.extraBonus;
        delete dice.bonus;

        const {rolls} = doRoll(dice);
        roll.didCrit=true;
        roll.rolls = rolls.concat(roll.rolls);

        this.setState({pos:-100});
        this.props.changeRoll(roll);
        this.setState({roll});
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    doReroll(evt) {
        if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
        }
        const {pendingRerolls} = this.state;

        const roll = Object.assign({},this.state.roll);
        for (let i in pendingRerolls) {
            const {rollid, pos, die}= pendingRerolls[i];

            const sides = sidesFromDieType(die);
            const newRoll = dicerandom(sides);
            let changeRoll;
    
            if (!rollid) {
                changeRoll = roll;
            } else {
                roll.extraRolls = roll.extraRolls.concat([]);
                changeRoll = Object.assign({}, roll.extraRolls[rollid-1]);
                roll.extraRolls[rollid-1] = changeRoll;
            }
    
            const rolls = changeRoll.rolls.concat([]);
            changeRoll.rolls = rolls;
            const advantage=(changeRoll.didAdvantage == "A");
            changeRoll.changes = Object.assign({}, changeRoll.changes||{});
            let epos=pos;
    
            if (rollid || ((pos >=0) && !changeRoll.didAdvantage)) {
                rolls[pos] = ((rolls[pos]<0)?-1:1)*newRoll;
            } else {
                const altRoll = (pos<0)?newRoll:changeRoll.altRoll;
                const selRoll = (pos<0)?rolls[0]:newRoll;
    
                if ((advantage && (selRoll>altRoll)) || (!advantage && (selRoll<altRoll))) {
                    rolls[0]=selRoll;
                    changeRoll.altRoll = altRoll;
                } else {
                    rolls[0]=altRoll;
                    changeRoll.altRoll = selRoll;

                    epos = (pos<0)?0:-1;
                }
            }
            changeRoll.changes[epos] = (changeRoll.changes[epos]||0)+1;
        }
        this.props.changeRoll(roll);
        this.props.onClose();
    }

    onReroll(rollid, pos, die) {
        const pendingRerolls = Object.assign({}, this.state.pendingRerolls||{});
        const id = rollid+"."+pos;

        if (pos==0) {
            delete pendingRerolls[rollid+".-1"];
        } 
        if (pos==-1) {
            delete pendingRerolls[rollid+".0"];
        } 
        if (pendingRerolls[id]){
            delete pendingRerolls[id];
        } else {
            pendingRerolls[id]={rollid, pos, die};
        }
        this.setState({pendingRerolls:Object.keys(pendingRerolls).length?pendingRerolls:undefined});
        return;

    }
}

class DicePopup extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    render() {
        const {roll,open} = this.props;
        if (!open || !roll){
            return null;
        }

        const dice = roll.dice||{};
        let sum=getRollSum(roll);

        return <Popover
            anchorPosition={this.props.anchorPos} 
            anchorReference="anchorPosition"
            open
            onClose={this.props.onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            classes={{paper:"whiteshadow"}}
            onClick={this.onClick.bind(this)}
        >
            <div className="mw5 minw4 pa1 bkdice white-80">
                <div className="mb1 tc b--light-gray bb colordice">{getStringFromDice(dice)}</div>
                <div className="mb1 flex flex-wrap justify-center items-center mwdice">
                    <DiceList roll={roll} scale={32}/>
                </div>
                <div className="tc f1 b--light-gray bt pt1">
                    {sum}
                </div>
            </div>
        </Popover>
    }

    onClick(event){
        this.props.onClose();
        event.preventDefault();
        event.stopPropagation();
    }
}

function nextScaleDown(scale) {
    switch (scale) {
        case 64:
            return 32;
        case 32:
            return 24;
        case 24:
            return 20;
        case 20:
            return 16;
        default:
            return scale/2;
    }
}

class DiceList extends React.Component {
    constructor(props) {
        super(props);
        this.state= { };
    }

    render() {
        const {roll, hideBonus, highlightRolls={}} = this.props;
        const rollList=[];
        let hwidth = this.props.hwidth;
        let scale=this.props.scale||64;
        let p=0;
        let sum=0;
        const {pos, rollid, onReroll} = this.props;
        const allRolls = [roll].concat(roll.extraRolls||[]);

        if (hwidth) {
            for (let r of allRolls) {
                const {dice, rolls,didAdvantage} = r;
                if (rolls) {
                    sum += rolls.length;
                }
                if (dice && (dice.bonus || dice.extraBonus)) {
                    hwidth-=30;
                    sum--;
                }
                if (didAdvantage) {
                    sum++;
                }
            }
            let fit = Math.trunc(hwidth/scale);
            while ((fit < sum) && (scale > 16)) {
                scale=nextScaleDown(scale);
                fit = Math.trunc(hwidth/scale)||1;
            }
        }

        for (let y in allRolls) {
            const r = allRolls[y];
            const rolls=r.rolls;
            const dice=r.dice||{};
            const bonusTotal = (dice.bonus||0)+(dice.extraBonus||0);
            const bonus=(bonusTotal)?signedNum(bonusTotal):null;
            const didCrit =r.didCrit||this.props.didCrit;
            const diceUrl = this.props.diceUrl || r.diceUrl || roll.diceUrl;
            const dieTypesFound = getDieTypesFromDice(dice);
            let lp=0;

            if (r.didAdvantage) {
                rollList.push(<DiceValue diceUrl={diceUrl||defaultDiceURL} animate={rollid==y && pos==-1} highlight={highlightRolls[y+".-1"]} className="mr2" onClick={onReroll?this.onReroll.bind(this, y, -1, "D20"):null} key="advantage" dieType="D20" advantage value={r.altRoll} scale={scale}/>)
            }

            for (let x=0; x<(didCrit?2:1); x++) {
                for (let i in dieTypesFound){
                    const d = dieTypesFound[i];
                    const fc = dice[d]||0;
                    const c = Math.abs(fc);
                    if ((fc<0)&&rolls[lp]) {
                        rollList.push(<span key={"m"+p} className="ml1">-</span>);
                    }
                    for (let x=0; x<c; x++) {
                        const v = rolls[lp];
                        if (v) {
                            rollList.push(<DiceValue diceUrl={diceUrl||defaultDiceURL} animate={rollid==y && pos==lp} highlight={highlightRolls[y+"."+lp]} onClick={onReroll?this.onReroll.bind(this, y, lp, d):null} dieType={d} value={v} key={p} scale={scale}/>)
                        }
                        p++;
                        lp++;
                    }
                }
            }

            if (bonus && (!hideBonus||!rollList.length)) {
                rollList.push(<div key={p} className={((scale < 64)?"f3":"f2")+" v-top dib white-80"}>{bonus}</div>);
                p++;
            }
        }

        return rollList;
    }

    onReroll(rollid, pos, die,e) { 
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.props.onReroll(Number(rollid), pos,die);
    }
}

class DiceValue extends React.Component {
    constructor(props) {
        super(props);

        this.state= { };
    }

    render() {
        const {highlight}=this.props;
        let dieType = getAdjustedDieType(this.props.dieType);
        const value = Math.abs(this.props.value);
        let inside;
        let size = this.props.scale||64;
        let width=size;
        const animate = this.props.animate;
        const style = {
            display:"inline-block",
            height:width*7+"px",
            width:width*10+"px",
            backgroundRepeat:"no-repeat",
            backgroundSize:width*10+"px "+width*7+"px",
            backgroundImage:"url("+getCorrectedDiceUrl(this.props.diceUrl)+")"
        }
        
        if (dieType=="D100") {
            const style100 = Object.assign({}, style);
            style.backgroundPosition = positionFromDieRoll("D101", value, size);
            style100.backgroundPosition = positionFromDieRoll("D100", value, size);
            inside= <div className={(this.props.className||"")+(this.props.onClick?" relative hoverhighlight dib":" relative dib")} onClick={this.props.onClick} style={{width:size*2, height:size}}>
                <div className="relative dib overflow-hidden" style={{width:size, height:size}}>
                    <span style={style100}/>
                    {this.props.advantage?<div className="absolute br2 striped-no" style={{top:0, left:0, width:size, height:size}}/>:null}
                </div>
                <div className="relative dib overflow-hidden" style={{width:size, height:size}}>
                    <span style={style}/>
                    {this.props.advantage?<div className="absolute br2 striped-no" style={{top:0, left:0, width:size, height:size}}/>:null}
                </div>
            </div>;
            width =size*2;
        } else {
            style.backgroundPosition = positionFromDieRoll(dieType, value, size);
            inside= <div className={(this.props.className||"")+(this.props.onClick?" relative hoverhighlight dib overflow-hidden":" relative dib overflow-hidden")} style={{width:size, height:size}} onClick={this.props.onClick}>
                <span style={style}/>
                {this.props.advantage?<div className="absolute br2 striped-no" style={{top:0, left:0, width:size, height:size}}/>:null}
            </div>;
        }
        return <div style={{width, height:size}} className={(this.props.className||"")+" relative dib"+(highlight?" ba b--near-white":"")}>
            {animate?<Zoom in timeout={animationTime}>
                {inside}
            </Zoom>:inside}
        </div>
    }
}

function getCorrectedDiceUrl(url) {
    const remap={
        "/dicered.svg":"/dicered.png",
        "/dicebluegold.svg":"/dicebluegold.png",
        "/diceorangeblue.svg":"/diceorangeblue.png",
        "/diceblackred.svg":"/diceblackred.png",
        "/dicegreengold.svg":"/dicegreengold.png",
        "/dicepurplegold.svg":"/dicepurplegold.png",
        "/dicepinkgold.svg":"/dicepinkgold.png",
        "/diceuo.svg":"/diceuo.png",
    }
    return remap[url]||url;
}

function positionFromDieRoll(dieType, value, scale) {
    const pos = offsetsFromDieRoll(dieType, value);
    return pos?("-"+(pos.x*scale)+"px -"+(pos.y*scale+1)+"px"):"-0px -0px";
}

function offsetsFromDieRoll(dieType, value) {
    if (dieType == "D100") {
        value = Math.trunc(value/10)-1;
        if (value <0){
            value=9;
        }
    } else if (dieType == "D101") {
        value = value%10;
    } else {
        value--;
    }
    const dicePos = dicePositions[dieType] || dicePositions.D20;
    const pos = dicePos[value];
    return pos;
}

const dicePositions={
    D20:[{x:0,y:0},{x:1,y:0},{x:2,y:0},{x:3,y:0},{x:4,y:0},{x:5,y:0},{x:6,y:0},{x:7,y:0},{x:8,y:0},{x:9,y:0},
         {x:0,y:1},{x:1,y:1},{x:2,y:1},{x:3,y:1},{x:4,y:1},{x:5,y:1},{x:6,y:1},{x:7,y:1},{x:8,y:1},{x:9,y:1},
    ],
    D12:[{x:0,y:2},{x:1,y:2},{x:2,y:2},{x:3,y:2},{x:4,y:2},{x:5,y:2},{x:6,y:2},{x:7,y:2},{x:8,y:2},{x:9,y:2},{x:0,y:3},{x:1,y:3}],
    D8:[{x:2,y:3},{x:3,y:3},{x:4,y:3},{x:5,y:3},{x:6,y:3},{x:7,y:3},{x:8,y:3},{x:9,y:3}],
    D10:[{x:0,y:4},{x:1,y:4},{x:2,y:4},{x:3,y:4},{x:4,y:4},{x:5,y:4},{x:6,y:4},{x:7,y:4},{x:8,y:4},{x:0,y:5}],
    D101:[{x:9,y:4},{x:0,y:4},{x:1,y:4},{x:2,y:4},{x:3,y:4},{x:4,y:4},{x:5,y:4},{x:6,y:4},{x:7,y:4},{x:8,y:4}],
    D100:[{x:0,y:5},{x:1,y:5},{x:2,y:5},{x:3,y:5},{x:4,y:5},{x:5,y:5},{x:6,y:5},{x:7,y:5},{x:8,y:5},{x:9,y:5},{x:0,y:5}],
    D6:[{x:0,y:6},{x:1,y:6},{x:2,y:6},{x:3,y:6},{x:4,y:6},{x:5,y:6}],
    D4:[{x:6,y:6},{x:7,y:6},{x:8,y:6},{x:9,y:6}],
}

const fullDiceOptions = [
    {description:"bluegold",diceUrl:"/dicebluegold.svg"},
    {description:"dicered",diceUrl:"/dicered.svg"},
    {description:"orangeblue",diceUrl:"/diceorangeblue.svg"},
    {description:"blackred",diceUrl:"/diceblackred.svg"},
    {description:"greengold",diceUrl:"/dicegreengold.svg"},
    {description:"purplegold",diceUrl:"/dicepurplegold.svg"},
    {description:"pinkgold",diceUrl:"/dicepinkgold.svg"},
    {description:"diceuo",diceUrl:"/diceuo.svg"},
];

const basicDiceOptions = [
    {description:"bluegold",diceUrl:"/dicebluegold.svg"},
    {description:"dicered",diceUrl:"/dicered.svg"}
];

const ginnydiDice = [
    {description:"ginnydi1",diceUrl:"/ginnydi1.png"},
    {description:"ginnydi2",diceUrl:"/ginnydi2.png"}
];

class DiceMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state= { };
    }

    render() {
        if (!this.props.open) {
            return null;
        }
        const list=[];
        const diceOptions = this.getPickList();
        const artDice = !isNoArt("Dice");

        for (let i in diceOptions) {
            const diceOpt = diceOptions[i];
            const diceUrl=diceOpt.diceUrl;
            list.push(<MenuItem key={i} 
                onClick={this.pickDiceUrl.bind(this, diceOpt)}
                classes={{root:"pv--1"}}
            >
                <div className="bkdice pt--3 ph--2 hoverhighlight">
                    <DiceValue diceUrl={diceUrl} dieType="D20" value={20} scale={32}/>
                    <DiceValue diceUrl={diceUrl} dieType="D12" value={12} scale={32}/>
                    <DiceValue diceUrl={diceUrl} dieType="D10" value={10} scale={32}/>
                    <DiceValue diceUrl={diceUrl} dieType="D8" value={8} scale={32}/>
                    <DiceValue diceUrl={diceUrl} dieType="D6" value={6} scale={32}/>
                    <DiceValue diceUrl={diceUrl} dieType="D4" value={4} scale={32}/>
                </div>
            </MenuItem>);
        }

        return <div>
            <Menu
                anchorEl={this.props.anchorEl}
                open
                onClose={this.onClose.bind(this)}
                anchorOrigin={{vertical: 'top',horizontal: 'right',}}
                transformOrigin={{vertical: 'top',horizontal: 'left',}}
            >
                {list}
                {artDice?<MenuItem onClick={this.showPickDice.bind(this)}>More...</MenuItem>:null}
            </Menu>
            <ArtPicker noCreate={!campaign.allowSpecialArtTypes} restrictType="Dice" defaultType="Dice" open={this.state.showPickDice} onClose={this.pickDiceArtwork.bind(this)}/>
        </div>
    }

    getPickList() {
        const list=[];
        const fav = campaign.getUserMRUList("diceFavorites");
        for (let i in fav) {
            list.push(fav[i]);
        }

        addToList(campaign.dicePermitted!="all"?basicDiceOptions:fullDiceOptions);
        if (campaign.ginnyDiDice) {
            addToList(ginnydiDice); 
        }

        return list;

        function addToList(opts) {
            for (let o of opts) {
                if (!list.find(function (a) {return (a.description==o.description) || (a.diceUrl==o.diceUrl);})) {
                    list.push(o);
                }
            }
        }
    }

    showPickDice(){
        this.setState({showPickDice:true});
    }
    
    pickDiceArtwork(artwork) {
        if (artwork) {
            this.pickDiceUrl({description:artwork.name, diceUrl:artwork.url});
        }
        this.setState({showPickDice:false});
    }

    onClose() {
        this.props.onClose();
    }

    pickDiceUrl(opt){
        this.props.onClose(opt.diceUrl);
        campaign.addUserMRUList("diceFavorites",opt);
    }
}

class DiceRollerPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    render() {
        const width=300;
        if (!this.props.show) {
            return null;
        }

        return <span onClick={function (evt){evt.preventDefault(); evt.stopPropagation()}}>
            <Popover
                open
                anchorEl={this.props.anchorEl}
                onClose={this.handleClose.bind(this)}
                classes={{paper:"whiteshadow bkchat colorchat"}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{width}} className="pa1">
                    <div className="flex">
                        <div className="flex-auto"/>
                        <div className="pa1 fas fa-times hoverhighlight" onClick={this.handleClose.bind(this)}/>
                    </div>
                    <DiceRoller noBig plusMinus doRoll={this.doCustomRoll.bind(this)} character={this.props.character}/>
                </div>
            </Popover>
        </span>;
    }

    doCustomRoll(dice) {
        this.props.onClose(dice)
    }
    
    handleClose() {
        this.props.onClose();
    }
}
class DiceRoller extends React.Component {
    constructor(props) {
        super(props);

        this.state= {};
    }

    render() {
        const {character,width, noBig, plusMinus} = this.props;
        const counts = this.state.dieCounts||{};
        let diceUrl;
        const scale=((width||500)>490)?48:(width>450)?40:32;
        if (character) {
            diceUrl = character.state.diceUrl;
        } else if (!campaign.isPlayerMode()) {
            diceUrl = campaign.getCampaignDice().diceUrl;
        }
        if (!diceUrl) {
            diceUrl = defaultDiceURL;
        }
        
        return <div className="flex flex-wrap items-center f4">
            <div className="flex items-center hoverhighlight" onClick={this.addDice.bind(this,"D4")}>
                <DiceValue diceUrl={diceUrl} dieType="D4" value={4} scale={scale}/>
                <span className="pa--2 br2 mh--2 minw25 bg-white-10">{counts.D4||0} D4</span>
            </div>
            <div className="flex items-center hoverhighlight" onClick={this.addDice.bind(this,"D6")}>
                <DiceValue diceUrl={diceUrl} dieType="D6" value={6} scale={scale}/>
                <span className="pa--2 br2 mh--2 minw25 bg-white-10">{counts.D6||0} D6</span>
                </div>
            <div className="flex items-center hoverhighlight" onClick={this.addDice.bind(this,"D8")}>
                <DiceValue diceUrl={diceUrl} dieType="D8" value={8} scale={scale}/>
                <span className="pa--2 br2 mh--2 minw25 bg-white-10">{counts.D8||0} D8</span>
            </div>
            <div className="flex items-center hoverhighlight" onClick={this.addDice.bind(this,"D10")}>
                <DiceValue diceUrl={diceUrl} dieType="D10" value={10} scale={scale}/>
                <span className="pa--2 br2 mh--2 minw25 bg-white-10">{counts.D10||0} D10</span>
            </div>
            <div className="flex items-center hoverhighlight" onClick={this.addDice.bind(this,"D12")}>
                <DiceValue diceUrl={diceUrl} dieType="D12" value={12} scale={scale}/>
                <span className="pa--2 br2 mh--2 minw25 bg-white-10">{counts.D12||0} D12</span>
            </div>
            {!noBig?<div className="flex items-center hoverhighlight" onClick={this.addDice.bind(this,"D20")}>
                <DiceValue diceUrl={diceUrl} dieType="D20" value={20} scale={scale}/>
                <span className="pa--2 br2 mh--2 minw25 bg-white-10">{counts.D20||0} D20</span>
            </div>:null}
            {!noBig?<div className="flex items-center hoverhighlight" onClick={this.addDice.bind(this,"D100")}>
                <DiceValue diceUrl={diceUrl} dieType="D100" value={100} scale={scale}/>
                <span className="pa--2 br2 mh--2 minw3 bg-white-10">{counts.D100||0} D100</span>
            </div>:null}
            {plusMinus?<div className="flex items-center pa1">
                <span className={"pa--2 ba br1 br--left b--near-white hoverhighlight "+(counts.minus?"white-30":"")} onClick={this.setMinus.bind(this,false)}>bonus</span>
                <span className={"pa--2 br br1 br--right bt bb b--near-white hoverhighlight "+(counts.minus?"":"white-30")} onClick={this.setMinus.bind(this,true)}>penalty</span>
            </div>:null}
            <div className="flex items-center ml--2 flex-auto">
                <div className="flex-auto"/>
                <div className="pa--2 ba br1 br--left b--near-white hoverhighlight w15 tc" onClick={this.addBonus.bind(this,1)}>+1</div>
                <div className="pa--2 br br1 br--right bt bb b--near-white hoverhighlight w15 tc" onClick={this.addBonus.bind(this,-1)}>-1</div>
                <div className="pa--2 br2 mh--2 minw2 bg-white-10">{signedNum(counts.bonus||0)}</div>
                <span className="ml1 pa--2 br1 ba b--near-white hoverhighlight f5" onClick={this.doRoll.bind(this)}>ROLL</span>
            </div>
        </div>;
    }

    doRoll() {
        if (this.props.doRoll) {
            this.props.doRoll(this.state.dieCounts);
            this.setState({dieCounts:null});
        }
    }

    setMinus(minus) {
        const dieCounts = Object.assign({}, this.state.dieCounts||{});
        if (minus) {
            dieCounts.minus=true;
        } else {
            delete dieCounts.minus;
        }
        this.setState({dieCounts});
    }

    addDice(d) {
        const dieCounts = Object.assign({}, this.state.dieCounts||{});
        dieCounts[d] = (dieCounts[d]||0)+1;
        this.setState({dieCounts});
    }

    addBonus(inc) {
        const dieCounts = Object.assign({}, this.state.dieCounts||{});
        dieCounts.bonus = (dieCounts.bonus||0)+inc;
        this.setState({dieCounts});
    }
}

function doRoll(counts) {
    const rolls = [];
    let sum=0;
    let min = counts.min||0;
    const dieTypesFound = getDieTypesFromDice(counts);

    for (let i in dieTypesFound){
        const d = dieTypesFound[i];
        const fc = counts[d]||0;
        const c = Math.abs(fc);
        const sign = fc/c;

        const sides = sidesFromDieType(d);
        for (let x=0; x<c; x++) {
            const r= Math.max(Math.min(min,sides), dicerandom(sides))*sign;
            sum=sum+r;
            rolls.push(r);
        }
    }
    if (counts.bonus){
        rolls.push(counts.bonus);
        sum=sum+counts.bonus;
    }
    if (counts.extraBonus) {
        rolls.push(counts.extraBonus);
        sum=sum+counts.extraBonus;
    }
    return {rolls,sum};
}

function sidesFromDieType(dieType) {
    return Number(dieType.substr(1))
}

function diceParse(dice, stats, estats) {
    let odice=dice;
    if (typeof stats !== 'undefined') {
        dice = replaceMetawords(dice, stats, estats);
    }
    const ret = {dice:[], named_dice:[]};

    dice = dice.trim();
    dice = dice.replace(/- */g,'+ -');
    dice = dice.replace(/D/,'d');
    let items = dice.split(/ *\+ */);
    let sign = 1;
    for ( var i=0; i<items.length; i++) {
        let ni = items[i];
        var match = ni.match(/^[ \t]*(-)?(\d+)?(?:(d)(\d+))?[ \t]*$/);
        if (match && (ni.length || items.length>1)) {
            if (match[1]) {
                sign=sign*-1;
            }
            if (match[3]) {
                const num = parseInt(match[2] || "1");
                const max = parseInt(match[4] || "0");
                ret.dice.push([sign, num, max]);
                ret.named_dice.push(sign * num + 'd' + max);
                sign=1;
            } else if (match[2]) {
                const num = parseInt(match[2]);
                ret.dice.push([sign, num]);
                ret.named_dice.push(sign * num);
                sign=1;
            }
        } else if (ni.match(/^\s*-?d\s*$/)){
            sign=1;
        } else {
            return ret;
        }
    }
    return ret;
}

function getDiceFromString(str, extraBonus, noAutoD20, stats, estats) {
    let bonus=0;
    const counts={};

    str = str.toString().toLowerCase().trim();
    if (str) {
        if (str.startsWith("{@dice ")) {
            const info = getDiceInfo("@dice",str.substring(7,str.length-1));
            str = info.toRoll;
        }
        if (!noAutoD20 && str.match(/^[\+\-]\d+$/)) {
            counts.D20=1;
            counts.bonus = Number(str);
            return counts;
        }
        const mins = str.match(/m\d+\b/ig);
        for (let i in mins) {
            const m = mins[i];
            counts.min = Math.max(counts.min||0, Number(m.substr(1)));
            str = str.replace(m,"");
        }
        const {dice}= diceParse(str,stats, estats);
        for (let i in dice) {
            const d=dice[i];
            switch (d.length) {
                case 3:{
                    const dType = "D"+d[2];
                    if (dType != "D0") {
                        counts[dType] = (counts[dType]||0)+(d[0]*d[1]);
                    }
                    break;
                }
                case 2:{
                    bonus = (bonus||0)+(d[0]*d[1]);
                    break;
                }
            }
        }
    }
    counts.bonus = bonus||0;
    counts.extraBonus = extraBonus||0;
    return counts;
}

function getAverageFromDice(counts,allowZero) {
    let average = 0;
    for (let d in counts) {
        let c = counts[d];
        let mult=1;
        if (d.startsWith("D")) {
            mult = (Number(d.substr(1)||0)+1)/2;
        }
        average += (mult*c);
    }
    if (average<0) {
        average = 0;
    }
    return Math.trunc(average)||(allowZero?0:1);
}

function getStringFromDice(counts) {
    let res="";
    const dieTypesFound = getDieTypesFromDice(counts);

    for (let i in dieTypesFound){
        const d = dieTypesFound[i];
        const c = counts[d]||0;
        if (c) {
            if (res.length) {
                res=res+signedNum(c)+d;
            } else {
                res = c+d;
            }
        }
    }
    const sum = (counts.bonus||0)+(counts.extraBonus||0);
    if (sum){
        res=res+signedNum(sum);
    }
    if (counts.min) {
        res = res + " m"+counts.min;
    }
    return res.toLowerCase();
}

const maxDiePosLoop = 1000;
let diePosLoop = maxDiePosLoop;
let dieArrayList = new Uint32Array(maxDiePosLoop);

function dicerandom(n) {
    if (!n) {
        return 0;
    }
    if (window.crypto) {
        if (maxDiePosLoop == diePosLoop) {
            window.crypto.getRandomValues(dieArrayList);
            diePosLoop=0;
        }
        const ret= (dieArrayList[diePosLoop] % n)+1;
        diePosLoop++;
        return ret;
    }
    return Math.trunc(Math.random()*n)+1;
}

function getRollLastActions(count) {
    const {Chat} = require('../lib/chat.js');
    const chatList = Chat.getFilteredChatList(campaign.getChat());
    const found=[];

    for (let i=(chatList.length-1); (i>=0) && (found.length < count); i--) {
        const c = chatList[i];
        if (["roll","droll"].includes(c.type)) {
            const {roll}=c;
            const {canAdvantage} = roll;

            const sum = getRollSum(roll);
            const spell = getHrefSpellInfo(c.href || c.roll?.sourceHref||null);

            if (!canAdvantage && sum) {
                found.push({chat:c, roll, sum, saveAbility:c.saveAbility, saveVal:c.saveVal, noHalfDamage:spell&&!spell.level});
            }
        }
    }
    return found;
}

function getHrefSpellInfo(href) {
    const page = getLocationInfo(href||"");
    if ((page.page == "spell") && page.id) {
        return campaign.getSpell(page.id);
    }
}

function getRollSum(roll) {
    const {rolls, damages} = roll;
    let sum=0;

    for (let i in rolls){
        sum+= rolls[i];
    }
    for (let i in damages) {
        sum += getRollSum(damages[i]);
    }
    if (roll.extraRolls) {
        for (let r of roll.extraRolls) {
            for (let i in r.rolls){
                sum+= r.rolls[i];
            }
        
        }
    }
    return sum;
}

function getDamagesStr(damages) {
    const dmgList = [];
    for (let i in damages) {
        dmgList.push(damages[i]?.dmg||"");
    }
    return dmgList.join("+");
}

function damagesFromExtraDamage(dmg, dmgType, extraDamage) {
    const damages = [];
    if (extraDamage[dmgType]) {
        dmg=(dmg||"")+"+"+extraDamage[dmgType];
    }
    if (extraDamage.default) {
        dmg=(dmg||"")+"+"+extraDamage.default;
    }
    if (dmg) {
        damages.push({dmgType:Parser.DMGTYPE_JSON_TO_FULL[dmgType]||dmgType||null, dmg});
    }
    for (let i in Parser.DMGTYPE_JSON_TO_FULL) {
        const e = extraDamage[i];
        if (e && (i != dmgType) && (i != "default")) {
            const dt = Parser.DMGTYPE_JSON_TO_FULL[i];
            damages.push({dmgType:dt||null, dmg:extraDamage[i]});
        }
    }
    return damages.length?damages:null;
}

export {
    DiceRollChat,
    DiceRollD20,
    DiceDamageRollChat,
    dicerandom,
    doRoll,
    getDiceFromString,
    DiceMenu,
    DiceValue,
    defaultDiceURL,
    getStringFromDice,
    DicePopup,
    getRollLastActions,
    getRollSum,
    DiceRoller,
    getActionFromDamages,
    getActionFromDmgType,
    actionColors,
    damagesFromExtraDamage,
    getDieTypesFromDice,
    sidesFromDieType,
    getAdjustedDieType,
    offsetsFromDieRoll,
    getCorrectedDiceUrl,
    getAverageFromDice,
    getHrefSpellInfo
};